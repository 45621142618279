import {
    BufferGeometry,
    Mesh,
    Vector3,
    Color,
    MeshStandardMaterial,
    MeshBasicMaterial,
    LineBasicMaterial,
    MeshPhongMaterial,
    Line,
    Line3,
    Plane,
    Group,
    BufferAttribute,
    DoubleSide,
    LineSegments,
    Ray,
    Float32BufferAttribute,
    LineLoop,
    FileLoader,
    ObjectLoader,
    SphereGeometry,
    Box3
} from 'three';

import { cloudAnsysCM } from "./cloudmsh"

class cloudAnsysCM2D extends cloudAnsysCM {
    constructor(parent, inputFile = false) {
        // this.inputFile = inputFile;
        super(parent, inputFile);
        return this;
    }
    beforeInit() {
        super.beforeInit();
    }
    afterInit(that) {
        super.afterInit(that);
        let nodes;
        for (let i in this.data.source.nodes) {
            nodes = this.data.source.nodes[i];
        }
        let MM=this.getNodesMM(nodes);
        let uv = this.createOneBiGUV(this.data.forDraw.pointOfTrangleAll, nodes, false, MM);
        console.log(uv);
        this.geometry.allTriangle.setAttribute('uv', new Float32BufferAttribute(new Float32Array(uv), 2));
    }
    getNodesMM(nodes)
    {
        let max={x:false,y:false,z:false};
        let min={x:false,y:false,z:false};
        for(let i in nodes){
            let perOne=nodes[i];
            if(max.x===false){
                max.x=perOne[0];
                max.y=perOne[1];
                max.z=perOne[2];
                min.x=perOne[0];
                min.y=perOne[1];
                min.z=perOne[2];
                continue;
            }
            if(perOne[0]>max.x){
                max.x=perOne[0];
            }
            if(perOne[0]<min.x){
                min.x=perOne[0];
            }
            if(perOne[1]>max.y){
                max.y=perOne[1];
            }
            if(perOne[1]<min.y){
                min.y=perOne[1];
            }
            if(perOne[2]>max.z){
                max.z=perOne[2];
            }
            if(perOne[2]<min.z){
                min.z=perOne[2];
            }
        }
        return {max,min};
    }
    createOneBiGUV(points, nodes, pressures, boxSize) {
        let uv = [];
        let addX, addY;
        if (boxSize.min.x <= 0) {
            addX = 0 - boxSize.min.x;
        }
        else if (boxSize.min.x > 0) {
            addX = -boxSize.min.x;
        }
        if (boxSize.min.y <= 0) {
            addY = 0 - boxSize.min.y;
        }
        else if (boxSize.min.y > 0) {
            addY = -boxSize.min.y;
        }
        let maxX = boxSize.max.x + addX;
        let maxY = boxSize.max.y + addY;
        let xScale=maxX>=maxY?1:(maxX/maxY);
        let yScale=maxX>=maxY?(maxY/maxX):1;
        for(let perPoint of points){
            let x=((nodes[perPoint][0] + addX)/maxX)*xScale;
            let y=((nodes[perPoint][1] + addY)/maxY)*yScale;
            uv.push(x,y);
            if(perPoint >7590){
                let abc=1;
            }
        }

        return uv;
    }
    createOneBigTexture(points, nodes, pressures, boxSize) {

    }

}

export { cloudAnsysCM2D }