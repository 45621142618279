// version conttour v.1.0
//功能：1、gid的msh与res显示
//      2、云图的变形与时间历程
import {
    BufferGeometry,
    Mesh,
    Vector3,
    Color,
    MeshStandardMaterial,
    MeshBasicMaterial,
    LineBasicMaterial,
    MeshPhongMaterial,
    PointsMaterial,
    Line,
    Line3,
    Plane,
    Group,
    BufferAttribute,
    DoubleSide,
    FrontSide,
    LineSegments,
    Ray,
    Float32BufferAttribute,
    LineLoop,
    FileLoader,
    ObjectLoader,
    ArrowHelper,
    CatmullRomCurve3,
    SplineCurve,
    Vector2,
    Points,
    Triangle
} from 'three';
import triangulate from "delaunay-triangulate";


let that;

class flowLineRoot {
    constructor(value) {
        that = this;
        this.parent = value.parent;
        this.scene = this.parent.parent.scene;
        this.render = this.parent.parent.render;
        this.value = value;



        this.data = {
            flowSetting: {},
            mesh: {
                abc: {
                    source: {//传入值      
                        part: "asdfa",
                        nodes: [],
                        hexahedron: [[], []],
                        res: [],
                        martix: [],
                    },

                    triangle: {//需要initial
                        //     triangles: {},//元素类型，内部为对象（顶点排序的三角形）
                        //     lines: {},//对象，或者改为MAP，顶点排序，内容为数组
                        //     points: [],//array（4），x,y,z,pressure,//对象赋值，
                    },
                    output: {//计算的
                        lines: [],//线的集合，首尾相连，值为index
                        values: {},//等值点，{0.1[],01.2:[]}
                    }
                }
            },
        };



        this.data.flowSetting = value.flowSetting;
        this.data.meshs = value.meshs;

        if (typeof value.flowSetting.step != 'undefined')
            this.pointSize = value.flowSetting.step.distance / 1500;


        //class内使用，减少new的量，通过赋值进行复用
        this.ray = new Ray();
        this.p1 = new Vector3();//A
        this.p2 = new Vector3();//B
        this.p3 = new Vector3();//C
        this.p4 = new Vector3();//D，交点

        this.meshGroupOfTimeOfArray = [];
        this.meshGroupOfTime = new Group();
        this.meshGroupOfTime.name = "TimeOfFlowLines"
        for (let m of this.data.flowSetting.matrix)
            this.meshGroupOfTime.applyMatrix4(m);

        this.NoOfTimerOfPlay = 0;
        this.timerOfPlay = false;

        this.ray_1 = new Ray(new Vector3(), new Vector3());
        this.ray_2 = new Ray(new Vector3(), new Vector3());
        this.ray_x1 = new Ray(new Vector3(0, 0, 0), new Vector3(1, 0, 0));
        this.ray_x2 = new Ray(new Vector3(0, 0, 0), new Vector3(-1, 0, 0));
        this.ray_y1 = new Ray(new Vector3(0, 0, 0), new Vector3(0, 1, 0));
        this.ray_y2 = new Ray(new Vector3(0, 0, 0), new Vector3(0, -1, 0));
        this.ray_z1 = new Ray(new Vector3(0, 0, 0), new Vector3(0, 0, 1));
        this.ray_z2 = new Ray(new Vector3(0, 0, 0), new Vector3(0, 0, -1));
        this.rays = [this.ray_x1, this.ray_x2, this.ray_y1, this.ray_y2, this.ray_z1, this.ray_z2];

        this.ray_temp = new Ray();

        this.plane_x = new Plane();
        this.plane_y = new Plane();
        this.plane_z = new Plane();

        this.point_zero = new Vector3(0, 0, 0);
        this.point_1 = new Vector3();
        this.point_2 = new Vector3();
        this.point_3 = new Vector3();
        this.point_4 = new Vector3();
        this.point_5 = new Vector3();
        this.point_6 = new Vector3();
        this.point_xy = new Vector3();
        this.dir = new Vector3(0.7, 0.7, -0.7);

        this.tri_x = new Triangle();
        this.tri_y = new Triangle();
        this.tri_z = new Triangle();

        this.tri_Ray = new Triangle();

        this.deepOfHex = 6;
        this.deepOfOneStep = 6;
        this._init();
    }

    _init() {
        this.beforeInit();
        this.init();
        this.afterInit();
    }
    afterInit() { }
    beforeInit() { }

    init() {
        this.makeTriangles();//三角化
        let setting = this.data.flowSetting;
        if (setting.meshType == "INP") {
            this.seedRayTraceing(this.data.flowSetting.input);
        }
        else if (setting.Dimension == 2 && setting.meshType == "GID") {
            this.initTriData2D();//三角的pressure的等值化，nodes的赋值
        }
        this.show(that.NoOfTimerOfPlay);
    }
    play(secend = 200) {

        if (that.timerOfPlay != false) {
            window.clearInterval(that.timerOfPlay)  // 去除定时器    
        }
        that.timerOfPlay = window.setInterval(function () {
            for (let perOne of that.meshGroupOfTimeOfArray) {
                perOne.visible = false
            }

            that.meshGroupOfTimeOfArray[that.NoOfTimerOfPlay++].visible = true;
            if (that.NoOfTimerOfPlay > that.meshGroupOfTimeOfArray.length - 1) {
                that.NoOfTimerOfPlay = 0;
            }
            that.parent.parent.render();
        }, secend)

    }
    stop() {
        window.clearInterval(that.timerOfPlay)  // 去除定时器
    }

    show(No = 0) {
        if (this.meshGroupOfTimeOfArray.length > 0) {
            this.meshGroupOfTimeOfArray[No].visible = true;
            this.parent.scene.add(this.meshGroupOfTime);
            this.parent.parent.render();
        }
    }


    // only doing triangle and hex27(tetr) of mesh for hexahedron or Tetrahedron
    makeTriangles() {
        let setting = this.data.flowSetting;
        if (setting.meshType == "INP") {
            this.makeTrianlgesOfHexadedron();//三角化
        }
        else if (setting.Dimension == 2 && setting.meshType == "GID") {
            this.makeTrianlgesOfGID2D();
        }

    }
    makeTrianlgesOfGID2D() {
        let meshs = this.data.meshs;
        for (let Mi in meshs) {//part 的 element 循环
            let perMesh = meshs[Mi];
            let perSource = perMesh.source;
            let perElement = perSource.quad;
            perMesh.triangles = {
                triangles: {},//元素类型，内部为对象（顶点排序的三角形）
                lines: {},//对象，或者改为MAP，顶点排序，内容为数组；//等值处理用
                points: [],//array(4)=x,y,z,pressure,
            };
            perMesh.output = {//计算的
                lines: {},//线的集合，首尾相连，值为index
                values: {},//等值点，{0.1[],01.2:[]}
            }

            for (let Ei in perElement) {
                for (let ei in perElement[Ei]) {
                    let element = perElement[Ei][ei];
                    let e1 = element[0];//element 的点序列，矩形体
                    let e2 = element[1];
                    let e3 = element[2];
                    let e4 = element[3];
                    //12345678顺序是abquas的
                    let triAll = [
                        [e1, e2, e4],
                        [e2, e3, e4],
                    ];
                    for (let side of triAll) {
                        let per = JSON.parse(JSON.stringify(side)).sort().join("-");
                        if (typeof perMesh.triangles.triangles[per] == 'undefined') {
                            perMesh.triangles.triangles[per] = {
                                points: side,
                                enable: true,
                            };
                        }
                    }//end of for of triAll
                    // perMesh.trianglesOfHExahdron.hex27[per] = this.computeHex27(element);}
                }//end of for of perElement content
                let abc = 1;
            }// end of for of perElement 
        }// end of for of Meshs
    }
    makeTrianlgesOfTetrahedron() {
    }
    makeTrianlgesOfHexadedron() {
        let meshs = this.data.meshs;

        for (let Mi in meshs) {//part 的 element 循环
            let perMesh = meshs[Mi];
            let perSource = perMesh.source;
            let perElement = perSource.hexahedron;
            perMesh.triangles = {
                triangles: [],
                lines: {},//对象，或者改为MAP，顶点排序，内容为数组；//等值处理用
                points: [],//array(4)=x,y,z,pressure,
                hex27: [],//index同element index,基于8个顶点的27hex
            };
            perMesh.output = {//计算的
                lines: {},//线的集合，首尾相连，值为index
                values: {},//等值点，{0.1[],01.2:[]}
            }

            let triTEMP = {};
            for (let Ei in perElement) {

                for (let ei in perElement[Ei]) {
                    let element = perElement[Ei][ei];
                    let e1 = element[0];//element 的点序列，矩形体
                    let e2 = element[1];
                    let e3 = element[2];
                    let e4 = element[3];
                    let e5 = element[4];
                    let e6 = element[5];
                    let e7 = element[6];
                    let e8 = element[7];
                    //12345678顺序是abquas的

                    let box = [e1, e2, e3, e4, e5, e6, e7, e8];
                    for (let per of box) {
                        let eiINT = parseInt(ei);
                        let alreadyHas = false;
                        if (typeof perMesh.triangles.hex27[per] == "undefined")
                            perMesh.triangles.hex27[per] = [];
                        for (let hex of perMesh.triangles.hex27[per]) {
                            if (hex == eiINT) {
                                alreadyHas = true
                            }
                        }
                        if (alreadyHas == false) {
                            perMesh.triangles.hex27[per].push(eiINT);
                        }
                    }//end of for of triAll
                }//end of for of perElement content
            }// end of for of perElement 

        }// end of for of Meshs
    }



    ///////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////
    // 2D 等值化算法
    //初始化等值
    initTriData2D() {
        for (let Mi in this.data.meshs) {
            let perMesh = this.data.meshs[Mi];
            let nodes = perMesh.source.nodes;
            let points = nodes;
            let tris = perMesh.triangles.triangles
            let lines = perMesh.triangles.lines;
            for (let TimeOfRes_i in perMesh.source.res) {
                if (TimeOfRes_i != "type") {
                    ////////////////////////////////////////////////////////////////
                    //需要每次重新初始化的值
                    nodes.splice(perMesh.source.nodesLength, nodes.length - perMesh.source.nodesLength);
                    perMesh.output = {//计算的
                        lines: {},//线的集合，首尾相连，值为index
                        values: {},//等值点，{0.1[],01.2:[]}
                    }
                    ///////////////////////////////////////////////////////////

                    let PDefault = this.data.meshs[Mi].source.res[TimeOfRes_i][this.data.flowSetting.pressuresName.default];
                    perMesh.triangles.lines = this.initTrianglesLinesData(tris, nodes, Mi, PDefault);

                    this.sametriangleS();//等值点输出
                    this.samepointS();//等值线排序输出
                    this.draw();//一个timer的线集合
                }
            }
            let abc = 1;
        }
    }
    //初始化每个instance的三角形的等值
    //nodes 发生变化
    initTrianglesLinesData(tris, nodes, instanceName, PDefault) {
        let step = this.data.flowSetting.perStep;
        // let PDefault = this.data.meshs[instanceName].source.res[1][this.data.flowSetting.pressuresName.default];
        let lines = {};
        for (let Ei in tris) {
            let perTri = tris[Ei];
            let perLine = false;
            if (perTri.enable) {
                let A = perTri.points[0];
                let B = perTri.points[1];
                let C = perTri.points[2];
                let PA = PDefault[A];
                let PB = PDefault[B];
                let PC = PDefault[C];
                nodes[A][3] = PA;
                nodes[B][3] = PB;
                nodes[C][3] = PC;

                let sameValuesInTriangle = [];
                if (typeof lines[[A, B].sort().join(',')] == 'undefined') {
                    perLine = this.computeABStep(A, B, nodes);
                    if (typeof perLine != 'undefined' && perLine.length) {
                        lines[[A, B].sort().join(',')] = perLine;
                    }
                }
                if (typeof lines[[A, C].sort().join(',')] == 'undefined') {
                    perLine = this.computeABStep(A, C, nodes);
                    if (typeof perLine != 'undefined' && perLine.length) {
                        lines[[A, C].sort().join(',')] = perLine;
                    }
                }

                if (typeof lines[[B, C].sort().join(',')] == 'undefined') {
                    perLine = this.computeABStep(B, C, nodes);
                    if (typeof perLine != 'undefined' && perLine.length) {
                        lines[[B, C].sort().join(',')] = perLine;
                    }
                }

            }
        }
        return lines;
    }

    //计算AB两点间的等值（等值，数量，位置）
    computeABStep(A1, B1, nodes) {
        let nodesLength = nodes.length;
        let points = [];
        let step = this.data.flowSetting.PStep;
        let perStep = this.data.flowSetting.perStep;
        let Max = this.data.flowSetting.Pmax;
        let Min = this.data.flowSetting.Pmin;


        let PA1 = nodes[A1][3];
        let PB1 = nodes[B1][3];
        let A, B;

        if (PA1 < PB1) {
            A = A1;
            B = B1;
        }
        else {
            A = B1;
            B = A1;
        }

        let PA = nodes[A][3];
        let PB = nodes[B][3];

        this.p1.x = nodes[A][0];
        this.p1.y = nodes[A][1];
        this.p1.z = nodes[A][2];

        this.p2.x = nodes[B][0];
        this.p2.y = nodes[B][1];
        this.p2.z = nodes[B][2];

        let DAB = this.p1.distanceTo(this.p2);

        let NA = Math.floor(PA / perStep);
        let NB = Math.floor(PB / perStep);

        if (NA == NB && NA != 0) {
            if (PA % perStep == 0) {
                return [A];
            }
            else if (PB % perStep == 0) {
                return [B];
            }
            else {
                return [];
            }
        }
        else {
            let IA, IB, IAB, IPA, IPB;

            this.ray.origin.x = nodes[A][0];
            this.ray.origin.y = nodes[A][1];
            this.ray.origin.z = nodes[A][2];

            this.ray.direction.x = nodes[B][0] - nodes[A][0];
            this.ray.direction.y = nodes[B][1] - nodes[A][1];
            this.ray.direction.z = nodes[B][2] - nodes[A][2];

            this.ray.direction.normalize();

            for (let i = NA + 1; i <= NB; i++) {
                let C = perStep * i;
                let D = (C - PA) / (PB - PA);
                if (D < 0 || D > 1) { console.log("A,B:", A, B, "perStep=", perStep, "NA,NB:", NA, NB, "PA,PB:", PA, PB, "C=", C, "D=", D, "DAB=", DAB) }
                else {
                    if (D != 0 && D != 1) {
                        this.ray.at(DAB * D, this.p3);
                        nodes[++nodesLength] = [this.p3.x, this.p3.y, this.p3.z, C];
                        points.push(nodesLength);
                    }
                    else if (D == 0) {
                        points.push(A);
                    }
                    else if (D == 1) {
                        points.push(B);
                    }
                }
            }
        }
        return points;
    }
    /////////////////////////////////////////////////////////////////////////////////
    //等值点输出
    sametriangleS() {
        for (let Mi in this.data.meshs) {
            let perMesh = this.data.meshs[Mi];
            let nodes = perMesh.source.nodes;
            let lines = perMesh.triangles.lines;
            let triangles = perMesh.triangles.triangles;
            let output = perMesh.output;
            for (let Ei in triangles) {
                let perTriangle = triangles[Ei];
                let pointsOfSameValue = {};//每个三角形的等值点
                if (perTriangle.enable) {
                    let triangleLines = [
                        [perTriangle.points[0], perTriangle.points[1]],
                        [perTriangle.points[0], perTriangle.points[2]],
                        [perTriangle.points[1], perTriangle.points[2]],
                    ]
                    let abc = 1;
                    // console.log(perTriangle, triangleLines)
                    for (let PLi in triangleLines) {//每个边
                        let perLine = triangleLines[PLi];

                        if (typeof lines[perLine.sort().join(',')] == 'undefined') {//被排除的边
                            let abc = 1;
                            // console.log("perLine:", perLine);
                        }
                        else
                            for (let onePoint of lines[perLine.sort().join(',')]) {
                                // console.log(onePoint)
                                if (typeof pointsOfSameValue[nodes[onePoint][3]] == 'undefined') {
                                    pointsOfSameValue[nodes[onePoint][3]] = [];
                                }
                                pointsOfSameValue[nodes[onePoint][3]].push(onePoint);
                            }
                    }
                    for (let PVi in pointsOfSameValue) {//等值点
                        let perValue = pointsOfSameValue[PVi];
                        if (perValue.length > 1) {
                            if (typeof output.values[PVi] == 'undefined') {
                                output.values[PVi] = [];
                            }
                            output.values[PVi].push(perValue);
                        }
                    }
                }

            }

        }
    }
    //等值线排序输出
    samepointS() {
        for (let Mi in this.data.meshs) {
            let perMesh = this.data.meshs[Mi];
            let nodes = perMesh.triangles.points;
            let lines = perMesh.triangles.lines;
            let triangles = perMesh.triangles.triangles;
            let output = perMesh.output;
            // let aaa = this.data.line.values;
            for (let perVi in output.values) {//等值数组,两个点的集合
                let perValue = output.values[perVi];
                let newPerValue = JSON.parse(JSON.stringify(perValue));//deep copy of per value
                output.lines[perVi] = [];//等值线数组
                for (let oneLine of perValue) {//逐个组进行
                    newPerValue = this.makeLine(oneLine, newPerValue, output.lines[perVi]);
                }
            }
        }
    }

    //做每条等值线线，排序,将连续的点压入数组
    /*
    online:[],两个点，每个三角形内的等值点
    perArray:删除排序后的数组集合
    lineForDraw:等值线集合（按照每个等值）
    */
    makeLine(oneLine, perArray, linesForDraw) {
        let A = oneLine[0];
        let B = oneLine[1];

        if (this.compareArray(oneLine, perArray[0])) {//比较循环的顺序点与剩余点的起始是否相同，同：没有使用过，否：已经用
            let pointsOfOneLine = [A, B];
            perArray.splice(0, 1);
            for (let i = 0; i < perArray.length - 1; i++) {
                let perPoint = perArray[i];
                let X = perPoint[0];
                let Y = perPoint[1];
                let flag = false;
                // let newArray = [];
                if (A == X) {
                    flag = true;
                    // newArray = [Y, X];
                    pointsOfOneLine.unshift(Y);
                    A = Y;
                }
                else if (A == Y) {
                    flag = true;
                    // newArray = [X, Y];
                    pointsOfOneLine.unshift(X);
                    A = X;
                }
                else if (B == X) {
                    flag = true;
                    // newArray = [X, Y];
                    pointsOfOneLine.push(Y);
                    B = Y;
                }
                else if (B == Y) {
                    flag = true;
                    // newArray = [Y, X];
                    pointsOfOneLine.push(X);
                    B = X;
                }
                if (flag) {
                    perArray.splice(i, 1);
                    i = 0;
                }
            }
            linesForDraw.push(pointsOfOneLine);
            return perArray;
        }
        else {
            return perArray;
        }
    }
    compareArray(a1, a2) {
        if (a1 === a2) return true;
        if ((!a1 && a2) || (a1 && !a2)) return false;//？忘了
        if (a1.length !== a2.length) return false;//长度
        for (var i = 0, n = a1.length; i < n; i++) {//逐个比较
            if (a1[i] !== a2[i]) return false;//出现不同
        }
        return true;
    }
    //
    draw() {
        let newGroup = new Group();

        let material;
        if (typeof this.data.flowSetting.lineColor.vertexColors != 'undefined' && this.data.flowSetting.lineColor.vertexColors == true) {
            material = new LineBasicMaterial({
                vertexColors: true,
            });
        }
        else {
            material = new LineBasicMaterial({
                color: this.data.flowSetting.lineColor.color,
            });
        }
        for (let Mi in this.data.meshs) {
            let perMesh = this.data.meshs[Mi];
            let output = perMesh.output;
            let lines = output.lines;
            let nodes = perMesh.source.nodes;
            let j = 0;
            for (let i in lines) {
                let meshGroupOfValues = new Group();
                newGroup.add(meshGroupOfValues);

                let perGroup = lines[i];
                for (let perLi in perGroup) {
                    let perLine = perGroup[perLi];
                    if (perLine.length > 1) {
                        let pointsCatmullRom = [];
                        let pressures = [];
                        let colors = [];
                        for (let perPointIndex of perLine) {
                            let perPoint = nodes[perPointIndex];
                            pointsCatmullRom.push(new Vector3(perPoint[0], perPoint[1], perPoint[2]));
                            pressures.push(new Vector2(perPoint[3], 0));
                        }

                        const curve = new CatmullRomCurve3(pointsCatmullRom);
                        const points = curve.getPoints(pointsCatmullRom.length * 6);
                        let geometry = new BufferGeometry().setFromPoints(points);

                        if (typeof this.data.flowSetting.lineColor.vertexColors != 'undefined' && this.data.flowSetting.lineColor.vertexColors == true) {
                            const curve2DofPreesures = new SplineCurve(pressures);
                            const point2DofPreesures = curve2DofPreesures.getPoints(pressures.length * 6);
                            for (let spline_i of point2DofPreesures) {
                                let oneColor = this.parent.parent.LUT.lut.getColor(spline_i.x);
                                colors.push(oneColor.r, oneColor.g, oneColor.b);
                            }
                            geometry.setAttribute('color', new Float32BufferAttribute(colors, 3));
                        }

                        let mesh = new Line(geometry, material);

                        meshGroupOfValues.add(mesh);

                        // break;
                    }
                }

            }

        }
        newGroup.visible = false;
        this.meshGroupOfTime.add(newGroup);
        this.meshGroupOfTimeOfArray.push(newGroup);

    }

    /////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////
    //3D
    /////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////
    //等值点输出
    // input: {
    //     startPosition: [
    //       [-15.5, 0, 0],
    //       [1, 0, 0],
    //     ], //this.parent.box3.min.x;//数值偏移量  //固定的，后期需要改变
    //     endPosition: [
    //       [-9.5, 0, 0],
    //       [1, 0, 0],
    //     ], //-this.parent.box3.max.x;//固定的，后期需要改变
    //     countOfSeed: 10,
    //     positionOfSeed:"center",// center ,rondam 
    //     interpolation: 0.05,
    //     interpolationCount: 10,
    //   },

    //开始raytracing
    seedRayTraceing(input = false) {
        // return;
        this.data.lines = [];
        this.mainMesh = this.data.meshs["water-1"];



        let stepV = this.data.flowSetting.step.pressureStepLenght;
        let stepS = this.data.flowSetting.step.distanceStepLenght;
        let pressures = this.mainMesh.source.res;
        let nodes = this.mainMesh.source.nodes;
        for (let pressure_i in pressures) {

            let oneOfPressure = pressures[pressure_i];
            let seeds = this.initSeed(2, oneOfPressure);
            let i = 1;
            for (let oneSeed of seeds) {
                console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>seed:", i, '>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>');
                let line = this.stepsOfSeed(oneSeed, oneOfPressure, stepS, stepV, nodes);
                console.log("<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<seed ", i++, " life end <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<", line);
                this.data.lines.push(line);
                this.drawLine(line);
            }
        }
    }
    /////////////////////////////////////////////////////////////////////////////////////////////////////////
    // seed
    // seed = {
    //     xyz: [ok.x, ok.y, ok.z],
    //     pressure: pressure,
    //     dir: dir,
    //     index:false,
    //     parent:[1,2,3]
    // }
    initSeed(n, pressures) {
        let p = 0;

        let xyz = this.data.flowSetting.input.startPosition[0];
        let dir = this.data.flowSetting.input.startPosition[1];
        let lines = this.mainMesh.source.lines;
        let nodes = this.mainMesh.source.nodes;
        this.mainMesh.source.startLayer = this.getOneLayersPoints(xyz, dir, lines, nodes, pressures);

        let abc = [];
        for (let i = 0; i < this.mainMesh.source.startLayer.points.length - 1; i += 100) {
            abc.push(this.mainMesh.source.startLayer.points[i])
        }
        return abc;
        // return this.mainMesh.source.startLayer.points;
        // return [this.mainMesh.source.startLayer.points[100], this.mainMesh.source.startLayer.points[150], this.mainMesh.source.startLayer.points[380], this.mainMesh.source.startLayer.points[500]];
    }

    // 初始切片
    getOneLayersPoints(xyz, dir, lines, nodes, pressures) {
        let startPosition = -15.5//this.parent.box3.min.x;//数值偏移量  //固定的，后期需要改变
        let currentPosition = - startPosition;
        let currentPlane = new Plane(new Vector3(dir[0], dir[1], dir[2]), -startPosition);
        //初始化一次，减少计算量
        let p1 = new Vector3();
        let p2 = new Vector3();
        let line = new Line3(p1, p2);
        let p3 = new Vector3();

        let perLayer = {//每个切片数据结构
            plane: {
                normal: [currentPlane.normal.x, currentPlane.normal.y, currentPlane.normal.z],
                constant: currentPosition,
            },
            pointsHash: {},
            points: [
                // {
                //     xyz: [],
                //     pressure: 0,
                //     dir: [],
                //     normOfVector: 0,//模
                //     selfIsNode: false,//true/false
                //     parent: {
                //         points: [],
                //         percent: 0.1,//to points[0]
                //     }
                // }
            ],
            triangles: [],//cell
            points2D: [],
        };

        for (let line_i in lines) {//每个实例化体的线集合
            let perLine = lines[line_i];
            if (perLine.enable == true) {//新是否重复，目前已经失去作用，但结构保留
                //不考虑矩阵偏移，模型偏移，最后进行统一处理，这里只处理原始数据
                let pi1 = perLine.points[0];
                let pi2 = perLine.points[1];

                p1.x = nodes[pi1][0];
                p1.y = nodes[pi1][1];
                p1.z = nodes[pi1][2];

                p2.x = nodes[pi2][0];
                p2.y = nodes[pi2][1];
                p2.z = nodes[pi2][2];

                line = new Line3(p1, p2);
                let isIntersect = currentPlane.intersectLine(line, p3);//交点
                if (isIntersect != null) {//相交
                    if ((p3 == p1) || (p3 == p2)) {//共平面
                        this.formatPoint2Layer(perLayer, pi1, false, p1, pressures);
                        this.formatPoint2Layer(perLayer, false, pi2, p2, pressures);
                    }
                    else {
                        this.formatPoint2Layer(perLayer, pi1, pi2, p3, pressures, p1, p2);
                    }
                }
            }// end of enable 
        }// end of all lines of per instance 

        perLayer.triangles = triangulate(perLayer.points2D);
        return perLayer;
    }

    //切片成的相交线或共面线的值计算，保存到oneLayer
    formatPoint2Layer(oneLayer, pi1, pi2, p3, pressures, p1 = false, p2 = false) {
        let xyzString = [p3.x, p3.y, p3.z].join('_');
        if (typeof oneLayer.pointsHash[xyzString] == 'undefined') {
            let vv1 = pressures[this.data.flowSetting.pressuresName.V1];
            let vv2 = pressures[this.data.flowSetting.pressuresName.V2];
            let vv3 = pressures[this.data.flowSetting.pressuresName.V3];
            let norm = pressures[this.data.flowSetting.pressuresName.norm];
            let dir = [];
            let point;

            if (pi1 == false || pi2 == false) {
                dir = [
                    pi1 ? vv1[pi1] : vv1[pi2],
                    pi1 ? vv2[pi1] : vv3[pi2],
                    pi1 ? vv3[pi1] : vv3[pi2],
                ];
                point = {
                    xyz: [p3.x, p3.y, p3.z],
                    pressure: pi1 ? norm[pi1] : norm[pi2],
                    dir: dir,
                    normOfVector: pi1 ? norm[pi1] : norm[pi2],//模
                    parentIsSelf: true,//true/false
                    parent: {
                        index: pi1 ? pi1 : pi2,
                    }
                };
            }
            else {
                let disOfp1p2 = p1.distanceTo(p2);
                let disOfp1V1 = p1.distanceTo(p3);
                let disOfp2V1 = p2.distanceTo(p3);

                dir = [
                    (1 - disOfp1V1 / disOfp1p2) * vv1[pi1] + disOfp1V1 / disOfp1p2 * vv1[pi2],
                    (1 - disOfp1V1 / disOfp1p2) * vv2[pi1] + disOfp1V1 / disOfp1p2 * vv2[pi2],
                    (1 - disOfp1V1 / disOfp1p2) * vv3[pi1] + disOfp1V1 / disOfp1p2 * vv3[pi2],
                ];
                let C = (1 - disOfp1V1 / disOfp1p2) * norm[pi1] + disOfp1V1 / disOfp1p2 * norm[pi2];

                point = {
                    xyz: [p3.x, p3.y, p3.z],
                    pressure: C,
                    dir: dir,
                    parent: [pi1, pi2],
                };
            }
            oneLayer.points.push(point)
            oneLayer.points2D.push(this.caculate2D(oneLayer, p3));
            oneLayer.pointsHash[xyzString] = point;
        }
    }

    //切面的二维点，需要改进，目前是简单的抛弃x值
    caculate2D(oneLayer, p3) {
        // return this._get2DxyOfLayer(layer, p3);
        return [p3.y, p3.z];
    }
    ////////////////////////////////////////////////////////////////////////////////////////////
    //单点逐步计算
    stepsOfSeed(seed, pressures, stepS, stepV, nodes, step = 1) {
        console.log("--------------------THis is step ", step, "-------------------------------")
        this.drwaSeed(seed);
        let newSeed = this.oneStepOfRayIntersectOfTriangles(seed, pressures, stepS, stepV, nodes);
        // console.log(newSeed);
        if (newSeed) {
            let end = this.stepsOfSeed(newSeed, pressures, stepS, stepV, nodes, ++step);
            return [seed].concat(end);
        }
        else {
            return [seed];
        }

    }


    //单步seed的增长值，双因子，步长，值长
    //通过值长，自动迭代，找到合格的下一步种子，步长每次衰减50%
    oneStepOfRayIntersectOfTriangles(seed, pressures, stepS, stepV, nodes, deep = 0) {
        if (deep > this.deepOfOneStep) {
            console.log("00000000000000000000000000000000000000000000000// return deep is MAX  \\\ 000000000000000000000000000000000000000000", seed, stepS, stepV, deep)
            return false;
        }
        else {
            // console.log("deep=", deep, "seed pressure=", seed.pressure, "步长", stepS, "值长", stepV);
        }
        //1、获取lines，this.hex2lines(getHexsOfPoints(A,B,C));
        //2、获取3个切面和point 2，this.getPlanyXYZ(onePoint, dir, stepValue)
        //3、Z面的线面相交点集合 this.getPointsOfLineIntersectPlane(onePlane, lines, nodes)
        //4、三角剖分(两种方法：A、三角剖分后，在ray求交点；B、三角剖分后，求点P4在三角形内，线段和最小)
        //5、seed的point 2的三角形权重，dir,preesure
        //6、pressure是否超限，没有返回，
        //7、超，则迭代

        this.drwaSeed(seed);

        let p3p = this.getPlanyXYZ(seed.xyz, seed.dir, stepS);

        let PT;
        if (deep == 0)
            PT = this.getPointsOfLineIntersectPlane(seed, seed.parent, p3p, nodes, pressures);
        else
            PT = this.getPointsOfLineIntersectPlane(seed, seed.parent, p3p, nodes, pressures, 0, false, 0x003366 + deep * 0x00002222);
        if (PT == false) {
            console.log("end of seed(doesn't have next seed)");
            return false;
        }
        // console.log("PT=", PT);
        let points = PT.points;
        let pointsOfTheTriangle = PT.pointsOfTheTriangle[0];
        let nextPointOfseed = PT.pointsOfTheTriangle[1];
        // console.log("points of lines", points);
        this.drawPoint(p3p[3].x, p3p[3].y, p3p[3].z, 0xffffff);
        this.drawOneLine(new Vector3(seed.xyz[0], seed.xyz[1], seed.xyz[2]), p3p[3], 0xffffff);


        let xy2 = this.getOnePointXYOfPlaneOf2D(nextPointOfseed, p3p);
        let weighted = this.getWeightedValeOfTriangle(xy2, points[pointsOfTheTriangle[0]].xy, points[pointsOfTheTriangle[1]].xy, points[pointsOfTheTriangle[2]].xy);
        // console.log("uv:", weighted);

        let pressure = (1 - weighted.u - weighted.v) * points[pointsOfTheTriangle[0]].pressure + weighted.u * points[pointsOfTheTriangle[1]].pressure + weighted.v * points[pointsOfTheTriangle[2]].pressure;

        let valueCheckForStepV = Math.abs(pressure - seed.pressure);
        // console.log("compaer of stepv and new value ,", stepV, valueCheckForStepV);
        if (valueCheckForStepV > stepV) {
            console.log("444444444444444444444444444444444444444444444444444 call self for stepV");
            // console.log("deep=",deep,"目标点 值=",pressure,"种子 值（第一次迭代）=", seed.pressure,"值差=", valueCheckForStepV,"新步长=", stepS, stepV);
            // console.log(deep,seed.pressure, stepS, stepV);
            return this.oneStepOfRayIntersectOfTriangles(seed, pressures, stepS * .25, stepV, nodes, ++deep);
        }
        else {
            let normal = new Vector3();
            normal.x = (1 - weighted.u - weighted.v) * points[pointsOfTheTriangle[0]].dir[0] + weighted.u * points[pointsOfTheTriangle[1]].dir[0] + weighted.v * points[pointsOfTheTriangle[2]].dir[0];
            normal.y = (1 - weighted.u - weighted.v) * points[pointsOfTheTriangle[0]].dir[1] + weighted.u * points[pointsOfTheTriangle[1]].dir[1] + weighted.v * points[pointsOfTheTriangle[2]].dir[1];
            normal.z = (1 - weighted.u - weighted.v) * points[pointsOfTheTriangle[0]].dir[2] + weighted.u * points[pointsOfTheTriangle[1]].dir[2] + weighted.v * points[pointsOfTheTriangle[2]].dir[2];
            normal.normalize();
            let dir = [normal.x, normal.y, normal.z];

            let parentObject = {};//最多会有6个点
            for (let perOne of pointsOfTheTriangle) {
                let perPoint = points[perOne];
                for (let perParentIndex of perPoint.parent)
                    parentObject[perParentIndex] = perParentIndex;
            }
            let point = {
                xyz: [this.point_4.x, this.point_4.y, this.point_4.z],
                pressure: pressure,
                dir: dir,
                parent: parentObject,//index of nodes(orgin)
            };

            // this.drawHexs(hexs);
            // this.drawPoints(points,0x0000ff);
            this.drawTrigangles([pointsOfTheTriangle], points);


            this.drawOneLine(new Vector3(seed.xyz[0], seed.xyz[1], seed.xyz[2]), this.point_4, 0x000000);
            this.drawPoint(this.point_4.x, this.point_4.y, this.point_4.z, 0xff0000);

            // console.log("p3p:", p3p);
            // console.log("line:", seed.xyz[0], seed.xyz[1], seed.xyz[2], p3p[3].x, p3p[3].y, p3p[3].z);


            // console.log("5555555555555555555555555555555555555555555555555555555555555555555555555555555555555555 one seed return :", point)
            return point;
        }
    }
    //获取一个点在平面上的投影，point = vertor3()
    getOnePointXYOfPlaneOf2D(point, planes) {
        let x = planes[0].distanceToPoint(point);
        let y = planes[1].distanceToPoint(point);
        return [x, y];
    }
    updatePointOfPlaneOf2D(points, planes) {
        for (let i in points) {
            let perOne = points[i];
            this.point_xy.x = perOne.xyz[0];
            this.point_xy.y = perOne.xyz[1];
            this.point_xy.z = perOne.xyz[2];
            perOne.xy = [];
            perOne.xy[0] = planes[0].distanceToPoint(this.point_xy);
            perOne.xy[1] = planes[1].distanceToPoint(this.point_xy);
        }
    }
    // 计算三角形内任意点(p4)的权重
    getWeightedValeOfTriangle(p4, p1, p2, p3) {
        //需要一个投影变换

        let a1 = p1[0], b1 = p1[1];
        let a2 = p2[0], b2 = p2[1];
        let a3 = p3[0], b3 = p3[1];
        let a4 = p4[0], b4 = p4[1];

        let u = -(a1 - a3) * (a1 * b2 - a1 * b4 - a2 * b1 + a2 * b4 + a4 * b1 - a4 * b2) / (a1 - a2) * (a1 * b2 - a1 * b3 - a2 * b1 + a2 * b3 + a3 * b1 - a3 * b2) + (a1 - a4) / (a1 - a2);
        let v = (a1 * b2 - a1 * b4 - a2 * b1 + a2 * b4 + a4 * b1 - a4 * b2) / (a1 * b2 - a1 * b3 - a2 * b1 + a2 * b3 + a3 * b1 - a3 * b2);

        if (a1 - a2 == 0) {
            console.log("error:a1-a2=0");
        }
        if ((a1 * b2 - a1 * b3 - a2 * b1 + a2 * b3 + a3 * b1 - a3 * b2) == 0) {
            console.log("error:(a1 * b2 - a1 * b3 - a2 * b1 + a2 * b3 + a3 * b1 - a3 * b2) =0");
        }
        //直角三角形，不适合勾股
        if (isNaN(u)) {
            let abc = 1
            u = 0;
        }
        if (isNaN(v)) {
            let abc = 1
            v = 0;
        }
        return { u: u, v: v };
    }

    drawLine(value) {

    }
    //////////////////////////
    //三平面空间切割发
    //使用class中的构建函数中定义的three的点线面变量，减少初始化与计算
    getPlanyXYZ(onePoint, dir, stepValue) {

        let max = this.data.flowSetting.step.distance * 2;
        this.point_1.x = onePoint[0];
        this.point_1.y = onePoint[1];
        this.point_1.z = onePoint[2];

        this.dir.x = dir[0];
        this.dir.y = dir[1];
        this.dir.z = dir[2];

        //点2
        this.ray_1.set(this.point_1, this.dir);
        this.ray_1.at(stepValue, this.point_2);
        //点3
        this.ray_1.closestPointToPoint(this.point_zero, this.point_3);

        //反向
        if (this.point_1.x == this.point_3.x && this.point_1.y == this.point_1.y && this.point_1.z == this.point_3.z) {
            this.ray_1.set(this.point_1, this.dir.divideScalar(-1));//反向
            this.ray_1.at(stepValue, this.point_2);
            this.ray_1.closestPointToPoint(this.point_zero, this.point_3);//点三
        }
        let p2tozeo = this.point_2.distanceTo(this.point_zero);
        let p1tozeo = this.point_1.distanceTo(this.point_zero);
        let p1top2 = this.point_1.distanceTo(this.point_2);

        if (p1tozeo == p1top2 + p2tozeo) {//dir，point1,zero在一条直线上
            this.plane_z.set(this.dir, p1tozeo + p1top2);

            for (let ray of this.rays) {
                if (ray.intersectPlane(this.plane_z, this.point_3)) {
                    this.tri_x.set(this.point_3, this.point_zero, this.point_2);
                    this.tri_x.getPlane(this.plane_x);
                    this.plane_x.constant += max;
                    break;
                }
            }
            //点4
            this.plane_x.projectPoint(this.point_zero, this.point_4);
            this.tri_y.set(this.point_4, this.point_zero, this.point_2);
            this.tri_y.getPlane(this.plane_y);
            this.plane_y.constant += max;
        }
        else {
            this.tri_y.set(this.point_2, this.point_3, this.point_zero);
            this.tri_y.getPlane(this.plane_y);
            this.plane_y.constant += max;

            this.plane_y.projectPoint(this.point_2, this.point_4);

            this.tri_x.set(this.point_4, this.point_2, this.point_3);

            this.tri_x.getPlane(this.plane_x);
            this.plane_x.constant += max;

            this.plane_x.projectPoint(this.point_2, this.point_5);
            this.tri_z.set(this.point_5, this.point_4, this.point_2);

            this.tri_z.getPlane(this.plane_z);
            // this.plane_z.direction += max;

        }
        this.ray_1.set(this.point_1, this.dir);
        this.ray_1.at(stepValue, this.point_2);
        return [this.plane_x, this.plane_y, this.plane_z, this.point_2];
    }
    //获取abc的hex群
    getHexsOfPoints(parent) {
        let HeXs = this.mainMesh.triangles.hex27;
        let hexOfABC = [];
        for (let i in parent) {
            hexOfABC.push(HeXs[parent[i]]);
        }
        let ABCObject = [];
        let ABC = [];
        for (let perGroup of hexOfABC) {
            for (let perhex of perGroup) {
                ABCObject[perhex] = perhex;
            }
        }
        for (let i in ABCObject) {
            ABC.push(ABCObject[i]);
        }
        return ABC;
    }
    getVectorOfHex27(hexs) {
        let hexsOfAll = this.mainMesh.source.hexahedron[0];
        let pointsOfHexs = {}
        for (let oneHex of hexs) {

            let element = hexsOfAll[oneHex];
            let e1 = element[0];//element 的点序列，矩形体
            let e2 = element[1];
            let e3 = element[2];
            let e4 = element[3];
            let e5 = element[4];
            let e6 = element[5];
            let e7 = element[6];
            let e8 = element[7];

            pointsOfHexs[e1] = e1;
            pointsOfHexs[e2] = e2;
            pointsOfHexs[e3] = e3;
            pointsOfHexs[e4] = e4;
            pointsOfHexs[e5] = e5;
            pointsOfHexs[e6] = e6;
            pointsOfHexs[e7] = e7;
            pointsOfHexs[e8] = e8;
        }
        let vectors = [];
        for (let i in pointsOfHexs) {
            vectors.push(pointsOfHexs[i]);
        }
        return vectors;
    }
    //获取hexs的所有线，返回对象
    hex2lines(hexs) {
        let lines = {};
        let hexsOfAll = this.mainMesh.source.hexahedron[0];
        let nodes = this.mainMesh.source.nodes;
        for (let oneHex of hexs) {

            let element = hexsOfAll[oneHex];
            let e1 = element[0];//element 的点序列，矩形体
            let e2 = element[1];
            let e3 = element[2];
            let e4 = element[3];
            let e5 = element[4];
            let e6 = element[5];
            let e7 = element[6];
            let e8 = element[7];

            let lineAll = [
                [e1, e4], [e4, e3], [e3, e2], [e2, e1],//right
                [e5, e8], [e8, e7], [e7, e6], [e6, e5],//left
                [e5, e1], [e2, e6], [e3, e7], [e4, e8],//other
            ];


            for (let per of lineAll) {
                let perLine = JSON.parse(JSON.stringify(per)).sort().join("-");
                lines[perLine] = per;
            }
        }
        return lines;
    }
    getPointsOfLineIntersectPlane(seed, parent, p3p, nodes, pressures, deep = 0, plus = false, color = 0x00ff00) {

        let onePlane = p3p[2];
        if (deep > this.deepOfHex && plus != true) {
            return false;
        }
        if (deep > this.deepOfHex * 2 && plus == true) {
            return false;
        }
        let hexs = this.getHexsOfPoints(parent);

        // this.drawPointsOfHex(hexs, 0x0000ff);

        // if (deep > 0)
        //     this.drawHexs(hexs);
        let lines = this.hex2lines(hexs);
        // console.log("hexs and lines", hexs, lines);

        let points = [];
        let point = [];
        let dir = new Vector3;
        let oneLine = new Line3();
        let p1 = new Vector3();
        let p2 = new Vector3();
        let p3 = new Vector3();
        let vv1 = pressures[this.data.flowSetting.pressuresName.V1];
        let vv2 = pressures[this.data.flowSetting.pressuresName.V2];
        let vv3 = pressures[this.data.flowSetting.pressuresName.V3];
        let norm = pressures[this.data.flowSetting.pressuresName.default];
        for (let i in lines) {
            let perLine = lines[i];
            let pi1 = perLine[0];
            let pi2 = perLine[1];
            p1.x = nodes[pi1][0];
            p1.y = nodes[pi1][1];
            p1.z = nodes[pi1][2];

            p2.x = nodes[pi2][0];
            p2.y = nodes[pi2][1];
            p2.z = nodes[pi2][2];

            oneLine.set(p1, p2);
            if (onePlane.intersectLine(oneLine, p3)) {
                if (color != 0x00ff00)
                    this.drawPoint(p3.x, p3.y, p3.z, color);
                else if (deep > 0)
                    this.drawPoint(p3.x, p3.y, p3.z, color);

                if (p1.x == p3.x && p1.y == p3.y && p1.z == p3.z) {
                    dir.x = vv1[pi1];
                    dir.y = vv2[pi1];
                    dir.z = vv3[pi1];
                    dir.normalize();
                    point = {
                        xyz: [p3.x, p3.y, p3.z],
                        pressure: norm[p1],
                        dir: [dir.x, dir.y, dir.z],
                        parent: [pi1],

                    };
                    points.push(point);
                }
                else if (p2.x == p3.x && p2.y == p3.y && p2.z == p3.z) {
                    dir.x = vv1[pi2];
                    dir.y = vv2[pi2];
                    dir.z = vv3[pi2];
                    dir.normalize();
                    point = {
                        xyz: [p3.x, p3.y, p3.z],
                        pressure: norm[p2],
                        dir: [dir.x, dir.y, dir.z],
                        parent: [pi2],
                    };
                    points.push(point);
                }
                else {
                    let disOfp1p2 = p1.distanceTo(p2);
                    let disOfp1V1 = p1.distanceTo(p3);
                    let disOfp2V1 = p2.distanceTo(p3);

                    dir.x = (1 - disOfp1V1 / disOfp1p2) * vv1[pi1] + disOfp1V1 / disOfp1p2 * vv1[pi2];
                    dir.y = (1 - disOfp1V1 / disOfp1p2) * vv2[pi1] + disOfp1V1 / disOfp1p2 * vv2[pi2];
                    dir.z = (1 - disOfp1V1 / disOfp1p2) * vv3[pi1] + disOfp1V1 / disOfp1p2 * vv3[pi2];
                    dir.normalize();
                    let C = (1 - disOfp1V1 / disOfp1p2) * norm[pi1] + disOfp1V1 / disOfp1p2 * norm[pi2];

                    point = {
                        xyz: [p3.x, p3.y, p3.z],
                        pressure: C,
                        dir: [dir.x, dir.y, dir.z],
                        parent: [pi1, pi2],
                    };
                    points.push(point);
                }
            }
        }

        if (points.length == 0) {
            let parentOfNewHex27 = this.getVectorOfHex27(hexs);
            return this.getPointsOfLineIntersectPlane(seed, parentOfNewHex27, p3p, nodes, pressures, ++deep);
        }
        else {
            let xy = this.getOnePointXYOfPlaneOf2D(p3p[3], p3p);
            this.updatePointOfPlaneOf2D(points, p3p);
            let triangles = this.getTrianglesOf2D(points);

            //这个基本可能性比较小
            if (triangles.length == 0) {
                console.log("2222222222222222222222222222222222222222222222222222222  return false by triangle.length");
                return false;
            }
            /** No. of points index 
             * [
             *  [1,2,3],
             *  [4,5,6]
             * ]
             */

            let pointsOfTheTriangle = this.getTriangleOfTargetByRay(triangles, points, seed);
            // console.log("pointsOfTheTriangle", pointsOfTheTriangle);
            // let xy2 = this.getOnePointXYOfPlaneOf2D(p3p[3], p3p);
            // console.log("two kind compute of next seed position :", seed.xyz, this.point_4.x, this.point_4.y, this.point_4.z, "xy1:xy2", xy, xy2);
            if (pointsOfTheTriangle == false && triangles.length != 0) {
                let parentOfNewHex27 = this.getVectorOfHex27(hexs);
                return this.getPointsOfLineIntersectPlane(seed, parentOfNewHex27, p3p, nodes, pressures, ++deep, true);
            }
            else if (pointsOfTheTriangle == false) {
                console.log("pointsOfTheTriangle,triangles", pointsOfTheTriangle, triangles);
                return false;
            }
            else if (pointsOfTheTriangle) {
                return { points: points, pointsOfTheTriangle: pointsOfTheTriangle };
            }
            else {
                console.log("没有相交的三角形，但存在三角形剖分返回值");
                return false;
            }
        }
    }

    getTrianglesOf2D(points) {
        let point2d = [];
        for (let i in points) {
            point2d.push(points[i].xy);
        }
        let cell = triangulate(point2d);
        return cell;
    }
    getTriangleOfTargetByRay(triangles, points, seed) {
        let index = false;
        this.point_5.x = seed.xyz[0];
        this.point_5.y = seed.xyz[1];
        this.point_5.z = seed.xyz[2];

        this.point_6.x = seed.dir[0];
        this.point_6.y = seed.dir[1];
        this.point_6.z = seed.dir[2];

        this.ray_temp.set(this.point_5, this.point_6);

        for (let i in triangles) {
            // console.log(i);
            let perTri = triangles[i];

            this.point_1.x = points[perTri[0]].xyz[0];
            this.point_1.y = points[perTri[0]].xyz[1];
            this.point_1.z = points[perTri[0]].xyz[2];

            this.point_2.x = points[perTri[1]].xyz[0];
            this.point_2.y = points[perTri[1]].xyz[1];
            this.point_2.z = points[perTri[1]].xyz[2];

            this.point_3.x = points[perTri[2]].xyz[0];
            this.point_3.y = points[perTri[2]].xyz[1];
            this.point_3.z = points[perTri[2]].xyz[2];

            // this.tri_Ray.set(this.point_1,this.point_2,this.point_3);


            let abc = this.ray_temp.intersectTriangle(this.point_1, this.point_2, this.point_3, false, this.point_4);
            if (abc) {
                index = i;
                // console.log(this.point_4);
                break;
            }
        }
        if (index == false) {
            return false;
        }
        else {
            return [triangles[index], this.point_4];
        }

    }
    getTriangleOfTargetByAdd(triangles, points, p4, xy) {
        for (let perOne of points) {
            this.point_1.x = perOne.xyz[0];
            this.point_1.y = perOne.xyz[1];
            this.point_1.z = perOne.xyz[2];
            perOne.distance = this.point_1.distanceTo(p4);
        }
        let max = [];
        for (let perOne of triangles) {
            max.push(points[perOne[0]].distance + points[perOne[1]].distance + points[perOne[2]].distance);
        }
        let index = 0;
        let no = max[0];
        for (let i in max) {
            if (max[i] > no) {
                no = max[i];
                index = i;
            }
        }
        return triangles[index];
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //可视化辅助
    drwaSeed(seed) {
        let geometry = new BufferGeometry();
        let points = [];
        points.push(seed.xyz[0], seed.xyz[1], seed.xyz[2]);
        geometry.setAttribute('position', new Float32BufferAttribute(points, 3));
        geometry.name = "seed";
        let mesh = new Points(geometry, new PointsMaterial({ color: 0xff0000, size: this.pointSize }));
        mesh.name = "seed";
        for (let m4 of this.data.flowSetting.matrix) {
            mesh.applyMatrix4(m4);
        }
        for (let m4 of this.mainMesh.source.matrix) {
            mesh.applyMatrix4(m4);
        }
        this.parent.worldBox.add(mesh);
    }
    drawPoint(x, y, z, colorD = false) {
        let geometry = new BufferGeometry();
        let points = [];
        points.push(x, y, z);
        geometry.setAttribute('position', new Float32BufferAttribute(points, 3));
        geometry.name = "point";
        let color = new Color(0, Math.random(), Math.random());
        // console.log(color.getHexString());
        let mesh = new Points(geometry, new PointsMaterial({ color: colorD != false ? colorD : color, size: this.pointSize }));
        mesh.name = "point";
        for (let m4 of this.data.flowSetting.matrix) {
            mesh.applyMatrix4(m4);
        }
        for (let m4 of this.mainMesh.source.matrix) {
            mesh.applyMatrix4(m4);
        }
        this.parent.worldBox.add(mesh);
    }
    drawPoints(points, color = 0x0000ff) {
        for (let perOne of points) {
            this.drawPoint(perOne.xyz[0], perOne.xyz[1], perOne.xyz[2], color);
        }
    }
    drawTriangle(p1, p2, p3) {
        let geometry = new BufferGeometry();
        let points = [];
        points.push(p1.xyz[0], p1.xyz[1], p1.xyz[2]);
        points.push(p2.xyz[0], p2.xyz[1], p2.xyz[2]);
        points.push(p3.xyz[0], p3.xyz[1], p3.xyz[2]);
        geometry.setAttribute('position', new Float32BufferAttribute(points, 3));
        geometry.name = "triangle";
        let mesh = new Mesh(geometry, new MeshBasicMaterial({ color: new Color(Math.random(), Math.random(), Math.random()), side: DoubleSide/*, transparent: true, opacity: .9*/ }));
        mesh.name = "triangle";
        for (let m4 of this.data.flowSetting.matrix) {
            mesh.applyMatrix4(m4);
        }
        for (let m4 of this.mainMesh.source.matrix) {
            mesh.applyMatrix4(m4);
        }
        this.parent.worldBox.add(mesh);
        // console.log(mesh);
    }
    drawTrigangles(triangles, points) {
        for (let perOne of triangles) {
            this.drawTriangle(points[perOne[0]], points[perOne[1]], points[perOne[2]]);
        }
    }
    drawPointsOfHex(hexarray, color = 0x0000ff) {
        let hexsOfAll = this.mainMesh.source.hexahedron[0];
        let nodes = this.mainMesh.source.nodes;
        if (typeof this.pointsOfHex == 'undefined') {
            this.pointsOfHex = {};
        }
        for (let hex of hexarray) {
            let element = hexsOfAll[hex];
            for (let i in element) {
                let perE = element[i];
                if (typeof this.pointsOfHex[perE] == 'undefined') {
                    this.pointsOfHex[perE] = perE;
                    this.drawPoint(nodes[perE][0], nodes[perE][1], nodes[perE][2], color);
                }
            }

        }
    }
    drwaHex(hex) {
        if (typeof this.hexForDraw == 'undefined') {
            this.hexForDraw = {};
        }
        let geometry = new BufferGeometry();
        let points = [];

        let hexsOfAll = this.mainMesh.source.hexahedron[0];
        let nodes = this.mainMesh.source.nodes;

        let element = hexsOfAll[hex];
        let e1 = element[0];//element 的点序列，矩形体
        let e2 = element[1];
        let e3 = element[2];
        let e4 = element[3];
        let e5 = element[4];
        let e6 = element[5];
        let e7 = element[6];
        let e8 = element[7];
        let triAll = [
            // [e5, e1, e6],
            // [e1, e2, e6],//front
            // [e8, e4, e7],
            // [e4, e3, e7],//back                
            // [e6, e2, e7],
            // [e2, e3, e7],//top
            // [e5, e1, e8],
            // [e1, e4, e8],//down      
            // [e8, e5, e7],
            // [e5, e6, e7],//L
            [e4, e1, e3],
            [e1, e2, e3],//R           
        ];
        for (let per of triAll) {
            let perString = per.sort().join(",");
            if (typeof this.hexForDraw[perString] == 'undefined') {
                this.hexForDraw[perString] = perString;
                for (let i of per)
                    points.push(nodes[i][0], nodes[i][1], nodes[i][2]);
            }
        }
        geometry.setAttribute('position', new Float32BufferAttribute(points, 3));
        geometry.name = "hex";
        let mesh = new Mesh(geometry, new MeshBasicMaterial({ color: new Color(Math.random(), Math.random(), Math.random()), side: DoubleSide, transparent: true, opacity: .31 }));
        mesh.name = "hex";
        for (let m4 of this.data.flowSetting.matrix) {
            mesh.applyMatrix4(m4);
        }
        for (let m4 of this.mainMesh.source.matrix) {
            mesh.applyMatrix4(m4);
        }
        this.parent.worldBox.add(mesh);
    }
    drawHexs(hexarray) {

        for (let i of hexarray) {

            this.drwaHex(i);

        }
    }
    drawOneLine(p1, p2, color) {
        const material = new LineBasicMaterial({
            color: color
        });

        let points = [];
        points.push(p1);
        points.push(p2);

        const geometry = new BufferGeometry().setFromPoints(points);
        geometry.name = "line";
        const mesh = new Line(geometry, material);
        mesh.name = "line";
        for (let m4 of this.data.flowSetting.matrix) {
            mesh.applyMatrix4(m4);
        }
        for (let m4 of this.mainMesh.source.matrix) {
            mesh.applyMatrix4(m4);
        }
        this.parent.worldBox.add(mesh);
    }
}

export { flowLineRoot }
