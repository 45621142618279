// version v.1.0
//功能：1、gid的msh与res显示
//      2、云图的变形与时间历程
import {
    BufferGeometry,
    Mesh,
    Vector3,
    Color,
    MeshStandardMaterial,
    MeshBasicMaterial,
    LineBasicMaterial,
    MeshPhongMaterial,
    Line,
    Line3,
    Plane,
    Group,
    BufferAttribute,
    DoubleSide,
    LineSegments,
    Ray,
    Float32BufferAttribute,
    LineLoop,
    FileLoader,
    ObjectLoader,
    ArrowHelper,
    Box3
} from 'three';

import { inpCM } from "./inpmsh"
import { flowLineContour } from "./flowlinecontour"

let that;
class inpFlowContour extends inpCM {
    // constructor(parent, fun = false) {
    //     super(parent);
    // }
    afterInit() {
        super.afterInit();
        that = this;

        // that = this;
        // console.log(this.zipFile)
        let value = {
            parent: this,
            flowSetting: {
                pressuresName: {
                    V1: "V.V1",
                    V2: "V.V2",
                    V3: "V.V3",
                    norm: "V.Magnitude",
                    default:"norm",
                },
                // type: "C3",
                matrix: [this.mesh.inpGroup.children[0].matrix, this.mesh.inpGroup.matrix],
                PMax: this.parent.LUT.max,
                PMin: this.parent.LUT.min,
                PStep: 10,
                perStep: (this.parent.LUT.max - this.parent.LUT.min) / 10,
            },
            pointOfLines:            //false,
            {
                //     instance: {
                //         line_index: {},//两个点为一条线
                //         // pressures: {},
                //         nodes: {}

                //     }
            },
            originPressures: {
                // x: {},
                // y: {},
                // z: {},
                // norm: {},
            },
            pointsOfOutsideShell: {},//外壳数据点


        }

        for (let i in this.data.res[1]) {
            let pressures = this.data.res[1][i].instance;
            if (i == value.flowSetting.pressuresName.V1) {
                value.originPressures.V1 = pressures;
            }
            if (i == value.flowSetting.pressuresName.V2) {
                value.originPressures.V2 = pressures;
            }
            if (i == value.flowSetting.pressuresName.V3) {
                value.originPressures.V3 = pressures;
            }
            if (i == value.flowSetting.pressuresName.norm) {
                value.originPressures.norm = pressures;
            }
        }
        value.data = this.data;

        this.flowLines = new flowLineContour(value);
        let abc = 1;
    }


    center(objects) {
        /**
 * 包围盒全自动计算：模型整体居中
 */
        var box3 = new Box3()
        // 计算层级模型group的包围盒
        // 模型group是加载一个三维模型返回的对象，包含多个网格模型
        box3.expandByObject(objects)
        // 计算一个层级模型对应包围盒的几何体中心在世界坐标中的位置
        var center = new Vector3()
        box3.getCenter(center)
        // console.log('查看几何体中心坐标', center);

        // 重新设置模型的位置，使之居中。
        objects.position.x = objects.position.x - center.x
        objects.position.y = objects.position.y - center.y
        objects.position.z = objects.position.z - center.z

        // console.log(box3)
        this.box3 = box3;
    }


}

export { inpFlowContour }