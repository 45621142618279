import * as THREE from 'three';
import { VertexNormalsHelper } from 'three/examples/jsm/helpers/VertexNormalsHelper.js';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { TransformControls } from 'three/examples/jsm/controls/TransformControls.js';
import { TrackballControls } from 'three/examples/jsm/controls/TrackballControls.js';
import { second } from "./second.js";
import { LUT_text } from '../LUT/LUT_text.js'

// import { Vector3 } from 'three';

// import { AmmoPhysics } from 'three/examples/jsm/physics/AmmoPhysics.js';
// let that;
class showMesh extends second {

    constructor(value = false, orbit = true) {
        super(value, orbit);
        // that = this;
        return this;
    }

    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    afterInit() {
        super.afterInit();
        // this.physics = await AmmoPhysics();
        // this.physics.addMesh(this.worldBox);
        // this.physics.addMesh(this.perpCamera);
    }
    init() {


        // that = this;
        this.showPoint = {
            isShow: false,
            isShowCallback: false,
            isShowClick: false,
            isShowMove: false,
            isShowInsidPoint: false,
        }
        // const loader = new THREE.FontLoader();
        // loader.load('font/helvetiker_regular.typeface.json', function (font) {
        //     that.font_HV = font;
        // }); //end load function

        this.showPoint.isShow = false;
        this.timeStepCloudMap = {
            nameOfCloudmap: false,
        }
        this.triangleOfSection = new THREE.Group();
        this.needsUpdate = false;

    }

    setClipping(object, enable = false) {
        let that = this;
        if (enable) {
            if (typeof that.localPlane == "undefined") {
                let box3 = object.box3;
                let x = box3.max.x - box3.min.x;
                let y = box3.max.y - box3.min.y;
                let z = box3.max.z - box3.min.z;
                let max = x > y ? x : y;
                max = max > z ? max : z;

                const geometry = new THREE.PlaneGeometry(max * 1.3, max * 1.3, 1, 1);
                const material = new THREE.MeshBasicMaterial({ color: 0x0000ff, side: THREE.DoubleSide, wireframe: true });
                that.plane = new THREE.Mesh(geometry, material);
                that.plane.rotateY(Math.PI)
                that.scene.add(that.plane);
                that.control.attach(that.plane);

                that.rayDirection = new THREE.Vector3(0, 0, 1);
                that.raySource = new THREE.Vector3(0, 0, 0);
                that.ray = new THREE.Ray(that.raySource, that.rayDirection);

                that.rayTriangleA = new THREE.Vector3(that.plane.geometry.attributes.position[0], that.plane.geometry.attributes.position[1], that.plane.geometry.attributes.position[2]);
                that.rayTriangleA.applyMatrix4(that.plane.matrix);
                that.rayTriangleB = new THREE.Vector3(that.plane.geometry.attributes.position[6], that.plane.geometry.attributes.position[7], that.plane.geometry.attributes.position[7]);
                that.rayTriangleB.applyMatrix4(that.plane.matrix);
                that.rayTriangleC = new THREE.Vector3(that.plane.geometry.attributes.position[3], that.plane.geometry.attributes.position[4], that.plane.geometry.attributes.position[5]);
                that.rayTriangleC.applyMatrix4(that.plane.matrix);
                that.rayTriangle = new THREE.Triangle(that.rayTriangleA, that.rayTriangleB, that.rayTriangleC)
                ///////////////////////////////////////////////////////////////////
                that.pointOfNarmal = new THREE.Vector3(0, 0, 1);
                that.localPlane = new THREE.Plane(that.pointOfNarmal, 0);//init clip,固定名称
                that.rayTriangle.getPlane(that.localPlane);
                object.setClipping(that.localPlane);
                that.renderer.localClippingEnabled = true;
            }
            else {
                that.plane.visible = true;
                that.renderer.localClippingEnabled = true;
                that.control.attach(that.plane);
            }
            // that.CM.repairPoly();
            that.Trender(that);
        }
        else {
            that.plane.visible = false;
            that.renderer.localClippingEnabled = false;
            that.control.detach();
            that.CM.setMeshClippingEnalbe(false);
        }
        that.render();
    }
    setFrameLineEnable(value) {
        let that = this;
        if (typeof that.CM.mesh.lines != 'undefined')
            that.CM.mesh.lines.visible = value;
        if (typeof that.CM.mesh.allTriangleLines != 'undefined')
            that.CM.mesh.allTriangleLines.visible = value;
        that.CM.setFramelineEnable(value);//clipping frame lines enable or disable
        that.CM.setting.frameline = value;

        that.render();
    }

    init_UI() {
        this.LUT = new LUT_text(this.DIV.lut, this, "curve", 1024);
        // //console.log(LUT_text_obj);
        //lut = this.LUT.get_lut();
        this.uiScene = this.LUT.get_uiScene();
        this.orthoCamera = this.LUT.get_orthoCamera();
        this.sprite = this.LUT.get_sprite();
    }
    initControls() {
        let that = this;
        this.control = new TransformControls(this.perpCamera, this.renderer.domElement);
        // console.log(this.renderer.domElement);
        // that.control.addEventListener('change', that.Trender);
        that.control.addEventListener('dragging-changed', function (event) {
            that.orbit.enabled = !event.value;
            that.dragging = true;
            that.Trender(that);
        });
        that.control.addEventListener('mouseUp', function (event) {

            that.needsUpdate = false;
            that.Trender(that);
            that.CM.repairPoly();
            that.render(that);
        });
        that.control.addEventListener('mouseDown', function (event) {
            that.needsUpdate = true;
        });
        this.scene.add(this.control);

        if (this.ctl == 'orbit') {
            this.orbit = new OrbitControls(this.perpCamera, this.renderer.domElement);
        }
        else {
            this.orbit = new TrackballControls(this.perpCamera, this.renderer.domElement);
            this.orbit.rotateSpeed = 4.0;
            this.orbit.zoomSpeed = 0.72;
            this.orbit.panSpeed = 0.2;
            this.orbit.dynamicDampingFactor = .35;
        }
        this.orbit.addEventListener('change', function () {
            if (that.showPoint.isShowMove || that.showPoint.isShowClick) {
                if (that.layerFont.isCleaned == false)
                    that.layerFont.clean();
            }
            that.render();
        });
        window.addEventListener('resize', function (event) {
            that._onWindowResize(event, that)
        });
        window.addEventListener('keydown', function (event) {
            that.controlSwitch(event, that);
        });
        this.container.addEventListener('click', function (event) {
            that.onClick(event, that);
        });
        this.container.addEventListener('mousemove', function (event) {
            that.onMouseMove(event, that);
        });
        // this.container.addEventListener('keydown', function (event) { console.log("asdfasdfasdfaf"); });
    }
    controlSwitch(event, that) {
        let code = false;
        if (typeof event.keyCode !== "undefined") code = event.keyCode;
        else code = keyCode;
        // console.log(code)
        if (typeof this.CM.getCurrentCMLevel == 'function') {

            this.CMLevel = this.CM.getCurrentCMLevel();

            // if (event.ctrlKey == true) 
            {
                switch (code) {
                    case 33: // page up
                        let nextL = this.getPreLevel(this.CMLevel[0], this.CMLevel[1]);
                        this.CM.showStep(nextL);
                        this.getFrameNo(nextL);
                        break;
                    case 34: // page down
                        let preL = this.getNextLevel(this.CMLevel[0], this.CMLevel[1]);
                        this.CM.showStep(preL);
                        this.getFrameNo(preL);
                        break;
                    case 87: // W
                        this.control.setMode("translate");
                        this.render();
                        break;

                    case 69: // E
                        this.control.setMode("rotate");
                        this.render();
                        break;

                    case 82: // R
                        this.control.setMode("scale");
                        this.render();
                        break;
                }
            }
        }
    }
    getNextLevel(level, currentLevel) {
        let newLevel = false;
        for (let i = 0; i < level.length; i++) {
            if (currentLevel == level[i]) {
                if (i == level.length - 1) {
                    return level[0];
                }
                else {
                    return level[i + 1];
                }
            }
        }
    }
    getPreLevel(level, currentLevel) {
        let newLevel = false;
        for (let i = 0; i < level.length; i++) {
            if (currentLevel == level[i]) {
                if (i == 0) {
                    return level[level.length - 1];
                }
                else {
                    return level[i - 1];
                }
            }
        }
    }
    updateSection() {
        let that = this;
        that.triangleOfSection.traverse(function (child) {
            that.triangleOfSection.remove(child);
        });
        // let pressure1,pressure2;
        // let distance1,distance2;
        // let p1,p2;
        // let line= new THREE.Line3(p1,p2);
        // let v1= new THREE.Vector3();
        // that.localPlane.intersectLine(line,v1)

    }
    reinitClipData() {
        let that = this;
        that.plane.material.color.r = 0;
        that.plane.material.color.g = 0;
        that.plane.material.color.b = 1;
        that.plane.rotation._x = 0;
        that.plane.rotation._y = 0;
        that.plane.rotation._z = 0;
        that.plane.position.x = 0;
        that.plane.position.y = 0;
        that.plane.position.z = 0;
    }
    Trender(that) {//update for geometry

        if (that.rayTriangle) {
            that.planehelper = new VertexNormalsHelper(that.plane, 1, 0x00ff00);
            let Nx = that.planehelper.geometry.attributes.position.array[3] - that.planehelper.geometry.attributes.position.array[0];
            let Ny = that.planehelper.geometry.attributes.position.array[4] - that.planehelper.geometry.attributes.position.array[1];
            let Nz = that.planehelper.geometry.attributes.position.array[5] - that.planehelper.geometry.attributes.position.array[2]
            let flag = true;
            that.rayTriangleA.x = that.plane.geometry.attributes.position.array[0];
            that.rayTriangleA.y = that.plane.geometry.attributes.position.array[1];
            that.rayTriangleA.z = that.plane.geometry.attributes.position.array[2];
            that.rayTriangleA.applyMatrix4(that.plane.matrix);

            that.rayTriangleB.x = that.plane.geometry.attributes.position.array[6];
            that.rayTriangleB.y = that.plane.geometry.attributes.position.array[7];
            that.rayTriangleB.z = that.plane.geometry.attributes.position.array[8];
            that.rayTriangleB.applyMatrix4(that.plane.matrix);

            that.rayTriangleC.x = that.plane.geometry.attributes.position.array[3];
            that.rayTriangleC.y = that.plane.geometry.attributes.position.array[4];
            that.rayTriangleC.z = that.plane.geometry.attributes.position.array[5];
            that.rayTriangleC.applyMatrix4(that.plane.matrix);

            that.rayTriangle.getPlane(that.localPlane);
            if (Nx > 0) {
                that.plane.material.color.r = 0;
            } else {
                that.plane.material.color.r = 1;
            }
            if (Ny > 0) {
                that.plane.material.color.g = 0;
            } else {
                that.plane.material.color.g = 1;
            }
            if (Nz > 0) {
                that.plane.material.color.b = 0;
            }
            else {
                that.plane.material.color.b = 1;
            }

        }

    }





    onClick(event, that) {
        var x, y;
        if (event.changedTouches) {
            x = event.changedTouches[0].pageX;
            y = event.changedTouches[0].pageY;
        } else {
            x = event.clientX;
            y = event.clientY;
        }
        that.mouse.x = ((x - that.viewpoint_px) / that.viewport_width) * 2 - 1;
        that.mouse.y = - ((y - that.viewpoint_py) / that.viewport_height) * 2 + 1;
        if (that.showPoint.isShowClick) {
            that.raycaster.setFromCamera(that.mouse, that.perpCamera);
            let intersects;
            // intersects = that.raycaster.intersectObjects(that.scene.children[0], true);
            intersects = that.raycaster.intersectObjects(that.pickupArray, false);
            // intersects = that.raycaster.intersectObjects(that.pickupArray, true);
            // let meshPoints = that.scene.getObjectByName('Points');
            // let groupWorldbox = [];
            // that.scene.getObjectByName('worldBox').traverse(function(child){
            //     if(child.type=='Mesh'){
            //         groupWorldbox.push(child);
            //     }
            // })
            // intersects = that.raycaster.intersectObjects(groupWorldbox, false);

            if (intersects.length > 0) {
                let selectObj = intersects[0].object;
                if (selectObj.isPoint == true) {
                    if (typeof that.lightPoint != 'undefined') {
                        // that.lightPoint.material = that.CM.material.pointBox;
                        that.lightPoint.visible = false;
                    }
                    that.lightPoint = selectObj;
                    that.lightPoint.visible = true;


                    that.showOnePointInfo(selectObj);
                    that.render();
                }
                else {
                    // that.render();
                }
                let abc = 1

            } else {

            }

        }
    }
    onMouseMove(event, that) {
        var x, y;
        if (event.changedTouches) {
            x = event.changedTouches[0].pageX;
            y = event.changedTouches[0].pageY;
        } else {
            x = event.clientX;
            y = event.clientY;
        }
        that.mouse.x = ((x - that.viewpoint_px) / that.viewport_width) * 2 - 1;
        that.mouse.y = - ((y - that.viewpoint_py) / that.viewport_height) * 2 + 1;
        // console.log(x, y, that.mouse.x, that.mouse.y)
        if (that.showPoint.isShowMove) {
            that.raycaster.setFromCamera(that.mouse, that.perpCamera);
            let intersects;
            intersects = that.raycaster.intersectObjects(that.pickupArray, false);
            if (intersects.length > 0) {
                let selectObj = intersects[0].object;
                if (selectObj.isPoint == true) {
                    if (typeof that.lightPoint != 'undefined') {
                        that.lightPoint.visible = false;
                    }
                    that.lightPoint = selectObj;
                    that.lightPoint.visible = true;
                    that.showOnePointInfo(selectObj);
                    that.render();
                }
                else {
                    // that.render();
                }
                let abc = 1

            } else {

            }

        }
    }


    //main.switchShowPoint(true,function(info){console.log(info)})
    switchShowPoint(show = true, fun = false) {
        let that = this;
        if (typeof that.lightPoint != 'undefined' && show == false) {
            // that.lightPoint.material = that.CM.material.pointBox;false
            that.lightPoint.visible = false;
        }
        if (typeof this.CM.mesh.Points == 'undefined') {
            this.CM.showPoints(true);
        }
        if (show == false) {
            this.switchClickAndMove(false, true);
            this.layerFont.clean();
            that.lightPoint.visible = false;
            this.render();
        }
        else if (show == true) {
            this.switchClickAndMove('move');
        }
        if (fun) {
            this.showPoint.isShowCallback = fun;
        }

    }
    switchClickAndMove(kind = 'click', isClosed = false) {
        if (kind == 'click') {
            this.showPoint.isShowClick = true;
            this.showPoint.isShowMove = false;
        }
        else if (kind == 'move') {
            this.showPoint.isShowClick = false;
            this.showPoint.isShowMove = true;
        }
        if (isClosed == true) {
            this.showPoint.isShowClick = false;
            this.showPoint.isShowMove = false;
        }
    }
    showOnePointInfo(selectObject, infoOnly = false) {
        let instanceName = selectObject.instanceName;
        let index = selectObject.name
        let info = this.CM.pointInfo(instanceName, index);
        if (infoOnly == false) this.showOenpointIndex(selectObject);
        // console.log(instanceName, index, info);
        this.callTheShowPointFUN(info);
    }
    callTheShowPointFUN(msg) {
        if (typeof this.showPoint.isShowCallback == 'function') {
            this.showPoint.isShowCallback(msg);
        }
        else {
            console.log(msg);
        }
    }
    searchPoint(index, instanceName = false) {
        let that = this;
        let No = parseInt(index);
        let pointOne = false;
        if (typeof this.pickupArray == 'undefined') {
            this.switchShowPoint();
        }
        index = this.CM.isAvailablePoint(instanceName, index);
        if (index) {
            if (typeof that.lightPoint != 'undefined') {
                that.lightPoint.visible = false;
                this.hideInsidePoint();
            }
            for (let perOne of this.pickupArray) {
                if (perOne.name == index) {
                    pointOne = perOne;
                }
            }
            if (pointOne) {
                that.lightPoint = pointOne;
                that.lightPoint.visible = true;
                this.textOfShowPointName = "point index";
                this.layerFont.deleteByName(this.textOfShowPointName);
                let vector = new THREE.Vector3();
                var widthHalf = 0.5 * that.container.offsetWidth;
                var heightHalf = 0.5 * that.container.offsetHeight;
                pointOne.updateMatrixWorld();
                vector.setFromMatrixPosition(pointOne.matrixWorld);
                vector.project(this.perpCamera);
                // console.log(vector);
                // this.layerFont.updateByName(this.textOfShowPointName, index, 0xffffff,vector.x, vector.y);               
                this.layerFont.updateObjectByObjectName({ name: this.textOfShowPointName, msg: index, color: 0xffffff, mesh: pointOne });
                this.render();
                this.showOnePointInfo(pointOne, true);
            }
            else {
                let msg = this.showInsidePoint(instanceName, index);
                if (msg == false) {
                    let msg = "please check the name of instance and the index of point.";
                    // console.log(msg);
                    this.callTheShowPointFUN(msg)
                    return;
                }
            }

        }
    }
    hideInsidePoint() {
        // this.CM.recomputeVertexNormals();
        // this.CM.material.allTriangle.transparent = false;//是否透明
        // this.CM.material.allTriangle.opacity = 1;//透明度
        if (this.showPoint.isShowInsidPoint) {
            this.scene.remove(this.showPoint.isShowInsidPoint);
            delete this.showPoint.isShowInsidPoint;
            this.showPoint.isShowInsidPoint = false;
        }
    }
    showInsidePoint(instanceName, index) {
        if (this.showPoint.isShowInsidPoint) {
            this.scene.remove(this.showPoint.isShowInsidPoint);
            delete this.showPoint.isShowInsidPoint;
            this.showPoint.isShowInsidPoint = false;
        }
        let onePointInfo = this.CM.getOnePointattributes(instanceName, index);

        if (onePointInfo) {
            const geometry = new THREE.SphereGeometry(onePointInfo.radius[0], onePointInfo.radius[1], onePointInfo.radius[2]);

            let mesh = new THREE.Mesh(geometry, onePointInfo.material);
            mesh.position.x = onePointInfo.xyz[0];
            mesh.position.y = onePointInfo.xyz[1];
            mesh.position.z = onePointInfo.xyz[2];

            mesh.name = index;
            mesh.instanceName = instanceName;
            mesh.isPoint = true;
            mesh.visible = true;

            mesh.applyMatrix4(onePointInfo.instaceMatrix);
            mesh.applyMatrix4(onePointInfo.inpMatrix);
            this.showPoint.isShowInsidPoint = mesh;
            this.scene.add(mesh);

            this.showOnePointInfo(mesh, true);
            // this.CM.material.allTriangle.transparent = true;//是否透明
            // this.CM.material.allTriangle.opacity = 0.3;//透明度

            this.layerFont.updateObjectByObjectName({ name: index, msg: "insid:" + index, color: 0xffffff, mesh: mesh });
            this.render();
            return mesh;
        }
        else {
            return false;
        }
    }
    showOenpointIndex(selectObject) {
        this.hideInsidePoint();
        let index = selectObject.name;
        let matrix = this.CM.mesh.Points.matrix;
        let position = selectObject.position;
        this.textOfShowPointName = "point index";
        this.layerFont.updateByName(this.textOfShowPointName, index, 0xffffff, this.mouse.x, this.mouse.y);
    }
    onWindowResize(left = false, top = false, activeWidth = false, activeHeight = false) {
        super.onWindowResize(left, top, activeWidth, activeHeight);
    }
    render() {
        super.render();
    }




}

export { showMesh }
