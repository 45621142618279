// version 1.0 20210818
// 剖分的回显使用
import {
  // BoxGeometry,
  // CircleGeometry,
  BufferGeometry,
  Mesh,
  Vector3,
  Vector2,
  // EllipseCurve,
  Color,
  MeshStandardMaterial,
  MeshBasicMaterial,
  LineBasicMaterial,
  ShaderMaterial,
  MeshPhongMaterial,
  // Line,
  // Group,
  BufferAttribute,
  // FrontSide,
  DoubleSide,
  FileLoader,
  LineSegments,
  Float32BufferAttribute,
  Box3,
  PointsMaterial,
} from "three";
// import { meshColor } from './meshcolor.js'

import JSZip from "jszip";
import JSZipUtils from "jszip-utils";
import { cloudIdb } from "../cloudidb/cloudidb";
// let that;
class baseMesh {
  constructor(parent, zipFile = false) {
    this.zipFile = zipFile;
    this.parent = parent;
    // this.color = new meshColor();
    this.worldBox = parent.worldBox;
    this.scene = parent.scene;
    this.mesh = {};
    this.matrixGroup = {
      main: false,
    };
    this.geometry = {};
    this.farDistance = 600;
    this.farDistanceScale = 0.15;
    if (typeof this.parent.farDistance != "undefined") {
      this.farDistance = this.parent.farDistance;
    }
    this.material = {
      //画边框线的材质
      lineOfPressure: new LineBasicMaterial({
        // color:0xffffff,
        vertexColors: true,
        // polygonOffset: true,
        // polygonOffsetFactor: -1.1,
        // polygonOffsetUnits: -1.5
      }),
      //外框线
      line: new LineBasicMaterial({
        color: 0x000000,

        polygonOffset: true,
        polygonOffsetFactor: -1.1,
        polygonOffsetUnits: -1.5,
      }),
      //画梁等的材质，白色
      OBJline: new LineBasicMaterial({
        vertexColors: true,
        // color: 0xffffff,
        polygonOffset: true,
        polygonOffsetFactor: -1.1,
        polygonOffsetUnits: -1.5,
      }),
      allTriangle: new MeshPhongMaterial({
        vertexColors: true,
        // color: 0xff0000,
        side: DoubleSide,
        // side: FrontSide,
        polygonOffset: true,
        polygonOffsetFactor: 1.5,
        polygonOffsetUnits: 1.5,
      }),
      allTriangleShader: new ShaderMaterial({
        clipping: true,
        side: DoubleSide,
        uniforms: {
          time: { value: 1.0 },
          val: { value: [1.0, 0.0, 0.3] },
          resolution: { value: new Vector2() },
        },
        vertexShader:
          " attribute vec4 color;" +
          " attribute vec3 cm;" +
          " varying vec3 val;" +
          " varying vec2 vuv;  " +
          " varying vec3 vposition;" +
          " void main() { " +
          "  val=cm;" +
          "     vposition = position;" +
          "    vuv = uv;" +
          "    gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );" +
          "   }",
        fragmentShader:
          "varying vec3 val;" +
          "varying vec2 vuv;" +
          "varying vec3 vposition;" +
          "void main() {" +
          "float w1 = (1.0 - vuv.x);" +
          "float w2 = (vuv.x - vuv.y);" +
          "float w3 = vuv.y;" +
          "float v = w1*val[0] + w2*val[1] + w3*val[2];" +
          " if( v <= 0.25 ) {" +
          "     gl_FragColor = vec4(0.0, v/0.25, 1.0, 1.0);" +
          " }else if ( v > 0.25 && v <= 0.5) {" +
          "     gl_FragColor = vec4(0.0, 1.0, 1.0-(v-0.25)/0.25 , 1.0);" +
          " }else if ( v > 0.5 && v <= 0.75) {" +
          "      gl_FragColor = vec4( (v-0.5)/0.25, 1.0, 0.0, 1.0);" +
          " }else{" +
          "      gl_FragColor = vec4( 1.0, 1.0-(v-0.75)/0.25, 0.0, 1.0);" +
          "  }" +
          " } ",

        polygonOffset: true,
        polygonOffsetFactor: 1.5,
        polygonOffsetUnits: 1.5,
      }),
      allTriangleShaderStair: new ShaderMaterial({
        clipping: true,
        side: DoubleSide,
        uniforms: {
          clippingPlanes: {
            needsUpdate: true,
            value: []
          },
          time: { value: 1.0 },
          val: { value: [1.0, 0.0, 0.3] },
          n: { value: 12 },
          resolution: { value: new Vector2() },
        },
        vertexShader:
          `
          #if NUM_CLIPPING_PLANES > 0

          varying vec3 vClipPosition;
        
          uniform vec4 clippingPlanes[ NUM_CLIPPING_PLANES ];
        
        #endif

       


           attribute vec4 color;
           attribute vec3 cm;
           varying vec3 val;
           varying vec2 vuv;  
           varying vec3 vposition;
           void main() { 
            vec3 transformed = vec3( position );
        
            vec4 mvPosition = vec4( transformed, 1.0 );
            
            #ifdef USE_INSTANCING
    
              mvPosition = instanceMatrix * mvPosition;
    
            #endif
    
            mvPosition = modelViewMatrix * mvPosition;

            val=cm;
            vposition = position;
            vuv = uv;
            // gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );

              #if NUM_CLIPPING_PLANES > 0

	              vClipPosition = - mvPosition.xyz;

              #endif


      
              gl_Position = projectionMatrix * mvPosition;

             }
             `
        ,
        fragmentShader:
          `
          #if NUM_CLIPPING_PLANES > 0

            varying vec3 vClipPosition;

            uniform vec4 clippingPlanes[ NUM_CLIPPING_PLANES ];

          #endif

          precision mediump float;
          precision mediump int;   
          uniform int n;
          varying vec3 val;
          varying vec2 vuv;
          varying vec3 vposition;
           void main() {

           #if NUM_CLIPPING_PLANES > 0

            vec4 plane;

            #pragma unroll_loop_start
            for ( int i = 0; i < UNION_CLIPPING_PLANES; i ++ ) {

              plane = clippingPlanes[ i ];
              if ( dot( vClipPosition, plane.xyz ) > plane.w ) discard;

            }
            #pragma unroll_loop_end

            #if UNION_CLIPPING_PLANES < NUM_CLIPPING_PLANES

              bool clipped = true;

              #pragma unroll_loop_start
              for ( int i = UNION_CLIPPING_PLANES; i < NUM_CLIPPING_PLANES; i ++ ) {

                plane = clippingPlanes[ i ];
                clipped = ( dot( vClipPosition, plane.xyz ) > plane.w ) && clipped;

              }
              #pragma unroll_loop_end

              if ( clipped ) discard;

            #endif

          #endif

              float w1 = (1.0 - vuv.x);
              float w2 = (vuv.x - vuv.y);
              float w3 = vuv.y;
              float v = w1*val[0] + w2*val[1] + w3*val[2];
               float dv = 1.0 / float(n);
              int nv = int(v/dv);
              float vv = float(nv)*dv;
              if( vv <= 0.2 ) {
                  gl_FragColor = vec4(0.0, vv/0.2, 1.0, 1.0);
               }else if ( vv > 0.2 && vv <= 0.5) {
                   gl_FragColor = vec4(0.0, 1.0, 1.0-(vv-0.2)/0.3 , 1.0);
              }else if ( vv > 0.5 && vv <= 0.8) {
                  gl_FragColor = vec4( (vv-0.5)/0.3, 1.0, 0.0, 1.0);
              }else{
                  gl_FragColor = vec4( 1.0, 1.0-(vv-0.8)/0.2, 0.0, 1.0);
              }
           }  
           `
        ,
        polygonOffset: true,
        polygonOffsetFactor: 3.5,
        polygonOffsetUnits: 5.5,
      }),
      lineClipping: new LineBasicMaterial({
        color: 0x000000,
        polygonOffset: true,
        polygonOffsetFactor: -1.1,
        polygonOffsetUnits: -5.5,
      }),
      polyOfClipping: new MeshBasicMaterial({
        // color: this.color.planeColor,
        vertexColors: true,
        // color: 0xff0000,
        side: DoubleSide,
        polygonOffset: true,
        polygonOffsetFactor: 1.5,
        polygonOffsetUnits: 1.5,
        wireframe: false,
      }),
      Elset: new MeshBasicMaterial({
        color: 0xff8800,
        side: DoubleSide,
        polygonOffset: true,
        polygonOffsetFactor: 1.0,
        polygonOffsetUnits: 1.0,
        wireframe: false,
      }),
      Nset: new PointsMaterial({
        color: 0xff8800,
        //  size: 1,
        polygonOffset: true,
        polygonOffsetFactor: -1.1,
        polygonOffsetUnits: -2.5,
      }),
      pointBox: new MeshStandardMaterial({
        color: 0x000,
        //  size: 1,
        polygonOffset: true,
        polygonOffsetFactor: -1.1,
        polygonOffsetUnits: -2.5,
      }),
      pointBoxLight: new MeshStandardMaterial({
        color: 0xffffff,
        //  size: 1,
        polygonOffset: true,
        polygonOffsetFactor: -1.1,
        polygonOffsetUnits: -2.5,
      }),
      rgb: new Color(0x1460ab),
      rgbCM: new Color(0x00e400),
      point: {},
    };
    this.flagshow = {
      line: true,
      mesh: true,
    };

    this.data = {
      source: {
        nodes: {},
        elements: {},
        boundary: {},
        part: {},
        instance: {},
        mesh: {},
        assembly: {
          Nset: {},
          Elset: {},
          surface: {},
        },
        materials: {},
        // boundary: {},
        step: [],
        // {
        //     Loads:{},
        //     Interaction:{},
        //     "OUTPUT REQUESTS":{},
        //     "FIELD OUTPUT":{},
        //     "HISTORY OUTPUT":{},

        // },

        // group:{},
      },
      forDraw: {
        pointOfLinesXYZ: false,
        pointOfLinesIndex: [],
        pointOfSurfaceLinesIndex: [],
        pointOfTrangleAll: [],
        trangle: {},
        points: {},
        lines: {},
        planes: {},
      },
      pressures: {
        current: [],
      },
      OBJ: {},
    };

    this.flag = {
      file: {
        nodes: false,
        boundary: false,
        elements: false,
      },
    };

    this.theEndOfDraw = [];

    this.flageClipping = false;
    // that = this;
    this._init();
    return this;
  }
  beforeInit() { }
  afterInit(that) {
    // use that replace this ,async call
    if (typeof that.zipFile.fun == "object") {
      for (let i in that.zipFile.fun) {
        if (typeof that.zipFile.fun[i] == "function") {
          // if (i == "theEndOfDraw") {
          //     that.theEndOfDraw.push(that.zipFile.fun[i]);
          // }is
          // else
          that.zipFile.fun[i](that);
        }
      }
    }
    this.parent.log("100%:计算结果可视化处理完成;");
  }
  initFormat() {
    if (this.zipFile.ziped == false) {
      // this.zipFile = this.inputFile;
    } else {
      this.zipFile.list = {
        "mesh.nodes": [this.callNodes, 0],
        "mesh.boundary": [this.callBoundary, 0],
        "mesh.elements": [this.callElements, 0],
      };
    }
    // let abc = 1;
  }
  getfirstEXTFile(Ext) {
    let first = false;
    for (let i in this.zipFS.files) {
      let perFile = this.zipFS.files[i].name;
      let getExt = perFile.split(".");
      if (getExt.length > 1 && getExt[getExt.length - 1] == Ext) {
        first = perFile;
        break;
      }
    }
    return first;
  }
  getfirstEXTFileList(Ext) {
    let list = [];
    for (let i in this.zipFS.files) {
      let perFile = this.zipFS.files[i].name.toLowerCase();
      let getExt = perFile.split(".");
      if (getExt.length > 1 && getExt[getExt.length - 1] == Ext) {
        list.push(perFile);
      }
    }
    return list;
  }
  _init() {
    this.parent.log("0%:开始处理计算结果可视化过程;");
    this.beforeInit();
    // this.initFormat();

    this.init();
    if (this.zipFile.ziped == false) {
      this.initLoadFile(this.zipFile);
    } else this.initZip(this.zipFile);
  }
  init() { }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // 二进制转换
  arrayBufferToBase64(buffer) {
    var binary = "";
    var bytes = new Uint8Array(buffer);
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }

  base64ToUint8Array(base64String) {
    const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
    const base64 = (base64String + padding)
      //.replace(/-/g, '+')//
      .replace(/\-/g, "+")
      .replace(/_/g, "/");

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  }
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  getFileMesh() { } //为msh and inp 需要覆写
  //为msh and inp
  initLoadFile(inputFile) {
    let that = this;
    if (inputFile.mesh) {
      const loader = new FileLoader();

      //加载一个文本文件，并把结果输出到控制台上
      loader.load(
        // resource URL
        inputFile.mesh,

        // onLoad回调
        function (data) {
          // output the text to the console
          // console.log(data)
          if (inputFile.type == "abaqus") {
            that.getFileMesh([data, 0]);
          } else if (inputFile.type == "gid") {
            that.getFileMesh(data);
          }
          that.callDraw(that);
          that.afterInit(that);
        },

        // onProgress回调
        function (xhr) {
          that.parent.log((xhr.loaded / xhr.total) * 100 + "% loaded");
        },

        // onError回调
        function (err) {
          that.parent.log("An error happened", err);
        }
      );
    }
  }
  initZip(zipFile) {
    // const promise = new JSZip.external.Promise(function (resolve, reject) {
    //     JSZipUtils.getBinaryContent(zipFile.zip, function (err, data) {
    //         if (err) {
    //             reject(err);
    //         } else {
    //             resolve(data);
    //         }
    //     });is
    // });
    let that = this;
    this.parent.log("1%:准备接收计算结果数据;");
    this.idb = new cloudIdb("idbZIP", function (isCache) {
      const promise = new JSZip.external.Promise(function (resolve, reject) {
        that.parent.log("10%:接收数据;");
        let zipName = zipFile.zip.split("/").pop();
        if (isCache == false) {
          JSZipUtils.getBinaryContent(zipFile.zip, function (err, data) {
            if (err) {
              reject(err);
            } else {
              let a2b = that.arrayBufferToBase64(data);
              // let b2a = that.base64ToUint8Array(a2b).buffer;
              // console.log("this is raw from zip ", data, b2a)
              that.idb.add(zipName, a2b);
              // console.log("The data loads from network")
              resolve(data);
            }
          });
        } else {
          that.idb.read(zipName, function (cacheData) {
            if (cacheData == false || isCache == false) {
              JSZipUtils.getBinaryContent(zipFile.zip, function (err, data) {
                if (err) {
                  reject(err);
                } else {
                  let a2b = that.arrayBufferToBase64(data);
                  // let b2a = that.base64ToUint8Array(a2b).buffer;
                  // console.log("this is raw from zip ", data, b2a)
                  that.idb.add(zipName, a2b);
                  // console.log("The data loads from network")
                  resolve(data);
                }
              });
            } else {
              // console.log("The data loads from cache");
              resolve(that.base64ToUint8Array(cacheData).buffer);
            }
          });
        }
      });
      promise
        .then(JSZip.loadAsync) // 2) chain with the zip promise
        .then(
          function (zip) {
            that.parent.log("20%:数据传输;");
            that.zipFS = zip;
            that.initFormat();
            let array = [];
            for (let i in zipFile.list) {
              if (typeof zipFile.list[i] == "function") {
                array.push(
                  zip
                    .file(i)
                    .async("string")
                    .then(function (data) {

                      that.parent.log("......");
                      zipFile.list[i].call(that, data);
                    })
                );
              } else {
                array.push(
                  zip
                    .file(i)
                    .async("string")
                    .then(function (data) {
                      that.parent.log("......");
                      zipFile.list[i][0].call(that, [data, zipFile.list[i][1]]);
                    })
                );
              }
            }

            function myPromise(promiseList) {
              that.parent.log("30%:数据处理;");
              return promiseList.map((the_promise) =>
                the_promise.then(
                  (res) => ({ status: "ok", res }),
                  (err) => ({ status: "not ok", err })
                )
              );
            }
            function pro(PromiseArr) {
              Promise.all(myPromise(PromiseArr)).then(
                (res) => {
                  // console.log(res);
                  if (PromiseArr.length == res.length) {
                    //开始处理
                    that.parent.log("70%:开始数据可视化处理;");
                    that.callDraw();
                    that.parent.log("90%:开始数据可视化处理;");
                    if (that.parent.render) that.parent.render();
                    else if (that.parent.parent.render)
                      that.parent.parent.render();
                    that.afterInit(that);
                  }
                },
                (err) => console.log(err)
              );
            }
            pro(array);
          },
          function error(e) {
            console.log(e);
          }
        );
    });
  }
  //reLoad 资源文件
  reLoadRES(zipfile) {
    let that=this;
    const promise = new JSZip.external.Promise(function (resolve, reject) {
      JSZipUtils.getBinaryContent(zipfile, function (err, data) {
        if (err) {
          reject(err);
        } else {
          resolve(data);
        }
      });
    });
    promise.then(JSZip.loadAsync)                     // 2) chain with the zip promise
      .then(function (zip) {
        that.zipFS = zip;
        let resFile=that.getfirstEXTFile("res");
        return zip.file(resFile).async("string"); // 3) chain with the text content promise
      })
      .then(function success(text) {                    // 4) display the result

        that.reNewRES(text);
        //create_Geometry();
        //draw();
      }, function error(e) {
        console.log(e)
      });

  }
  initZip_old(zipFile) {
    let that = this;
    const promise = new JSZip.external.Promise(function (resolve, reject) {
      JSZipUtils.getBinaryContent(zipFile.zip, function (err, data) {
        if (err) {
          reject(err);
        } else {
          resolve(data);
        }
      });
    });
    promise
      .then(JSZip.loadAsync) // 2) chain with the zip promise
      .then(
        function (zip) {
          that.zipFS = zip;
          that.initFormat();
          let array = [];
          for (let i in zipFile.list) {
            if (typeof zipFile.list[i] == "function") {
              array.push(
                zip
                  .file(i)
                  .async("string")
                  .then(function (data) {
                    zipFile.list[i].call(that, data, that);
                  })
              );
            } else {
              array.push(
                zip
                  .file(i)
                  .async("string")
                  .then(function (data) {
                    zipFile.list[i][0].call(
                      that,
                      [data, zipFile.list[i][1]],
                      that
                    );
                  })
              );
            }
          }

          function myPromise(promiseList) {
            return promiseList.map((the_promise) =>
              the_promise.then(
                (res) => ({ status: "ok", res }),
                (err) => ({ status: "not ok", err })
              )
            );
          }
          function pro(PromiseArr) {
            Promise.all(myPromise(PromiseArr)).then(
              (res) => {
                // console.log(res);
                if (PromiseArr.length == res.length) {
                  //开始处理
                  that.callDraw(that);
                  if (that.parent.render) that.parent.render();
                  else if (that.parent.parent.render)
                    that.parent.parent.render();
                  that.afterInit(that);
                }
              },
              (err) => console.log(err)
            );
          }
          pro(array);
        },
        function error(e) {
          console.log(e);
        }
      );
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  callNodes(dataArray) {
    let data = dataArray[0];
    let msh_file_lines = data.split("\n");
    for (let i in msh_file_lines) {
      let perLine = msh_file_lines[i];
      let lineArray = perLine.split("\r")[0].split(/\s+/);
      if (lineArray.length === 5)
        this.data.source.nodes[lineArray[0]] = [
          parseFloat(lineArray[2]),
          parseFloat(lineArray[3]),
          parseFloat(lineArray[4]),
        ];
    }
    this.emuPressures();
    // console.log(that.data.source.nodes);
    return true;
  }
  callElements(dataArray, that) {
    let data = dataArray[0];
    let msh_file_lines = data.split("\n");
    for (let i in msh_file_lines) {
      let perLine = msh_file_lines[i];
      let lineArray = perLine.split("\r")[0].split(/\s+/);
      if (lineArray.length > 2) {
        that.data.source.elements[lineArray[0]] = {
          type: lineArray[2],
          points: [
            parseFloat(lineArray[3]),
            parseFloat(lineArray[4]),
            parseFloat(lineArray[5]),
            parseFloat(lineArray[6]),
            parseFloat(lineArray[7]),
            parseFloat(lineArray[8]),
            parseFloat(lineArray[9]),
            parseFloat(lineArray[10]),
          ],
        };
      }
    }
    // console.log(that.data.source.elements);
    return true;
  }
  callBoundary(dataArray, that) {
    let data = dataArray[0];
    let msh_file_lines = data.split("\n");
    for (let i in msh_file_lines) {
      let perLine = msh_file_lines[i];
      let lineArray = perLine.split("\r")[0].split(/\s+/);
      if (lineArray.length > 2) {
        that.boundary[lineArray[0]] = {
          unit: lineArray[2],
          type: lineArray[4],
          points: [
            parseFloat(lineArray[5]),
            parseFloat(lineArray[6]),
            parseFloat(lineArray[7]),
            parseFloat(lineArray[8]),
          ],
        };
      }
    }
    // console.log(that.boundary);
    return true;
  }
  callDraw(that) {
    that.calculateTrangle(that);
    that.drawTrangleAll(that);
    that.calculateAllLines(that);
    that.calculateSurfaceLines(that);
    that.drawAllLines(that);
    // that.afterInit();
    that.center(that.mesh.allTriangle, that);
    that.center(that.mesh.allTriangleLines, that);

    // if (this.theEndOfDraw.length > 0) {
    //     for (let fun in this.theEndOfDraw)
    //         fun(that);
    // }
  }

  //计算六面体的全部，三角形，只有不重复的三角形*2（四边形）
  calculateTrangle(that) {
    for (let i in that.data.source.elements) {
      let perOne = that.data.source.elements[i];
      let points = perOne.points;

      let top1 = [points[7], points[6], points[3]];
      let top2 = [points[6], points[2], points[3]];

      if (typeof this.data.forDraw.trangle[top1.join("_")] == "undefined") {
        this.data.forDraw.trangle[top1.join("_")] = {
          points: top1,
          enable: true,
          index: 0,
        };
      } else {
        this.data.forDraw.trangle[top1.join("_")].enable = false;
      }
      if (typeof this.data.forDraw.trangle[top2.join("_")] == "undefined") {
        this.data.forDraw.trangle[top2.join("_")] = {
          points: top2,
          enable: true,
          index: 1,
        };
      } else this.data.forDraw.trangle[top2.join("_")].enable = false;

      let bottom1 = [points[4], points[5], points[0]];
      let bottom2 = [points[5], points[1], points[0]];
      if (typeof this.data.forDraw.trangle[bottom1.join("_")] == "undefined") {
        this.data.forDraw.trangle[bottom1.join("_")] = {
          points: bottom1,
          enable: true,
          index: 0,
        };
      } else this.data.forDraw.trangle[bottom1.join("_")].enable = false;

      if (typeof this.data.forDraw.trangle[bottom2.join("_")] == "undefined") {
        this.data.forDraw.trangle[bottom2.join("_")] = {
          points: bottom2,
          enable: true,
          index: 1,
        };
      } else this.data.forDraw.trangle[bottom2.join("_")].enable = false;

      let left1 = [points[0], points[4], points[3]];
      let left2 = [points[4], points[7], points[3]];
      if (typeof this.data.forDraw.trangle[left1.join("_")] == "undefined") {
        this.data.forDraw.trangle[left1.join("_")] = {
          points: left1,
          enable: true,
          index: 0,
        };
      } else this.data.forDraw.trangle[left1.join("_")].enable = false;

      if (typeof this.data.forDraw.trangle[left2.join("_")] == "undefined") {
        this.data.forDraw.trangle[left2.join("_")] = {
          points: left2,
          enable: true,
          index: 1,
        };
      } else this.data.forDraw.trangle[left2.join("_")].enable = false;

      let right1 = [points[1], points[5], points[2]];
      let right2 = [points[5], points[6], points[2]];
      if (typeof this.data.forDraw.trangle[right1.join("_")] == "undefined") {
        this.data.forDraw.trangle[right1.join("_")] = {
          points: right1,
          enable: true,
          index: 0,
        };
      } else this.data.forDraw.trangle[right1.join("_")].enable = false;

      if (typeof this.data.forDraw.trangle[right2.join("_")] == "undefined") {
        this.data.forDraw.trangle[right2.join("_")] = {
          points: right2,
          enable: true,
          index: 1,
        };
      } else this.data.forDraw.trangle[right2.join("_")].enable = false;

      let front1 = [points[4], points[5], points[7]];
      let front2 = [points[5], points[6], points[7]];

      if (typeof this.data.forDraw.trangle[front1.join("_")] == "undefined") {
        this.data.forDraw.trangle[front1.join("_")] = {
          points: front1,
          enable: true,
          index: 0,
        };
      } else this.data.forDraw.trangle[front1.join("_")].enable = false;

      if (typeof this.data.forDraw.trangle[front2.join("_")] == "undefined") {
        this.data.forDraw.trangle[front2.join("_")] = {
          points: front2,
          enable: true,
          index: 1,
        };
      } else this.data.forDraw.trangle[front2.join("_")].enable = false;

      let back1 = [points[0], points[1], points[3]];
      let back2 = [points[1], points[2], points[3]];
      if (typeof this.data.forDraw.trangle[back1.join("_")] == "undefined") {
        this.data.forDraw.trangle[back1.join("_")] = {
          points: back1,
          enable: true,
          index: 0,
        };
      } else this.data.forDraw.trangle[back1.join("_")].enable = false;

      if (typeof this.data.forDraw.trangle[back2.join("_")] == "undefined") {
        this.data.forDraw.trangle[back2.join("_")] = {
          points: back2,
          enable: true,
          index: 1,
        };
      } else this.data.forDraw.trangle[back2.join("_")].enable = false;
    }
  }
  //计算所有线段，内外部，全部
  calculateAllLines(that) {
    that.data.forDraw.pointOfLinesIndex = { one: [] }; //cube 线段index

    for (let i in that.data.source.elements) {
      let perOne = that.data.source.elements[i];
      let points = perOne.points;
      let abc = [
        points[4],
        points[5],
        points[5],
        points[1],
        points[1],
        points[0],
        points[0],
        points[4],
        points[4],
        points[7],
        points[5],
        points[6],
        points[1],
        points[2],
        points[0],
        points[3],
        points[7],
        points[6],
        points[6],
        points[2],
        points[2],
        points[3],
        points[3],
        points[7],
      ];
      that.data.forDraw.pointOfLinesIndex.one.push(abc);
    }
  }
  subCalculateSurfaceLine(line1) {
    if (typeof this.data.forDraw.trangle[line1.join("_")] == "undefined") {
      this.data.forDraw.pointOfSurfaceLinesIndex[line1.join("_")] = {
        points: line1,
        enable: true,
        index: 0,
      };
    } else {
      this.data.forDraw.pointOfSurfaceLinesIndex[
        line1.join("_")
      ].enable = false;
    }
    // that.data.forDraw.pointOfSurfaceLinesIndex.push(abc);
  }
  calculateSurfaceLines(that) {
    // that.data.forDraw.pointOfLinesIndex = [];//cube 线段index
    for (let i in that.data.source.elements) {
      let perOne = that.data.source.elements[i];
      // let points = perOne.points;
      let points = perOne.points;
      // let line1 = [points[4], points[5]];
      let abc = [
        points[4],
        points[5],
        points[5],
        points[1],
        points[0],
        points[1],
        points[4],
        points[0],
        points[4],
        points[7],
        points[5],
        points[6],
        points[1],
        points[2],
        points[0],
        points[3],
        points[7],
        points[6],
        points[6],
        points[2],
        points[3],
        points[2],
        points[7],
        points[3],
      ];
      // let abc = [
      //     points[4], points[5], points[5], points[1], points[1], points[0], points[0], points[4],
      //     points[4], points[7], points[5], points[6], points[1], points[2], points[0], points[3],
      //     points[7], points[6], points[6], points[2], points[2], points[3], points[3], points[7],
      // ];
      for (let j = 0; j < abc.length; j += 2) {
        let line1 = [abc[j], abc[j + 1]];
        this.subCalculateSurfaceLine(line1);
      }
    }
  }
  emuPressures() {
    this.data.pressures.current = [];
    for (let i in this.data.source.nodes) {
      // let perOne = this.data.source.nodes[i];
      this.data.pressures.current[i] = Math.random();
    }
  }
  //update that.geometry.allTriangle pressures
  updatePressures(data) {
    // let pressures = {};
    let pressures = [];
    for (let i in this.data.forDraw.pointOfTrangleAll) {
      let perOne = this.data.forDraw.pointOfTrangleAll[i];
      let pressure = data[perOne];
      pressures.push(pressure);
      // pressures[i]=pressure;
      // if (pressure == null) {
      //     console.log(i)
      // }
    }
    this.geometry.allTriangle.setAttribute(
      "pressure",
      new Float32BufferAttribute(new Float32Array(pressures), 1)
    );
    this.data.pressures.current = pressures;
    // let abc = 1;
  }

  //update colors by LUT
  updateColors() {
    if (this.parent.LUT) {
      let lut = this.parent.LUT.lut;
      let sprite = this.parent.LUT.sprite;
      this.parent.LUT.setMaxMin(this.data.pressures.current); //顺序顶点的pressure值
      let mesh = this.mesh.allTriangle;
      const geometry = mesh.geometry;
      const pressures = geometry.attributes.pressure;
      const colors = geometry.attributes.color;
      for (let i = 0; i < pressures.array.length; i++) {
        const colorValue = pressures.array[i];
        const color = lut.getColor(colorValue);
        if (color === undefined) {
          //////console.log('Unable to determine color for value:', colorValue);
        } else {
          colors.setXYZ(i, color.r, color.g, color.b);
        }
      }
      colors.needsUpdate = true;
      const map = sprite.material.map;
      this.parent.LUT.update_text(" ");
      map.needsUpdate = true;
      // let abc = 1;
      //////console.log(child);
    }
  }
  //draw 三角形，
  drawTrangleAll(that) {
    let points = [];
    let pressures = [];
    let colors = [];
    // this.data.forDraw.pointOfTrangleAll={
    //     one:[],
    // }
    for (let i in this.data.forDraw.trangle) {
      let perOne = this.data.forDraw.trangle[i];
      if (perOne.enable) {
        points.push(that.data.source.nodes[perOne.points[0]][0]);
        points.push(that.data.source.nodes[perOne.points[0]][1]);
        points.push(that.data.source.nodes[perOne.points[0]][2]);

        points.push(that.data.source.nodes[perOne.points[1]][0]);
        points.push(that.data.source.nodes[perOne.points[1]][1]);
        points.push(that.data.source.nodes[perOne.points[1]][2]);

        points.push(that.data.source.nodes[perOne.points[2]][0]);
        points.push(that.data.source.nodes[perOne.points[2]][1]);
        points.push(that.data.source.nodes[perOne.points[2]][2]);

        // pressures.push(0, 0, 0 );
        pressures.push(
          this.data.pressures.current[perOne.points[0]],
          this.data.pressures.current[perOne.points[1]],
          this.data.pressures.current[perOne.points[2]]
        );
        colors.push(1, 1, 1, 1, 1, 1, 1, 1, 1);
        this.data.forDraw.pointOfTrangleAll.push(
          perOne.points[0],
          perOne.points[1],
          perOne.points[2]
        );
      }
    }

    that.geometry.allTriangle = new BufferGeometry();
    const vertices = new Float32Array(points);
    that.geometry.allTriangle.setAttribute(
      "position",
      new BufferAttribute(vertices, 3)
    );
    that.geometry.allTriangle.setAttribute(
      "color",
      new Float32BufferAttribute(new Float32Array(colors), 3)
    );
    that.geometry.allTriangle.setAttribute(
      "pressure",
      new Float32BufferAttribute(new Float32Array(pressures), 1)
    );
    // that.material.allTriangle = new MeshBasicMaterial({ color: that.color.planeColor, side: DoubleSide });
    that.mesh.allTriangle = new Mesh(
      that.geometry.allTriangle,
      that.material.allTriangle
    );
    that.mesh.allTriangle.name = "allTrangels";
    that.worldBox.add(that.mesh.allTriangle);
  }

  drawAllLines(that) {
    let points = [];
    for (let i in that.data.forDraw.pointOfSurfaceLinesIndex) {
      let perOne = that.data.forDraw.pointOfSurfaceLinesIndex[i];
      if (perOne.enable) {
        for (let j in perOne.points) {
          let perLine = perOne.points[j];
          points.push(that.data.source.nodes[perLine][0]);
          points.push(that.data.source.nodes[perLine][1]);
          points.push(that.data.source.nodes[perLine][2]);
        }
      }
    }

    that.geometry.line = new BufferGeometry();
    const vertices = new Float32Array(points);
    that.geometry.line.setAttribute(
      "position",
      new BufferAttribute(vertices, 3)
    );
    // that.material.line = new LineBasicMaterial({ color: that.color.lineColor, side: DoubleSide });
    if (typeof that.mesh.allTriangleLines != "undefined")
      that.scene.remove(that.mesh.allTriangleLines);
    that.mesh.allTriangleLines = new LineSegments(
      that.geometry.line,
      that.material.line
    );
    that.mesh.allTriangleLines.name = "allLines";
    that.worldBox.add(that.mesh.allTriangleLines);
    that.data.forDraw.pointOfLinesXYZ = points; //所有三角形的xyz，cube*index*3
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //设置局部剪切平面
  setClipping(localPlane = false) {
    if (localPlane) {
      this.flageClipping = true;
      this.material.line.clippingPlanes = [localPlane];
      this.material.allTriangle.clippingPlanes = [localPlane];

      // this.material.allTriangleShader.clippingPlanes = [localPlane];
      this.material.allTriangleShaderStair.clippingPlanes = [localPlane];
      // this.material.allTriangleShaderStair.uniforms.clippingPlanes.value = [localPlane];
      // this.material.allTriangleShaderStair.uniforms.clippingPlanes = {needsUpdate:true,value:[localPlane]};
      this.material.OBJline.clippingPlanes = [localPlane];
      // this.setMeshClippingEnalbe(true);
    } else {
      this.flageClipping = false;
      this.material.line.clippingPlanes = [];
      this.material.allTriangle.clippingPlanes = [];
      // this.material.allTriangleShader.clippingPlanes = [];
      // this.material.allTriangleShaderStair.uniforms.clippingPlanes.value = [];
      // this.material.allTriangleShaderStair.uniforms.clippingPlanes.needsUpdate = false;
      this.material.OBJline.clippingPlanes = [];
      // this.setMeshClippingEnalbe(false);
    }
  }
  // setMeshClippingEnalbe(enable = false) { }
  setMeshClippingEnalbe() { }

  center(obj) {
    if (this.matrixGroup.main) {
      obj.applyMatrix4(this.matrixGroup.main);
    } else {
      this.matrixGroup.main = this.centerMatrix(obj);
    }
  }
  centerForTrains() {
    let temp = this.parent.worldBox;
    var box3 = new Box3();
    // 计算层级模型group的包围盒
    // 模型group是加载一个三维模型返回的对象，包含多个网格模型
    box3.expandByObject(temp);

    if (
      this.box3.max.x == -Infinity ||
      this.box3.max.y == -Infinity ||
      this.box3.max.z == -Infinity
    ) {
      if (this.meshType == "gid") {
        for (let i in this.mesh) {
          let perMesh = this.mesh[i];
          if (i == "allTriangle") {
            // 重新设置模型的位置，使之居中。
            this.matrixGroup.main = false;

            // let cx = box3.max.x - box3.min.x;
            // let cy = box3.max.x - box3.min.x;
            // let cz = box3.max.x - box3.min.x;
            // let centerMax = 0;
            // cx > cy ? centerMax = cx : (cy > cz ? centerMax = cy : centerMax = cz);
            let scale = 1;
            // if (centerMax > this.farDistance * this.farDistanceScale) {
            //     scale = 1 / Math.ceil(centerMax / (this.farDistance * this.farDistanceScale));
            // }
            // if (scale != 1) {
            //     let worldBox = this.parent.scene.getObjectByName('worldBox');
            //     worldBox.scale.x = scale;
            //     worldBox.scale.y = scale;
            //     worldBox.scale.z = scale;
            // }
            var center = new Vector3();
            box3.getCenter(center);
            this.box3 = box3;
            this.box3.centerMM = {
              max: {
                x: (perMesh.position.x + (box3.max.x - box3.min.x) / 2) * scale,
                y: (perMesh.position.y + (box3.max.y - box3.min.y) / 2) * scale,
                z: (perMesh.position.z + (box3.max.z - box3.min.z) / 2) * scale,
              },
              min: {
                x: (perMesh.position.x - (box3.max.x - box3.min.x) / 2) * scale,
                y: (perMesh.position.y - (box3.max.y - box3.min.y) / 2) * scale,
                z: (perMesh.position.z - (box3.max.z - box3.min.z) / 2) * scale,
              },
            };
            box3.max.x *= scale;
            box3.max.y *= scale;
            box3.max.z *= scale;
            box3.min.x *= scale;
            box3.min.y *= scale;
            box3.min.z *= scale;

            perMesh.position.x = perMesh.position.x - center.x;
            perMesh.position.y = perMesh.position.y - center.y;
            perMesh.position.z = perMesh.position.z - center.z;

            perMesh.updateMatrix();
            this.matrixGroup.main = perMesh.matrix;
          } else {
            perMesh.applyMatrix4(this.matrixGroup.main);
          }
        }
      }
    }

    this.parent.updateENVLast();
  }

  centerMatrix(objects) {
    /**
     * 包围盒全自动计算：模型整体居中
     */

    let temp = objects;

    var box3 = new Box3();
    // 计算层级模型group的包围盒
    // 模型group是加载一个三维模型返回的对象，包含多个网格模型
    box3.expandByObject(temp);

    // box3.copy(objects.geometry.boundingBox);
    // let cx = box3.max.x - box3.min.x;
    // let cy = box3.max.x - box3.min.x;
    // let cz = box3.max.x - box3.min.x;
    // let centerMax = 0;
    // cx > cy ? centerMax = cx : (cy > cz ? centerMax = cy : centerMax = cz);
    let scale = 1;
    // if (centerMax > this.farDistance * this.farDistanceScale) {
    //     scale = 1 / Math.ceil(centerMax / (this.farDistance * this.farDistanceScale));
    // }
    // if (scale != 1) {
    //     let worldBox = this.parent.scene.getObjectByName('worldBox');
    //     worldBox.scale.x = scale;
    //     worldBox.scale.y = scale;
    //     worldBox.scale.z = scale;
    // }

    // 计算一个层级模型对应包围盒的几何体中心在世界坐标中的位置
    var center = new Vector3();
    box3.getCenter(center);
    // console.log("basemesh.js:P692,center of CM",center)
    // console.log('查看几何体中心坐标', center);

    // 重新设置模型的位置，使之居中。
    objects.position.x = temp.position.x - center.x;
    objects.position.y = temp.position.y - center.y;
    objects.position.z = temp.position.z - center.z;

    // console.log(box3)
    this.box3 = box3;
    this.box3.centerMM = {
      max: {
        x: (objects.position.x + (box3.max.x - box3.min.x) / 2) * scale,
        y: (objects.position.y + (box3.max.y - box3.min.y) / 2) * scale,
        z: (objects.position.z + (box3.max.z - box3.min.z) / 2) * scale,
      },
      min: {
        x: (objects.position.x - (box3.max.x - box3.min.x) / 2) * scale,
        y: (objects.position.y - (box3.max.y - box3.min.y) / 2) * scale,
        z: (objects.position.z - (box3.max.z - box3.min.z) / 2) * scale,
      },
    };
    box3.max.x *= scale;
    box3.max.y *= scale;
    box3.max.z *= scale;
    box3.min.x *= scale;
    box3.min.y *= scale;
    box3.min.z *= scale;

    objects.updateMatrix();

    return objects.matrix;
    // this.parent.setENVLast(this);
  }
  center_Old(objects, OBJCenter = false) {
    /**
     * 包围盒全自动计算：模型整体居中
     */
    let temp = OBJCenter ? OBJCenter : objects;

    var box3 = new Box3();
    // 计算层级模型group的包围盒
    // 模型group是加载一个三维模型返回的对象，包含多个网格模型
    box3.expandByObject(temp);
    let cx = box3.max.x - box3.min.x;
    let cy = box3.max.x - box3.min.x;
    let cz = box3.max.x - box3.min.x;
    let centerMax = 0;
    cx > cy ? (centerMax = cx) : cy > cz ? (centerMax = cy) : (centerMax = cz);
    let scale = 1;
    if (centerMax > this.farDistance * this.farDistanceScale) {
      scale =
        1 / Math.ceil(centerMax / (this.farDistance * this.farDistanceScale));
    }
    if (scale != 1) {
      let worldBox = this.parent.scene.getObjectByName("worldBox");
      worldBox.scale.x = scale;
      worldBox.scale.y = scale;
      worldBox.scale.z = scale;
    }

    // 计算一个层级模型对应包围盒的几何体中心在世界坐标中的位置
    var center = new Vector3();
    box3.getCenter(center);
    // console.log("basemesh.js:P692,center of CM",center)
    // console.log('查看几何体中心坐标', center);

    // 重新设置模型的位置，使之居中。
    objects.position.x = temp.position.x - center.x;
    objects.position.y = temp.position.y - center.y;
    objects.position.z = temp.position.z - center.z;

    // console.log(box3)
    this.box3 = box3;
    this.box3.centerMM = {
      max: {
        x: (objects.position.x + (box3.max.x - box3.min.x) / 2) * scale,
        y: (objects.position.y + (box3.max.y - box3.min.y) / 2) * scale,
        z: (objects.position.z + (box3.max.z - box3.min.z) / 2) * scale,
      },
      min: {
        x: (objects.position.x - (box3.max.x - box3.min.x) / 2) * scale,
        y: (objects.position.y - (box3.max.y - box3.min.y) / 2) * scale,
        z: (objects.position.z - (box3.max.z - box3.min.z) / 2) * scale,
      },
    };
    box3.max.x *= scale;
    box3.max.y *= scale;
    box3.max.z *= scale;
    box3.min.x *= scale;
    box3.min.y *= scale;
    box3.min.z *= scale;
    // this.parent.setENVLast(this);
  }
  //外部获取变形场
  getTransformName() { }
  getPressureSName() { }
  getFilenameFromZip() { }

  getMaxMin(json) {
    let max = false;
    let min = false;
    for (let i in json) {
      // if (i > 6648) {
      //     let abc = 1;
      // }
      if (max === false) {
        max = json[i];
        min = json[i];
      }
      if (max < json[i]) {
        max = json[i];
      }
      if (min > json[i]) {
        min = json[i];
      }
    }
    return [max, min];
  }
  getNodeMMSize() {
    let max = {
      x: false,
      y: false,
      z: false,
    };
    let min = {
      x: false,
      y: false,
      z: false,
    };
    if (this.meshType == "gid") {
      for (let perNode_i in this.data.source.nodes) {
        let perNode = this.data.source.nodes[perNode_i];
        for (let i in perNode) {
          let xyz = perNode[i];
          if (max.x === false) {
            max.x = xyz[0];
            max.y = xyz[1];
            max.z = xyz[2];

            min.x = xyz[0];
            min.y = xyz[1];
            min.z = xyz[2];
          }
          if (max.x < xyz[0]) max.x = xyz[0];
          if (max.y < xyz[1]) max.y = xyz[1];
          if (max.z < xyz[2]) max.z = xyz[2];

          if (min.x > xyz[0]) min.x = xyz[0];
          if (min.y > xyz[1]) min.y = xyz[1];
          if (min.z > xyz[2]) min.z = xyz[2];
        }
      }
    }
    return { max: max, min: min };
  }
  getNodeMaxSize() {
    let MM = this.getNodeMMSize();
    let x = Math.abs(MM.max.x - MM.min.x);
    let y = Math.abs(MM.max.y - MM.min.y);
    let z = Math.abs(MM.max.z - MM.min.z);
    return x >= y ? x : (y >= z ? y : z);
  }
  getBestTransRate() {
    let A = this.getCMTransMaxSize();
    let B = this.getNodeMaxSize();
    let rate = .3;
    if (typeof this.setting.bestTransRate != 'undefined') {
      rate = this.setting.bestTransRate;
    }
    return parseInt(rate * B / A);
  }
  getCMTransMaxSize() {
    let MM = this.getCMTransSize();
    let x = Math.abs(MM.max.x - MM.min.x);
    let y = Math.abs(MM.max.y - MM.min.y);
    let z = Math.abs(MM.max.z - MM.min.z);
    return x >= y ? x : (y >= z ? y : z);
  }
  getCMTransSize() {
    let max = {
      x: false,
      y: false,
      z: false,
    };
    let min = {
      x: false,
      y: false,
      z: false,
    };
    let uvw = this.getUVW();
    if (uvw == false) {
      return false;
    }
    if (this.meshType == "gid") {
      for (let perNode_i in this.data.res[uvw[0]]) {
        let perNode = this.data.res[uvw[0]][perNode_i];//step of pressure

        for (let i in perNode[uvw[1][0]]) {

          let x = perNode[uvw[1][0]][i];
          let y = perNode[uvw[1][1]][i];
          let z = 0;
          if (typeof uvw[1][2] != 'undefined') {
            z = perNode[uvw[1][2]][i];
          }
          let xyz = perNode[i];
          if (max.x === false) {
            max.x = x;
            max.y = y;
            max.z = z;

            min.x = x;
            min.y = y;
            min.z = z;
          }
          if (max.x < x) max.x = x;
          if (max.y < y) max.y = y;
          if (max.z < z) max.z = z;

          if (min.x > x) min.x = x;
          if (min.y > y) min.y = y;
          if (min.z > z) min.z = z;

        }
      }
    }
    // else if (this.meshType == 'abaqus') {

    // }
    return { max: max, min: min };
  }
  //nameOfPressure=['unoda0','u'];
  // one press of all timer
  getCMMM(nameOfPressure) {
    let max = false;
    let min = false;

    if (this.meshType == "gid") {
      for (let perNode_i in this.data.res[nameOfPressure[0]]) {
        let perNode = this.data.res[nameOfPressure[0]][perNode_i];
        for (let i in perNode[nameOfPressure[1]]) {
          let v = perNode[nameOfPressure[1]][i]
          let xyz = perNode[i];
          if (max === false) {
            max = v;
            min = v;
          }
          if (max < v) max = v;
          if (min > v) min = v;
        }
      }
    }
    // else if (this.meshType == 'abaqus') {

    // }
    return { max: max, min: min };
  }
  setGlobalCMMM(value = true) {
    this.setting.globalCMMM = value;
  }
  getGlobalCMMM() {
    return this.setting.globalCMMM;
  }
  isTimerOfCM(nameOfPressure) {
    if (this.meshType == "gid") {
      return Object.keys(this.data.res[nameOfPressure]).length - 1;
    }
  }
}

export { baseMesh };
