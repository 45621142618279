let that;
class cloudIdb {
  constructor(name, callback) {
    that = this;
    this.request;
    this.db_IndexDB;
    this.localIDB;
    this.init(name, callback);
  }

  init(name, callback) {
    if (name == false) {
      callback(false);
      return;
    }
    var request = window.indexedDB.open(name);
    request.onerror = function (event) {
      ////console.log('数据库打开报错');
      // that.cache=false;
      callback(false);
    };

    request.onsuccess = function (event) {
      that.db_IndexDB = request.result;
      callback(true);
      // that.cache=true;
      //console.log('数据库打开成功');

      // read();
      // readAll();
    };
    request.onupgradeneeded = function (event) {
      that.db_IndexDB = event.target.result;
      var objectStore;
      if (!that.db_IndexDB.objectStoreNames.contains("inp")) {
        objectStore = that.db_IndexDB.createObjectStore("inp", {
          keyPath: "name",
        });
        // objectStore.createIndex('name', 'name', { unique: false });
        // that.cache=true;
        //console.log("create new db")
      }
      callback(true);
      // that.cache=true;
    };
  }

  add(name, obj) {
    var request = that.db_IndexDB
      .transaction(["inp"], "readwrite")
      .objectStore("inp")
      .add({ name: name, what: obj });

    request.onsuccess = function (event) {
      //console.log('数据写入成功');
    };
    request.onerror = function (event) {
      //console.log('数据写入失败');
    };
  }

  readAll(fun) {
    var objectStore = that.db_IndexDB.transaction("inp").objectStore("inp");

    objectStore.openCursor().onsuccess = function (event) {
      var cursor = event.target.result;

      if (cursor) {
        ////console.log('Id: ' + cursor.key);
        ////console.log('Name: ' + cursor.value.name);
        ////console.log('what: ' + cursor.value.what);
        // if (cursor.value.name == "bridge") {
        //     $(fun).callBack(cursor.value.what);
        //     return 1;
        // }
        cursor.continue();
      } else {
        //console.log('没有更多数据了！');
        $(fun).callBack(false);
      }
    };
  }
  read(name, fun) {
    var transaction = that.db_IndexDB.transaction(["inp"]);
    var objectStore = transaction.objectStore("inp");
    var request = objectStore.get(name);

    request.onerror = function (event) {
      ////console.log('事务失败');
      fun(false);
    };

    request.onsuccess = function (event) {
      if (request.result) {
        fun(request.result.what);
      } else {
        ////console.log('未获得数据记录');
        fun(false);
      }
    };
  }
}

export { cloudIdb };
