// version v.1.1   20210907 16:13
// abquas 时间历程使用
import {
    BufferGeometry,
    Mesh,
    Vector3,
    Color,
    MeshStandardMaterial,
    MeshBasicMaterial,
    LineBasicMaterial,
    MeshPhongMaterial,
    Line,
    Line3,
    Plane,
    Group,
    BufferAttribute,
    DoubleSide,
    LineSegments,
    Ray,
    Float32BufferAttribute,
    LineLoop,
    FileLoader,
    ObjectLoader,
    Matrix4,
    EdgesGeometry
} from 'three';

// import { cloudAnsysCM } from "./cloudmsh"
import { inpCM2D } from "./inpmsh.v2d"

// let that;
class inpCM extends inpCM2D {
    constructor(parent, zipFile = false) {
        super(parent, zipFile);
    }

    init() {
        // that = this;
        super.init()
        // that = this;
        // console.log(this.zipFile)
    }

    afterInit(that) {
        super.afterInit(that);
    }





    show(value = false) {
        if (value) {
            // let temp = value.split(".");
            this.nameOfCloudmap = value;
        }
        if (this.nameOfCloudmap) {
            this.doTrans();
            this.updatePressures(this.nameOfCloudmap);
            this.updateColors();
        }
        if (this.flageClipping) {
            this.repairPoly()
        }
        this.centerForTrains();
        this.parent.render();
    }


    //rewrite 
    //for 3D ,only shell
    doTransLines() {
        //if (this.setting.trans) 
        if (this.mesh.lines.visible) {
            this.worldBox.remove(this.mesh.lines);
            if (this.flagshow.line)
                this.drawLines();
            this.mesh.lines.applyMatrix4(this.mesh.inpGroup.matrix);
        }
        this.parent.render();
    }



}

export { inpCM }
