// version 2.1
// 功能：切面,位置、矩阵功能完整，线框体同步模型设置
import {
    BufferGeometry,
    Mesh,
    Vector3,
    Color,
    MeshStandardMaterial,
    MeshBasicMaterial,
    LineBasicMaterial,
    MeshPhongMaterial,
    Line,
    Line3,
    Plane,
    Group,
    BufferAttribute,
    DoubleSide,
    LineSegments,
    Ray,
    Float32BufferAttribute,
    LineLoop,
    ConeBufferGeometry,
    BoxGeometry
} from 'three';
import { baseMesh } from "./basemesh.js"
import triangulate from "delaunay-triangulate";


// let that;
class baseCM extends baseMesh {
    constructor(parent, inputFile = false) {
        super(parent, inputFile);
        this.data.clip = {
            pointsOfClipping: [],
            pointsOfClipping: [],
            pointsOfClippingOf001: [],
        }
        // that = this;
        this.tempPoint = new Vector3(0, 0, 0);
        this.x0y0z1Direction = new Vector3(0, 0, 1);
        this.x0y0z1Plane = new Plane(this.x0y0z1Direction, 0);//plane 0,0,1
        this.ray = new Ray(this.tempPoint, this.x0y0z1Plane);
        this.pointOfRay = false;
        this.pointsOfClipping = [];
        this.data.M4Points = {};
        this.data.M4PointsTrans = {};

        // return this;
    }
    beforeInit() {
        let that = this;
        this.tempPoint = new Vector3(0, 0, 0);
        this.x0y0z1Direction = new Vector3(0, 0, 1);
        this.x0y0z1Plane = new Plane(this.x0y0z1Direction, 0);//plane 0,0,1
        this.ray = new Ray(this.tempPoint, this.x0y0z1Plane);
        this.pointOfRay = false;
        this.pointsOfClipping = [];
        this.polyStruct = {
            vertex: [],
            elements: {},
            group: {},
        };
        this.data.res = {};

        this.timerA = {
            index: 0,
            timer: false,
            visible: false,
            sss: 2,
            count: 0,
            countCurrent: 1,
        };
        this.setting = {
            bestTransRate:.3,
            transCM: false,
            trans: false,
            transValueOld: false,
            transValue: 0,
            matrix: false,
            frameline: true,
            cm: {
                "use":'dispersed',
                "continuation": this.material.allTriangleShader,
                "dispersed": this.material.allTriangleShaderStair,
                "default": this.material.allTriangle
            },
            globalCMMM:true,

        };
        this.nameOfCloudmap = false;//当前云图的场
        this.data.pressuresMM = {
            lut: {
                min: false,
                max: false,
            },
            data: {
                min: false,
                max: false,
            },

        }
    }
    afterInit(that) {
        super.afterInit(that);
        // this.getPressureMMOfMesh();
        // this.getPressureMMOfMeshData();
    }


    repairPoly() {
        /*
                1,old line's pressure1
                2,new point's xyz   交点
                3,distance to AB form C 
                4,calculate point's pressure
                4,push[point]
                5,buffergeomory,linesegment
        */

        if (this.parent.plane.visible) {
            this.calcutePointOfClipping();
            this.drawPolyOfClipping();
        }
        this.setFramelineEnable(this.setting.frameline);
        this.setMeshClippingEnalbe(this.parent.plane.visible)

    }
    setFramelineEnable(value = true) {
        this.flagshow.line = value;
        this.setting.frameline = value;
        if (value) {
            if (typeof this.mesh.polyOfClipping != "undefined") {
                this.mesh.polyOfClipping.traverse(function (child) {
                    if (child.type === "LineSegments") {
                        child.visible = true;
                    }
                });
            }
        }
        else {
            if (typeof this.mesh.polyOfClipping != "undefined")
                this.mesh.polyOfClipping.traverse(function (child) {
                    if (child.type === "LineSegments") {
                        child.visible = false;
                    }
                });
        }
        this.parent.render();
    }
    endClipping() {
        this.parent.worldBox.remove(this.mesh.polyOfClipping);//有可能需mesh的geometry的.dispose ()
        // this.mesh.polyOfClipping = new Group();
    }
    setEnableFrameLine(value = true) {
        this.flagshow.line = value;
        this.setting.frameline = value;
        if (this.mesh.lines)
            this.mesh.lines.visible = value;
        if (this.mesh.allTriangleLines)
            this.mesh.allTriangleLines.visible = value;
        this.parent.render();
    }
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    calcutePointOfClipping() {
        if (this.meshType == 'gid') {
            this.calcutePointOfClipping_gid();
        }
        else if (this.meshType == 'divide') {
            this.calcutePointOfClipping_divide();
        }
        else if (this.meshType == 'abaqus') {
            this.calcutePointOfClipping_abaqus();
        }

    }
    calcutePointOfClipping_divide() {
        //this.data.pressures ，当前的数据源,array
        //this.data.forDraw.pointOfLinesIndex,array ,所有剖分的线段的数组
        //        数组值：  [{enable:true/false,points:[index1,index2]},{}]
        this.pointsOfClipping = [];
        this.pointsOfClippingOf001 = [];
        if (typeof this.data.forDraw.pointOfLinesIndex != "undefined") {
            for (let i in this.data.forDraw.pointOfLinesIndex) {// 线的集合，准备求与面的交点
                let perMesh = this.data.forDraw.pointOfLinesIndex[i];
                for (let perOne of perMesh) {
                    this.calcutePointsOfPeronePoly(perOne, this.data.source.nodes)
                }
            }
        }


    }
    calcutePointOfClipping_abaqus() {
        //this.data.pressures ，当前的数据源,array
        //this.data.forDraw.pointOfLinesIndex,array ,所有剖分的线段的数组
        //        数组值：  [{enable:true/false,points:[index1,index2]},{}]
        this.pointsOfClipping = [];
        this.pointsOfClippingOf001 = [];
        this.data.type = "abaqus";
        if (typeof this.data.source.instance != "undefined") {
            for (let i in this.data.source.instance) {// 线的集合，准备求与面的交点
                let perInstance = this.data.source.instance[i];
                let perPart = this.data.source.part[perInstance.part];
                if (typeof perPart.pointsOfPeronePart != "undefined") {
                    for (let j in perPart.pointsOfPeronePart) {
                        let allOnePart = perPart.pointsOfPeronePart[j];
                        for (let perOnePart of allOnePart)
                            this.calcutePointsOfPeronePoly(perOnePart, perPart.vertex, i)
                    }
                }
            }
        }
        let abc = 1;
    }
    calcutePointOfClipping_gid() {
        this.pointsOfClipping = [];
        this.pointsOfClippingOf001 = [];
        if (typeof this.data.forDraw.pointOfLinesIndex != "undefined") {
            for (let i in this.data.forDraw.pointOfLinesIndex) {// 线的集合，准备求与面的交点
                let perMesh = this.data.forDraw.pointOfLinesIndex[i];
                for (let perOne of perMesh) {
                    this.calcutePointsOfPeronePoly(perOne, this.data.source.mesh[i].nodes)
                }
            }
        }
        let abc = 1;
    }


    getPointXYZ(index) {

    }
    //perOne=[],为三维体的轮廓线-顶点线的集合
    //1、参加cloudmesh.js的calculateAllLines()
    //2、
    calcutePointsOfPeronePoly(perOne, nodes, nameOfInstance = false) {
        let points = [], point2D = [], pressures = [], colors = [], pindex = [];
        let M4_111 = [
            1,
            0,
            0,
            0,
            0,
            1,
            0,
            0,
            0,
            0,
            1,
            0,
            0,
            0,
            0,
            1
        ];
        // let perOne = this.data.forDraw.pointOfLinesIndex[i];
        for (let j = 0; j < perOne.length; j += 2) {


            if (j >= 22) {
                let abc = 1;
            }
            // console.log(i,j)
            let x1 = nodes[perOne[j + 0]][0];
            let y1 = nodes[perOne[j + 0]][1];
            let z1 = nodes[perOne[j + 0]][2];

            let x2 = nodes[perOne[j + 1]][0];
            let y2 = nodes[perOne[j + 1]][1];
            let z2 = nodes[perOne[j + 1]][2];

            let p1, p2;
            let xyz1 = perOne[j + 0];
            let xyz2 = perOne[j + 1];


            if (this.setting.trans == true) {//变形的

                // 变形比例没有改变
                if (this.setting.transValueOld == this.setting.transValue && this.setting.transValue != 0) {
                    p1 = this.data.M4PointsTrans[xyz1];
                    p2 = this.data.M4PointsTrans[xyz2];
                }
                else {//未应用过变形，第一次计算变形数据,或者，变形比例已经变化
                    //第一个点，p1

                    if (this.meshType == "abaqus") {
                        let per = perOne[j + 0];
                        let x, y, z;
                        let orgPressX = 0;
                        let orgPressY = 0;
                        let orgPressZ = 0;

                        if (this.data.resKey.transform.x)
                            orgPressX = this.data.res[this.timerA.countCurrent][this.data.resKey.transform.x].instance[nameOfInstance];
                        if (this.data.resKey.transform.x)
                            orgPressY = this.data.res[this.timerA.countCurrent][this.data.resKey.transform.y].instance[nameOfInstance];
                        if (this.data.resKey.transform.z)
                            orgPressZ = this.data.res[this.timerA.countCurrent][this.data.resKey.transform.z].instance[nameOfInstance];

                        x = orgPressX[per] * this.setting.transValue;
                        y = orgPressY[per] * this.setting.transValue;
                        z = orgPressZ[per] * this.setting.transValue;
                        p1 = new Vector3(x1 + x, y1 + y, z1 + z);

                        if (nameOfInstance) {
                            let M4 = false;
                            this.mesh.inpGroup.traverse(function (child) {
                                if (child.name == nameOfInstance) {
                                    M4 = child.matrix;
                                }
                            });

                            if (JSON.stringify(M4.elements) == JSON.stringify(M4_111)) {
                            }
                            else {
                                p1.applyMatrix4(M4);
                            }
                        }

                        p1.applyMatrix4(this.mesh.inpGroup.matrix);
                    }
                    else {
                        let x = 0, y = 0, z = 0;
                        let per = perOne[j + 0];
                        let ComponentNames = this.data.res[this.nameOfCloudmap[0]].ComponentNames;
                        x = this.data.res[this.nameOfCloudmap[0]]["value"][ComponentNames[0]][this.timerA.countCurrent][per] * this.setting.transValue;
                        y = this.data.res[this.nameOfCloudmap[0]]["value"][ComponentNames[1]][this.timerA.countCurrent][per] * this.setting.transValue;
                        z = this.data.res[this.nameOfCloudmap[0]]["value"][ComponentNames[2]][this.timerA.countCurrent][per] * this.setting.transValue;
                        p1 = new Vector3(x1 + x, y1 + y, z1 + z);
                        p1.applyMatrix4(this.mesh.allTriangle.matrix);
                    }
                    this.data.M4PointsTrans[xyz1] = p1;
                    //第二个点，p2
                    // p2 = new Vector3(x2, y2, z2);
                    if (this.meshType == "abaqus") {
                        let per = perOne[j + 1];
                        let x, y, z;
                        let orgPressX = 0;
                        let orgPressY = 0;
                        let orgPressZ = 0;

                        if (this.data.resKey.transform.x)
                            orgPressX = this.data.res[this.timerA.countCurrent][this.data.resKey.transform.x].instance[nameOfInstance];
                        if (this.data.resKey.transform.x)
                            orgPressY = this.data.res[this.timerA.countCurrent][this.data.resKey.transform.y].instance[nameOfInstance];
                        if (this.data.resKey.transform.z)
                            orgPressZ = this.data.res[this.timerA.countCurrent][this.data.resKey.transform.z].instance[nameOfInstance];

                        x = orgPressX[per] * this.setting.transValue;
                        y = orgPressY[per] * this.setting.transValue;
                        z = orgPressZ[per] * this.setting.transValue;
                        if (nameOfInstance) {
                            let M4 = false;
                            this.mesh.inpGroup.traverse(function (child) {
                                if (child.name == nameOfInstance) {
                                    M4 = child.matrix;
                                }
                            });
                            if (JSON.stringify(M4.elements) == JSON.stringify(M4_111)) {
                            }
                            else {
                                p2.applyMatrix4(M4);
                            }
                        }
                        p2 = new Vector3(x2 + x, y2 + y, z2 + z);
                        p2.applyMatrix4(this.mesh.inpGroup.matrix);
                    }
                    else {
                        let x = 0, y = 0, z = 0;
                        let per = perOne[j + 1];
                        let ComponentNames = this.data.res[this.nameOfCloudmap[0]].ComponentNames;
                        x = this.data.res[this.nameOfCloudmap[0]]["value"][ComponentNames[0]][this.timerA.countCurrent][per] * this.setting.transValue;
                        y = this.data.res[this.nameOfCloudmap[0]]["value"][ComponentNames[1]][this.timerA.countCurrent][per] * this.setting.transValue;
                        z = this.data.res[this.nameOfCloudmap[0]]["value"][ComponentNames[2]][this.timerA.countCurrent][per] * this.setting.transValue;
                        p2 = new Vector3(x2 + x, y2 + y, z2 + z);
                        p2.applyMatrix4(this.mesh.allTriangle.matrix);
                    }
                    this.data.M4PointsTrans[xyz2] = p2;
                }
            }
            else {
                //计算原始的线的点JSON对象，未变行的
                if (typeof this.data.M4Points[xyz1] == "undefined") {
                    p1 = new Vector3(x1, y1, z1);
                    // p2 = new Vector3(x2, y2, z2);
                    if (this.meshType == "abaqus") {
                        if (nameOfInstance) {
                            let M4 = false;
                            this.mesh.inpGroup.traverse(function (child) {
                                if (child.name == nameOfInstance) {
                                    M4 = child.matrix;
                                }
                            });
                            if (JSON.stringify(M4.elements) == JSON.stringify(M4_111)) {
                            }
                            else {
                                p1.applyMatrix4(M4);
                            }

                        }
                        p1.applyMatrix4(this.mesh.inpGroup.matrix);
                    }
                    else {
                        p1.applyMatrix4(this.mesh.allTriangle.matrix);
                    }
                    this.data.M4Points[xyz1] = p1;
                }
                else {
                    p1 = this.data.M4Points[xyz1];
                }
                if (typeof this.data.M4Points[xyz2] == "undefined") {

                    p2 = new Vector3(x2, y2, z2);
                    if (this.meshType == "abaqus") {
                        if (nameOfInstance) {
                            let M4 = false;
                            this.mesh.inpGroup.traverse(function (child) {
                                if (child.name == nameOfInstance) {
                                    M4 = child.matrix;
                                }
                            });

                            if (JSON.stringify(M4.elements) == JSON.stringify(M4_111)) {
                            }
                            else {
                                p2.applyMatrix4(M4);
                            }

                        }
                        p2.applyMatrix4(this.mesh.inpGroup.matrix);
                    }
                    else {
                        p2.applyMatrix4(this.mesh.allTriangle.matrix);
                    }
                    this.data.M4Points[xyz2] = p2;
                }
                else {
                    p2 = this.data.M4Points[xyz2];
                }
            }


            let line = new Line3(p1, p2);

            let v0 = new Vector3(0, 0, 0);
            let v1 = new Vector3();
            let v2 = new Vector3();
            this.parent.localPlane.intersectLine(line, v1);//交点
            let dis1 = this.parent.localPlane.distanceToPoint(p1);//到p1的距离

            //交点=原点或p1,p2
            let abc = true;
            if (((v1.x != v0.x || v1.y != v0.y || v1.z != v0.z) && !(v1.x == p1.x && v1.y == p1.y && v1.z == p1.z))
                ||
                ((v1.x == v0.x || v1.y == v0.y || v1.z == v0.z) && (v1.x == p1.x && v1.y == p1.y && v1.z == p1.z) && dis1 == 0)
            ) {
                let disOfp1p2 = p1.distanceTo(p2);
                let disOfp1V1 = p1.distanceTo(v1);
                let disOfp2V1 = p2.distanceTo(v1);

                // console.log("j=", j, "v1=", v1, "p1=", p1, "p2=", p2)
                points.push([v1.x, v1.y, v1.z]);//交点

                //向XY投影
                //投影点，平行（0，0，1）
                if (this.parent.localPlane.normal.x == this.x0y0z1Direction.x && this.parent.localPlane.normal.y == this.x0y0z1Direction.y && this.parent.localPlane.normal.z == this.x0y0z1Direction.z) {
                    point2D.push([v1.x, v1.y]);
                }
                else {
                    // this.ray.origin.x=v1.x;
                    // this.ray.origin.y=v1.y;
                    // this.ray.origin.z=v1.z;
                    this.ray.origin = v1;
                    this.ray.direction.x = this.parent.localPlane.normal.x;
                    this.ray.direction.y = this.parent.localPlane.normal.y;
                    this.ray.direction.z = this.parent.localPlane.normal.z;
                    if (v1.z > 0 && this.ray.direction.z > 0)
                        this.ray.direction.z = -this.ray.direction.z;
                    else if (v1.z < 0 && this.ray.direction.z < 0)
                        this.ray.direction.z = -this.ray.direction.z;
                    if (v1.z == 0.) {//交点在Z轴=0
                        point2D.push([v1.x, v1.y])
                    }
                    else {//投影
                        this.ray.intersectPlane(this.x0y0z1Plane, v2);
                        // console.log("v1=", v1,"v2=", v2);
                        point2D.push([v2.x, v2.y]);
                    }
                }
                let C;
                if (this.nameOfCloudmap == false) {
                    if (this.meshType == "abaqus")
                        colors.push([this.material.rgb.r, this.material.rgb.g, this.material.rgb.b]);
                    else
                        colors.push([this.material.rgbCM.r, this.material.rgbCM.g, this.material.rgbCM.b]);
                }
                else {


                    //pressure and color 
                    if (this.meshType == "abaqus") {
                        let p = this.data.res[this.timerA.countCurrent][this.nameOfCloudmap].instance[nameOfInstance];
                        C = (1 - disOfp1V1 / disOfp1p2) * p[perOne[j]] + disOfp1V1 / disOfp1p2 * p[perOne[j + 1]];
                    }
                    else {
                        let p = this.data.res[this.nameOfCloudmap[0]][this.timerA.countCurrent][this.nameOfCloudmap[1]];
                        // C = (1 - disOfp1V1 / disOfp1p2) * this.data.pressures.current[perOne[j]] + disOfp1V1 / disOfp1p2 * this.data.pressures.current[perOne[j + 1]];
                        C = (1 - disOfp1V1 / disOfp1p2) * p[perOne[j]] + disOfp1V1 / disOfp1p2 * p[perOne[j + 1]];
                    }
                    // console.log(j, "A=", parseInt(this.parent.LUT.lut.getColor(this.data.pressures.current[perOne[j]]).getHex()), "B=", parseInt(this.parent.LUT.lut.getColor(this.data.pressures.current[perOne[j + 1]]).getHex()), "C=", C);
                    // if (disOfp1V1 < disOfp1p2 / 2) {
                    //     C = this.data.pressures.current[perOne[j]];
                    // }
                    // else {
                    //     C = this.data.pressures.current[perOne[j + 1]];
                    // }
                    pressures.push(C);
                    if (this.parent.LUT) {
                        let c1 = this.parent.LUT.lut.getColor(C);
                        colors.push([c1.r, c1.g, c1.b]);
                    }
                    else {
                        colors.push([1, 1, 1]);
                    }
                }
                pindex.push([perOne[j], perOne[j + 1]]);
                // console.log("j,perOne[j] ,j+1:", j, perOne[j], perOne[j + 1], "p1=", p1, "p2=", p2, "v1=", v1, "pressure of C,p1,p2", C, this.data.pressures.current[perOne[j]], this.data.pressures.current[perOne[j + 1]], "disOfp1V1=", disOfp1V1, "disOfp1p2=", disOfp1p2)
            }
        }


        if (points.length >= 3) {
            // if (points.length) console.log(points.length, points)
            this.pointsOfClipping.push({
                points: points,
                pressures: pressures,
                colors: colors,
                index: pindex,
            });
            this.pointsOfClippingOf001.push(point2D);


        }
    }

    //计算多边形
    caclculateSmallTriangle(i) {
        let inputPoints = this.pointsOfClipping[i].points;
        let inputPressures = this.pointsOfClipping[i].pressures;
        let inputColors = this.pointsOfClipping[i].colors;
        let inputPoints2D = this.pointsOfClippingOf001[i];
        if (inputPoints.length < 3) return false;
        let points = [];
        let pointsOfLineLoop = [];
        let pressures = [];
        let colors = [];
        if (inputPoints.length == 3) {//only 3 points
            for (let i in inputPoints) {
                let perPoint = inputPoints[i];
                points.push(perPoint[0]);
                points.push(perPoint[1]);
                points.push(perPoint[2]);

                pressures.push(inputPressures[i]);
                colors.push(inputColors[i][0], inputColors[i][1], inputColors[i][2]);
            }
            pointsOfLineLoop.push(new Vector3(inputPoints[0][0], inputPoints[0][1], inputPoints[0][2]));
            pointsOfLineLoop.push(new Vector3(inputPoints[1][0], inputPoints[1][1], inputPoints[1][2]));
            pointsOfLineLoop.push(new Vector3(inputPoints[1][0], inputPoints[1][1], inputPoints[1][2]));
            pointsOfLineLoop.push(new Vector3(inputPoints[2][0], inputPoints[2][1], inputPoints[2][2]));
            pointsOfLineLoop.push(new Vector3(inputPoints[2][0], inputPoints[2][1], inputPoints[2][2]));
            pointsOfLineLoop.push(new Vector3(inputPoints[0][0], inputPoints[0][1], inputPoints[0][2]));
        } else {
            let cell = triangulate(inputPoints2D);
            // console.log(cell,inputPoints2D)
            let llll = {};
            for (let j in cell) {
                let perOne = cell[j];
                //triangle's points
                for (let k in perOne) {
                    let per = perOne[k];
                    points.push(inputPoints[per][0]);
                    points.push(inputPoints[per][1]);
                    points.push(inputPoints[per][2]);

                    pressures.push(inputPressures[per]);
                    colors.push(inputColors[per][0], inputColors[per][1], inputColors[per][2]);
                }
                //lines of squr
                let perLine1 = [perOne[0], perOne[1]];
                let perLine2 = [perOne[1], perOne[2]];
                let perLine3 = [perOne[2], perOne[0]];
                let perLine_1 = JSON.parse(JSON.stringify(perLine1)).sort().join("-");
                let perLine_2 = JSON.parse(JSON.stringify(perLine2)).sort().join("-");
                let perLine_3 = JSON.parse(JSON.stringify(perLine3)).sort().join("-");
                if (typeof llll[perLine_1] == "undefined") {
                    llll[perLine_1] = {
                        enable: true,
                        index: perLine1
                    };
                }
                else {
                    llll[perLine_1].enable = false;
                }

                if (typeof llll[perLine_2] == "undefined") {
                    llll[perLine_2] = {
                        enable: true,
                        index: perLine2
                    };
                }
                else {
                    llll[perLine_1].enable = false;
                }

                if (typeof llll[perLine_3] == "undefined") {
                    llll[perLine_3] = {
                        enable: true,
                        index: perLine3
                    };
                }
                else {
                    llll[perLine_3].enable = false;
                }
            }
            // console.log(llll)
            for (let i in llll) {
                if (llll[i].enable) {
                    let p1 = new Vector3(inputPoints[llll[i].index[0]][0], inputPoints[llll[i].index[0]][1], inputPoints[llll[i].index[0]][2]);
                    let p2 = new Vector3(inputPoints[llll[i].index[1]][0], inputPoints[llll[i].index[1]][1], inputPoints[llll[i].index[1]][2]);
                    pointsOfLineLoop.push(p1, p2);
                }
            }
            for (let i in inputPoints) {
                let perPoint = inputPoints[i];
                pointsOfLineLoop.push(new Vector3(perPoint[0], perPoint[1], perPoint[2]));
            }
        }
        if (points.length) return { points: points, pointsOfLineLoop: pointsOfLineLoop, pressures: pressures, colors: colors };
        else return false;
    }

    drawPolyOfClipping() {
        let temp = [];
        this.parent.worldBox.remove(this.mesh.polyOfClipping);//有可能需mesh的geometry的.dispose ()
        this.mesh.polyOfClipping = new Group();
        this.parent.worldBox.add(this.mesh.polyOfClipping);
        // this.mesh.polyOfClipping.applyMatrix4(this.mesh.allTriangle.matrix);

        for (let i in this.pointsOfClipping) {
            let perOne = this.pointsOfClipping[i];//每组
            if (this.meshType == "divide")
                for (let i in perOne.points) {
                    const geometry = new BoxGeometry(1, 1, 1);
                    const material = new MeshBasicMaterial({ color: new Color(perOne.colors[i][0], perOne.colors[i][1], perOne.colors[i][2]) });
                    const cube = new Mesh(geometry, material);
                    cube.position.x = perOne.points[i][0];
                    cube.position.y = perOne.points[i][1];
                    cube.position.z = perOne.points[i][2];
                    this.scene.add(cube);
                }

            let all = this.caclculateSmallTriangle(i);
            // console.log(all)
            if (all == false) break;

            let geometry = new BufferGeometry();
            let vertices = new Float32Array(all.points)
            geometry.setAttribute('position', new BufferAttribute(vertices, 3));
            geometry.setAttribute('color', new Float32BufferAttribute(new Float32Array(all.colors), 3));
            geometry.setAttribute('pressure', new Float32BufferAttribute(new Float32Array(all.pressures), 1));
            // console.log("position , color ,pressure",all)
            // let material = new MeshBasicMaterial({ color: this.color.planeColor, side: DoubleSide });
            // let material = new MeshBasicMaterial({ color: new Color(Math.random(), Math.random(), Math.random()), side: DoubleSide });
            // let mesh = new Mesh(geometry, material);
            let mesh = new Mesh(geometry, this.material.polyOfClipping);
            // mesh.applyMatrix4(this.mesh.allTriangle.matrix);
            this.mesh.polyOfClipping.add(mesh);


            let line = new BufferGeometry().setFromPoints(all.pointsOfLineLoop);
            // line.applyMatrix4(this.mesh.allTriangle.matrix)
            this.mesh.polyOfClipping.add(new LineSegments(line, this.material.lineClipping));

        }
        // if (this.data.type)
        //     this.mesh.polyOfClipping.applyMatrix4(this.mesh.inpGroup.matrix);
        // else
        //     this.mesh.polyOfClipping.applyMatrix4(this.mesh.allTriangle.matrix);
    }
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //


    setMeshClippingEnalbe(enable = false) {
        if (typeof this.mesh.polyOfClipping != "undefined")
            this.mesh.polyOfClipping.visible = enable;
    }


    // getPressureMMOfMeshData() {
    //     this.data.pressuresMM.data.max = Math.max.apply(null, this.data.pressures.current);
    //     this.data.pressuresMM.data.min = Math.min.apply(null, this.data.pressures.current);
    // }
    // getPressureMMOfMesh() {
    //     if (this.parent.LUT)
    //         this.data.pressuresMM.lut.max = this.parent.LUT.lut.maxV;
    //     this.data.pressuresMM.lut.min = this.parent.LUT.lut.minV;
    // }
    // getPressureOfLUT(value = false) {
    //     if (this.parent.LUT && value) {
    //         if (this.data.pressuresMM.data.max && this.data.pressuresMM.data.min && this.data.pressuresMM.lut.max && this.data.pressuresMM.lut.min) {

    //             return this.parent.LUT.lut.getColor(value);
    //         }
    //     }
    //     return false;
    // }
}

export { baseCM }