import {
    BufferGeometry,
    Mesh,
    Vector3,
    Vector2,
    Color,
    MeshStandardMaterial,
    MeshBasicMaterial,
    LineBasicMaterial,
    MeshPhongMaterial,
    ShaderMaterial,
    Line,
    Line3,
    Plane,
    Group,
    BufferAttribute,
    DoubleSide,
    LineSegments,
    Ray,
    Float32BufferAttribute,
    LineLoop,
    FileLoader,
    ObjectLoader,
    SphereGeometry,
    Box3
} from 'three';

import { cloudAnsysCM } from "./cloudmsh"

class cloudAnsysCMshader extends cloudAnsysCM {
    constructor(parent, inputFile = false) {
        // this.inputFile = inputFile;
        super(parent, inputFile);
        return this;
    }
    //显示云图，value是字符串，exp:unodb0.sxx
    show(value = false) {
        if (value) {
            let temp = value.split(".");
            this.nameOfCloudmap = temp;
        }
        if (this.nameOfCloudmap) {
            this.doTrans();
            this.updatePressures(this.data.res[this.nameOfCloudmap[0]][this.timerA.countCurrent][this.nameOfCloudmap[1]]);
            // this.updateColors();
        }
        if (this.flageClipping) {
            this.repairPoly()
        }
        this.centerForTrains();
        this.parent.render();
    }
    stop() {
        clearInterval(this.timerA.timer);
        this.timerA.visible = false;
        this.timerA.index = 0;
    }
    play(s = false, ComponentNames = false) {
        if (s) this.timerA.sss = s;
        if (this.nameOfCloudmap !== false) {
            let list = Object.keys(this.data.res[this.nameOfCloudmap[0]]).length;
            if (list > 1) {
                this.timerA.count = list;

                this.timerA.visible = true;
                this.timerIndexList = this.getRESTimerList();
                this.timerIndex = 0;
                this.timerA.countCurrent = this.timerIndexList[this.timerIndex];


                this.timerA.timer = setInterval(function () {

                    if (this.setting.trans) {
                        this.doTrans(ComponentNames);
                        // if (ComponentNames == false) {
                        //     ComponentNames = this.getDefaultTrans();
                        // }
                        // if (ComponentNames) this.doTrans(ComponentNames);
                    }

                    this.updatePressures(this.data.res[this.nameOfCloudmap[0]][this.timerA.countCurrent][this.nameOfCloudmap[1]]);
                    // this.updateColors();
                    this.parent.render();
                    if (this.timerA.visible == false) {
                        clearInterval(this.timerA.timer);
                    }

                    if (this.timerIndex >= this.timerIndexList.length - 1) {
                        this.timerIndex = 0;
                        this.timerA.countCurrent = this.timerIndexList[this.timerIndex];
                    }
                    else {
                        this.timerIndex++;
                        this.timerA.countCurrent = this.timerIndexList[this.timerIndex];
                    }
                    // console.log(this.timerA.countCurrent);
                }, s * 1000);
            }
        }
        else {
            console.log("plases select one value of 参数")
            return { type: false, msg: "none value for select" }
        }
    }
    callDraw() {
        this.calculateTrangle();
        this.drawTrangleAll();
        this.calculateAllLines();
        // this.calculateSurfaceLines();
        if (this.flagshow.line)
            this.drawAllLines();
        // this.afterInit();
        this.center(this.mesh.allTriangle);
        this.center(this.mesh.allTriangleLines);
        if (typeof this.mesh.linesOfMesh != 'undefined') {
            this.center(this.mesh.linesOfMesh, this.mesh.allTriangle);
        }
        // if (this.theEndOfDraw.length > 0) {
        //     for (let fun in this.theEndOfDraw)
        //         fun(that);
        // }
        this.parent.render();
    }
    //draw 三角形，
    drawTrangleAll() {
        this.mesh.allTriangle = new Group();
        for (let meshName in this.data.forDraw.trangle) {
            let per = this.data.forDraw.trangle[meshName];
            let ii = 1;
            let material = new ShaderMaterial({
                uniformsNeedUpdate: true,
                uniforms: {
                    time: { value: 1.0 },
                    val: { value: [1, 1, 1] },
                    resolution: { value: new Vector2() }
                },
                side: DoubleSide,
                vertexShader: "        precision mediump float;       precision mediump int;    attribute vec4 color;    uniform vec3 val;      varying vec2 vuv;      varying vec3 vposition;    void main() {      vposition = position;   vuv = uv;  gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );   }",
                //'attribute vec4 color;uniform vec3 val;varying vec2 vuv;   varying vec3 vposition;void main() {        vposition = position;    vuv = uv;    gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );}',
                fragmentShader: "precision mediump float;                        precision mediump int;                  uniform vec3 val;                                        varying vec2 vuv;                        varying vec3 vposition;                        void main() {                            float w1 = (1.0 - vuv.x);                            float w2 = (vuv.x - vuv.y);                            float w3 = vuv.y;                            float v = w1*val[0] + w2*val[1] + w3*val[2];                            if( v <= 0.25 ) {                                gl_FragColor = vec4(0.0, v/0.25, 1.0, 1.0);                            }else if ( v > 0.25 && v <= 0.5) {                                gl_FragColor = vec4(0.0, 1.0, 1.0-(v-0.25)/0.25 , 1.0);                            }else if ( v > 0.5 && v <= 0.75) {                                gl_FragColor = vec4( (v-0.5)/0.25, 1.0, 0.0, 1.0);                            }else{                                gl_FragColor = vec4( 1.0, 1.0-(v-0.75)/0.25, 0.0, 1.0);                            }                        }                          ",
                // " precision mediump float;        precision mediump int;   uniform vec3 val;       varying vec2 vuv;        varying vec3 vposition;        void main() {            float w1 = (1.0 - vuv.x);            float w2 = (vuv.x - vuv.y);            float w3 = vuv.y;            float v = w1*val[0] + w2*val[1] + w3*val[2];            if( v <= 0.25 ) {                gl_FragColor = vec4(0.0, v/0.25, 1.0, 1.0);            }else if ( v > 0.25 && v <= 0.5) {                gl_FragColor = vec4(0.0, 1.0, 1.0-(v-0.25)/0.25 , 1.0);            }else if ( v > 0.5 && v <= 0.75) {                gl_FragColor = vec4( (v-0.5)/0.25, 1.0, 0.0, 1.0);            }else{                gl_FragColor = vec4( 1.0, 1.0-(v-0.75)/0.25, 0.0, 1.0);            }        }        ",
                polygonOffset: true,
                polygonOffsetFactor: 1.5,
                polygonOffsetUnits: 1.5,
            });
            for (let i in per) {
                let perOne = per[i];
                let points = [];
                let pressures = [];
                let colors = [];
                let normals = [];
                let uv1 = new Float32Array([
                    0.0, 0,
                    1.0, 0,
                    0, 1.0,
                ]);
                let uv2 = new Float32Array([
                    1, 0,
                    1, 1,
                    0, 1,
                ]);
                let uvN = 1;
                if (perOne.enable) {
                    for (let j of perOne.points) {
                        // let perPoint=perOne.points[j];
                        points.push(this.data.source.mesh[meshName].nodes[j][0]);
                        points.push(this.data.source.mesh[meshName].nodes[j][1]);
                        points.push(this.data.source.mesh[meshName].nodes[j][2]);
                        pressures.push(1);
                        // colors.push(1, 1, 1);
                        colors.push(this.material.rgbCM.r, this.material.rgbCM.g, this.material.rgbCM.b);
                        normals.push(0, 0, 1);

                        this.data.forDraw.pointOfTrangleAll.push(j);//update pressures use
                    }
                    let geometry = new BufferGeometry();
                    const vertices = new Float32Array(points);
                    geometry.setAttribute('position', new BufferAttribute(vertices, 3));
                    geometry.setAttribute('color', new Float32BufferAttribute(new Float32Array(colors), 3));
                    geometry.setAttribute('normal', new Float32BufferAttribute(new Float32Array(normals), 3));
                    geometry.computeVertexNormals();
                    geometry.setAttribute('pressure', new Float32BufferAttribute(new Float32Array(pressures), 1));
                    if (uvN == 1) {
                        geometry.setAttribute('uv', new Float32BufferAttribute(uv1, 2));
                        uvN = 2;
                    }
                    else if (uvN == 2) {
                        geometry.setAttribute('uv', new Float32BufferAttribute(uv2, 2));
                        uvN = 1;
                    }
                    // let material = new     MeshStandardMaterial({ color: 0xff0000, });
                    // this.mesh.allTriangle = new Mesh(this.geometry.allTriangle, material);

                    let a = [.0, .0, .0];
                    let b = [1.0, 1.0, 1.0];
                    let c;
                    if (ii == 1) {
                        c = a;
                        ii = 2
                    }
                    else if (ii == 2) {
                        c = b;
                        ii = 1;
                    }
                    let M1 = material.clone();
                    M1.uniforms = {
                        time: { value: 1.0 },
                        val: { value: c },
                        // resolution: { value: new Vector2() }
                    };
                    // let material = new ShaderMaterial({
                    //     uniforms: {
                    //         time: { value: 1.0 },
                    //         val: { value:c},
                    //         resolution: { value: new Vector2() }
                    //     },
                    //     side: DoubleSide,
                    //     vertexShader: "        precision mediump float;       precision mediump int;    attribute vec4 color;    uniform vec3 val;      varying vec2 vuv;      varying vec3 vposition;    void main() {      vposition = position;   vuv = uv;  gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );   }",
                    //     //'attribute vec4 color;uniform vec3 val;varying vec2 vuv;   varying vec3 vposition;void main() {        vposition = position;    vuv = uv;    gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );}',
                    //     fragmentShader: "precision mediump float;                        precision mediump int;                  uniform vec3 val;                                        varying vec2 vuv;                        varying vec3 vposition;                        void main() {                            float w1 = (1.0 - vuv.x);                            float w2 = (vuv.x - vuv.y);                            float w3 = vuv.y;                            float v = w1*val[0] + w2*val[1] + w3*val[2];                            if( v <= 0.25 ) {                                gl_FragColor = vec4(0.0, v/0.25, 1.0, 1.0);                            }else if ( v > 0.25 && v <= 0.5) {                                gl_FragColor = vec4(0.0, 1.0, 1.0-(v-0.25)/0.25 , 1.0);                            }else if ( v > 0.5 && v <= 0.75) {                                gl_FragColor = vec4( (v-0.5)/0.25, 1.0, 0.0, 1.0);                            }else{                                gl_FragColor = vec4( 1.0, 1.0-(v-0.75)/0.25, 0.0, 1.0);                            }                        }                          ",
                    //     // " precision mediump float;        precision mediump int;   uniform vec3 val;       varying vec2 vuv;        varying vec3 vposition;        void main() {            float w1 = (1.0 - vuv.x);            float w2 = (vuv.x - vuv.y);            float w3 = vuv.y;            float v = w1*val[0] + w2*val[1] + w3*val[2];            if( v <= 0.25 ) {                gl_FragColor = vec4(0.0, v/0.25, 1.0, 1.0);            }else if ( v > 0.25 && v <= 0.5) {                gl_FragColor = vec4(0.0, 1.0, 1.0-(v-0.25)/0.25 , 1.0);            }else if ( v > 0.5 && v <= 0.75) {                gl_FragColor = vec4( (v-0.5)/0.25, 1.0, 0.0, 1.0);            }else{                gl_FragColor = vec4( 1.0, 1.0-(v-0.75)/0.25, 0.0, 1.0);            }        }        ",
                    //     polygonOffset: true,
                    //     polygonOffsetFactor: 1.5,
                    //     polygonOffsetUnits: 1.5,
                    // });


                    let mesh = new Mesh(geometry, M1);
                    mesh.name = i;
                    mesh.meshname = meshName;
                    this.mesh.allTriangle.add(mesh);
                }
            }
        }
        this.worldBox.add(this.mesh.allTriangle);
    }
    doTrans(ComponentNames = false) {
        this.confirmTrans();
        if (ComponentNames == false) {
            ComponentNames = this.getDefaultTrans();
        }
        if (ComponentNames) {
            let points = [];
            // let ComponentNames = this.data.res[this.nameOfCloudmap[0]].ComponentNames;
            for (let meshName in this.data.forDraw.trangle) {
                let per = this.data.forDraw.trangle[meshName];
                for (let i in per) {
                    let perOne = per[i];
                    if (perOne.enable) {
                        for (let j of perOne.points) {
                            let x1 = 0, x2 = 0, x3 = 0, y1 = 0, y2 = 0, y3 = 0, z1 = 0, z2 = 0, z3 = 0;
                            if (this.setting.trans) {
                                x1 = this.data.res[ComponentNames[0]][this.timerA.countCurrent][ComponentNames[1][0]][j] * this.setting.transValue;
                                y1 = this.data.res[ComponentNames[0]][this.timerA.countCurrent][ComponentNames[1][1]][j] * this.setting.transValue;
                                if (this.data.source.dimension != 2)
                                    z1 = this.data.res[ComponentNames[0]][this.timerA.countCurrent][ComponentNames[1][2]][j] * this.setting.transValue;

                            }
                            // let perPoint=perOne.points[j];
                            points.push(this.data.source.mesh[meshName].nodes[j][0] + x1);
                            points.push(this.data.source.mesh[meshName].nodes[j][1] + y1);
                            points.push(this.data.source.mesh[meshName].nodes[j][2] + z1);
                        }
                        const vertices = new Float32Array(points);
                        let mesh = this.mesh.allTriangle.getObjectByName(i);
                        mesh.geometry.setAttribute('position', new BufferAttribute(vertices, 3));
                    }
                }
            }


            // if (this.setting.matrix)
            //     this.geometry.allTriangle.applyMatrix4(this.setting.matrix);

            if (Object.keys(this.data.forDraw.lines).length > 0) {
                this.doLinesOfMesh(ComponentNames);
            }
            if (this.setting.frameline)
                this.doTransLines(ComponentNames);
        }
    }


    //update this.geometry.allTriangle pressures
    updatePressures(data) {
        let material = new ShaderMaterial({
            uniformsNeedUpdate: true,
            uniforms: {
                time: { value: 1.0 },
                val: { value: [1, 1, 1] },
                resolution: { value: new Vector2() }
            },
            side: DoubleSide,
            vertexShader: "        precision mediump float;       precision mediump int;    attribute vec4 color;    uniform vec3 val;      varying vec2 vuv;      varying vec3 vposition;    void main() {      vposition = position;   vuv = uv;  gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );   }",
            //'attribute vec4 color;uniform vec3 val;varying vec2 vuv;   varying vec3 vposition;void main() {        vposition = position;    vuv = uv;    gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );}',
            fragmentShader: "precision mediump float;                        precision mediump int;                  uniform vec3 val;                                        varying vec2 vuv;                        varying vec3 vposition;                        void main() {                            float w1 = (1.0 - vuv.x);                            float w2 = (vuv.x - vuv.y);                            float w3 = vuv.y;                            float v = w1*val[0] + w2*val[1] + w3*val[2];                            if( v <= 0.25 ) {                                gl_FragColor = vec4(0.0, v/0.25, 1.0, 1.0);                            }else if ( v > 0.25 && v <= 0.5) {                                gl_FragColor = vec4(0.0, 1.0, 1.0-(v-0.25)/0.25 , 1.0);                            }else if ( v > 0.5 && v <= 0.75) {                                gl_FragColor = vec4( (v-0.5)/0.25, 1.0, 0.0, 1.0);                            }else{                                gl_FragColor = vec4( 1.0, 1.0-(v-0.75)/0.25, 0.0, 1.0);                            }                        }                          ",
            // " precision mediump float;        precision mediump int;   uniform vec3 val;       varying vec2 vuv;        varying vec3 vposition;        void main() {            float w1 = (1.0 - vuv.x);            float w2 = (vuv.x - vuv.y);            float w3 = vuv.y;            float v = w1*val[0] + w2*val[1] + w3*val[2];            if( v <= 0.25 ) {                gl_FragColor = vec4(0.0, v/0.25, 1.0, 1.0);            }else if ( v > 0.25 && v <= 0.5) {                gl_FragColor = vec4(0.0, 1.0, 1.0-(v-0.25)/0.25 , 1.0);            }else if ( v > 0.5 && v <= 0.75) {                gl_FragColor = vec4( (v-0.5)/0.25, 1.0, 0.0, 1.0);            }else{                gl_FragColor = vec4( 1.0, 1.0-(v-0.75)/0.25, 0.0, 1.0);            }        }        ",
            polygonOffset: true,
            polygonOffsetFactor: 1.5,
            polygonOffsetUnits: 1.5,
        });
        let MM = this.getMaxMin(data);
        for (let meshName in this.data.forDraw.trangle) {
            let per = this.data.forDraw.trangle[meshName];
            let ii = 1;
            for (let i in per) {
                let perOne = per[i];

                if (perOne.enable) {
                    let pressures = [(data[perOne.points[0]] - MM[1]) / (MM[0] - MM[1]), (data[perOne.points[1]] - MM[1]) / (MM[0] - MM[1]), (data[perOne.points[2]] - MM[1]) / (MM[0] - MM[1])];
                    // console.log(i, pressures, data[perOne.points[0]], data[perOne.points[1]], data[perOne.points[2]], MM[0], MM[1]);
                    let mesh = this.mesh.allTriangle.getObjectByName(i);
                    mesh.material.uniforms.val.value[0] = pressures[0];
                    mesh.material.uniforms.val.value[1] = pressures[1];
                    mesh.material.uniforms.val.value[2] = pressures[2];
                    // this.parent.render();
                    // if (ii ==1) {
                    //     mesh.material['uniforms']['val'].value =  [.8, .8, .8] ;
                    //     ii=2;
                    // }
                    // else  {
                    //     mesh.material['uniforms']['val'].value =  [.08, .08, .08] ;
                    //     ii=1;
                    // }

                    // let a = [.50, .50, .50];
                    // let b = [.0, .0, .0];
                    // let c;
                    // if (ii == 1) {
                    //     c = a;
                    //     ii = 2
                    // }
                    // else if (ii == 2) {
                    //     c = b;
                    //     ii = 1;
                    // }
                    // let M1 = material.clone();
                    // M1.uniforms =  {
                    //     time: { value: 1.0 },
                    //     val: { value: c },
                    //     // resolution: { value: new Vector2() }
                    // };
                    // mesh.material=M1;
                    // console.log(c)
                }
            }
        }

        if (Object.keys(this.data.forDraw.lines).length > 0) {
            for (let Mi in this.data.forDraw.lines) {
                let perMeshLines = this.data.forDraw.lines[Mi];
                let colorsOfLines = [];
                let pressuresOfLines = [];
                for (let Li in perMeshLines) {
                    let perLine = perMeshLines[Li].points;
                    for (let Pi of perLine) {
                        pressuresOfLines.push(data[Pi]);
                    }
                }
                this.geometry.linesOfMesh[Mi].setAttribute('pressure', new Float32BufferAttribute(new Float32Array(pressuresOfLines), 1));
            }
        }
        // this.data.pressures.current = pressures;
        this.data.pressures.current = data;
        let abc = 1;
    }

    getMaxMin(json) {
        let max = false;
        let min = false;
        for (let i in json) {
            // if (i > 6648) {
            //     let abc = 1;
            // }
            if (max === false) {
                max = json[i];
                min = json[i];
            }
            if (max < json[i]) {
                max = json[i];
            }
            if (min > json[i]) {
                min = json[i];
            }
        }
        return [max, min];
    }
    //update colors by LUT 
    updateColors() {
        if (this.parent.LUT) {
            let lut = this.parent.LUT.lut;
            let sprite = this.parent.LUT.sprite;
            this.parent.LUT.setMaxMin(this.data.pressures.current);//顺序顶点的pressure值
            let mesh = this.mesh.allTriangle;
            let geometry = mesh.geometry;
            let pressures = geometry.attributes.pressure;
            let colors = geometry.attributes.color;
            for (let i = 0; i < pressures.array.length; i++) {
                const colorValue = pressures.array[i];
                const color = lut.getColor(colorValue);
                if (color === undefined) {
                    //////console.log('Unable to determine color for value:', colorValue);
                } else {
                    colors.setXYZ(i, color.r, color.g, color.b);
                }
            }
            colors.needsUpdate = true;
            let map = sprite.material.map;
            this.parent.LUT.update_text(" ");
            map.needsUpdate = true;
            let abc = 1;

            if (Object.keys(this.data.forDraw.lines).length > 0) {
                this.mesh.linesOfMesh.traverse(function (child) {
                    if (child.type === "LineSegments") {
                        let geometry = child.geometry;
                        let pressures = geometry.attributes.pressure;
                        let colors = geometry.attributes.color;
                        for (let i = 0; i < pressures.array.length; i++) {
                            const colorValue = pressures.array[i];
                            const color = lut.getColor(colorValue);
                            if (color === undefined) {
                                //////console.log('Unable to determine color for value:', colorValue);
                            } else {
                                colors.setXYZ(i, color.r, color.g, color.b);
                            }
                        }
                        colors.needsUpdate = true;
                    }
                })
            }
        }
    }
}

export { cloudAnsysCMshader }