// version v.1.5
// abquas 时间历程使用
import {
    BufferGeometry,
    Mesh,
    Vector3,
    Color,
    MeshStandardMaterial,
    MeshBasicMaterial,
    LineBasicMaterial,
    MeshPhongMaterial,
    Line,
    Line3,
    Plane,
    Group,
    BufferAttribute,
    DoubleSide,
    LineSegments,
    Ray,
    Float32BufferAttribute,
    LineLoop,
    FileLoader,
    ObjectLoader,
    Matrix4,
    EdgesGeometry, Box3,
    ArrowHelper,
    Points,
    SphereGeometry,
    BoxGeometry
} from 'three';

import { cloudAnsysCM } from "./cloudmsh";
import { baseCM } from "../csmesh/basecm.js";

let thisINP;
class inpCM2D extends baseCM {
    constructor(parent, zipFile = false) {
        super(parent, zipFile);
    }


    beforeInit() {
        this.data.resKey = {
            // data input json
            // file: "data",
            // title: "Computation algorithm: EXTRAPOLATE_COMPUTE_AVERAGE",
            pressure_kind: {
                //   "U.Magnitude": 2,
                //   "U.U1": 3,
                //   "U.U2": 4,
                //   "U.U3": 5,
                //   "S.Mises": 6,
                //   "S.S11": 7,
                //   "S.S22": 8,
                //   "S.S33": 9,
                //   "S.S12": 10,
                //   "S.S13": 11,
                //   "S.S23": 12,
            },
            pressure_title: {
                //   "U.Magnitude": "U.Magnitude",
                //   "U.U1": "U.U1",
                //   "U.U2": "U.U",
                //   "U.U3": "U.U3",
                //   "S.Mises": "S.Mises",
                //   "S.S11": "S.S11",
                //   "S.S22": "S.S22",
                //   "S.S33": "S.S33",
                //   "S.S12": "S.S12",
                //   "S.S13": "S.S13",
                //   "S.S23": "S.S23",
            },
            part_split: {
                title: ["for region: ", "for part: "],
                split: [
                    [":", 1],
                    [" ", 1],
                    [".Region", 0],
                ], //分割顺序与特征与保留部分
            },
            Pt_Data: {
                start_line: "------------------",
                end_line: "",
            },
            transform: {
                x: false,
                y: false,
                z: false,
                array: [],
            },
        }
        thisINP = this;
        this.meshType = "abaqus";
        super.beforeInit();

    }

    initFormat() {
        // this.data.resKey = this.zipFile.resKey;


        if (this.zipFile.ziped) {
            this.zipFile.list = {};

            let perOne = this.getfirstEXTFile("inp");
            this.zipFile.list[perOne] = [thisINP.getFileMesh, 0];

            let list = this.getfirstEXTFileList("dat");
            if (list.length > 0) {
                let ii = 1;
                for (let j in list) {
                    let secndPerOne = list[j]
                    this.zipFile.list[secndPerOne] = [thisINP.getRES, ii++];
                }
            }
        }//end of if 

    }
    init() {

        // console.log(this.zipFile)
    }
    afterInit(that) {
        super.afterInit(that);
    }
    ////////////////////////////////////////////////////////////////////////////////
    //  inp file's function  about structe
    // 

    getFileMesh(value) {
        let inpStringSplit = {
            Part_name: {
                split: [",", "name="],
                result: ["*Part",],
            }
        }
        let text = value[0];
        let index = value[1];
        if (text) {
            let msh_file_lines = text.split('\n');

            let Step_name = false;
            let subStep_name = false;
            let flag_subStep_Name = false;
            let flag_substar = false;
            let flag_subStep_LOADS = false;
            let flag_subStep_INTERACTIONS = false;
            let flag_subStep_OUTPUTREQUESTS = false;
            let flag_subStep_FIELDOUTPUT = false;
            let flag_subStep_HISTORYOUTPUT = false;

            let flag_loadName = false;
            let flag_loadName_content = false;

            let Assembly_name = false;
            let Assembly_instance = false;
            let Assembly_Nset = false;
            let Assembly_Elset = false;
            let Assembly_Surface = false;
            let Assembly_Surface_type = false;

            let Instance_name = false;
            let Part_name = false;
            let Material_name = false;
            let subMaterial_name = false;
            let subMaterial_content_name = false;

            let BOUNDARY_name = false;
            let BOUNDARY_name_inStep = false;
            let subBOUNDARY_name = false;
            let subBOUNDARY_type = false;
            let subBOUNDARY_flag = false;

            let subINTERACTIONS = false;
            let subINTERACTIONS_kind = false;

            let STEP_name = false;

            let flag_node = false;//标志位，
            let flag_element = false;//标志位，
            let flag_elementType = "";
            let flag_Nset = false;
            let flag_Elset = false;
            let flag_Section = false;
            let flag_Section_Elset = false;
            let flag_Material = false;

            let flag_NsetGenerate = false;
            let flag_ElsetGenerate = false;


            for (var item_1 of msh_file_lines) {// 每行
                ////console.log(item_1,item_1.split('\r')[0].length);
                let temp_1 = item_1.split('\r')[0].split(',');
                //判断大的块区
                if (temp_1[0] == '*Part') {
                    let name = temp_1[1].split('name=')[1].replace(/\"/g, '');
                    //console.log(temp_1[0], name[1]);
                    Part_name = name.toLocaleLowerCase();
                    this.data.source.part[Part_name] = {};
                    this.data.source.part[Part_name].element = {};//change  new 20210831
                    Instance_name = false;
                    flag_node = false;
                    flag_element = false;
                    flag_Elset = false;
                    flag_Nset = false;
                    flag_Section = false;
                    continue;
                }
                else if (temp_1[0] == '*Instance') {
                    let name = temp_1[1].split('name=')[1].replace(/\"/g, '');
                    let part = temp_1[2].split('part=')[1].replace(/\"/g, '');
                    Instance_name = name.toLowerCase();
                    if (typeof this.data.source.instance.Instance_name == "undefined") {
                        this.data.source.instance[Instance_name] = {};
                        this.data.source.instance[Instance_name].part = part.toLowerCase();
                    }
                    Part_name = false;
                    flag_node = false;
                    flag_element = false;
                    flag_Elset = false;
                    flag_Nset = false;
                    flag_Section = false;
                    continue;
                }
                else if (temp_1[0] == '*Assembly') {
                    let name = temp_1[1].split('name=')[1].replace(/\"/g, '');
                    Assembly_name = name.toLocaleLowerCase();
                    continue;
                }
                else if (temp_1[0] == '** MATERIALS') {
                    // Material_name = temp_1[1].split('name=')[1].replace(/\"/g, '');
                    Material_name = true;
                    continue;
                }
                else if (temp_1[0] == '** BOUNDARY CONDITIONS' && Step_name == false) {
                    flag_subStep_Name = false;//关闭setp name部分的input
                    // let name = temp_1[1].split('name=')[1].replace(/\"/g, '');
                    Material_name = false;
                    subMaterial_name = false;
                    BOUNDARY_name = true;
                    BOUNDARY_name_inStep = false;
                    continue;
                }
                else if (temp_1[0] == '** BOUNDARY CONDITIONS' && Step_name == true) {
                    flag_subStep_Name = false;//关闭setp name部分的input
                    // let name = temp_1[1].split('name=')[1].replace(/\"/g, '');
                    Material_name = false;
                    subMaterial_name = false;
                    BOUNDARY_name = false;
                    BOUNDARY_name_inStep = true;
                    // if (typeof subStep_name.boundary == 'undefined')
                    //     subStep_name.boundary = {};
                    continue;
                }
                else if (temp_1[0].split(":")[0] == '** STEP') {
                    BOUNDARY_name = false;
                    Material_name = false;
                    subBOUNDARY_name = false;
                    subBOUNDARY_type = false;
                    subBOUNDARY_flag = false;
                    //存在多个step,reset values
                    subStep_name = false;
                    flag_subStep_Name = false;
                    flag_substar = false;
                    flag_subStep_LOADS = false;
                    flag_subStep_INTERACTIONS = false;
                    flag_subStep_OUTPUTREQUESTS = false;
                    flag_subStep_FIELDOUTPUT = false;
                    flag_subStep_HISTORYOUTPUT = false;

                    // Step_name = temp_1[1].split(':')[1].replace(/\"/g, '');
                    Step_name = true;
                    subStep_name = {};
                    // subStep_name.name = Step_name;
                    continue;
                }

                //每个不同的块区
                if (Part_name) {
                    if (temp_1[0] == '*End Part') {
                        // //console.log(temp_1[0]);
                        for (let typei in this.data.source.part[Part_name].element) {
                            let perType = this.data.source.part[Part_name].element[typei].type;
                            if (perType == "CPE3") {
                                this.CPE3(Part_name, perType);
                            }
                            else if (perType == "CPE4") {
                                this.CPE4(Part_name, perType);
                            }
                            else if (perType.indexOf("S3") != -1) {
                                this.S3(Part_name, perType);
                            }
                            else if (perType.indexOf("S4") != -1) {
                                this.S4(Part_name, perType);
                            }
                            // else if (perType == "S4" || perType == "S4R") {
                            //     this.S4(Part_name, perType);
                            // }
                            else if (perType.indexOf("C3D8") != -1) {
                                this.C3D8R(Part_name, perType);
                            }
                            else if (perType.indexOf("C3D4") != -1) {
                                this.C3D4(Part_name, perType);
                            }
                            else if (perType.indexOf("C3D10") != -1) {
                                this.C3D10(Part_name, perType);
                            }

                            else if (perType == "T3D2") {
                                this.T3D2(Part_name, perType);
                            }
                            else if (perType == "B31") {
                                this.B31(Part_name, perType);
                            }
                            // this.format2structure(Part_name, perType);
                        }
                        flag_node = false;
                        flag_element = false;
                        Part_name = false;
                        continue;
                    }
                    let temp_2 = temp_1[0].split('\*');
                    let temp_3 = temp_1[0].split(':');
                    if (temp_2[1] == "Node") {
                        flag_node = true;
                        flag_element = false;
                        flag_Elset = false;
                        flag_Nset = false;
                        continue;
                    }
                    else if (temp_2[1] == "Element") {
                        this.data.source.part[Part_name].nodesLength = this.data.source.part[Part_name].vertex.length;
                        flag_node = false;
                        flag_element = true;
                        flag_Elset = false;
                        flag_Nset = false;
                        let kind = temp_1[1].split('=');
                        flag_elementType = kind[1];
                        this.data.source.part[Part_name].element[kind[1]] = {
                            type: flag_elementType,
                            element: [],
                        }
                        continue;
                    }
                    else if (temp_2[1] == "Nset") {
                        flag_node = false;
                        flag_element = false;
                        flag_Nset = temp_1[1].replace(/\s+/g, '').split("nset=")[1];
                        if (typeof temp_1[2] != "undefined") {
                            flag_NsetGenerate = true;//temp_1[2];
                        }
                        flag_Elset = false;
                        flag_Section = false;
                        continue;
                    }
                    else if (temp_2[1] == "Elset") {
                        flag_node = false;
                        flag_element = false;
                        flag_Elset = temp_1[1].split("elset=")[1];
                        if (typeof temp_1[2] != "undefined") {
                            flag_ElsetGenerate = temp_1[2];
                        }
                        flag_Nset = false;
                        flag_Section = false;
                        continue;
                    }
                    else if (temp_3[0] == "** Section") {
                        flag_node = false;
                        flag_element = false;
                        flag_Elset = false
                        flag_Nset = false;
                        flag_Section = temp_3[1].substr(1, temp_3[1].length).split(/\s+/)[0];

                        if (typeof this.data.source.part[Part_name].Section == "undefined") {
                            this.data.source.part[Part_name].Section = {};
                        }
                        if (typeof this.data.source.part[Part_name].Section[flag_Section] == "undefined") {
                            this.data.source.part[Part_name].Section[flag_Section] = {
                                name: flag_Section,
                                // profile: profile,
                                content: [],
                            };
                            if (temp_3.length > 2) {
                                let profile_name = temp_3[1].substr(1).split(/\s+/)[1];
                                let profile = temp_3[2].substr(1);
                                this.data.source.part[Part_name].Section[flag_Section][profile_name] = profile;
                            }
                        }
                        continue;
                    }
                    else if (temp_2[1] == "*") {
                        flag_node = false;
                        flag_element = false;
                        flag_Elset = false;
                        flag_Nset = false;
                        flag_Section = false;
                        continue;
                    }

                    if (flag_element) {
                        // let temp2 = temp_1[0].split(/\s+/);
                        if (/^\s+\d+$/.test(temp_1[0]) || /^\d+$/.test(temp_1[0])) {
                            let temp_element = parseInt(temp_1[0]);
                            if (typeof this.data.source.part[Part_name].element[flag_elementType].start == 'undefined')
                                this.data.source.part[Part_name].element[flag_elementType].start = temp_element;
                            this.data.source.part[Part_name].element[flag_elementType].end = temp_element;
                            if (typeof this.data.source.part[Part_name].element[flag_elementType].element == "undefined") {
                                this.data.source.part[Part_name].element[flag_elementType].element = [];
                            }
                            if (typeof this.data.source.part[Part_name].element[flag_elementType].element[temp_element] == "undefined") {
                                this.data.source.part[Part_name].element[flag_elementType].element[temp_element] = [];
                            }
                            //根据长度压入element数组
                            for (let i = 1; i < temp_1.length; i++) {
                                // this.data.source.part[Part_name].element[temp_element][i-1]=temp_1[i];//ok
                                this.data.source.part[Part_name].element[flag_elementType].element[temp_element].push(parseInt(temp_1[i]));//ok
                            }
                        }
                        else {
                            flag_node = false;
                            flag_element = false;
                            continue;
                        }
                    }
                    else if (flag_node) {//vertex 
                        if (typeof this.data.source.part[Part_name].vertex == "undefined") {
                            this.data.source.part[Part_name].vertex = [];
                        }
                        if (temp_1.length == 3)
                            this.data.source.part[Part_name].vertex[temp_1[0].split(/\s+/)[1]] = [parseFloat(temp_1[1].split(/\s+/)[1]), parseFloat(temp_1[2].split(/\s+/)[1]), 0.0];
                        else
                            this.data.source.part[Part_name].vertex[temp_1[0].split(/\s+/)[1]] = [parseFloat(temp_1[1].split(/\s+/)[1]), parseFloat(temp_1[2].split(/\s+/)[1]), parseFloat(temp_1[3].split(/\s+/)[1])];
                        continue;
                    }
                    else if (flag_Nset) {
                        if (typeof this.data.source.part[Part_name].Nset == "undefined") {
                            this.data.source.part[Part_name].Nset = {}
                        }
                        this.data.source.part[Part_name].Nset[flag_Nset] = {
                            name: flag_Nset,
                            generate: flag_NsetGenerate,
                            content: [],
                        };
                        for (let i of temp_1) {
                            this.data.source.part[Part_name].Nset[flag_Nset].content.push(parseInt(i));
                        }
                    }
                    else if (flag_Elset) {
                        if (typeof this.data.source.part[Part_name].Elset == "undefined") {
                            this.data.source.part[Part_name].Elset = {}
                        }
                        if (typeof this.data.source.part[Part_name].Elset[flag_Elset] == "undefined") {
                            this.data.source.part[Part_name].Elset[flag_Elset] = {
                                name: flag_Elset,
                                generate: flag_ElsetGenerate,
                                content: [],
                            }
                        }
                        for (let i of temp_1) {
                            this.data.source.part[Part_name].Elset[flag_Elset].content.push(parseInt(i));
                        }

                    }
                    else if (flag_Section) {
                        if (temp_2.length > 1) {//判断split的*分割的数组是否大于1
                            for (let sectionI in temp_1) {
                                if (sectionI == 0) {
                                    this.data.source.part[Part_name].Section[flag_Section].type = temp_2[1];//*Beam Section
                                }
                                else if (temp_1[sectionI].indexOf('elset=') != -1) {
                                    flag_Section_Elset = temp_1[sectionI].split('=')[1];
                                    if (typeof this.data.source.part[Part_name].Section[flag_Section].elset == 'undefined')
                                        this.data.source.part[Part_name].Section[flag_Section].elset = {};
                                    this.data.source.part[Part_name].Section[flag_Section].elset[flag_Section_Elset] = [];
                                }
                                else {                                    //elset=Set-14, material=pre-back, temperature=GRADIENTS, section=CIRC
                                    let temp_split = temp_1[sectionI].split('=');
                                    if (temp_split.length > 1)
                                        this.data.source.part[Part_name].Section[flag_Section][temp_split[0].substr(1)] = temp_split[1];
                                }
                            }
                        }
                        else {
                            if (this.data.source.part[Part_name].Section[flag_Section].content.length == 0)
                                this.data.source.part[Part_name].Section[flag_Section].content.push(temp_1.join(","));

                            if (flag_Section_Elset) this.data.source.part[Part_name].Section[flag_Section].elset[flag_Section_Elset][0] = temp_1.join(",");
                        }
                    }

                }
                else if (Instance_name) {
                    if (temp_1[0] == '*End Instance') {
                        Instance_name = false;
                        continue;
                    }
                    if (Instance_name && temp_1.length == 3) {
                        this.data.source.instance[Instance_name].transform = [parseFloat(temp_1[0]), parseFloat(temp_1[1]), parseFloat(temp_1[2])];
                    }
                    if (Instance_name && temp_1.length == 7) {
                        this.data.source.instance[Instance_name].xyz1 = [parseFloat(temp_1[0]), parseFloat(temp_1[1]), parseFloat(temp_1[2])];
                        this.data.source.instance[Instance_name].xyz2 = [parseFloat(temp_1[3]), parseFloat(temp_1[4]), parseFloat(temp_1[5])];
                        this.data.source.instance[Instance_name].rotate = parseFloat(temp_1[6]);
                    }
                }
                else if (Material_name) {
                    let temp_2 = temp_1[0].split(',');
                    let temp_3 = temp_1[0].split('\*');

                    if (temp_2[0] == "*Material") {
                        subMaterial_name = temp_1[1].split('name=')[1].replace(/\"/g, '');
                        if (typeof this.data.source.materials[subMaterial_name] == "undefined") {
                            this.data.source.materials[subMaterial_name] = {
                                name: subMaterial_name,

                            }
                        }
                        continue;
                    }
                    if (temp_3[0] == "" && typeof temp_3[1] != "undefined") {
                        if (temp_3[1] != '') {
                            subMaterial_content_name = temp_3[1];
                            continue;
                        }
                    }
                    if (subMaterial_content_name) {
                        if (typeof this.data.source.materials[subMaterial_name][subMaterial_content_name] == "undefined") {
                            this.data.source.materials[subMaterial_name][subMaterial_content_name] = {
                                name: subMaterial_content_name,
                                content: []
                            }
                        }
                        this.data.source.materials[subMaterial_name][subMaterial_content_name].content.push(item_1.split('\r')[0]);
                        subMaterial_content_name = false;
                        let abc = 1;
                    }
                }
                else if (BOUNDARY_name) {//全局的在这里，step的在step中

                    if (temp_1[0] == '** PREDEFINED FIELDS' || temp_1[0] == '** OUTPUT REQUESTS') {//结束boundary的条件之一，是否还有其他，待定
                        BOUNDARY_name = false;
                        continue;
                    }
                    let temp_2 = temp_1[0].split(/\s+/);
                    let temp_3 = temp_1[0].split('*');
                    if (temp_2.length == 5 && temp_2[0] == "**" && (temp_2[1] == "Name:" || temp_2[1] == "Interaction:")) {
                        subBOUNDARY_name = temp_2[2];
                        subBOUNDARY_type = temp_2[4] ? temp_2[4] : false;

                        if (typeof this.data.source.boundary[subBOUNDARY_name] == "undefined") {
                            this.data.source.boundary[subBOUNDARY_name] = {
                                name: subBOUNDARY_name,
                            }
                            if (subBOUNDARY_type) {
                                this.data.source.boundary[subBOUNDARY_name].type = subBOUNDARY_type;
                            }
                        }
                        continue;
                    }
                    // if (subBOUNDARY_name && (temp_1[0] == "*Boundary" || temp_1[0] == "*Fluid Boundary")) {
                    if (subBOUNDARY_name && temp_3.length > 1 && temp_3[0] == '' && temp_1[0].indexOf('**') == '-1') {
                        // subBOUNDARY_flag = true;
                        subBOUNDARY_flag = temp_1[0].substr(1, temp_1[0].length);//boundray的种类不同
                        if (typeof this.data.source.boundary[subBOUNDARY_name][subBOUNDARY_flag] == "undefined") {
                            this.data.source.boundary[subBOUNDARY_name][subBOUNDARY_flag] = {};
                            this.data.source.boundary[subBOUNDARY_name][subBOUNDARY_flag].content = [];
                            for (let ti = 1; ti < temp_1.length; ti++) {
                                let temp_4 = temp_1[ti].split('=');
                                if (temp_4.length > 1) {
                                    this.data.source.boundary[subBOUNDARY_name][subBOUNDARY_flag][temp_4[0].substr(1)] = temp_4[1];
                                }
                                else {
                                    this.data.source.boundary[subBOUNDARY_name][subBOUNDARY_flag][temp_4[0].substr(1)] = temp_4[0].substr(1);
                                }
                            }
                        }
                        continue;
                    }
                    if (subBOUNDARY_flag && temp_2[0] !== '**') {
                        if (subBOUNDARY_flag) {
                            // if (typeof this.data.source.boundary[subBOUNDARY_name][subBOUNDARY_flag] == "undefined") {
                            //     this.data.source.boundary[subBOUNDARY_name][subBOUNDARY_flag] = {};
                            //     this.data.source.boundary[subBOUNDARY_name][subBOUNDARY_flag].content = [item_1.split('\r')[0]];
                            // }
                            // else {
                            this.data.source.boundary[subBOUNDARY_name][subBOUNDARY_flag].content.push(item_1.split('\r')[0]);
                            // }
                            let abc = 1;
                        }
                        let abc = 1;
                    }

                }
                else if (Step_name) {
                    let temp_2 = temp_1[0].split(',');
                    let temp_3 = temp_1[0].split(':');

                    if (temp_1[0] == '*Step') {

                        subStep_name.name = temp_1[1].split('name=')[1];
                        subStep_name.step = {
                            name: temp_1[1].split('name=')[1],
                            nlgeom: temp_1[2].split('nlgeom=')[1],
                        };
                        // subStep_name.step.push(temp_1.join(","));
                        flag_subStep_Name = true;
                        continue;
                    }

                    else if (temp_1[0] == '** LOADS') {
                        subStep_name.loads = {};
                        // subStep_name.loads.push(item_1.split('\r')[0]);
                        flag_subStep_LOADS = true
                        flag_subStep_Name = false;
                        flag_subStep_INTERACTIONS = false;
                        flag_loadName = false;
                        flag_loadName_content = false;
                        flag_subStep_Name = false;
                        BOUNDARY_name_inStep = false;
                        continue;
                    }
                    else if (temp_1[0] == '** INTERACTIONS' /*||temp_3[0] == '** Interaction'*/) {
                        // subStep_name.INTERACTIONS = [];
                        subStep_name.INTERACTIONS = {};
                        // subStep_name.INTERACTIONS.push(item_1.split('\r')[0]);
                        flag_subStep_INTERACTIONS = true;
                        subINTERACTIONS = false;
                        subINTERACTIONS_kind = false;

                        flag_subStep_LOADS = false;
                        flag_loadName = false;
                        flag_loadName_content = false;
                        flag_subStep_Name = false;
                        BOUNDARY_name_inStep = false;
                        continue;
                    }
                    else if (temp_1[0] == '** OUTPUT REQUESTS') {
                        subStep_name.outputRequests = [];
                        subStep_name.outputRequests.push(item_1.split('\r')[0]);
                        flag_subStep_LOADS = false;
                        flag_subStep_INTERACTIONS = false;
                        flag_subStep_OUTPUTREQUESTS = true;
                        BOUNDARY_name_inStep = false;
                        continue;
                    }
                    else if (temp_3[0] == '** FIELD OUTPUT') {
                        subStep_name.fieldoutput = [];
                        subStep_name.fieldoutput.push(item_1.split('\r')[0]);
                        flag_subStep_FIELDOUTPUT = true;
                        flag_subStep_OUTPUTREQUESTS = false;
                        BOUNDARY_name_inStep = false;
                        continue;
                    }
                    else if (temp_3[0] == '** HISTORY OUTPUT') {
                        subStep_name.historyoutput = [];
                        subStep_name.historyoutput.push(item_1.split('\r')[0]);
                        flag_subStep_FIELDOUTPUT = false;
                        flag_subStep_HISTORYOUTPUT = true;
                        BOUNDARY_name_inStep = false;
                        continue;
                    }
                    else if (BOUNDARY_name_inStep) {//边界
                        flag_subStep_Name = false;
                        if (typeof subStep_name.boundary == 'undefined')
                            subStep_name.boundary = {};
                        // continue;
                    }


                    if (temp_1[0] != "** " && temp_1[0] != '*End Step') {

                        if (flag_subStep_Name) {
                            let temp_first_star = temp_1[0].split('*');
                            if (temp_first_star.length > 1 && temp_first_star[0] == '') {
                                flag_substar = temp_first_star[1];
                                if (typeof subStep_name.step[flag_substar] == 'undefined') {
                                    subStep_name.step[flag_substar] = {}
                                }
                                subStep_name.step[flag_substar][temp_1[0].substr(1)] = temp_1[1] ? temp_1[1].substr(1) : "";//消除空格,同时可以没有第二个值

                                continue;
                            }
                            else {
                                if (typeof subStep_name.step[flag_substar].content == 'undefined') {
                                    subStep_name.step[flag_substar].content = []
                                }
                                subStep_name.step[flag_substar].content.push(item_1.split('\r')[0]);
                                continue;
                            }
                        }
                        else if (BOUNDARY_name_inStep) {//边界，step中的boundary的标志位
                            if (temp_1[0].indexOf(":") != -1) {//判断boundary的名称，//** Name: Disp-BC-3 Type: 位移/转角
                                let temp_2 = temp_1[0].split(':');
                                if (temp_2[0].indexOf('**') != -1) {
                                    let temp_3 = temp_2[1].split(/\s+/)[1]//.substr(1);
                                    // if (temp_2.length == 5 && temp_2[0] == "**" && (temp_2[1] == "Name:" || temp_2[1] == "Interaction:")) {
                                    subBOUNDARY_name = temp_3;
                                    if (temp_2.length > 2) {
                                        subBOUNDARY_type = temp_2[2]
                                    }
                                    else {
                                        subBOUNDARY_type = false;
                                    }

                                    if (typeof subStep_name.boundary[subBOUNDARY_name] == "undefined") {
                                        subStep_name.boundary[subBOUNDARY_name] = {
                                            name: subBOUNDARY_name,
                                        }
                                        if (subBOUNDARY_type) {
                                            subStep_name.boundary[subBOUNDARY_name].type = subBOUNDARY_type;
                                        }
                                    }
                                    continue;
                                }
                            }

                            // if (subBOUNDARY_name && (temp_1[0] == "*Boundary" || temp_1[0] == "*Fluid Boundary")) {
                            let temp_b_kind = temp_1[0].split('*');//具体内容 //*Boundary, op=NEW
                            if (subBOUNDARY_name && temp_b_kind.length > 1 && temp_b_kind[0] == '' && temp_1[0].indexOf('**') == '-1') {//处理boundary后的的name部分
                                // subBOUNDARY_flag = true;
                                subBOUNDARY_flag = temp_1[0].substr(1, temp_1[0].length);//boundray的种类不同  //temp_1[0]是‘，’分割的,//subBOUNDARY_flag=Boundary
                                // if (typeof subStep_name.boundary[subBOUNDARY_name] == "undefined") {//增加的
                                //     subStep_name.boundary[subBOUNDARY_name] = {};
                                if (typeof subStep_name.boundary[subBOUNDARY_name][subBOUNDARY_flag] == "undefined") {
                                    subStep_name.boundary[subBOUNDARY_name][subBOUNDARY_flag] = {};
                                    subStep_name.boundary[subBOUNDARY_name][subBOUNDARY_flag].content = [];
                                    for (let ti = 1; ti < temp_1.length; ti++) {
                                        if (temp_1[ti].indexOf('=') != -1) {
                                            let temp_4 = temp_1[ti].split('=');
                                            subStep_name.boundary[subBOUNDARY_name][subBOUNDARY_flag][temp_4[0].substr(1)] = temp_4[1];
                                        }
                                        else {
                                            subStep_name.boundary[subBOUNDARY_name][subBOUNDARY_flag][temp_1[ti].substr(1)] = temp_1[ti].substr(1);
                                        }
                                    }
                                }
                                // }
                                continue;
                            }


                            if (subBOUNDARY_flag && temp_2[0] !== '**') {//处理boundary属性之后的数值部分，一行或多行，//temp是“，”分割，多次分割temp_1 也是，'**'多次还是'**'(非终止)
                                if (subBOUNDARY_flag) {
                                    if (typeof subStep_name.boundary[subBOUNDARY_name][subBOUNDARY_flag].content == 'undefined')
                                        subStep_name.boundary[subBOUNDARY_name][subBOUNDARY_flag].content = [];
                                    subStep_name.boundary[subBOUNDARY_name][subBOUNDARY_flag].content.push(item_1.split('\r')[0]);
                                    let abc = 1;
                                }
                                let abc = 1;
                            }
                        }
                        else if (flag_subStep_LOADS) {
                            // subStep_name.loads.push(item_1.split('\r')[0]);
                            let temp_first_star = temp_1[0].split(':');
                            if (temp_first_star.length > 1 && temp_first_star[0] == '** Name') {
                                flag_loadName = temp_first_star[1].split(/\s+/)[1];
                                let loadType = temp_first_star[2].substr(1);
                                if (typeof subStep_name.loads[flag_loadName] == 'undefined') {
                                    subStep_name.loads[flag_loadName] = {
                                        name: flag_loadName,
                                        type: loadType,
                                    }
                                }
                                continue;
                            }
                            else {
                                if (typeof subStep_name.loads[flag_loadName].content == 'undefined') {
                                    subStep_name.loads[flag_loadName].content = {};
                                }
                                let temp_first_star = temp_1[0].split('*');

                                if (temp_first_star.length > 1 && temp_first_star[0] == '') {
                                    flag_loadName_content = temp_first_star[1];
                                    continue;
                                }

                                subStep_name.loads[flag_loadName].content[flag_loadName_content] = item_1.split('\r')[0];
                                continue;
                            }

                            continue;
                        }
                        else if (flag_subStep_INTERACTIONS) {
                            // subStep_name.INTERACTIONS.push(item_1.split('\r')[0]);
                            if (temp_1[0].indexOf(":") != -1) {
                                let temp_2 = temp_1[0].split(':');
                                if (temp_2[0].indexOf('**') != -1) {
                                    let temp_3 = temp_2[1].split(/\s+/)[1]//.substr(1);
                                    // if (temp_2.length == 5 && temp_2[0] == "**" && (temp_2[1] == "Name:" || temp_2[1] == "Interaction:")) {
                                    subINTERACTIONS = temp_3;
                                    // if (temp_2.length > 2) {
                                    //     subINTERACTIONS = temp_2[2]
                                    // }
                                    // else {
                                    //     subINTERACTIONS = false;
                                    // }

                                    if (typeof subStep_name.INTERACTIONS[subINTERACTIONS] == "undefined") {
                                        subStep_name.INTERACTIONS[subINTERACTIONS] = {
                                            name: subINTERACTIONS,
                                        }
                                        // if (subBOUNDARY_type) {
                                        //     subStep_name.boundary[subBOUNDARY_name].type = subBOUNDARY_type;
                                        // }
                                    }
                                    continue;
                                }
                            }
                            let temp_b_kind = temp_1[0].split('*');
                            if (subINTERACTIONS && temp_b_kind.length > 1 && temp_b_kind[0] == '' && temp_1[0].indexOf('**') == '-1') {
                                subINTERACTIONS_kind = temp_1[0].substr(1, temp_1[0].length);//boundray的种类不同
                                // if (typeof subStep_name.boundary[subBOUNDARY_name] == "undefined") {//增加的
                                //     subStep_name.boundary[subBOUNDARY_name] = {};
                                if (typeof subStep_name.INTERACTIONS[subINTERACTIONS][subINTERACTIONS_kind] == "undefined") {
                                    subStep_name.INTERACTIONS[subINTERACTIONS][subINTERACTIONS_kind] = {};
                                    subStep_name.INTERACTIONS[subINTERACTIONS][subINTERACTIONS_kind].content = [];
                                    for (let ti = 1; ti < temp_1.length; ti++) {


                                        if (temp_1[ti].indexOf('=') != -1) {
                                            let temp_4 = temp_1[ti].split('=');
                                            subStep_name.INTERACTIONS[subINTERACTIONS][subINTERACTIONS_kind][temp_4[0].substr(1)] = temp_4[1];
                                        }
                                        else {
                                            subStep_name.INTERACTIONS[subINTERACTIONS][subINTERACTIONS_kind][temp_1[ti].substr(1)] = temp_1[ti].substr(1);
                                        }
                                    }
                                }
                                continue;
                            }
                            if (subINTERACTIONS && subINTERACTIONS_kind) {
                                subStep_name.INTERACTIONS[subINTERACTIONS][subINTERACTIONS_kind].content.push(item_1.split('\r')[0]);
                            }
                            continue;
                        }
                        else if (flag_subStep_OUTPUTREQUESTS) {
                            subStep_name.outputRequests.push(item_1.split('\r')[0]);
                            continue;
                        }
                        else if (flag_subStep_FIELDOUTPUT) {
                            subStep_name.fieldoutput.push(item_1.split('\r')[0]);
                            continue;
                        }
                        else if (flag_subStep_HISTORYOUTPUT) {
                            subStep_name.historyoutput.push(item_1.split('\r')[0]);
                            continue;
                        }
                    }
                    else if (temp_1[0] == '*End Step') {
                        this.data.source.step.push(subStep_name);
                        subStep_name = false;
                        flag_subStep_Name = false;
                        BOUNDARY_name = false;
                        flag_subStep_LOADS = false;
                        flag_subStep_INTERACTIONS = false;
                        flag_subStep_OUTPUTREQUESTS = false;
                        flag_subStep_FIELDOUTPUT = false;
                        flag_subStep_HISTORYOUTPUT = false;
                        BOUNDARY_name_inStep = false;
                    }
                }
                else if (Assembly_name) {
                    let temp_2 = temp_1[0].split('\*');
                    if (temp_2[1] == "Nset") {
                        Assembly_Nset = temp_1[1].replace(/\s+/g, '').split("nset=")[1];
                        Assembly_instance = temp_1[2].split("instance=")[1].replace(/\"/g, '').toLowerCase();
                        Assembly_Elset = false;
                        if (typeof this.data.source.assembly.Nset[Assembly_Nset] == "undefined") {
                            this.data.source.assembly["Nset"][Assembly_Nset] = {}
                        }
                        this.data.source.assembly["Nset"][Assembly_Nset][Assembly_instance] = {
                            content: []
                        };// per instance of content ,array


                        // Assembly_instance = false;
                        // Assembly_Nset = false;
                        Assembly_Elset = false;
                        Assembly_Surface = false;
                        Assembly_Surface_type = false;
                        continue;
                    }
                    else if (temp_2[1] == "Elset") {
                        Assembly_instance = false;
                        Assembly_Nset = false;
                        Assembly_Elset = false;
                        Assembly_Surface = false;
                        Assembly_Surface_type = false;

                        for (let ELS_i = 1; ELS_i < temp_1.length; ELS_i++) {
                            let per = temp_1[ELS_i];
                            if (per.indexOf("elset=") != -1) {
                                Assembly_Elset = per.split("elset=")[1];
                                if (typeof this.data.source.assembly["Elset"][Assembly_Elset] == "undefined") {
                                    this.data.source.assembly.Elset[Assembly_Elset] = {}
                                }
                            }
                            else if (per.indexOf("instance=") != -1) {
                                Assembly_instance = per.split("instance=")[1].replace(/\"/g, '').toLowerCase();
                                this.data.source.assembly["Elset"][Assembly_Elset][Assembly_instance] = { content: [] };// per instance of content ,array
                                let abc = 1;
                            }
                            else if (per.indexOf('=') != -1) {
                                let temp_set_1 = per.replace(/\s+/g, '').split("=");
                                if (Assembly_instance) {
                                    this.data.source.assembly["Elset"][Assembly_Elset][Assembly_instance][temp_set_1[0]] = temp_set_1[1];
                                }
                                else {
                                    this.data.source.assembly["Elset"][Assembly_Elset][temp_set_1[0]] = temp_set_1[1];
                                }
                            }
                            else {
                                let temp_set_1 = per.replace(/\s+/g, '');
                                if (Assembly_instance) {
                                    this.data.source.assembly["Elset"][Assembly_Elset][Assembly_instance][temp_set_1] = true;
                                }
                                else {
                                    this.data.source.assembly["Elset"][Assembly_Elset][temp_set_1] = true;
                                }
                            }
                        }


                        continue;
                    }
                    else if (temp_2[1] == "Surface") {
                        Assembly_instance = false;
                        Assembly_Nset = false;
                        Assembly_Elset = false;
                        Assembly_Surface = temp_1[2].replace(/\s+/g, '').split("name=")[1];
                        Assembly_Surface_type = temp_1[1].replace(/\s+/g, '').split("type=")[1];
                        if (typeof this.data.source.assembly.surface[Assembly_Surface] == "undefined") {
                            this.data.source.assembly.surface[Assembly_Surface] = {};
                        }
                        this.data.source.assembly.surface[Assembly_Surface] = {
                            name: Assembly_Surface,
                            type: Assembly_Surface_type,
                            content: [],
                        };

                        continue;
                    }
                    else if (temp_1[0] == "*End Assembly") {
                        Assembly_name = false;
                        Assembly_instance = false;
                        Assembly_Nset = false;
                        Assembly_Elset = false;
                        Assembly_Surface = false;
                        Assembly_Surface_type = false;
                        continue;
                    }
                    if (Assembly_Nset) {
                        for (let i of temp_1) {
                            // this.data.source.assembly["Nset"][Assembly_Nset].content.push(parseInt(i));

                            if (!isNaN(parseInt(i)))
                                this.data.source.assembly["Nset"][Assembly_Nset][Assembly_instance].content.push(parseInt(i));
                        }
                    }
                    else if (Assembly_Elset) {
                        for (let i of temp_1) {
                            if (!isNaN(parseInt(i)))
                                this.data.source.assembly["Elset"][Assembly_Elset][Assembly_instance].content.push(parseInt(i));
                        }
                    }
                    else if (Assembly_Surface) {
                        this.data.source.assembly.surface[Assembly_Surface].content.push(temp_1.join(','));
                    }
                }


            }
            // this.get_size_max_min()
            // return this.data.source;
            return JSON.parse(JSON.stringify(this.data.source));
        }
    }
    T3D2(Part_name, type) {
        this.B31(Part_name, type);
    }
    B31(Part_name, type) {
        if (typeof this.data.source.part[Part_name].non_index == "undefined") {
            this.data.source.part[Part_name].non_index = new Array();
        }
        if (typeof this.data.source.part[Part_name].colors == "undefined") {
            this.data.source.part[Part_name].colors = new Array();
        }
        if (typeof this.data.source.part[Part_name].pressure == "undefined") {
            this.data.source.part[Part_name].pressure = new Array();
        }
        if (typeof this.data.source.part[Part_name].point_index == "undefined") {
            this.data.source.part[Part_name].point_index = {};//new Array();
            this.data.source.part[Part_name].line_index = {};//new Array();
            this.data.source.part[Part_name].point_index[type] = {};//new Array();
            this.data.source.part[Part_name].line_index[type] = {};//new Array();
        }
        let pointIndexObject = this.data.source.part[Part_name].point_index[type];
        let lineIndexObject = this.data.source.part[Part_name].line_index[type];
        for (let i in this.data.source.part[Part_name].element[type].element) {
            let element = this.data.source.part[Part_name].element[type].element[i];

            let e1 = element[0];
            let e2 = element[1];

            let lineAll = [
                e1, e2
            ];
            let triAll = [
                e1, e2
            ];
            for (let j = 0; j < triAll.length; j += 2) {
                let per = [triAll[j + 0], triAll[j + 1]];
                let perStore = JSON.parse(JSON.stringify(per));
                if (typeof pointIndexObject[perStore] == "undefined") {
                    pointIndexObject[perStore] = {
                        points: per,
                        enable: true,
                    };
                }
                else {
                    // pointIndexObject[perStore].enable = false;
                }
            }

            for (let j = 0; j < lineAll.length; j += 2) {
                let per = [lineAll[j + 0], lineAll[j + 1]];
                let perStore = JSON.parse(JSON.stringify(per));
                if (typeof lineIndexObject[perStore] == "undefined") {
                    lineIndexObject[perStore] = {
                        points: per,
                        enable: true,
                    };
                }
                else {
                    // lineIndexObject[perStore].enable = false;
                }
            }
        }
        this.data.source.part[Part_name].type = "line";
        this.data.source.part[Part_name].element[type].line = true;
    }

    CPE3(Part_name, type) {
        if (typeof this.data.source.part[Part_name].non_index == "undefined") {
            this.data.source.part[Part_name].non_index = new Array();
        }
        if (typeof this.data.source.part[Part_name].colors == "undefined") {
            this.data.source.part[Part_name].colors = new Array();
        }
        if (typeof this.data.source.part[Part_name].pressure == "undefined") {
            this.data.source.part[Part_name].pressure = new Array();
        }
        if (typeof this.data.source.part[Part_name].point_index == "undefined") {
            this.data.source.part[Part_name].point_index = {};//new Array();
            this.data.source.part[Part_name].line_index = {};//new Array();
            this.data.source.part[Part_name].shell_index = {};//new Array();
            this.data.source.part[Part_name].shell_points = {};//new Array();
            this.data.source.part[Part_name].point_index[type] = {};//new Array();
            this.data.source.part[Part_name].line_index[type] = {};//new Array();
            this.data.source.part[Part_name].shell_index[type] = {};//new Array();
            this.data.source.part[Part_name].shell_points[type] = {};//new Array();
        }
        else {
            this.data.source.part[Part_name].point_index[type] = {};//new Array();
            this.data.source.part[Part_name].line_index[type] = {};//new Array();
            this.data.source.part[Part_name].shell_index[type] = {};//new Array();
            this.data.source.part[Part_name].shell_points[type] = {};//new Array();
        }

        let pointIndexObject = this.data.source.part[Part_name].point_index[type];
        let lineIndexObject = this.data.source.part[Part_name].line_index[type];
        let shell = this.data.source.part[Part_name].shell_index[type];
        let shellPoints = this.data.source.part[Part_name].shell_points[type];

        let cubeFace = {};
        for (let i in this.data.source.part[Part_name].element[type].element) {
            let element = this.data.source.part[Part_name].element[type].element[i];

            let e1 = element[0];
            let e2 = element[1];
            let e3 = element[2];
            let lineAll = [
                e1, e2, e2, e3, e3, e1,
            ];
            let triAll = [
                e1, e2, e3
            ];
            for (let j = 0; j < triAll.length; j += 3) {
                let per = [triAll[j + 0], triAll[j + 1], triAll[j + 2]];
                let perStore = JSON.parse(JSON.stringify(per));
                if (typeof cubeFace[perStore] == "undefined") {
                    cubeFace[perStore] = {
                        points: per,
                        enable: true,
                    };
                }
                else {
                    // pointIndexObject[perStore].enable = false;
                }
            }

            for (let j = 0; j < lineAll.length; j += 2) {
                let per = [lineAll[j + 0], lineAll[j + 1]];
                let perStore = JSON.parse(JSON.stringify(per));
                if (typeof lineIndexObject[perStore] == "undefined") {
                    lineIndexObject[perStore] = {
                        points: per,
                        enable: true,
                    };
                }
                else {
                    // lineIndexObject[perStore].enable = false;
                }
            }

        }
        this.data.source.part[Part_name].plane_index[type] = cubeFace;
        for (let i in cubeFace) {
            let perFace = cubeFace[i];
            let name = i;
            if (perFace.enable) {
                let points = perFace.points;
                pointIndexObject[name] = {
                    points: [points[0], points[1], points[2]],
                    enable: true,
                };
                //外壳
                shell[[points[0], points[1]].join("-")] = [points[0], points[1]];
                shell[[points[1], points[2]].join("-")] = [points[1], points[2]];
                shell[[points[2], points[0]].join("-")] = [points[2], points[0]];
                shellPoints[points[0]] = points[0];
                shellPoints[points[1]] = points[1];
                shellPoints[points[2]] = points[2];
            }
        }
        // //console.log(Part_name);
    }
    CPE4(Part_name, type) {
        this.S4(Part_name, type);
    }
    S3(Part_name, type) {
        this.CPE3(Part_name, type);
    }
    S4(Part_name, type) {//四边形，同六面体
        if (typeof this.data.source.part[Part_name].non_index == "undefined") {
            this.data.source.part[Part_name].non_index = new Array();
        }
        if (typeof this.data.source.part[Part_name].colors == "undefined") {
            this.data.source.part[Part_name].colors = new Array();
        }
        if (typeof this.data.source.part[Part_name].pressure == "undefined") {
            this.data.source.part[Part_name].pressure = new Array();
        }
        if (typeof this.data.source.part[Part_name].point_index == "undefined") {
            this.data.source.part[Part_name].point_index = {};//new Array();
            this.data.source.part[Part_name].point_index[type] = {};//new Array();
        }
        else {
            this.data.source.part[Part_name].point_index[type] = {};//new Array();
        }
        if (typeof this.data.source.part[Part_name].line_index == "undefined") {
            this.data.source.part[Part_name].line_index = {};//new Array();
            this.data.source.part[Part_name].line_index[type] = {};//new Array();
        }
        else {
            this.data.source.part[Part_name].line_index[type] = {};//new Array();
        }
        if (typeof this.data.source.part[Part_name].shell_index == "undefined") {
            this.data.source.part[Part_name].shell_index = {};//new Array();
            this.data.source.part[Part_name].shell_index[type] = {};//new Array();
        }
        else {
            this.data.source.part[Part_name].shell_index[type] = {};//new Array();
        }
        if (typeof this.data.source.part[Part_name].shell_points == "undefined") {
            this.data.source.part[Part_name].shell_points = {};//new Array();
            this.data.source.part[Part_name].shell_points[type] = {};//new Array();
        }
        else {
            this.data.source.part[Part_name].shell_points[type] = {};//new Array();
        }


        let pointIndexObject = this.data.source.part[Part_name].point_index[type];// 三角化顶点
        let lineIndexObject = this.data.source.part[Part_name].line_index[type];//网格
        let shell = this.data.source.part[Part_name].shell_index[type];         //外壳线段顶点
        let shellPoints = this.data.source.part[Part_name].shell_points[type];  //外壳连续三角化顶点

        let cubeFace = {};
        for (let i in this.data.source.part[Part_name].element[type].element) {
            let element = this.data.source.part[Part_name].element[type].element[i];

            let e1 = element[0];
            let e2 = element[1];
            let e3 = element[2];
            let e4 = element[3];
            let lineAll = [
                e1, e2, e2, e3, e3, e4, e4, e1,
            ];
            // let triAll = [
            //     e2, e1, e3, e1, e4, e3
            // ];
            let box = [
                [e1, e2, e3, e4],//face
            ];

            for (let side of box) {
                let per = JSON.parse(JSON.stringify(side)).sort().join("-");
                if (typeof cubeFace[per] == 'undefined') {
                    cubeFace[per] = {
                        points: side,
                        enable: true,
                    }
                }
                else {
                    cubeFace[per].enable = false;
                }
            }


            for (let j = 0; j < lineAll.length; j += 2) {
                let per = [lineAll[j + 0], lineAll[j + 1]];
                let perStore = JSON.parse(JSON.stringify(per));
                if (typeof lineIndexObject[perStore] == "undefined") {
                    lineIndexObject[perStore] = {
                        points: per,
                        enable: true,
                    };
                }
                else {
                    // lineIndexObject[perStore].enable = false;
                }
            }
        }
        if (typeof this.data.source.part[Part_name].plane_index == "undefined") {//无序列的 点索引
            this.data.source.part[Part_name].plane_index = {};//new Array();
        }
        this.data.source.part[Part_name].plane_index[type] = cubeFace;
        for (let i in cubeFace) {
            let perFace = cubeFace[i];
            let name = i;
            if (perFace.enable) {
                let points = perFace.points;
                pointIndexObject[name] = {
                    points: [points[0], points[1], points[3], points[1], points[2], points[3],],
                    enable: true,
                    uv: [0, 0,
                        1, 0,
                        1, 1,

                        0, 0,
                        1, 0,
                        1, 1,
                    ],
                };
                //外壳
                shell[[points[0], points[1]].join("-")] = [points[0], points[1]];
                shell[[points[1], points[2]].join("-")] = [points[1], points[2]];
                shell[[points[2], points[3]].join("-")] = [points[2], points[3]];
                shell[[points[3], points[0]].join("-")] = [points[3], points[0]];
                shellPoints[points[0]] = points[0];
                shellPoints[points[1]] = points[1];
                shellPoints[points[2]] = points[2];
                shellPoints[points[3]] = points[3];
            }
        }
    }
    FC3D8(Part_name, type) {
        this.C3D8R(Part_name, type);
    }
    xyz_decrease_xyz(xyz1, xyz2) {
        const x = xyz2[0] - xyz1[0];
        const y = xyz2[1] - xyz1[1];
        const z = xyz2[2] - xyz1[2];
        // const max = get_max(x, y, z);
        // if(max ==0) max=1;
        return [parseFloat(x), parseFloat(y), parseFloat(z)]

    }
    create_Geometry(inp_json) {//每个part是一个geometry
        let index = 0;
        for (let i in inp_json) {
            let temp_instance = inp_json[i];
            // if (typeof temp_instance.type == "undefined" || temp_instance.type != "line") {
            if (typeof temp_instance.point_index != 'undefined') {
                let geometry = new BufferGeometry();

                let vertices = [];
                let colors = [];
                let pressures = [];
                let normals = [];
                let uv = [];
                let cm = [];

                for (let PEi in temp_instance.point_index) {
                    if (PEi.indexOf('B31') != -1 || PEi.indexOf('T3D2') != -1) {
                        continue;
                    }
                    let perElement = temp_instance.point_index[PEi];
                    for (let Ti in perElement) {
                        let perTri = perElement[Ti];
                        if (Ti == '1022861-1032761-1033548-1039845') {
                            console.log(Ti);
                        }
                        if (perTri.enable == true) {
                            for (let Pi in perTri.points) {
                                let per = perTri.points[Pi];
                                vertices.push(temp_instance.vertex[per][0]);
                                vertices.push(temp_instance.vertex[per][1]);
                                vertices.push(temp_instance.vertex[per][2]);
                                colors.push(thisINP.material.rgb.r, thisINP.material.rgb.g, thisINP.material.rgb.b);
                                pressures.push(0);
                                normals.push(0, 0, 1);
                                cm.push(0.12870588, 0.12870588, 0.12870588);
                            }
                            if (perTri.points.length == 6) {
                                uv.push(0, 0, 1, 0, 1, 1,);
                                uv.push(0, 0, 1, 0, 1, 1,);
                            }
                            if (perTri.points.length == 3)
                                uv.push(0, 0, 1, 0, 1, 1,);
                        }
                    }
                }

                vertices = new Float32Array(vertices);
                colors = new Float32Array(colors);
                pressures = new Float32Array(pressures);

                geometry.setAttribute('position', new BufferAttribute(vertices, 3));
                geometry.setAttribute('color', new Float32BufferAttribute(colors, 3));
                geometry.setAttribute('uv', new Float32BufferAttribute(uv, 2));
                geometry.setAttribute('cm', new Float32BufferAttribute(cm, 3));
                geometry.setAttribute('pressure', new Float32BufferAttribute(pressures, 1));
                geometry.setAttribute('normal', new Float32BufferAttribute(new Float32Array(normals), 3));
                geometry.computeVertexNormals();
                let name = i.toLowerCase();
                if (typeof this.geometry.part == "undefined") {
                    this.geometry.part = {};
                }
                geometry.Part_name = name;
                this.geometry.part[name] = geometry;
            }

            if (temp_instance.type == "line") {
                let lgeometry = new BufferGeometry();
                let lvertices = [];
                let lcolors = [];
                let lpressures = [];
                for (let lPEi in temp_instance.point_index) {
                    if (typeof temp_instance.element[lPEi].line != "undefined" && temp_instance.element[lPEi].line == true) {
                        let lperElement = temp_instance.point_index[lPEi];
                        for (let Ti in lperElement) {
                            let perTri = lperElement[Ti];
                            if (perTri.enable == true) {
                                for (let Pi in perTri.points) {
                                    let per = perTri.points[Pi];
                                    if (per == 1778) {
                                        console.log("1778", perTri.points);
                                    }
                                    lvertices.push(temp_instance.vertex[per][0]);
                                    lvertices.push(temp_instance.vertex[per][1]);
                                    lvertices.push(temp_instance.vertex[per][2]);
                                    lcolors.push(1, 1, 1);
                                    lpressures.push(1);
                                }
                            }
                            index++;
                        }
                        lvertices = new Float32Array(lvertices);
                        lcolors = new Float32Array(lcolors);
                        lpressures = new Float32Array(lpressures);

                        lgeometry.setAttribute('position', new BufferAttribute(lvertices, 3));
                        lgeometry.setAttribute('color', new Float32BufferAttribute(lcolors, 3));
                        lgeometry.setAttribute('pressure', new Float32BufferAttribute(lpressures, 1));
                        let name = i.toLowerCase();

                        if (typeof this.geometry.part == "undefined") {
                            this.geometry.part = {};
                        }
                        // if (typeof this.geometry.line == "undefined") {
                        //     this.geometry.line = {};
                        // }
                        lgeometry.Part_type = "line";//line和面的区别，需要在drawINP中区别对待
                        lgeometry.Part_name = name;
                        this.geometry.part[name + "_line"] = lgeometry;
                    }
                }
            }

        }
        console.log(index)
    }
    drawINP() {
        let group = new Group();
        group.name = "trangle and line from INP";
        for (let i in this.data.source.instance) {
            let temp_instance = this.data.source.instance[i];
            let Mpart, Mrotate, Mtransform, Mxyz1, Mxyz2;
            Mpart = temp_instance.part;

            if (typeof temp_instance.rotate == "undefined") {
                Mrotate = false;
            }
            else {
                Mrotate = temp_instance.rotate;
            }

            if (typeof temp_instance.transform == "undefined") {
                Mtransform = false;
            }
            else {
                Mtransform = temp_instance.transform;
            }

            if (typeof temp_instance.xyz1 == "undefined") {
                Mxyz1 = false;
            }
            else {
                Mxyz1 = temp_instance.xyz1;
            }

            if (typeof temp_instance.xyz2 == "undefined") {
                Mxyz2 = false;
            }
            else {
                Mxyz2 = temp_instance.xyz2;
            }

            let name = Mpart.toLowerCase();


            if (typeof this.geometry.part != 'undefined')
                if (typeof this.geometry.part[name] != 'undefined') {
                    let cloen_geometry = this.geometry.part[name].clone();
                    // mesh = new Mesh(cloen_geometry, thisINP.material.allTriangleShaderStair);
                    let mesh;
                    mesh = new Mesh(cloen_geometry, thisINP.material.allTriangle);
                    mesh = this.inp_Matrix(mesh, Mtransform, Mrotate, Mxyz1, Mxyz2);
                    // if (typeof this.mesh.inp == "undefined") {
                    //     this.mesh.inp = {};
                    // }
                    // this.mesh.inp[i] = mesh;
                    mesh.name = i;
                    mesh.Part_name = name;
                    mesh.PJ = "INP";
                    mesh.updateMatrix();
                    mesh.updateWorldMatrix();
                    group.add(mesh);
                }
            if (typeof this.geometry.part != 'undefined')
                if (typeof this.geometry.part[name + "_line"] != 'undefined') {
                    let lcloen_geometry = this.geometry.part[name + "_line"].clone();
                    let lmesh;
                    lmesh = new LineSegments(lcloen_geometry, thisINP.material.OBJline);
                    lmesh = this.inp_Matrix(lmesh, Mtransform, Mrotate, Mxyz1, Mxyz2);
                    // if (typeof this.mesh.inp == "undefined") {
                    //     this.mesh.inp = {};
                    // }
                    // this.mesh.inp[i] = mesh;
                    lmesh.name = i;
                    lmesh.Part_name = name;
                    lmesh.PJ = "INP";
                    lmesh.updateMatrix();
                    lmesh.updateWorldMatrix();
                    group.add(lmesh);

                }




        }
        group.updateWorldMatrix();
        this.mesh.inpGroup = group;
        this.worldBox.add(group);
    }
    inp_Matrix(object, Trans, Rotate, xyz1, xyz2) {

        const position_obj = new Vector3(0, 0, 0);
        //object.getWorldDirection(position_obj)
        //console.log("obj position:", position_obj);

        let A1 = [position_obj.x, position_obj.y, position_obj.z];

        let B_axia, axis;
        if (Rotate) {
            B_axia = this.xyz_decrease_xyz(xyz1, xyz2);
            axis = new Vector3(B_axia[0], B_axia[1], B_axia[2]);
        }


        //console.log("A1:", A1);
        if (Trans) {
            if (xyz1) {
                if (Trans[0] == xyz1[0] && Trans[1] == xyz1[1] && Trans[2] == xyz1[2]) {
                    //console.log("世界坐标==与形心坐标")
                    A1 = false;
                } else {
                    const temp_a1 = this.xyz_decrease_xyz(A1, xyz2);
                    //console.log("世界坐标！=与形心坐标")
                    let A1_temp = new Matrix4();
                    A1_temp.makeTranslation(temp_a1[0], temp_a1[1], temp_a1[2]);
                    A1_temp.multiply(Object.matrix);
                    object.applyMatrix4(A1_temp);
                }
            }
        }
        if (Rotate) {
            //console.log("旋转:", Rotate);
            object.rotateOnWorldAxis(axis, Rotate * Math.PI / 180.0);//世界坐标 等价与 上面五行
        }
        if (Trans) {
            object.position.set(Trans[0], Trans[1], Trans[2]);//需要确定，是相对位移，还是绝对位移
        }
        // if (xyz1) {
        //     object.position.set(xyz1[0], xyz1[1], xyz1[2]);//需要确定，是相对位移，还是绝对位移
        // }
        // object.rotateOnWorldAxis(new Vector3(1, 0, 0) ,180.0 * Math.PI / 90.0);
        return (object);
    }
    createLineOfGeometry(inp_json, instanceName) {//每个part是一个geometry

        let temp_instance = inp_json;
        // if (typeof temp_instance.type == "undefined")
        {
            const geometry = new BufferGeometry();


            let orgPressX = 0;
            let orgPressY = 0;
            let orgPressZ = 0;
            if (thisINP.setting.trans) {
                if (thisINP.data.resKey.transform.x)
                    orgPressX = thisINP.data.res[thisINP.timerA.countCurrent][thisINP.data.resKey.transform.x].instance[instanceName];
                if (thisINP.data.resKey.transform.x)
                    orgPressY = thisINP.data.res[thisINP.timerA.countCurrent][thisINP.data.resKey.transform.y].instance[instanceName];
                if (thisINP.data.resKey.transform.z)
                    orgPressZ = thisINP.data.res[thisINP.timerA.countCurrent][thisINP.data.resKey.transform.z].instance[instanceName];
            }
            let vertices = [];
            let colors = [];
            let pressures = [];
            for (let PEi in temp_instance.shell_index) {
                let perElement = temp_instance.shell_index[PEi];
                for (let Li in perElement) {
                    let perLine = perElement[Li];
                    for (let per of perLine) {
                        // let per = perTri.points[Pi];
                        let x1 = 0, y1 = 0, z1 = 0;
                        if (thisINP.setting.trans) {
                            x1 = orgPressX[per] * thisINP.setting.transValue;
                            y1 = orgPressY[per] * thisINP.setting.transValue;
                            z1 = orgPressZ ? orgPressZ[per] * thisINP.setting.transValue : 0;
                        }
                        vertices.push(temp_instance.vertex[per][0] + x1);
                        vertices.push(temp_instance.vertex[per][1] + y1);
                        vertices.push(temp_instance.vertex[per][2] + z1);

                    }
                }
            }
            if (vertices.length == 0) {
                return false;
            }

            vertices = new Float32Array(vertices);
            colors = new Float32Array(colors);
            pressures = new Float32Array(pressures);

            geometry.setAttribute('position', new BufferAttribute(vertices, 3));
            // geometry.setAttribute('color', new Float32BufferAttribute(colors, 3));
            // geometry.setAttribute('pressure', new Float32BufferAttribute(pressures, 1));
            let name = instanceName.toLowerCase();
            if (typeof this.geometry.line == "undefined") {
                this.geometry.line = {};
            }
            geometry.Part_name = name;
            this.geometry.line[name] = geometry;
            return geometry;
        }
        // else {
        //     return false;

        // }

    }
    drawLineOfINP() {
        let group = new Group();
        group.name = "lines of geomerty";
        let normal_and_normal = 1;

        for (let i in this.data.source.instance) {
            let temp_instance = this.data.source.instance[i];
            let Mpart, Mrotate, Mtransform, Mxyz1, Mxyz2;
            Mpart = temp_instance.part;

            if (typeof temp_instance.rotate == "undefined") {
                Mrotate = false;
            }
            else {
                Mrotate = temp_instance.rotate;
            }

            if (typeof temp_instance.transform == "undefined") {
                Mtransform = false;
            }
            else {
                Mtransform = temp_instance.transform;
            }

            if (typeof temp_instance.xyz1 == "undefined") {
                Mxyz1 = false;
            }
            else {
                Mxyz1 = temp_instance.xyz1;
            }

            if (typeof temp_instance.xyz2 == "undefined") {
                Mxyz2 = false;
            }
            else {
                Mxyz2 = temp_instance.xyz2;
            }

            let name = Mpart.toLowerCase();
            // let cloen_geometry = this.geometry.line[name].clone();
            let cloen_geometry = this.createLineOfGeometry(this.data.source.part[name], i);
            if (cloen_geometry == false) {
                continue;
            }

            // cloen_geometry = this.inp_Matrix(cloen_geometry, Mtransform, Mrotate, Mxyz1, Mxyz2);
            let mesh;
            mesh = new LineSegments(cloen_geometry, thisINP.material.line);

            mesh = this.inp_Matrix(mesh, Mtransform, Mrotate, Mxyz1, Mxyz2);
            // if (typeof this.mesh.inp == "undefined") {
            //     this.mesh.inp = {};
            // }
            // this.mesh.inp[i] = mesh;
            mesh.name = i + " network lines";
            mesh.Part_name = name;
            mesh.PJ = "INP_line";
            mesh.updateMatrix();
            mesh.updateWorldMatrix();
            group.add(mesh);
        }
        group.updateWorldMatrix();
        this.mesh.lines = group;
        this.worldBox.add(group);
    }
    drawLines() {
        this.mesh.lines = new Group();
        // this.createLineOfGeometry(this.data.source.part);
        this.drawLineOfINP();
        // this.worldBox.add(this.mesh.lines);


    }
    getPointXYZ(index) {//未完成
        if (this.meshType == "abaqus") {
            if (nameOfInstance) {
                let M4 = false;
                this.mesh.inpGroup.traverse(function (child) {
                    if (child.name == nameOfInstance) {
                        M4 = child.matrix;
                    }
                });

                if (JSON.stringify(M4.elements) == JSON.stringify(M4_111)) {
                }
                else {
                    p1.applyMatrix4(M4);
                }
            }
            p1.applyMatrix4(this.mesh.inpGroup.matrix);
        }
    }
    doTrans() {
        // thisINP.confirmTrans();
        if (thisINP.setting.trans) {
            this.mesh.inpGroup.traverse(function (child) {
                if (child.type === "Mesh") {
                    let vertices = [];
                    let orgPressX = 0;
                    let orgPressY = 0;
                    let orgPressZ = 0;
                    if (thisINP.data.resKey.transform.x)
                        orgPressX = thisINP.data.res[thisINP.timerA.countCurrent][thisINP.data.resKey.transform.x].instance[child.name];
                    if (thisINP.data.resKey.transform.x)
                        orgPressY = thisINP.data.res[thisINP.timerA.countCurrent][thisINP.data.resKey.transform.y].instance[child.name];
                    if (thisINP.data.resKey.transform.z)
                        orgPressZ = thisINP.data.res[thisINP.timerA.countCurrent][thisINP.data.resKey.transform.z].instance[child.name];

                    let temp_instance = thisINP.data.source.part[child.Part_name];

                    for (let PEi in temp_instance.point_index) {
                        if (PEi.indexOf('B31') != -1 || PEi.indexOf('T3D2') != -1) {
                            continue;
                        }

                        let perElement = temp_instance.point_index[PEi];
                        for (let Ti in perElement) {
                            let perTri = perElement[Ti];
                            if (perTri.enable == true) {
                                for (let Pi in perTri.points) {
                                    let per = perTri.points[Pi];


                                    let x1 = orgPressX[per] * thisINP.setting.transValue;
                                    let y1 = orgPressY[per] * thisINP.setting.transValue;
                                    let z1 = orgPressZ ? orgPressZ[per] * thisINP.setting.transValue : 0;

                                    vertices.push(temp_instance.vertex[per][0] + x1);
                                    vertices.push(temp_instance.vertex[per][1] + y1);
                                    vertices.push(temp_instance.vertex[per][2] + z1);
                                }
                            }
                        }

                    }

                    vertices = new Float32Array(vertices);
                    child.geometry.setAttribute('position', new BufferAttribute(vertices, 3));
                }
                else if (child.type === "LineSegments") {
                    let vertices = [];
                    let orgPressX = 0;
                    let orgPressY = 0;
                    let orgPressZ = 0;
                    if (thisINP.data.resKey.transform.x)
                        orgPressX = thisINP.data.res[thisINP.timerA.countCurrent][thisINP.data.resKey.transform.x].instance[child.name];
                    if (thisINP.data.resKey.transform.x)
                        orgPressY = thisINP.data.res[thisINP.timerA.countCurrent][thisINP.data.resKey.transform.y].instance[child.name];
                    if (thisINP.data.resKey.transform.z)
                        orgPressZ = thisINP.data.res[thisINP.timerA.countCurrent][thisINP.data.resKey.transform.z].instance[child.name];

                    let temp_instance = thisINP.data.source.part[child.Part_name];

                    for (let PEi in temp_instance.point_index) {
                        if (PEi.indexOf('B31') == -1 && PEi.indexOf('T3D2') == -1) {
                            continue;
                        }
                        let perElement = temp_instance.point_index[PEi];
                        for (let Ti in perElement) {
                            let perTri = perElement[Ti];
                            if (perTri.enable == true) {
                                for (let Pi in perTri.points) {
                                    let per = perTri.points[Pi];

                                    let x1 = orgPressX[per] * thisINP.setting.transValue;
                                    let y1 = orgPressY[per] * thisINP.setting.transValue;
                                    let z1 = orgPressZ ? orgPressZ[per] * thisINP.setting.transValue : 0;

                                    vertices.push(temp_instance.vertex[per][0] + x1);
                                    vertices.push(temp_instance.vertex[per][1] + y1);
                                    vertices.push(temp_instance.vertex[per][2] + z1);
                                }
                            }
                        }

                    }

                    vertices = new Float32Array(vertices);
                    child.geometry.setAttribute('position', new BufferAttribute(vertices, 3));
                }
            });

        }
        else {
            this.mesh.inpGroup.traverse(function (child) {
                if (child.type == "Mesh") {
                    let vertices = [];
                    let temp_instance = thisINP.data.source.part[child.Part_name];
                    for (let PEi in temp_instance.point_index) {
                        if (PEi.indexOf('B31') != -1 || PEi.indexOf('T3D2') != -1) {
                            continue;
                        }
                        let perElement = temp_instance.point_index[PEi];
                        for (let Ti in perElement) {
                            let perTri = perElement[Ti];
                            if (perTri.enable == true) {
                                for (let Pi in perTri.points) {
                                    let per = perTri.points[Pi];
                                    vertices.push(temp_instance.vertex[per][0]);
                                    vertices.push(temp_instance.vertex[per][1]);
                                    vertices.push(temp_instance.vertex[per][2]);
                                }
                            }
                        }

                    }
                    vertices = new Float32Array(vertices);
                    child.geometry.setAttribute('position', new BufferAttribute(vertices, 3));
                }
                else if (child.type == "LineSegments") {
                    let vertices = [];

                    let temp_instance = thisINP.data.source.part[child.Part_name];
                    for (let PEi in temp_instance.point_index) {
                        if (PEi.indexOf('B31') == -1 && PEi.indexOf('T3D2') == -1) {
                            continue;
                        }
                        let perElement = temp_instance.point_index[PEi];
                        for (let Ti in perElement) {
                            let perTri = perElement[Ti];
                            if (perTri.enable == true) {
                                for (let Pi in perTri.points) {
                                    let per = perTri.points[Pi];
                                    vertices.push(temp_instance.vertex[per][0]);
                                    vertices.push(temp_instance.vertex[per][1]);
                                    vertices.push(temp_instance.vertex[per][2]);
                                }
                            }
                        }

                    }
                    vertices = new Float32Array(vertices);
                    child.geometry.setAttribute('position', new BufferAttribute(vertices, 3));
                }
            });
        }
        // if (this.flagshow.line)
        if (this.setting.frameline)
            thisINP.doTransLines();
    }

    //for 2D,
    // 3D rewrite by inpmsh.js
    doTransLines() {
        // if (thisINP.setting.trans) 
        if (this.mesh.lines.visible) {
            this.worldBox.remove(this.mesh.lines);
            this.mesh.lines = new Group();
            this.mesh.inpGroup.traverse(function (child) {
                if (child.type === "Mesh") {
                    let Edges = new EdgesGeometry(child.geometry, 0);
                    let Edge_Line = new LineSegments(Edges, thisINP.material.line);
                    Edge_Line.applyMatrix4(child.matrix);
                    // Edge_Line.applyMatrix4(child.matrixWorld);
                    Edge_Line.updateMatrix();
                    // Edge_Line.updateWorldMatrix();

                    thisINP.mesh.lines.add(Edge_Line);
                    // this.mesh.lines.name = "edge_line";
                }
            })
            // this.center(this.mesh.lines);
            this.mesh.lines.applyMatrix4(this.mesh.inpGroup.matrix);
            this.worldBox.add(this.mesh.lines);

        }
        this.parent.render();
    }

    ///////////////////////////////////////////////////////////////////////////////
    // inp file's function about data
    //
    getRES(value) {
        let text = value[0];
        let index = value[1];
        thisINP.data.res[index] = thisINP.data_parse(text, thisINP.data.resKey);
        let ac = 1;
    }

    get_region_name(line, region_line_title) {
        let temp_1 = line.split('\r')[0];//去除 \r
        let name;
        if (line.includes(region_line_title[0])) {
            name = line.split(region_line_title[0])[1];
        }
        else if (line.includes(region_line_title[1])) {
            name = line.split(region_line_title[1])[1];
        }

        name = name.toLowerCase();
        return name;
    }
    setPressuresTransX(pressure) {
        this.data.resKey.transform.x = pressure;
    }
    setPressuresTransY(pressure) {
        this.data.resKey.transform.y = pressure;
    }
    setPressuresTransZ(pressure) {
        this.data.resKey.transform.z = pressure;
    }
    setPressuresTransPush(pressure) {
        this.data.resKey.transform.array.push(pressure);
    }
    getUVW() {
        return [
            [
                1
            ],
            [
                this.data.resKey.transform.x,
                this.data.resKey.transform.y,
                this.data.resKey.transform.z

            ]
        ]
    }
    setPressuresTranArray(pressures) {
        for (let i in pressures) {
            if (i == "U.U1") {
                this.setPressuresTransX(i);
                this.setPressuresTransPush(i);
            }
            if (i == "U.U2") {
                this.setPressuresTransY(i);
                this.setPressuresTransPush(i);
            }
            if (i == "U.U3") {
                this.setPressuresTransZ(i);
                this.setPressuresTransPush(i);
            }
            if (i == "V.V1" && this.data.resKey.transform.x != "U.U1") {
                this.setPressuresTransX(i);
                this.setPressuresTransPush(i);
            }
            else if (i == "V.V1") {
                this.setPressuresTransPush(i);
            }
            if (i == "V.V2" && this.data.resKey.transform.x != "U.U2") {
                this.setPressuresTransY(i);
                this.setPressuresTransPush(i);
            }
            else if (i == "V.V2") {
                this.setPressuresTransPush(i);
            }
            if (i == "V.V3" && this.data.resKey.transform.x != "U.U3") {
                this.setPressuresTransZ(i);
                this.setPressuresTransPush(i);
            }
            else if (i == "V.V3") {
                this.setPressuresTransPush(i);
            }
        }
    }
    data_parse(text, pressures) {
        let lines = text.split('\r\n');
        let Part_name = false;//=region name
        let flage_region = false;
        let flag_data = false;

        let region_line_title = pressures.part_split.title;
        let region_line_split = pressures.part_split.split;

        let pressure_I = false;

        let json_data_parse = {};//本函数内的总json
        for (var per_line of lines) {//per line 
            if (per_line.includes(region_line_title[0]) || per_line.includes(region_line_title[1])) {//数据开始 for regio
                flage_region = true;//开始
                Part_name = this.get_region_name(per_line, region_line_title);
                if (Part_name.indexOf('.region_') != -1) {
                    Part_name = Part_name.split('.region_')[0];
                }
                // console.log('=====',Part_name)
                pressure_I = true;
                continue;
            }
            if (flage_region) {
                if (per_line.length == 0 && pressure_I == true) {
                    pressure_I = 2;
                    continue;
                }


                if (pressure_I === 2) {
                    let name_line = per_line.split(/\s+/);
                    let temp_i = 0;
                    for (let name_i in name_line) {
                        let name = name_line[name_i];
                        if (temp_i > 1) {
                            this.data.resKey.pressure_kind[name] = temp_i;
                            this.data.resKey.pressure_title[name] = name;
                        }
                        temp_i++;
                    }
                    this.setPressuresTranArray(this.data.resKey.pressure_title);
                    pressure_I = false;
                    continue;
                }
            }
            // if (pressure_I) pressure_I++;
            if (flage_region) {
                // if (pressure_I == 6) {
                //     let name_line = per_line.split(/\s+/);
                //     let temp_i = 0;
                //     for (let name_i in name_line) {
                //         let name = name_line[name_i];
                //         if (temp_i > 1) {
                //             this.data.resKey.pressure_kind[name] = temp_i;
                //             this.data.resKey.pressure_title[name] = name;
                //         }
                //         temp_i++;
                //     }
                //     this.setPressuresTranArray(this.data.resKey.pressure_title);
                //     continue;
                // }
                if (per_line.includes(pressures.Pt_Data.start_line)) {
                    flag_data = true;
                    continue;
                }
                if (flag_data && per_line === pressures.Pt_Data.end_line) {
                    flag_data = false;
                    flage_region = false;
                    Part_name = false;
                    continue;
                }
                if (flag_data) {//开始数据处理
                    let data_line = per_line.split(/\s+/);
                    let per_vertex = data_line[1]; //vertec 

                    for (let key in pressures.pressure_kind) {//pressure kind of per line 
                        let output_name = key;// pressure name
                        let output_name_index = pressures.pressure_kind[key]; // No.

                        if (typeof json_data_parse[key] == "undefined") {//per pressure
                            json_data_parse[key] = {};
                        }
                        if (typeof json_data_parse[key]["instance"] == "undefined") {// [key]["instance"],目前看可以去掉，需要全局考虑多个版本的inp的同步
                            json_data_parse[key]["instance"] = {};
                        }
                        if (typeof json_data_parse[key]["instance"][Part_name] == "undefined") {//the "Part_name" in here is Intance name ,not real part name 
                            json_data_parse[key]["instance"][Part_name] = [];
                        }

                        let v = parseFloat(data_line[output_name_index]);               //float 
                        // if (json_data_parse[key]["instance"][Part_name][per_vertex]) { // per point 多个region的多个值，加权方法与权重待议，目前问题是会追加的数据权重大
                        //     json_data_parse[key]["instance"][Part_name][per_vertex] += v;
                        //     json_data_parse[key]["instance"][Part_name][per_vertex] *= 0.5;
                        // }
                        // else {
                        json_data_parse[key]["instance"][Part_name][per_vertex] = v;
                        // }
                        let abc = 1;

                        if (typeof json_data_parse[key].title == "undefined") {
                            json_data_parse[key].title = pressures.pressure_title[key];
                        }
                        // if (typeof json_data_parse[key].file == "undefined") {       // for nodejs server's output files 
                        //     json_data_parse[key].file = pressures.file;
                        // }
                    }//end of kind of presures
                }//end of data
            }// end of part of instance 
        }
        return json_data_parse;
    }
    format_to_instance(part, instance, json_data_parse, pressure_input_json) {
        let json_end = {}
        for (let key in pressure_input_json.pressures.pressure_kind) {//pressure kind of per line 
            // let output_name = key;// pressure name
            // let output_name_index = pressures.pressure_kind[key]; // No.

            if (typeof json_end[key] == "undefined") {//per pressure
                json_end[key] = {};
            }
            if (typeof json_end[key].title == "undefined") json_end[key].title = json_data_parse[key].title;
            if (typeof json_end[key].file == "undefined") json_end[key].file = json_data_parse[key].file;

            if (typeof json_end[key]["instance"] == "undefined") {//per pressure
                json_end[key]["instance"] = {};
            }
            for (let per_instance in instance) {
                if (per_instance == 'assembly') continue;
                let per_part = instance[per_instance]["part"];
                let points = part[per_part].point_index;


                if (typeof json_end[key]["instance"][per_instance] == "undefined") {//per pressure
                    json_end[key]["instance"][per_instance] = [];
                }
                for (let per_point of points) {
                    let pressure = json_data_parse[key]["instance"][per_instance][per_point];
                    json_end[key]["instance"][per_instance].push(pressure)
                }
            }
        }
        let abc = 1;
        return json_end;
    }

    ////////////////////////////////////////////////////////////////////////////////////////////
    // rewrte parent function 
    callDraw() {
        this.create_Geometry(this.data.source.part);
        this.drawINP();
        this.center(this.mesh.inpGroup);
        if (this.flagshow.line)
            this.drawLines();
        this.center(this.mesh.lines, this.mesh.inpGroup);

        this.parent.render();
    }
    ////////////////////////////////////////////////////////////////////////////////////////////////
    // about cloud map
    getCMName() {
        return this.getPressureSName();
    }
    getPressureSName() {
        let names = [];
        for (let i in this.data.resKey.pressure_title) {
            names.push(i)
        }
        // for (let i in this.data.res['1']) {
        //     names.push(i)
        // }
        // console.log(names)
        return names;
    }

    confirmTrans() {
        if (this.setting.trans) {
            if (thisINP.data.res[thisINP.nameOfCloudmap]) {
                // this.setting.trans=value;
                this.setting.trans = false;
            }
            else {
                this.setting.trans = false;
            }
        }
    }
    setEnableTransformation(value) {
        this.setting.trans = value;
    }
    setTransformationValue(value = false) {
        if (value) this.setting.transValue = value;
    }
    show(value = false) {
        if (value) {
            // let temp = value.split(".");
            thisINP.nameOfCloudmap = value;
        }
        if (thisINP.nameOfCloudmap) {
            thisINP.doTrans();
            thisINP.updatePressures(value);
            thisINP.updateColors();
        }
        if (this.flageClipping) {
            this.repairPoly()
        }
        this.centerForTrains();
        this.parent.render();
    }
    stop() {
        clearInterval(thisINP.timerA.timer);
        thisINP.timerA.visible = false;
        thisINP.timerA.index = 0;
    }
    play(s = false) {
        if (s) thisINP.timerA.sss = s;
        if (thisINP.nameOfCloudmap !== false) {
            // thisINP.data.resDo['1'][name].instance[child.name];
            let list = thisINP.data.res;
            if (Object.keys(list).length > 1) {
                thisINP.timerA.count = Object.keys(list).length;
                thisINP.timerA.countCurrent = 1;
                thisINP.timerA.visible = true;

                thisINP.timerA.timer = setInterval(function () {

                    // thisINP.doTrans();
                    thisINP.updatePressures(thisINP.nameOfCloudmap, thisINP.timerA.countCurrent++);
                    thisINP.updateColors();

                    if (thisINP.timerA.visible == false) {
                        clearInterval(thisINP.timerA.timer);
                    }
                    if (thisINP.timerA.countCurrent >= thisINP.timerA.count) {

                        thisINP.timerA.countCurrent = 1;
                    }
                    // console.log(thisINP.timerA.countCurrent);
                }, s * 1000);
            }
        }
        else {
            console.log("plases select one value of 参数")
            return { type: false, msg: "none value for select" }
        }
    }


    /////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // upadate 
    updatePressures(name, index = 1) {//mesh json,group id 's array, groupid,meshcolor_json=fale==no presure

        // let edgesMtl = new THREE.LineBasicMaterial({ color: 0x000000 });

        this.mesh.inpGroup.traverse(function (child) {
            if (child.type == "Mesh") {
                let orgPress = thisINP.data.res[index][name].instance[child.name];
                let MM = thisINP.getMaxMin(orgPress);
                let pressure = [];
                let cm = [];

                let temp_instance = thisINP.data.source.part[child.Part_name];
                for (let PEi in temp_instance.point_index) {
                    if (PEi.indexOf('B31') != -1 || PEi.indexOf('T3D2') != -1) {
                        continue;
                    }
                    let perElement = temp_instance.point_index[PEi];
                    for (let Ti in perElement) {
                        let perTri = perElement[Ti];
                        if (perTri.enable == true) {


                            for (let Pi in perTri.points) {
                                let per = perTri.points[Pi];
                                pressure.push(orgPress[per]);
                            }

                            if (perTri.points.length == 6) {
                                let perOne1 = perTri.points[0];
                                let perOne2 = perTri.points[1];
                                let perOne3 = perTri.points[2];
                                let perOne4 = perTri.points[3];
                                let perOne5 = perTri.points[4];
                                let perOne6 = perTri.points[5];
                                let pressures1 = (orgPress[perOne1] - MM[1]) / (MM[0] - MM[1]);
                                let pressures2 = (orgPress[perOne2] - MM[1]) / (MM[0] - MM[1]);
                                let pressures3 = (orgPress[perOne3] - MM[1]) / (MM[0] - MM[1]);
                                let pressures4 = (orgPress[perOne4] - MM[1]) / (MM[0] - MM[1]);
                                let pressures5 = (orgPress[perOne5] - MM[1]) / (MM[0] - MM[1]);
                                let pressures6 = (orgPress[perOne6] - MM[1]) / (MM[0] - MM[1]);
                                cm.push(pressures1, pressures2, pressures3);
                                cm.push(pressures1, pressures2, pressures3);
                                cm.push(pressures1, pressures2, pressures3);
                                cm.push(pressures4, pressures5, pressures6);
                                cm.push(pressures4, pressures5, pressures6);
                                cm.push(pressures4, pressures5, pressures6);

                            }
                            if (perTri.points.length == 3) {
                                let perOne1 = perTri.points[0];
                                let perOne2 = perTri.points[1];
                                let perOne3 = perTri.points[2];
                                let pressures1 = (orgPress[perOne1] - MM[1]) / (MM[0] - MM[1]);
                                let pressures2 = (orgPress[perOne2] - MM[1]) / (MM[0] - MM[1]);
                                let pressures3 = (orgPress[perOne3] - MM[1]) / (MM[0] - MM[1]);
                                cm.push(pressures1, pressures2, pressures3);
                                cm.push(pressures1, pressures2, pressures3);
                                cm.push(pressures1, pressures2, pressures3);
                            }
                        }
                    }
                }

                child.geometry.setAttribute('pressure', new Float32BufferAttribute(pressure, 1));
                if (child.type != "LineSegments")
                    child.geometry.setAttribute('cm', new Float32BufferAttribute(new Float32Array(cm), 3));
            } else if (child.type == "LineSegments") {
                let orgPress = thisINP.data.res[index][name].instance[child.name];
                // let MM = thisINP.getMaxMin(orgPress);
                let pressure = [];
                // let cm = [];

                let temp_instance = thisINP.data.source.part[child.Part_name];
                for (let PEi in temp_instance.point_index) {
                    if (PEi.indexOf('B31') != -1 || PEi.indexOf('T3D2') != -1) {
                        let perElement = temp_instance.point_index[PEi];
                        for (let Ti in perElement) {
                            let perTri = perElement[Ti];
                            if (perTri.enable == true) {
                                for (let Pi in perTri.points) {
                                    let per = perTri.points[Pi];
                                    pressure.push(orgPress[per]);
                                }

                            }
                        }
                    }
                }
                child.geometry.setAttribute('pressure', new Float32BufferAttribute(pressure, 1));
            }
        })

    }
    updateColors() {
        // return;
        if (this.parent.LUT) {
            let bigArray = []
            this.mesh.inpGroup.traverse(function (child) {
                if (child.PJ == "INP") {
                    for (let i of child.geometry.attributes.pressure.array)
                        bigArray.push(i);
                }
            });

            // if (this.setting.cm.use == "default")
            {
                let lut = this.parent.LUT.lut;
                let sprite = this.parent.LUT.sprite;
                this.parent.LUT.setMaxMin(bigArray);//顺序顶点的pressure值
                this.mesh.inpGroup.traverse(function (child) {
                    if (child.type == "Mesh" || child.type == "LineSegments") {
                        // thisINP.parent.LUT.setMaxMin(child.geometry.attributes.pressure.array);//顺序顶点的pressure值
                        let mesh = child;
                        const geometry = mesh.geometry;
                        const pressures = geometry.attributes.pressure;
                        let colors = geometry.attributes.color;
                        for (let i = 0; i < pressures.array.length; i++) {
                            const colorValue = pressures.array[i];
                            const color = lut.getColor(colorValue);
                            if (color !== undefined) {
                                colors.setXYZ(i, color.r, color.g, color.b);
                            }
                        }
                        colors.needsUpdate = true;
                    }
                })
                let map = sprite.material.map;
                this.parent.LUT.update_text(" ");
                map.needsUpdate = true;
            }
        }
    }
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //外壳
    C3D8R(Part_name, type, other = false) {
        let pointIdeexOfFaceOutside;
        let alllineIndexObject;
        let shell, shellPoints;
        let triangles;

        if (typeof this.data.source.part[Part_name].non_index == "undefined") {//画三角形点序列，无index
            this.data.source.part[Part_name].non_index = new Array();
        }
        if (typeof this.data.source.part[Part_name].colors == "undefined") {//颜色，无index
            this.data.source.part[Part_name].colors = new Array();
        }
        if (typeof this.data.source.part[Part_name].pressure == "undefined") {// pressure ,no index
            this.data.source.part[Part_name].pressure = new Array();
        }
        if (typeof this.data.source.part[Part_name].point_index == "undefined") {//有索引的体外面的json
            this.data.source.part[Part_name].point_index = {};//new Array();
            this.data.source.part[Part_name].point_index[type] = {};//new Array();
            this.data.source.part[Part_name].line_index = {};//new Array();
            this.data.source.part[Part_name].line_index[type] = {};//new Array();
            this.data.source.part[Part_name].shell_index = {};//new Array();
            this.data.source.part[Part_name].shell_points = {};//new Array();
            this.data.source.part[Part_name].shell_index[type] = {};//new Array();
            this.data.source.part[Part_name].shell_points[type] = {};//new Array();
            this.data.source.part[Part_name].tri_index = {};//new Array();
            this.data.source.part[Part_name].tri_index[type] = {};//new Array();
        }
        else {
            this.data.source.part[Part_name].point_index[type] = {};//new Array();
            this.data.source.part[Part_name].line_index[type] = {};//new Array();
            this.data.source.part[Part_name].shell_index[type] = {};//new Array();
            this.data.source.part[Part_name].shell_points[type] = {};//new Array();
            this.data.source.part[Part_name].tri_index[type] = {};//new Array();
        }
        if (typeof this.data.source.part[Part_name].pointsOfPeronePart == "undefined") {//画三角形点序列，无index,每个体的点集合
            this.data.source.part[Part_name].pointsOfPeronePart = {};
        }

        this.data.source.part[Part_name].pointsOfPeronePart[type] = [];
        pointIdeexOfFaceOutside = this.data.source.part[Part_name].point_index[type];//外壳面的点集合：json个数
        alllineIndexObject = this.data.source.part[Part_name].line_index[type];//全部线的点集合：json个数
        shell = this.data.source.part[Part_name].shell_index[type];
        shellPoints = this.data.source.part[Part_name].shell_points[type];
        triangles = this.data.source.part[Part_name].tri_index[type];

        let cubeFace = {};
        let a = 0, b = 0, c = 0;

        let allElements = [];

        allElements = this.data.source.part[Part_name].element[type].element;

        for (let i in allElements) {//part 的 element 循环
            let element = allElements[i];//element的 单体

            let e1 = element[0];//element 的点序列，矩形体
            let e2 = element[1];
            let e3 = element[2];
            let e4 = element[3];
            let e5 = element[4];
            let e6 = element[5];
            let e7 = element[6];
            let e8 = element[7];
            //12345678顺序是abquas的
            let lineAll = [
                e1, e4, e4, e3, e3, e2, e2, e1,//right
                e5, e8, e8, e7, e7, e6, e6, e5,//left
                e5, e1, e2, e6, e3, e7, e4, e8,//other
            ];
            let triAll = [
                [e5, e1, e6,],
                [e1, e2, e6],//front
                [e8, e4, e7],
                [e4, e3, e7],//back                
                [e6, e2, e7],
                [e2, e3, e7],//top
                [e5, e1, e8],
                [e1, e4, e8],//down      
                [e8, e5, e7],
                [e5, e6, e7],//L
                [e4, e1, e3],
                [e1, e2, e3],//R           
            ];
            let box = [
                [e5, e1, e2, e6],//f
                [e8, e4, e3, e7],//b
                [e6, e2, e3, e7],//U
                [e5, e1, e4, e8],//D
                [e8, e5, e6, e7],//L
                [e4, e1, e2, e3],//R
            ];

            this.data.source.part[Part_name].pointsOfPeronePart[type].push(lineAll);//每个单元的集合，array


            for (let side of box) {//消隐
                let per = JSON.parse(JSON.stringify(side)).sort().join("-");
                if (typeof cubeFace[per] == 'undefined') {
                    cubeFace[per] = {
                        points: side,
                        enable: true,
                    }
                }
                else {
                    cubeFace[per].enable = false;
                }
            }

            if (other == false)
                for (let j = 0; j < lineAll.length; j += 2) {//线集合
                    let per = [lineAll[j + 0], lineAll[j + 1]];
                    let perStore = JSON.parse(JSON.stringify(per)).sort().join("-");
                    if (typeof alllineIndexObject[perStore] == "undefined") {
                        alllineIndexObject[perStore] = {
                            points: per,
                            enable: true,
                        };
                    }
                    else {
                        // alllineIndexObject[perStore].enable = false;
                    }
                }
            // console.log(i)
        }
        let RPoints = [];
        for (let i in cubeFace) {
            let perFace = cubeFace[i];
            let name = i;
            if (perFace.enable) {
                let points = perFace.points;
                pointIdeexOfFaceOutside[name] = {
                    points: [points[0], points[1], points[3], points[1], points[2], points[3],],
                    enable: true,
                };
                //外壳
                shell[[points[0], points[1]].join("-")] = [points[0], points[1]];
                shell[[points[1], points[2]].join("-")] = [points[1], points[2]];
                shell[[points[2], points[3]].join("-")] = [points[2], points[3]];
                shell[[points[3], points[0]].join("-")] = [points[3], points[0]];
                shellPoints[points[0]] = points[0];
                shellPoints[points[1]] = points[1];
                shellPoints[points[2]] = points[2];
                shellPoints[points[3]] = points[3];

            }
        }
        return other == false ? RPoints : 1;
        // //console.log(Part_name);
        let abc = 1;
    }
    C3D4(Part_name, type, other = false) {
        let pointIdeexOfFaceOutside;
        let alllineIndexObject;
        let shell, shellPoints;
        let triangles;

        if (typeof this.data.source.part[Part_name].non_index == "undefined") {//画三角形点序列，无index
            this.data.source.part[Part_name].non_index = new Array();
        }
        if (typeof this.data.source.part[Part_name].colors == "undefined") {//颜色，无index
            this.data.source.part[Part_name].colors = new Array();
        }
        if (typeof this.data.source.part[Part_name].pressure == "undefined") {// pressure ,no index
            this.data.source.part[Part_name].pressure = new Array();
        }
        if (typeof this.data.source.part[Part_name].point_index == "undefined") {//有索引的体外面的json
            this.data.source.part[Part_name].point_index = {};//new Array();
            this.data.source.part[Part_name].point_index[type] = {};//new Array();
            this.data.source.part[Part_name].line_index = {};//new Array();
            this.data.source.part[Part_name].line_index[type] = {};//new Array();
            this.data.source.part[Part_name].shell_index = {};//new Array();
            this.data.source.part[Part_name].shell_points = {};//new Array();
            this.data.source.part[Part_name].shell_index[type] = {};//new Array();
            this.data.source.part[Part_name].shell_points[type] = {};//new Array();
            this.data.source.part[Part_name].tri_index = {};//new Array();
            this.data.source.part[Part_name].tri_index[type] = {};//new Array();
        }
        else {
            this.data.source.part[Part_name].point_index[type] = {};//new Array();
            this.data.source.part[Part_name].line_index[type] = {};//new Array();
            this.data.source.part[Part_name].shell_index[type] = {};//new Array();
            this.data.source.part[Part_name].shell_points[type] = {};//new Array();
            this.data.source.part[Part_name].tri_index[type] = {};//new Array();
        }
        if (typeof this.data.source.part[Part_name].pointsOfPeronePart == "undefined") {//画三角形点序列，无index,每个体的点集合
            this.data.source.part[Part_name].pointsOfPeronePart = {};
        }

        this.data.source.part[Part_name].pointsOfPeronePart[type] = [];
        pointIdeexOfFaceOutside = this.data.source.part[Part_name].point_index[type];//外壳面的点集合：json个数
        alllineIndexObject = this.data.source.part[Part_name].line_index[type];//全部线的点集合：json个数
        shell = this.data.source.part[Part_name].shell_index[type];
        shellPoints = this.data.source.part[Part_name].shell_points[type];
        triangles = this.data.source.part[Part_name].tri_index[type];

        let cubeFace = {};
        let a = 0, b = 0, c = 0;

        let allElements = [];

        allElements = this.data.source.part[Part_name].element[type].element;

        for (let i in allElements) {//part 的 element 循环
            let element = allElements[i];//element的 单体

            let e1 = element[0];//element 的点序列，矩形体
            let e2 = element[1];
            let e3 = element[2];
            let e4 = element[3];
            //12345678顺序是abquas的
            let lineAll = [
                e1, e2,
                e2, e3,
                e3, e1,

                e1, e4,
                e2, e4,
                e3, e4,
            ];
            let faceAll = [
                [e1, e2, e3],
                [e1, e2, e4],
                [e2, e3, e4],
                [e3, e1, e4],
            ];

            this.data.source.part[Part_name].pointsOfPeronePart[type].push(lineAll);//每个单元的集合，array

            for (let side of faceAll) {//消隐
                let per = JSON.parse(JSON.stringify(side)).sort().join("-");
                if (typeof cubeFace[per] == 'undefined') {
                    cubeFace[per] = {
                        points: side,
                        enable: true,
                    }
                }
                else {
                    cubeFace[per].enable = false;
                }
            }

            if (other == false)
                for (let j = 0; j < lineAll.length; j += 2) {//线集合
                    let per = [lineAll[j + 0], lineAll[j + 1]];
                    let perStore = JSON.parse(JSON.stringify(per)).sort().join("-");
                    if (typeof alllineIndexObject[perStore] == "undefined") {
                        alllineIndexObject[perStore] = {
                            points: per,
                            enable: true,
                        };
                    }
                    else {
                        // alllineIndexObject[perStore].enable = false;
                    }
                }
            // console.log(i)
        }
        let RPoints = [];
        for (let i in cubeFace) {
            let perFace = cubeFace[i];
            let name = i;
            if (perFace.enable) {
                let points = perFace.points;
                pointIdeexOfFaceOutside[name] = {
                    points: [points[0], points[1], points[2]],
                    enable: true,
                };
                //外壳
                shell[[points[0], points[1]].join("-")] = [points[0], points[1]];
                shell[[points[1], points[2]].join("-")] = [points[1], points[2]];
                shell[[points[2], points[0]].join("-")] = [points[2], points[0]];
                shellPoints[points[0]] = points[0];
                shellPoints[points[1]] = points[1];
                shellPoints[points[2]] = points[2];
            }
        }
        return other == false ? RPoints : 1;
        // //console.log(Part_name);
        let abc = 1;
    }
    C3D10(Part_name, type, other = false) {
        let pointIdeexOfFaceOutside;
        let alllineIndexObject;
        let shell, shellPoints;
        let triangles;

        if (typeof this.data.source.part[Part_name].non_index == "undefined") {//画三角形点序列，无index
            this.data.source.part[Part_name].non_index = new Array();
        }
        if (typeof this.data.source.part[Part_name].colors == "undefined") {//颜色，无index
            this.data.source.part[Part_name].colors = new Array();
        }
        if (typeof this.data.source.part[Part_name].pressure == "undefined") {// pressure ,no index
            this.data.source.part[Part_name].pressure = new Array();
        }
        if (typeof this.data.source.part[Part_name].point_index == "undefined") {//有索引的体外面的json
            this.data.source.part[Part_name].point_index = {};//new Array();
            this.data.source.part[Part_name].point_index[type] = {};//new Array();
            this.data.source.part[Part_name].line_index = {};//new Array();
            this.data.source.part[Part_name].line_index[type] = {};//new Array();
            this.data.source.part[Part_name].shell_index = {};//new Array();
            this.data.source.part[Part_name].shell_points = {};//new Array();
            this.data.source.part[Part_name].shell_index[type] = {};//new Array();
            this.data.source.part[Part_name].shell_points[type] = {};//new Array();
            this.data.source.part[Part_name].tri_index = {};//new Array();
            this.data.source.part[Part_name].tri_index[type] = {};//new Array();
        }
        else {
            this.data.source.part[Part_name].point_index[type] = {};//new Array();
            this.data.source.part[Part_name].line_index[type] = {};//new Array();
            this.data.source.part[Part_name].shell_index[type] = {};//new Array();
            this.data.source.part[Part_name].shell_points[type] = {};//new Array();
            this.data.source.part[Part_name].tri_index[type] = {};//new Array();
        }
        if (typeof this.data.source.part[Part_name].pointsOfPeronePart == "undefined") {//画三角形点序列，无index,每个体的点集合
            this.data.source.part[Part_name].pointsOfPeronePart = {};
        }

        this.data.source.part[Part_name].pointsOfPeronePart[type] = [];
        pointIdeexOfFaceOutside = this.data.source.part[Part_name].point_index[type];//外壳面的点集合：json个数
        alllineIndexObject = this.data.source.part[Part_name].line_index[type];//全部线的点集合：json个数
        shell = this.data.source.part[Part_name].shell_index[type];
        shellPoints = this.data.source.part[Part_name].shell_points[type];
        triangles = this.data.source.part[Part_name].tri_index[type];

        let cubeFace = {};
        let a = 0, b = 0, c = 0;

        let allElements = [];

        allElements = this.data.source.part[Part_name].element[type].element;

        for (let i in allElements) {//part 的 element 循环
            let element = allElements[i];//element的 单体

            let e1 = element[0];//element 的点序列，矩形体
            let e2 = element[1];
            let e3 = element[2];
            let e4 = element[3];
            let e5 = element[4];
            let e6 = element[5];
            let e7 = element[6];
            let e8 = element[7];
            let e9 = element[8];
            let e10 = element[9];

            //12345678顺序是abquas的
            let lineAll = [
                e1, e2,
                e2, e3,
                e3, e1,

                e1, e4,
                e2, e4,
                e3, e4,
            ];
            let faceAll = [
                [e1, e2, e3],
                [e1, e2, e4],
                [e2, e3, e4],
                [e3, e1, e4],
            ];

            this.data.source.part[Part_name].pointsOfPeronePart[type].push(lineAll);//每个单元的集合，array

            for (let side of faceAll) {//消隐
                let per = JSON.parse(JSON.stringify(side)).sort().join("-");
                if (typeof cubeFace[per] == 'undefined') {
                    cubeFace[per] = {
                        points: side,
                        enable: true,
                    }
                }
                else {
                    cubeFace[per].enable = false;
                }
            }

            if (other == false)
                for (let j = 0; j < lineAll.length; j += 2) {//线集合
                    let per = [lineAll[j + 0], lineAll[j + 1]];
                    let perStore = JSON.parse(JSON.stringify(per)).sort().join("-");
                    if (typeof alllineIndexObject[perStore] == "undefined") {
                        alllineIndexObject[perStore] = {
                            points: per,
                            enable: true,
                        };
                    }
                    else {
                        // alllineIndexObject[perStore].enable = false;
                    }
                }
            // console.log(i)
        }
        let RPoints = [];
        for (let i in cubeFace) {
            let perFace = cubeFace[i];
            let name = i;
            if (perFace.enable) {
                let points = perFace.points;
                pointIdeexOfFaceOutside[name] = {
                    points: [points[0], points[1], points[2]],
                    enable: true,
                };
                //外壳
                shell[[points[0], points[1]].join("-")] = [points[0], points[1]];
                shell[[points[1], points[2]].join("-")] = [points[1], points[2]];
                shell[[points[2], points[0]].join("-")] = [points[2], points[0]];
                shellPoints[points[0]] = points[0];
                shellPoints[points[1]] = points[1];
                shellPoints[points[2]] = points[2];
            }
        }
        return other == false ? RPoints : 1;
        // //console.log(Part_name);
        let abc = 1;
    }
    //作废
    format2structure(Part_name, perType, other = false) {
        let points;
        if (perType == "CPE3") {
            points = this.CPE3(Part_name, perType, other);
        }
        if (perType == "CPE4") {
            points = this.CPE4(Part_name, perType, other);
        }

        if (perType == "S4" || perType == "S4R") {
            points = this.S4(Part_name, perType, other);
        }
        if (perType.indexOf("C3D8") != -1) {
            points = this.C3D8R(Part_name, perType, other);
        }
        // if (perType == "C3D8R" || perType == "C3D8" || perType == "DC3D8") {
        //     this.C3D8R(Part_name, perType);
        // }
        // if (perType == "FC3D8") {
        //     this.FC3D8(Part_name, perType);
        // }
        if (perType == "T3D2") {
            points = this.T3D2(Part_name, perType, other);
        }
        if (perType == "B31") {
            points = this.B31(Part_name, perType, other);
        }
        return points;
    }
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // output 
    getInstanceAll() {
        let value = {};
        for (let i in this.data.source.instance) {
            let perPart = this.data.source.instance[i].part;
            let partkind;
            let elType;
            for (let j in this.data.source.part[perPart]) {
                let element = this.data.source.part[perPart].element;
                for (let k in element) {
                    elType = k;
                }
            }
            switch (elType) {
                case "C3D8R":
                    partkind = "part";
                    break;
                case "FC3D8":
                    partkind = "part";
                    break;
                case "S4":
                    partkind = "shell";
                    break;
                case "CPE3":
                    partkind = "shell";
                    break;
                case "B31":
                    partkind = "line";
                    break;
                case "T3D2":
                    partkind = "line";
                    break;
            }
            value[i] = {
                eltype: elType,
                partkind: partkind,
                name: i,
            };
        }
        return value;
    }
    getPartAll() {
        let value = {};
        let partkind;
        let elType;
        for (let i in this.data.source.part) {
            let element = this.data.source.part[i].element;
            for (let k in element) {
                elType = k;
            }
            switch (elType) {
                case "C3D8R":
                    partkind = "part";
                    break;
                case "FC3D8":
                    partkind = "part";
                    break;
                case "S4R":
                    partkind = "shell";
                    break;
                case "S4":
                    partkind = "shell";
                    break;
                case "CPE3":
                    partkind = "shell";
                    break;
                case "B31":
                    partkind = "line";
                    break;
                case "T3D2":
                    partkind = "line";
                    break;
            }
            value[i] = {
                eltype: elType,
                partkind: partkind,
                name: i,
            };
        }

        return value;
    }
    getMaterialAll() {
        return this.data.source.materials;
    }
    getSectionForInstance() {
        let value = {};
        for (let i in this.data.source.instance) {
            let perPart = this.data.source.instance[i].part;
            let per = this.data.source.part[perPart];
            if (Object.keys(per.Section).length > 0) {
                value[i] = per.Section;
            }
        }
        return value;
    }
    getSectionForPart() {
        let value = {};
        for (let i in this.data.source.part) {
            let per = this.data.source.part[i];
            if (Object.keys(per.Section).length > 0) {
                value[i] = per.Section;

            }
        }
        return value;
    }
    getStepAll() {
        let value = {};
        for (let i in this.data.source.step) {
            let per = this.data.source.step[i];
            value[i] = {
                name: per.name,
                step: per.step,
            }
        }
        return value;
    }
    getLoads() {
        let value = {};
        let loadsOfStep = {};
        for (let i in this.data.source.step) {
            let perStep = this.data.source.step[i];

            let stepB = {};
            stepB.stepname = perStep.name;

            if (typeof perStep.loads != 'undefined') {
                stepB['loads'] = perStep.loads ? perStep.loads : false;

            }
            if (typeof perStep.INTERACTIONS != 'undefined') {
                for (let i in perStep.INTERACTIONS) {
                    if (typeof perStep.INTERACTIONS[i].Sfilm != 'undefined')
                        stepB['INTERACTIONS'] = perStep.INTERACTIONS;
                }
            }
            if (Object.keys(stepB).length > 1) {
                value[i] = stepB;
            }

        }
        if (Object.keys(value).length > 0)
            return value;
        else
            return false;
    }
    getBoundary() {
        let flag_New = false;
        let value = {};
        let boundaryOfStep = {};
        if (Object.keys(this.data.source.boundary).length > 0) {
            value.global = this.data.source.boundary;
        }
        else {
            // value.global=false;
        }
        for (let i in this.data.source.step) {
            let perStep = this.data.source.step[i];
            let stepB = {};
            stepB.stepname = perStep.name;
            if (typeof perStep.boundary != 'undefined') {
                for (let i in perStep.boundary) {
                    stepB[i] = perStep.boundary[i];
                    if (typeof perStep.boundary[i].Boundary != 'undefined') {
                        if (typeof perStep.boundary[i].Boundary.op != 'undefined')
                            if (perStep.boundary[i].Boundary.op.toLowerCase() == 'new')
                                flag_New = true;
                    }

                }
            }
            // if (typeof perStep.INTERACTIONS != 'undefined') {
            //     for (let i in perStep.INTERACTIONS) {
            //         if (typeof perStep.INTERACTIONS[i].Sfilm != 'undefined')
            //             stepB[i] = perStep.INTERACTIONS[i];
            //     }
            // }
            if (Object.keys(stepB).length > 1) {
                boundaryOfStep[i] = stepB;
            }

        }
        if (Object.keys(boundaryOfStep).length > 0) {
            value.step = boundaryOfStep;
        }
        else {
            // value.step = false;
        }
        if (flag_New) {
            delete value.global;
        }

        if (Object.keys(value).length > 0)
            return value;
        else
            return false;
    }
    getFluidBoundaryDirction() {
        let dir;
        // let boundaryOfStep = thisINP.getBoundary();
        // for (let step_i of boundaryOfStep) {
        //     let perStep = boundaryOfStep[step_i];
        //     for (let b_i of perStep) {
        //         let perBoundary = perStep[b_i];
        //         if (typeof perBoundary['Fluid Boundary'] != 'undefined') {
        //             let content = perBoundary.content;
        //             let x = 0, y = 0, z = 0;
        //             for (let perDir of content) {
        //                 let perDirContent = perDir.split(',');
        //                 if (perDirContent[1] == 'VELX') {
        //                     x = perDirContent[2];
        //                 }
        //                 else if (perDirContent[1] == 'VELY') {
        //                     y = perDirContent[2];
        //                 }
        //                 else if (perDirContent[1] == 'VELZ') {
        //                     z = perDirContent[2];
        //                 }
        //             }
        //             dir = [x, y, z];
        //         }
        //     }
        // }

        for (let i in this.data.source.step) {
            let perStep = this.data.source.step[i];
            if (typeof perStep.boundary != 'undefined') {
                for (let i in perStep.boundary) {
                    let perBoundary = perStep.boundary[i];
                    if (typeof perBoundary['Fluid Boundary'] != 'undefined') {
                        let content = perBoundary['Fluid Boundary'].content;
                        let x = 0, y = 0, z = 0;
                        for (let perDir of content) {
                            let perDirContent = perDir.split(',');
                            if (perDirContent[1] == 'VELX') {
                                x = perDirContent[2];
                            }
                            else if (perDirContent[1] == 'VELY') {
                                y = perDirContent[2];
                            }
                            else if (perDirContent[1] == 'VELZ') {
                                z = perDirContent[2];
                            }
                        }
                        dir = [x, y, z];
                    }

                }
            }
        }
        return dir;
    }
    getSurface() {
        return this.data.source.assembly.surface;

    }
    getNset() {
        return this.data.source.assembly.Nset;

    }
    getElset() {
        return this.data.source.assembly.Elset;

    }
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //箭头
    //this.drawArrow("V.V1", "V.V2", "V.V3","V.Magnitude");
    //main.CM.drawArrow("V.V1", "V.V2", "V.V3","V.Magnitude")
    drawArrow(a1, a2, a3, M, kk = 50) {
        let pressures = [];
        if (typeof this.mesh.arrow != "undefined")
            this.parent.worldBox.remove(this.mesh.arrow);
        this.mesh.arrow = new Group();
        this.parent.worldBox.add(this.mesh.arrow);
        this.mesh.arrow.applyMatrix4(this.mesh.inpGroup.matrix);

        let percent = 0.1;
        let length = .1;
        let k = 0;
        for (let i in this.data.source.instance) {// per instance i=instance name
            let Part = this.data.source.instance[i].part;//每个instance，对应的part
            let perPart = this.data.source.part[Part].vertex;//per instance of part's vertex 
            let MAX = false, MIN = false;
            for (let mi in thisINP.data.res[this.timerA.countCurrent][M]["instance"][i]) {//计算每个instance 模的最大与最小值
                let perM = thisINP.data.res[this.timerA.countCurrent][M]["instance"][i][mi];
                if (MAX === false) {
                    MAX = perM;
                    MIN = perM;
                }
                if (MAX < perM) {
                    MAX = perM;
                }
                if (MIN > perM) {
                    MIN = perM;
                }
            }
            let temp_group = new Group();
            for (let j in perPart) {
                k++;
                if (k == kk) {

                    let perOne = perPart[j];
                    let x = thisINP.data.res[this.timerA.countCurrent][a1]["instance"][i][j];
                    let y = thisINP.data.res[this.timerA.countCurrent][a2]["instance"][i][j];
                    let z = thisINP.data.res[this.timerA.countCurrent][a3]["instance"][i][j];

                    let valueOfPerM = thisINP.data.res[this.timerA.countCurrent][M]["instance"][i][j];

                    const dir = new Vector3(x, y, z);
                    dir.normalize();
                    const origin = new Vector3(perOne[0], perOne[1], perOne[2]);

                    // let perLength = length * ((valueOfPerM - MIN) / Math.abs(MAX - MIN));
                    let perLength = length;
                    let hex = 0xffff00;
                    if (thisINP.nameOfCloudmap) {
                        let pressureValue = thisINP.data.res[this.timerA.countCurrent][thisINP.nameOfCloudmap]["instance"][i][j];
                        hex = this.parent.LUT.lut.getColor(pressureValue);
                    }

                    const arrowHelper = new ArrowHelper(dir, origin, perLength, hex, perLength * percent, perLength * percent);
                    temp_group.add(arrowHelper);
                    k = 0;
                }

            }
            let temp_instance = this.data.source.instance[i];
            let Mpart, Mrotate, Mtransform, Mxyz1, Mxyz2;
            Mpart = temp_instance.part;

            if (typeof temp_instance.rotate == "undefined") {
                Mrotate = false;
            }
            else {
                Mrotate = temp_instance.rotate;
            }

            if (typeof temp_instance.transform == "undefined") {
                Mtransform = false;
            }
            else {
                Mtransform = temp_instance.transform;
            }

            if (typeof temp_instance.xyz1 == "undefined") {
                Mxyz1 = false;
            }
            else {
                Mxyz1 = temp_instance.xyz1;
            }

            if (typeof temp_instance.xyz2 == "undefined") {
                Mxyz2 = false;
            }
            else {
                Mxyz2 = temp_instance.xyz2;
            }

            temp_group = this.inp_Matrix(temp_group, Mtransform, Mrotate, Mxyz1, Mxyz2);

            this.mesh.arrow.add(temp_group);
        }
        this.parent.render();
    }


    //////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////
    makeArrayForGeometry(part, type, points) {
        let newPoints;
        if (type == "CPE3") {
            newPoints = this.El_CPE3(part, type, points);
        }
        if (type == "CPE4") {
            newPoints = this.El_CPE4(part, type, points);
        }

        if (type == "S4" || type == "S4R") {
            newPoints = this.El_S4(part, type, points);
        }
        if (type.indexOf("C3D8") != -1) {
            newPoints = this.El_C3D8(part, type, points);
        }

        if (type == "T3D2") {
            newPoints = this.El_T3D2(part, type, points);
        }
        if (type == "B31") {
            newPoints = this.El_B31(part, type, points);
        }
        return newPoints;
    }
    El_C3D8(part, type, points) {
        let element2TriArray = { S1: [], S2: [], S3: [], S4: [], S5: [], S6: [], };
        let elements = part.element[type].element;
        for (let element_nodeID_i of points) {
            if (isNaN(element_nodeID_i)) {
                continue;
            }
            let element = elements[element_nodeID_i];
            // console.log(element,element_nodeID_i)
            let e1 = element[0];//element 的点序列，矩形体
            let e2 = element[1];
            let e3 = element[2];
            let e4 = element[3];
            let e5 = element[4];
            let e6 = element[5];
            let e7 = element[6];
            let e8 = element[7];
            //12345678顺序是abquas的

            let triAll = {
                S1: [e4, e1, e3,
                    e1, e2, e3],//R    

                S2: [e8, e5, e7,
                    e5, e6, e7],//L

                S3: [e5, e1, e6,
                    e1, e2, e6],//front

                S4: [e6, e2, e7,
                    e2, e3, e7],//top

                S5: [e8, e4, e7,
                    e4, e3, e7],//back                

                S6: [e5, e1, e8,
                    e1, e4, e8],//down      
            };
            for (let Si in triAll) {
                let perSide = triAll[Si];
                for (let pi of perSide) {
                    element2TriArray[Si].push(pi);
                }
            }
            // let triAll = [
            //     e5, e1, e6,
            //     e1, e2, e6,//front
            //     e8, e4, e7,
            //     e4, e3, e7,//back                
            //     e6, e2, e7,
            //     e2, e3, e7,//top
            //     e5, e1, e8,
            //     e1, e4, e8,//down      
            //     e8, e5, e7,
            //     e5, e6, e7,//L
            //     e4, e1, e3,
            //     e1, e2, e3,//R           
            // ];
            // for (let i in triAll) {
            // element2TriArray.push(i);
            // }
        }
        return element2TriArray;
    }
    //////////////////////////////////////////////////////////////////////////////

    setPointColor(color = 0xfff) {
        S
        if (typeof color == 'number') {
            this.material.point.color = color;
        }
        else {
            console.log("PLS number")
        }
    }
    setNsetPointSize(newSize) {
        if (typeof newSize == 'number') {
            this.material.Nset.size = newSize;
        }
        else {
            console.log("PLS number")
        }
    }
    setNsetPointColor(color = 0xfff) {
        if (typeof color == 'number') {
            this.material.Nset.color = color;
        }
        else {
            console.log("PLS number")
        }
    }
    setElsetFaceColor(color = 0xfff) {
        if (typeof color == 'number') {
            this.material.Elset.color = color;
        }
        else {
            console.log("PLS number")
        }
    }
    getGeometryOfPerNsetOfElement(part, points, instanceName) {
        let nodes = part.vertex;
        let geometry = new BufferGeometry();
        let positions = [];
        for (let Pi of points) {
            if (!isNaN(Pi)) {
                let perXYZ = nodes[Pi];
                positions.push(perXYZ[0], perXYZ[1], perXYZ[2]);
            }
        }
        geometry.setAttribute('position', new Float32BufferAttribute(positions, 3));
        // this.C3D8R(Part_name, type, other = false);  
        return geometry;
    }
    getGeometryOfPerElsetOfElement(part, points, instanceName) {
        let nodes = part.vertex;
        let partName = this.data.source.instance[instanceName].part;
        let element = this.data.source.part[partName].element;
        let type;
        for (let i in element) {
            type = i;
            break;
        }
        let geometrys = {};
        let pointsOfStructure = this.makeArrayForGeometry(part, type, points);
        // let pointsOfStructure = this.format2structure(partName, type, points);
        for (let perSide_i in pointsOfStructure) {
            let perGeometry = pointsOfStructure[perSide_i];
            let positions = [];
            let geometry = new BufferGeometry();
            for (let pi of perGeometry) {
                let perXYZ = nodes[pi]
                positions.push(perXYZ[0], perXYZ[1], perXYZ[2]);
            }
            geometry.setAttribute('position', new Float32BufferAttribute(positions, 3));
            geometrys[perSide_i] = geometry;
        }
        return geometrys;
    }
    drawNsets(name) {
        this.autoFixNsetPointSize();
        if (typeof this.mesh.Nsets == 'undefined') {
            this.mesh.Nsets = new Group();
            this.mesh.Nsets.name = "Nsets";
            this.worldBox.add(this.mesh.Nsets);
            this.mesh.Nsets.applyMatrix4(this.mesh.inpGroup.matrix);
        }
        if (typeof this.mesh.Nsets.getObjectByName(name) == 'undefined') {
            let sets = this.getNset();
            for (let Els_i in sets) {
                let perElset = sets[Els_i];
                for (let instance_i in perElset) {
                    let perInstance = perElset[instance_i];
                    if (typeof perInstance == 'object' && typeof perInstance.content != 'undefined') {
                        let perPart = this.data.source.part[this.data.source.instance[instance_i].part];
                        let geometry = this.getGeometryOfPerNsetOfElement(perPart, perInstance.content, instance_i);
                        let mesh = new Points(geometry, this.material.Nset);
                        mesh.name = Els_i;
                        mesh.visible = false;
                        mesh.applyMatrix4(this.mesh.inpGroup.getObjectByName(instance_i).matrix)
                        this.mesh.Nsets.add(mesh);
                    }
                }
            }
        }
    }

    drawElsets(name) {
        this.autoFixNsetPointSize();
        if (typeof this.mesh.Elsets == 'undefined') {
            this.mesh.Elsets = new Group();
            this.mesh.Elsets.name = "Elsets";
            this.worldBox.add(this.mesh.Elsets);
            this.mesh.Elsets.applyMatrix4(this.mesh.inpGroup.matrix);
        }
        if (!this.mesh.Elsets.getObjectByName(name)) {
            let sets = this.getElset();
            for (let Els_i in sets) {
                if (Els_i == name) {
                    let perElset = sets[Els_i];

                    for (let instance_i in perElset) {

                        let perInstance = perElset[instance_i];
                        if (typeof perInstance == 'object' && typeof perInstance.content != 'undefined') {
                            let ElsetGroup = new Group();
                            if (typeof perInstance.generate != 'undefined')
                                if (perInstance.generate == true) {

                                    let generate = JSON.parse(JSON.stringify(perInstance.content));
                                    perInstance.content = [];
                                    for (let i = generate[0]; i < generate[1]; i += generate[2]) {
                                        perInstance.content.push(i);
                                    }
                                }

                            ElsetGroup.name = name;
                            this.mesh.Elsets.add(ElsetGroup);
                            ElsetGroup.applyMatrix4(this.mesh.inpGroup.getObjectByName(instance_i).matrix);
                            let perPart = this.data.source.part[this.data.source.instance[instance_i].part];
                            let geometrys = this.getGeometryOfPerElsetOfElement(perPart, perInstance.content, instance_i);

                            for (let geometry_name_i in geometrys) {
                                let geometry = geometrys[geometry_name_i];
                                let mesh = new Mesh(geometry, this.material.Elset);
                                mesh.name = geometry_name_i;
                                // mesh.visible = false;                                
                                ElsetGroup.add(mesh);
                            }
                        }
                    }
                }
            }
        }
    }
    //kind = step ,global
    //name = Nset
    showBoundary(kind, name, step = 1, all = false, show = true) {
        this.showNoneOFAssembly();
        let boundary = this.getBoundary();
        let oneBoundary;
        let arrayOfNset = [];
        if (kind == "global") {
            oneBoundary = boundary[kind][name];
            let contents = oneBoundary.Boundary.content;
            for (let perContent of contents) {
                arrayOfNset.push(perContent.split(',')[0]);
            }
        }
        else if (all) {
            let oneStepBoundary = boundary[kind][parseInt(step) - 1];
            for (let step_i in oneStepBoundary) {
                if (step_i !== "stepname") {
                    oneBoundary = oneStepBoundary[step_i];
                    let contents = oneBoundary.Boundary.content;
                    for (let perContent of contents) {
                        arrayOfNset.push(perContent.split(',')[0]);
                    }
                }
            }
        }
        else {
            oneBoundary = boundary[kind][parseInt(step) - 1][name];
            let contents = oneBoundary.Boundary.content;
            for (let perContent of contents) {
                arrayOfNset.push(perContent.split(',')[0]);
            }
        }
        for (let perNset of arrayOfNset) {
            this.showNset(perNset, true, false)
        }
        this.parent.render();
    }
    //kind = loads,INTERACTIONS
    //name = Nset
    showLoad(kind = 'loads', name, step = 1) {
        this.showNoneOFAssembly();
        let boundary = this.getLoads();
        let oneLoad;
        let arrayOf = [];
        if (kind == 'loads' || kind == 'INTERACTIONS') {

        }
        else {
            kind == 'loads';
        }
        // if (all) {
        //     let oneStepBoundary = boundary[kind][parseInt(step) - 1];
        //     for (let step_i in oneStepBoundary) {
        //         if (step_i !== "stepname") {
        //             oneBoundary = oneStepBoundary[step_i];
        //             let contents = oneBoundary.Boundary.content;
        //             for (let perContent of contents) {
        //                 arrayOfNset.push(perContent.split(',')[0]);
        //             }
        //         }
        //     }
        // }
        // else 

        oneLoad = boundary[parseInt(step) - 1][kind][name];
        let contents;
        if (kind == 'loads') {
            contents = oneLoad.content;
        }
        else if (kind == 'INTERACTIONS') {
            contents = oneLoad.Sfilm.content;
        }
        for (let i in contents) {
            let perContent = contents[i];
            let values = perContent.split(',');
            if (values[0] == '') {

            }
            else {
                arrayOf.push(values[0]);
            }
        }

        for (let per of arrayOf) {
            this.showSurface(per)
        }
        this.parent.render();
    }
    showElset(name, show = true, cover = true) {
        if (cover)
            this.showNoneOFAssembly();
        if (typeof this.mesh.Elsets == 'undefined') {
            this.drawElsets(name);
        }
        else if (!this.mesh.Elsets.getObjectByName(name)) {
            this.drawElsets(name);
        }
        this.mesh.Elsets.traverse(function (child) {
            if (child.type === "Group") {
                if (child.name == name)
                    child.visible = show;
                else
                    child.visible = show;
            }
            if (child.parent.name == name) {
                child.visible = true;
            }

        })
        this.parent.render();
    }
    showSurface(name) {
        this.showNoneOFAssembly();
        let surfaces = this.getSurface();
        for (let Si in surfaces) {
            if (Si == name) {
                let perSurface = surfaces[Si];
                let content = perSurface.content;
                for (let perOne of content) {
                    let perDetial = perOne.split(",");
                    perDetial[1] = perDetial[1].substr(1);
                    if (typeof this.mesh.Elsets == 'undefined') {
                        this.showElset(perDetial[0], true, false);
                    }
                    let Elset = this.mesh.Elsets.getObjectByName(perDetial[0]);
                    if (!Elset) {
                        this.showElset(perDetial[0], true, false);
                        Elset = this.mesh.Elsets.getObjectByName(perDetial[0]);

                    }
                    Elset.traverse(function (child) {
                        if (child.name == perDetial[1]) {
                            child.visible = true;
                        }
                        else {
                            child.visible = false;
                        }
                    })
                    let face = Elset.getObjectByName(perDetial[1]);
                    face.visible = true;
                    Elset.visible = true;

                }
            }
        }
        this.parent.render();
    }
    showNoneOFAssembly() {
        if (typeof this.mesh.Elsets != 'undefined') {
            let Elsets = this.mesh.Elsets;
            Elsets.traverse(function (child) {
                if (child.type === "Group" && child.name != "Elsets") {
                    child.visible = false;
                }
                if (child.type === "Mesh") {
                    child.visible = false;
                }
            })
        }
        if (typeof this.mesh.Nsets != 'undefined') {
            this.mesh.Nsets.traverse(function (child) {
                if (child.type === "Points" && child.name != "Nsets") {
                    child.visible = false;
                }
            })
        }
    }
    showNset(name, show = true, over = true) {
        if (over)
            this.showNoneOFAssembly();
        if (typeof this.mesh.Nsets == 'undefined') {
            this.drawNsets(name);
        }
        else if (!this.mesh.Nsets.getObjectByName(name)) {
            this.drawNsets(name);
        }
        this.mesh.Nsets.traverse(function (child) {
            if (child.type === "Points") {
                if (child.name == name)
                    child.visible = show;
                // else
                //     child.visible = show;
            }
        })
        this.parent.render();
    }
    ////////////////////////////////////////////////////////////////////////////
    // 以下是inp和msh都需要有的
    ////////////////////////////////////////////////////////////////////////////

    autoFixPointSize() {
        let x = Math.abs(this.box3.max.x - this.box3.min.x);
        let y = Math.abs(this.box3.max.x - this.box3.min.x);
        let z = Math.abs(this.box3.max.x - this.box3.min.x);
        let value = x > y ? x : y;
        value = value > z ? value : z;
        value = value / 70;
        this.setPointSize(value);
    }
    autoFixNsetPointSize() {
        let x = Math.abs(this.box3.max.x - this.box3.min.x);
        let y = Math.abs(this.box3.max.x - this.box3.min.x);
        let z = Math.abs(this.box3.max.x - this.box3.min.x);
        let value = x > y ? x : y;
        value = value > z ? value : z;
        value = value / 50;
        this.setNsetPointSize(value);
    }
    setPointSize(newSize) {
        if (typeof newSize == 'number') {
            this.material.point.size = newSize;
        }
        else {
            console.log("PLS number")
        }
    }

    /////////////////////////////////////////////////////
    isAvailablePoint(instanceName, index) {
        if (!instanceName) {
            return false;
        }
        let partName = this.data.source.instance[instanceName].part;
        let onePart = this.data.source.part[partName];
        let nodes = onePart.vertex;
        if (typeof nodes[index] !== undefined) {
            return index;
        }
        else {
            return false;
        }
    }
    getOnePointattributes(instanceName, index) {
        index = this.isAvailablePoint(instanceName, index);
        if (index) {
            let partName = this.data.source.instance[instanceName].part;
            let onePart = this.data.source.part[partName];
            let nodes = onePart.vertex;
            let pressure = this.pointInfo(instanceName, index);
            let inpMatrix = this.mesh.inpGroup.matrix;
            let instaceMatrix = this.mesh.inpGroup.getObjectByName(instanceName).matrix;
            return {
                xyz: nodes[parseInt(index)],
                pressure: pressure,
                inpMatrix: inpMatrix,
                instaceMatrix: instaceMatrix,
                radius: [this.material.point.size, 6, 6],//2824 line
                material: this.material.pointBoxLight,
            }
        }
        return false;
    }
    drawPoints() {
        this.autoFixPointSize();
        const geometry = new SphereGeometry(this.material.point.size/2, 36, 36);
        // const geometry = new BoxGeometry(this.material.point.size, this.material.point.size, this.material.point.size);
        this.parent.pickupArray = [];
        // for(let mesh_i of this.mesh.inpGroup.children){
        //     this.parent.pickupArray.push(mesh_i);
        // }
        this.mesh.inpGroup.traverse(function (child) {
            if (child.type == 'Mesh') {
                thisINP.parent.pickupArray.push(child);
            }
        })
        
        this.mesh.Points = new Group();
        this.worldBox.add(this.mesh.Points);
        this.mesh.Points.name = "Points";
        this.mesh.Points.applyMatrix4(this.mesh.inpGroup.matrix);
        let instances = this.data.source.instance;

        const material = new MeshBasicMaterial({ color: 0x550000 });


        for (let instance_i in instances) {
            let perInstance = instances[instance_i];
            let perInstanceGroup = new Group();
            perInstanceGroup.name = instance_i;
            perInstanceGroup.applyMatrix4(this.mesh.inpGroup.getObjectByName(instance_i).matrix)
            this.mesh.Points.add(perInstanceGroup);
            let perPart = this.data.source.part[perInstance.part];
            let nodes = perPart.vertex;
            let targetPoints = perPart.shell_points;
            for (let element_i in targetPoints) {
                let perElementPoints = targetPoints[element_i];

                for (let pp_i in perElementPoints) {
                    let perXYZ = nodes[perElementPoints[pp_i]];


                    // let geometry = new BufferGeometry();
                    // let points = [];
                    // points.push(perXYZ[0], perXYZ[1], perXYZ[2]);
                    // geometry.setAttribute('position', new Float32BufferAttribute(points, 3));
                    // let mesh = new Points(geometry, this.material.point);

                    
                    let mesh = new Mesh(geometry, this.material.pointBoxLight);
                    mesh.position.x = perXYZ[0];
                    mesh.position.y = perXYZ[1];
                    mesh.position.z = perXYZ[2];

                    mesh.name = pp_i;
                    mesh.instanceName = instance_i;
                    mesh.isPoint = true;
                    mesh.visible = false;
                    // perInstanceGroup[parseInt(pp_i)]=mesh;
                    perInstanceGroup.add(mesh);
                    this.parent.pickupArray.push(mesh);
                }
                // mesh.visible = false;
            }
        }
    }
    showPoints(show = false, name = false) {
        if (typeof this.mesh.Points == 'object')
            if (name == false) {
                if (typeof show == 'boolean') {
                    this.mesh.Points.visible = show;
                }
                else {
                    this.mesh.Points.visible = !this.mesh.Points.visible;
                }
            }
            else {
                let target = this.mesh.Points.getObjectByName(name);
                if (target) {
                    target.visible = show;
                    // target.visible=!target.visible;
                }
            }
        else
            this.drawPoints();
        this.parent.render();
    }
    showOnePointInfo(index) {

    }


    pointInfo(instanceName, index) {
        let res = this.data.res[thisINP.timerA.countCurrent];

        let pressures = {
            p: {},
            // xyz:{},
            index: index,
        }
        // pressures.xyz=
        for (let pi in res) {
            let perPressure = res[pi];
            pressures.p[pi] = perPressure.instance[instanceName][index];
        }
        return pressures;
    }

    //调整材质后，受光验收异常，二次调整失效。
    recomputeVertexNormals() {
        // for(let i in this.geometry.part){
        //     let perGEO= this.geometry.part[i];
        //     perGEO.computeVertexNormals();
        // }
        this.mesh.inpGroup.traverse(function (child) {
            if (child.type == 'mesh') {
                child.geometry.computeVertexNormals();
            }
        })
    }
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // let input = {
    //     pressuresName: {
    //       V1: "V.V1",
    //       V2: "V.V2",
    //       V3: "V.V3",
    //       norm: "V.Magnitude",
    //       default: "norm",
    //     },
    //     input: {
    //       startPosition: [
    //         [-15.5, 0, 0],
    //         [1, 0, 0],
    //       ], //this.parent.box3.min.x;//数值偏移量  //固定的，后期需要改变
    //       endPosition: [
    //         [-9.5, 0, 0],
    //         [1, 0, 0],
    //       ], //-this.parent.box3.max.x;//固定的，后期需要改变
    //       countOfSeed: 10,
    //       positionOfSeed:"center",// center ,rondam 
    //       interpolation: 0.05,
    //       interpolationCount: 10,
    //     },
    //   };
    getDataOfFlowlines(input = false, color = 0xaaaaaa, vertexColors = false) {
        if (input == false) {
            console.log("PLS check the value of input!")
            return false;
        }
        let value = {
            parent: this,
            flowSetting: {
                Dimension: 2,
                lineColor: {
                    color: color,
                    vertexColors: vertexColors
                },
                meshType: "INP",
                // type: "C3",
                matrix: [thisINP.mesh.inpGroup.matrix],
                PMax: thisINP.parent.LUT.max,
                PMin: thisINP.parent.LUT.min,
                PStep: 50,
                perStep: (thisINP.parent.LUT.max - thisINP.parent.LUT.min) / 50,

            },
            meshs: {},
        }
        value.flowSetting.pressuresName = input.pressuresName;
        value.flowSetting.input = input.input;
        value.flowSetting.step = input.step;

        let x = thisINP.box3.centerMM.max.x - thisINP.box3.centerMM.min.x;
        let y = thisINP.box3.centerMM.max.y - thisINP.box3.centerMM.min.y;
        let z = thisINP.box3.centerMM.max.z - thisINP.box3.centerMM.min.z;
        value.flowSetting.step.distance = x > y ? (x > z ? x : z) : (y > z ? y : z);

        value.flowSetting.step.pressureStepLenght = (thisINP.parent.LUT.max - thisINP.parent.LUT.min) / value.flowSetting.step.pressureStep;
        value.flowSetting.step.distanceStepLenght = value.flowSetting.step.distance / value.flowSetting.step.distanceStep;

        value.flowSetting.dir = thisINP.getFluidBoundaryDirction();


        let Res = this.data.res;
        for (let i in this.data.source.instance) {
            let perInstance = this.data.source.instance[i];
            let perPart = this.data.source.part[perInstance.part];
            let matrix;
            let source = {
                nodes: false,
                hexahedron: [],
                res: {},
                matrix: [],
                nodesLength: perPart.nodesLength,
            };
            let C3D8_array = [];
            for (let Ei in perPart.element) {
                if (Ei.indexOf("C3D8") != -1) {
                    this.mesh.inpGroup.traverse(function (child) {
                        if (child.name == i) {
                            source.matrix.push(child.matrix);
                        }
                    });
                    source.hexahedron.push(perPart.element[Ei].element);
                    source.nodes = perPart.vertex;
                    source.nodesLength = perPart.vertex.length;
                    source.lines = perPart.line_index[Ei];
                    for (let Ri in Res) {
                        source.res[Ri] = {};
                        for (let Pressure_i in Res[Ri]) {
                            let perRes = Res[Ri][Pressure_i];
                            if (typeof perRes.instance[i] != 'undefined') {
                                source.res[Ri][Pressure_i] = perRes.instance[i];
                            }
                        }
                    }
                }
            }
            if (source.length > perPart.nodesLength) {
                source.nodes.splice(source.nodesLength, source.nodes.length - source.nodesLength);
            }
            value.meshs[i] = {
                source: source
            };
        }
        return value;
    }
    getMeshType(){
        return this.meshType;
    }


}

export { inpCM2D }

