import { GUI } from "../libs/dat.gui.module";
import { threeMain } from "./threeMain.box.js";
import { inpCM } from "../msh/inpmsh";
import { inpFlowContour } from "../msh/inpflowcontour";
import { inpFlowRoot } from "../msh/inpflowroot";
import { gidFlowRoot } from "../msh/gidflowroot";
import { inpFlowTrace } from "../msh/inpflowtrace";
import { cloudAnsysCM } from "../msh/cloudmsh.js";
import { cloudAnsysCM2D } from "../msh/cloudmesh2D";
import { cloudAnsysCMshader } from "../msh/cloudmeshshader";
import { output2Compute } from "../msh/output2compute";
import { flowLineRoot } from "../msh/flowlineroot";


// let that;
class second extends threeMain {
    constructor(value = false, orbit = true) {
        super(value, orbit);
        // that = this;
        this.CM = false;
        this.O2C = new output2Compute();
    }
    beforeInit() {
        super.beforeInit();
    }
    afterInit() {
        super.afterInit();
    }
    clean() {
        if (this.LUT) {
            this.LUT.clean();
        }
        if (this.CM)
            if (typeof this.CM.timerA.timer != "undefined")
                if (this.CM.timerA.timer) {
                    clearInterval(this.CM.timerA.timer);
                }

        this.reinitWorldBox();

        if (typeof this.CM !== "undefined") {
            this.CM = false;
        }
        if (typeof this.GEOs !== "undefined") {
            this.GEOs = {};
        }

        this.render();
        this.log("重置场景。");
    }

    flowLines(value) {
        this.flowline = new flowLineRoot(value);
    }

    flowlinesGID_root(input) {
        this.__initMeshGUI(input, this);
        return (this.CM = new gidFlowRoot(this, input));
    }
    flowlines_root(input) {
        this.__initMeshGUI(input, this);
        return (this.CM = new inpFlowRoot(this, input));
    }
    flowlines_contour(input) {
        this.__initMeshGUI(input, this);
        return (this.CM = new inpFlowContour(this, input));
    }
    flowlines_trace(input) {
        this.__initMeshGUI(input, this);
        input;
        return (this.CM = new inpFlowTrace(this, input));
    }
    __initMeshGUI(value, that) {
        let showMenu = value.fun.menu;
        if (typeof value.fun == "undefined") {
            value.fun = {
                menu: function () {
                    that.setENVLast();
                    that.render();
                },
            };
        } else if (typeof value.fun.menu != "undefined" && value.fun.menu) {
            value.fun.menu = function () {
                let abc = document.getElementsByClassName("dg main a"); // console.log(abc.length, abc)
                if (abc.length >= 1) {
                    for (let i = abc.length; i >= 1; i--) {
                        abc[i - 1].remove();
                    }
                }
                that.CM.show(false);
                // this.CM.show(this.CM.getPressureSName()[0]);


                that.gui = new GUI(that.DIV.gui);
                if (showMenu == 2) { // that.gui.GUI.TEXT_CLOSED = "收起";
                    // that.gui.GUI.TEXT_OPEN = "展开";
                    that.params = {
                        pressure: "",
                        play: function () {
                            that.CM.play();
                        },
                        second: 0.5,
                        play1: false,
                        time: "",
                        transvalue: 0,
                        trans: false,
                        transArray: "",
                        transX: "",
                        transY: "",
                        transZ: "",
                        wireframe: true,
                        clipping: false,
                    };
                    that.gui
                        .add(that.params, "pressure", that.CM.getPressureSName())
                        .name("仿真场")
                        .onChange(function (value_pressure) {
                            if (value_pressure) {
                                that.CM.show(value_pressure);
                                that.render();
                            }
                        });

                    that.gui
                        .add(that.params, "wireframe")
                        .name("网格")
                        .onChange(function (value) {

                            that.setFrameLineEnable(value);
                            that.CM.show();
                        });

                    that.gui
                        .add(that.params, "transvalue", 1, 5000)
                        .name("变形系数")
                        .step(0.5)
                        .onChange(function (value) {
                            // console.log(value);
                            that.CM.setTransformationValue(value);
                            if (that.params.trans) {
                                that.CM.show();
                                that.render();
                            }
                        });
                    // that.gui
                    //   .add(that.params, "transArray", that.CM.getTransformName())
                    //   .name("变形场")
                    //   .onChange(function (value_pressure) {
                    //     if (value_pressure) {
                    //       that.CM.setTransCM(value_pressure);
                    //     }
                    //   });
                    that.gui
                        .add(that.params, "trans")
                        .name("变形")
                        .onChange(function (value) {
                            that.CM.setEnableTransformation(value);
                            that.CM.show();
                            that.render();
                        });

                    that.gui
                        .add(that.params, "time")
                        .name("求解步结果")
                        .onChange(function (value) { });
                    that.gui
                        .add(that.params, "second", 0.001, 0.9)
                        .name("播放间隔")
                        .step(0.1)
                        .onChange(function () {
                            // that.CM.play();
                        });
                    that.gui
                        .add(that.params, "play1")
                        .name("时间历程")
                        .onChange(function (value) {
                            if (value) that.CM.play(that.params.second);
                            else that.CM.stop();
                            that.render();
                            that.render();
                        });

                    that.gui
                        .add(that.params, "clipping")
                        .name("剖面")
                        .onChange(function (value) {
                            that.setClipping(that.CM, value);
                            that.Trender(that);
                            that.CM.repairPoly();
                            that.render();
                        });
                }

                that.setENVLast();
                that.render();
            };
        } else {
            if (typeof value.fun != "object") {
                value.fun = {};
            }
            if (value.typeCM == true) {
                value.fun.menu = function () {
                    // that.CM.show(that.CM.getPressureSName()[0]);
                    that.setENVLast();
                    that.render();
                };
            } else
                value.fun.menu = function () {
                    that.setENVLast();
                    that.render();
                };
        }
    }

    importGIDshader(input = false) {
        this.__initMeshGUI(input, this);
        return (this.CM = new cloudAnsysCMshader(this, input));
    }

    importGID2D(input = false) {
        this.__initMeshGUI(input, this);
        return (this.CM = new cloudAnsysCM2D(this, input));
    }

    import(value = false) {
        this.__initMeshGUI(value, this);

        let geo;
        if (value.type == "gid") {
            this.log("准备加载模型。");
            this.log(
                "计算结果信息:",
                typeof value.zip != "undefined" ? value.zip : value.mesh
            );
            geo = this.importGID(value);
        } else if (value.type == "abaqus") {
            this.log("准备加载模型。");
            this.log(
                "计算结果信息:",
                typeof value.zip != "undefined" ? value.zip : value.mesh
            );
            geo = this.importAbquas(value);
        }
        return this.CM;
    }
    setClipping() { }

    setFrameLineEnable(value) {
        let that = this;
        if (typeof that.CM.mesh.lines != "undefined")
            that.CM.mesh.lines.visible = value;
        if (typeof that.CM.mesh.allTriangleLines != "undefined") {
            that.CM.mesh.allTriangleLines.visible = value;
            that.CM.mesh.allLinesOriginFrame.visible = value;
        }
        that.CM.setFramelineEnable(value); //clipping frame lines enable or disable
        that.CM.setting.frameline = value;
        that.render();
    }
    importGID(input) {
        return this.importMSH(input);
    }
    importAbquas(input) {
        return this.importINP(input);
    }
    importMSH(input) {
        return (this.CM = new cloudAnsysCM(this, input));
    }
    importINP(input) {
        return (this.CM = new inpCM(this, input));
    }
    showCMLines(show = true) {
        if (typeof this.CM != "undefined") {
            if (this.CM.meshType == "abaqus") {
                this.CM.mesh.lines.visible = show;
            } else if (this.CM.meshType == "gid") {
                this.CM.mesh.allTriangleLines.visible = show;
            }
        }
    }
    showCMPlane(show = true) {
        if (typeof this.CM != "undefined") {
            if (this.CM.meshType == "abaqus") {
                this.CM.mesh.inpGroup.visible = show;
            } else if (this.CM.meshType == "gid") {
                this.CM.mesh.allTriangle.visible = show;
            }
        }
    }
}
export { second };