
class output2Compute {
    constructor() {
        this.output = {
            material: [],
            section: [],
            part: [],
            assembly: {
                instance: {

                },
                nset: {},
                elset: {},
                surface: {},
            },
            boundary: [],
            // load: {},
            step: []
        }
    }
    outPut() {
        // return JSON.stringify(this.output);
        return this.output;
    }
    set(target, objects) {
        this.output[target] = [];
        for (let i in objects) {
            let one = { name: i };
            for (let j in objects[i]) {
                one[j] = objects[i][j];
            }
            this.output[target].push(one)
        }
    }
    set2(target1, target2, objects) {
        for (let i in objects) {
            let one = objects[i];
            this.output[target1][target2][i] = one;
        }
    }
    setMaterial(objects) {
        this.set("material", objects)
    }
    setSection(objects) {
        let values = {
            name: '',
            profiel: '',
            type: '',
            Elset: '',
            material: '',
            temperature: '',
            section: '',
            content: [],
        };
        let OBJS = [];
        for (let i in objects) {
            for (let ii in objects[i]) {
                let perObj = objects[i][ii];
                for (let j in values) {
                    let jj=j.toLowerCase();
                    if (jj == "name") {
                        perObj[jj] = ii;
                    }
                    else if (typeof perObj[jj] == 'undefined') {
                        perObj[jj] = '';
                    }
                }
                OBJS.push(perObj);
            }
        }
        this.output.section = OBJS;
        // this.set("section", objects)
    }
    setBoundary(objects) {
        // this.set("boundary", objects)
        if (typeof objects.global != "undefined")
            this.set("boundary", objects.global)
        // for (let i in objects.global) {
        //     let one = { name: i };
        //     for (let j in objects[i]) {
        //         one[j] = objects[i][j];
        //     }
        //     this.output[target].push(one)
        // }
        if (typeof objects.step != "undefined" && objects.step.length)
            if (this.output.step.length)
                this.set("boundary", objects.global)
            else
                console.log("PLS format step data first");
    }
    setLoad(objects) {
        // this.set("load", objects)
        if (this.output.step.length) {
            for (let i in objects) {
                let LOADS = []
                for (let j in objects[i].loads) {
                    let per = objects[i].loads[j];
                    LOADS.push(per);
                }
                this.output.step[i].loads = LOADS;
            }
        }
        else
            console.log("PLS format step data first");

    }
    setStep(objects) {
        // this.set("step", objects)
        let OBJS = [];
        for (let i in objects) {
            for (let ii in objects[i]) {
                let perObj = objects[i][ii];
                for (let j in values) {
                    if (j == "name") {
                        perObj[j] = ii;
                    }
                    else if (typeof perObj[j] == 'undefined') {
                        perObj[j] = '';
                    }
                }
                OBJS.push(perObj);
            }
        }
        this.output.step = OBJS;
    }
    setPart(objects) {
        this.set("part", objects)
    }
    setInstance(objects) {
        this.set2("assembly", "instance", objects)
    }
    setNset(objects) {
        this.set2("assembly", "nset", objects)
    }
    setElset(objects) {
        this.set2("assembly", "elset", objects)
    }
    setSurface(objects) {
        this.set2("assembly", "surface", objects)
    }
}
export { output2Compute }