// version:1.4
import {
    Mesh,
    Vector3,
    Scene,
    FontLoader,
    // LineBasicMaterial,
    MeshBasicMaterial,
    DoubleSide,
    ShapeBufferGeometry,
    OrthographicCamera,
    // Sprite,
    // CanvasTexture,
    // SpriteMaterial,

} from 'three';
// import {FontLoader} from    'three/examples/jsm/loaders/FontLoader.js'

let that;
class fontLayer {
    constructor(mainScene) {
        this.mainScene = mainScene;
        that = this;
        this.distance = 1500;
        this.objet_text = {};//text mesh group
        this.object_font = {};//font object
        this.allObject = {};
        this.uiScene;
        this.sprite;
        this.orthoCamera;
        this.isCleaned=false;
        const loader = new FontLoader();
        loader.load('./font/helvetiker_regular.typeface.json', function (load_font) { that.object_font = load_font; });
        this.init_UI();
        return this;
    }
    //////////////////////////////////////////////////////////////
    // init 
    //default init
    init_UI() {
        this.uiScene = new Scene();
        this.orthoCamera = new OrthographicCamera(- 1, 1, 1, - 1, 1, 5);
        this.orthoCamera.position.set(0., 0, this.distance);
    }

    ////////////////////////////////////////////////////////////////
    // get 
    get_uiScene() {
        return this.uiScene;
    }
    get_orthoCamera() {
        return this.orthoCamera;
    }


    deleteByName(name) {
        this.uiScene.remove(this.uiScene.getObjectByName(name));
    }
    clean() {
        this.uiScene.traverse(function(child){
            that.uiScene.remove(child);
        })
        this.isCleaned=true;
        // this.mainScene.render();
    }
    update() {
        for (let i in this.allObject) {
            let perOne = this.allObject[i];
            perOne.x = false;
            perOne.y = false;
            this.updateObjectByObject(perOne);
        }
    }
    updateByName(name, msg, color = 0x999999, x, y, z = false) {
        this.deleteByName(name);
        return this.add(name, msg, color, x, y, z);
    }
    add(name, msg, color = 0x999999, x, y, z = false) {
        let message = ' ' + msg;
        let position = [x, y, z ? z : this.distance - 1];
        // const matDark = new LineBasicMaterial({
        //     color: color,
        //     side: DoubleSide
        // });
        const matLite = new MeshBasicMaterial({
            color: color,
            transparent: true,
            opacity: 1.0,
            side: DoubleSide
        });
        //const message = "   _-0.34";
        const shapes = this.object_font.generateShapes(message, 0.04);
        const geometry = new ShapeBufferGeometry(shapes);
        geometry.computeBoundingBox();
        // const xMid = - 0.5 * (geometry.boundingBox.max.x - geometry.boundingBox.min.x);

        //geometry.translate(-0.45, -0.05, 0);

        // make shape ( N.B. edge view not visible )

        const text = new Mesh(geometry, matLite);
        // text.position.x = -0.5;
        // text.position.y = -0.99;
        // // text.position.y = -0.03;
        text.position.x = position[0];
        text.position.y = position[1];
        text.position.z = position[2];
        text.name = name;
        this.uiScene.add(text);
        this.objet_text[name] = text;
        // let abc = 1;
        this.isCleaned=false;
        return text;
    }
    updateObjectByObject(obj) {
        let text = this.updateByName(obj.name, obj.msg, obj.color, obj.x ? obj.x : this.getXYByObject(obj).x, obj.y ? obj.y : this.getXYByObject(obj).y);
        return text;
    }
    updateObjectByObjectName(obj) {
        let target = this.getObjectattributesByObjectName(obj.name);
        if (target) {
            let text = this.updateByName(obj.name, obj.msg, obj.color, obj.x ? obj.x : this.getXYByObject(obj).x, obj.y ? obj.y : this.getXYByObject(obj).y);
            // delete target.text;
            target.text = text;
        }
        else {
            target = this.addForObject(obj);
        }
    }
    getObjectattributesByObjectName(name) {
        let obj = false;
        for (let i in this.allObject) {
            let perOne = this.allObject[i];
            if (perOne.name == name) {
                obj = perOne;
            }
        }
        return obj;
    }
    getXYByObject(pointOne) {
        let vector = new Vector3();
        pointOne.mesh.updateMatrixWorld();
        vector.setFromMatrixPosition(pointOne.mesh.matrixWorld);
        vector.project(this.mainScene.perpCamera);
        return { x: vector.x, y: vector.y };
    }
    addForObject(obj) {
        if (typeof obj.name == 'undefined' || typeof obj.mesh == 'undefined' || typeof obj.mesh == 'undefined') {
            console.log("please check the input value,the name ,the mesh and the msg must have.")
            return false;
        }

        let name = obj.name ? obj.name : obj.mesh.uuid;
        let message = ' ' + obj.msg;
        let color = obj.color ? obj.color : 0x999999;
        let z = false;
        let x = obj.x ? obj.x : this.getXYByObject(obj).x;
        let y = obj.y ? obj.y : this.getXYByObject(obj).y;
        let position = [x, y, z ? z : this.distance - 1];
        // const matDark = new LineBasicMaterial({
        //     color: color,
        //     side: DoubleSide
        // });
        const matLite = new MeshBasicMaterial({
            color: color,
            transparent: true,
            opacity: 1.0,
            side: DoubleSide
        });
        //const message = "   _-0.34";
        const shapes = this.object_font.generateShapes(message, 0.035);
        const geometry = new ShapeBufferGeometry(shapes);
        geometry.computeBoundingBox();
        // const xMid = - 0.5 * (geometry.boundingBox.max.x - geometry.boundingBox.min.x);

        //geometry.translate(-0.45, -0.05, 0);

        // make shape ( N.B. edge view not visible )

        const text = new Mesh(geometry, matLite);
        // text.position.x = -0.5;
        // text.position.y = -0.99;
        // // text.position.y = -0.03;
        text.position.x = position[0];
        text.position.y = position[1];
        text.position.z = position[2];
        text.name = name;
        this.uiScene.add(text);
        this.objet_text[name] = text;
        this.allObject[name] = obj;
        this.allObject[name].text = text;
        this.isCleaned=false;
        // let abc = 1;
    }
}






export { fontLayer };