// version conttour v.1.0
//功能：1、gid的msh与res显示
//      2、云图的变形与时间历程
import {
    BufferGeometry,
    Mesh,
    Vector3,
    Color,
    MeshStandardMaterial,
    MeshBasicMaterial,
    LineBasicMaterial,
    MeshPhongMaterial,
    Line,
    Line3,
    Plane,
    Group,
    BufferAttribute,
    DoubleSide,
    LineSegments,
    Ray,
    Float32BufferAttribute,
    LineLoop,
    FileLoader,
    ObjectLoader,
    ArrowHelper,
    CatmullRomCurve3,
    SplineCurve,
    Vector2,
} from 'three';
import triangulate from "delaunay-triangulate";
 

let that;

class flowLineContour {
    constructor(value) {
        that = this;
        this.parent = value.parent;
        this.scene = this.parent.parent.scene;
        this.render = this.parent.parent.render;
        this.value = value;
        this.data = {
            flowLines: {},
            triangle: {
                triangles: {
                    // "1,2,3": {

                    // }
                },
                lines: {
                    // "1,2": {},
                },
                points: [],/*
                [
                    [
                        [5.5,6.6,7.7,11.2211112,]
                    ]
                ]
                */
            },
            line: {
                lines: [],
                /**
                 * {
                 *  1223:[
                 *      [A,B],[D,C],[B,D]
                 * ],
                 * }
                 */
                values: {},/*
                [
                    [A,B],
                    
                    [B,C]
                ]
                */
            }
        }
        this.data.flowSetting = value.flowSetting;
        this.data.data = value.data;
        this.data.originPressures = value.originPressures;
        this.layerForRay = new Plane(new Vector3(), 0);
        // this.layerP = new Plane(new Vector3(), 0);
        this.ray = new Ray();
        this.p1 = new Vector3();
        this.p2 = new Vector3();
        this.p3 = new Vector3();
        this.mesh = new Group();
        this.mesh.name = "FlowLines"
        for (let m of this.data.flowSetting.matrix)
            this.mesh.applyMatrix4(m);
        this.parent.scene.add(this.mesh);
        this._init();
    }

    _init() {
        this.beforeInit();
        this.init();
        this.afterInit();
    }
    afterInit() { }
    beforeInit() {
        // super.beforeInit();
        // this.data = {}

    }

    init() {
        this.initTriData();
        this.sametriangleS();
        this.samepointS();
        this.draw();
    }
    draw() {
        let nodes = this.data.triangle.points;
        let lines = this.data.line.lines;
        const material = new LineBasicMaterial({
            //  color: 0xff0000，
            vertexColors: true,
        });
        let j = 0;
        for (let i in lines) {
            let perGroup = lines[i];
            if (perGroup.length > 4) {
                let pointsCatmullRom = [];
                let pressures = [];
                let colors = [];
                for (let perPointIndex of perGroup) {
                    let perPoint = nodes[perPointIndex];
                    pointsCatmullRom.push(new Vector3(perPoint[0], perPoint[1], perPoint[2]));
                    pressures.push(new Vector2(perPoint[3], 0));
                }

                const curve = new CatmullRomCurve3(pointsCatmullRom);
                const points = curve.getPoints(pointsCatmullRom.length * 6);
                let geometry = new BufferGeometry().setFromPoints(points);

                const curve2DofPreesures = new SplineCurve(pressures);
                const point2DofPreesures = curve2DofPreesures.getPoints(pressures.length * 6);

                for (let spline_i of point2DofPreesures) {
                    let oneColor = this.parent.parent.LUT.lut.getColor(spline_i.x);
                    colors.push(oneColor.r, oneColor.g, oneColor.b);
                }

                geometry.setAttribute('color', new Float32BufferAttribute(colors, 3));

                let mesh = new Line(geometry, material);

                this.mesh.add(mesh);

                // break;
            }
        }

    }
    draw_old() {
        let nodes = this.data.triangle.points;
        let Vi = this.data.line.values.length;
        let values = this.data.line.values;

        let j = 0;
        for (let i in values) {
            let perGroup = values[i];
            let points = [];
            for (let perTri of perGroup) {
                for (let perPoint of perTri)
                    points.push(nodes[perPoint][0], nodes[perPoint][1], nodes[perPoint][2]);
            }
            // console.log(points)
            const geometry_lines = new BufferGeometry();
            const vertices_lines = new Float32Array(points);
            geometry_lines.setAttribute('position', new Float32BufferAttribute(vertices_lines, 3));
            let colorHEX = that.parent.parent.LUT.lut.getColor(i).getHex();
            // console.log(colorHEX)
            const line = new LineSegments(geometry_lines, new LineBasicMaterial({ color: colorHEX }));
            // const line = new Line(geometry_lines, new LineBasicMaterial({ color: colorHEX }));
            this.mesh.add(line);
            break;
        }

    }
    sametriangleS() {
        let nodes = this.data.triangle.points;
        let lines = this.data.triangle.lines;
        let triangles = this.data.triangle.triangles;
        for (let Ei in triangles) {
            let perElement = triangles[Ei];
            for (let Ti in perElement) {
                let perTriangle = perElement[Ti];
                let pointsOfSameValue = {};//每个三角形的等值点
                if (perTriangle.enable) {
                    let triangleLines = [
                        [perTriangle.points[0], perTriangle.points[1]],
                        [perTriangle.points[0], perTriangle.points[2]],
                        [perTriangle.points[1], perTriangle.points[2]],
                    ]
                    let abc = 1;
                    // console.log(perTriangle, triangleLines)
                    for (let PLi in triangleLines) {//每个边
                        let perLine = triangleLines[PLi];

                        if (typeof lines[perLine.sort().join(',')] == 'undefined') {//被排除的边
                            let abc = 1;
                            // console.log("perLine:", perLine);
                        }
                        else
                            for (let onePoint of lines[perLine.sort().join(',')]) {
                                // console.log(onePoint)
                                if (typeof pointsOfSameValue[nodes[onePoint][3]] == 'undefined') {
                                    pointsOfSameValue[nodes[onePoint][3]] = [];
                                }
                                pointsOfSameValue[nodes[onePoint][3]].push(onePoint);
                            }
                    }
                    for (let PVi in pointsOfSameValue) {//等值点
                        let perValue = pointsOfSameValue[PVi];
                        if (perValue.length > 1) {
                            if (typeof this.data.line.values[PVi] == 'undefined') {
                                this.data.line.values[PVi] = [];
                            }
                            this.data.line.values[PVi].push(perValue);
                        }
                    }
                }

            }
        }
    }
    samepointS() {
        let nodes = this.data.triangle.points;
        let lines = this.data.triangle.lines;
        let triangles = this.data.triangle.triangles;
        // let aaa = this.data.line.values;
        for (let perVi in this.data.line.values) {
            let perValue = this.data.line.values[perVi];
            let newPerValue = JSON.parse(JSON.stringify(perValue));
            for (let oneLine of perValue) {
                newPerValue = this.makeLine(oneLine, newPerValue);
            }
        }
    }
    compareArray(a1, a2) {
        if (a1 === a2) return true;
        if ((!a1 && a2) || (a1 && !a2)) return false;
        if (a1.length !== a2.length) return false;
        for (var i = 0, n = a1.length; i < n; i++) {
            if (a1[i] !== a2[i]) return false;
        }
        return true;
    }
    makeLine(oneLine, perArray) {
        let A = oneLine[0];
        let B = oneLine[1];

        if (this.compareArray(oneLine, perArray[0])) {
            let pointsOfOneLine = [A, B];
            perArray.splice(0, 1);
            for (let i = 0; i < perArray.length - 1; i++) {
                let perPoint = perArray[i];
                let X = perPoint[0];
                let Y = perPoint[1];
                let flag = false;
                // let newArray = [];
                if (A == X) {
                    flag = true;
                    // newArray = [Y, X];
                    pointsOfOneLine.unshift(Y);
                    A = Y;
                }
                else if (A == Y) {
                    flag = true;
                    // newArray = [X, Y];
                    pointsOfOneLine.unshift(X);
                    A = X;
                }
                else if (B == X) {
                    flag = true;
                    // newArray = [X, Y];
                    pointsOfOneLine.push(Y);
                    B = Y;
                }
                else if (B == Y) {
                    flag = true;
                    // newArray = [Y, X];
                    pointsOfOneLine.push(X);
                    B = X;
                }
                if (flag) {
                    perArray.splice(i, 1);
                    i = 0;
                }
            }
            this.data.line.lines.push(pointsOfOneLine);
            return perArray;
        }
        else {
            return perArray;
        }
    }

    initTriData() {
        for (let Ii in this.data.data.source.instance) {
            let perInstance = this.data.data.source.instance[Ii];
            for (let ELi in this.data.data.source.part[perInstance.part].element) {
                let perElement = this.data.data.source.part[perInstance.part].element[ELi];
                if (perElement.type.indexOf("C3D8") != -1) {
                    this.data.triangle.points = this.data.data.source.part[perInstance.part].vertex;
                    this.data.triangle.triangles = this.data.data.source.part[perInstance.part].tri_index;
                    this.data.triangle.lines = this.initTrianglesLinesData(this.data.triangle.triangles, this.data.triangle.points, Ii);
                }
            }
        }
    }
    initTrianglesLinesData(tris, nodes, instanceName) {
        let step = this.data.flowSetting.perStep;
        let PDefault = this.data.originPressures[this.data.flowSetting.pressuresName.default][instanceName];
        let lines = {};
        for (let Ei in tris) {
            let perElement = tris[Ei];
            for (let Ti in perElement) {
                let perTri = perElement[Ti];
                let perLine = false;
                if (perTri.enable) {
                    let A = perTri.points[0];
                    let B = perTri.points[1];
                    let C = perTri.points[2];
                    let PA = PDefault[A];
                    let PB = PDefault[B];
                    let PC = PDefault[C];
                    nodes[A][3] = PA;
                    nodes[B][3] = PB;
                    nodes[C][3] = PC;

                    let sameValuesInTriangle = [];
                    if (typeof lines[[A, B].sort().join(',')] == 'undefined') {
                        perLine = this.computeABStep(A, B, nodes);
                        if (typeof perLine != 'undefined' && perLine.length) {
                            lines[[A, B].sort().join(',')] = perLine;
                        }
                    }
                    if (typeof lines[[A, C].sort().join(',')] == 'undefined') {
                        perLine = this.computeABStep(A, C, nodes);
                        if (typeof perLine != 'undefined' && perLine.length) {
                            lines[[A, C].sort().join(',')] = perLine;
                        }
                    }

                    if (typeof lines[[B, C].sort().join(',')] == 'undefined') {
                        perLine = this.computeABStep(B, C, nodes);
                        if (typeof perLine != 'undefined' && perLine.length) {
                            lines[[B, C].sort().join(',')] = perLine;
                        }
                    }

                }
            }
        }
        return lines;
    }
    computeABStep(A1, B1, nodes) {
        let nodesLength = nodes.length;
        let points = [];
        let step = this.data.flowSetting.PStep;
        let perStep = this.data.flowSetting.perStep;
        let Max = this.data.flowSetting.Pmax;
        let Min = this.data.flowSetting.Pmin;


        let PA1 = nodes[A1][3];
        let PB1 = nodes[B1][3];
        let A, B;

        if (PA1 < PB1) {
            A = A1;
            B = B1;
        }
        else {
            A = B1;
            B = A1;
        }

        let PA = nodes[A][3];
        let PB = nodes[B][3];

        this.p1.x = nodes[A][0];
        this.p1.y = nodes[A][1];
        this.p1.z = nodes[A][2];

        this.p2.x = nodes[B][0];
        this.p2.y = nodes[B][1];
        this.p2.z = nodes[B][2];

        let DAB = this.p1.distanceTo(this.p2);

        let NA = Math.floor(PA / perStep);
        let NB = Math.floor(PB / perStep);

        if (NA == NB && NA != 0) {
            if (PA % perStep == 0) {
                return [A];
            }
            else if (PB % perStep == 0) {
                return [B];
            }
            else {
                return [];
            }
        }
        else {
            let IA, IB, IAB, IPA, IPB;

            this.ray.origin.x = nodes[A][0];
            this.ray.origin.y = nodes[A][1];
            this.ray.origin.z = nodes[A][2];

            this.ray.direction.x = nodes[B][0] - nodes[A][0];
            this.ray.direction.y = nodes[B][1] - nodes[A][1];
            this.ray.direction.z = nodes[B][2] - nodes[A][2];

            this.ray.direction.normalize();

            for (let i = NA + 1; i <= NB; i++) {
                let C = perStep * i;
                let D = (C - PA) / (PB - PA);
                if (D < 0 || D > 1)
                    console.log("A,B:", A, B, "perStep=", perStep, "NA,NB:", NA, NB, "PA,PB:", PA, PB, "C=", C, "D=", D, "DAB=", DAB)
                else {
                    if (D != 0 && D != 1) {
                        this.ray.at(DAB * D, this.p3);
                        nodes[++nodesLength] = [this.p3.x, this.p3.y, this.p3.z, C];
                        points.push(nodesLength);
                    }
                    else if (D == 0) {
                        points.push(A);
                    }
                    else if (D == 1) {
                        points.push(B);
                    }
                }
            }
        }
        return points;
    }
    computeABStep_old(A, B, nodes) {
        let nodesLength = nodes.length;
        let points = [];
        let step = this.data.flowSetting.PStep;
        let perStep = this.data.flowSetting.perStep;
        let Max = this.data.flowSetting.Pmax;
        let Min = this.data.flowSetting.Pmin;

        let PA = nodes[A][3];
        let PB = nodes[B][3];

        this.p1.x = nodes[A][0];
        this.p1.y = nodes[A][1];
        this.p1.z = nodes[A][2];

        this.p2.x = nodes[B][0];
        this.p2.y = nodes[B][1];
        this.p2.z = nodes[B][2];

        let DAB = this.p1.distanceTo(this.p2);

        let NA = Math.floor(PA / perStep);
        let NB = Math.floor(PB / perStep);
        // let NA = PA >= PB ? Math.floor(PA / perStep) : Math.ceil(PA / perStep);
        // let NB = PB >= PA ? Math.floor(PB / perStep) : Math.ceil(PB / perStep);

        if (NA == NB && NA != 0) {
            if (PA % perStep == 0) {
                return [A];
            }
            else if (PB % perStep == 0) {
                return [B];
            }
            else {
                return [];
            }
        }
        else {
            let IA, IB, IAB, IPA, IPB;
            IA = NA;
            IB = NB;
            IPA = PA;
            IPB = PB;
            IAB = Math.abs(IB - IA);

            this.ray.origin.x = nodes[A][0];
            this.ray.origin.y = nodes[A][1];
            this.ray.origin.z = nodes[A][2];

            this.ray.direction.x = nodes[A][0] - nodes[B][0];
            this.ray.direction.y = nodes[A][1] - nodes[B][1];
            this.ray.direction.z = nodes[A][2] - nodes[B][2];

            this.ray.direction.normalize();

            for (let i = 0; i < IAB; i++) {
                let C = 0;//线上的等值点
                if (NA < NB) {
                    C = perStep * (NA + i + 1);
                }
                else {
                    C = perStep * (NA - i);
                }
                if (C == 0) {
                    let abc = 1
                }
                else {
                    let D = (C - PA) / (PB - PA);
                    if (D < 0 || D > 1)
                        console.log("A,B:", A, B, "NA,NB:", NA, NB, "PA,PB:", PA, PB, "C=", C, "D=", D, "DAB=", DAB)
                    else {
                        if (D != 0 && D != 1) {
                            this.ray.at(DAB * D, this.p3);
                            nodes[++nodesLength] = [this.p3.x, this.p3.y, this.p3.z, C];
                            points.push(nodesLength);
                        }
                        else if (D == 0) {
                            points.push(A);
                        }
                        else if (D == 1) {
                            points.push(B);
                        }
                    }
                }
            }
        }
        return points;
    }
}

export { flowLineContour }