// version v.1.0
//功能：1、gid的msh与res显示
//      2、云图的变形与时间历程
import {
    BufferGeometry,
    Mesh,
    Vector3,
    Color,
    MeshStandardMaterial,
    MeshBasicMaterial,
    LineBasicMaterial,
    MeshPhongMaterial,
    Line,
    Line3,
    Plane,
    Group,
    BufferAttribute,
    DoubleSide,
    LineSegments,
    Ray,
    Float32BufferAttribute,
    LineLoop,
    FileLoader,
    ObjectLoader,
    ArrowHelper,
    Box3
} from 'three';

import { inpCM } from "./inpmsh"
import { flowLineRoot } from "./flowlineroot"

let that;
class inpFlowRoot extends inpCM {
    // constructor(parent, fun = false) {
    //     super(parent);
    // }
    afterInit() {
        super.afterInit();
        that = this;

        // that = this;
        // console.log(this.zipFile)
        let value = {
            parent: this,
            flowSetting: {
                Dimension:3,
                lineColor: {
                    color: 0xffffff,
                    vertexColors:false,
                },
                meshType:"INP",
                pressuresName: {
                    V1: "V.V1",
                    V2: "V.V2",
                    V3: "V.V3",
                    norm: "V.Magnitude",
                    default: "V.Magnitude",
                },
                // type: "C3",
                matrix: [this.mesh.inpGroup.children[0].matrix, this.mesh.inpGroup.matrix],
                PMax: this.parent.LUT.max,
                PMin: this.parent.LUT.min,
                PStep: 5,
                perStep: (this.parent.LUT.max - this.parent.LUT.min) / 5,
            },
            meshs: {

            }


        }
        let Res=this.data.res;
        for (let i in this.data.source.instance) {
            let perInstance = this.data.source.instance[i];
            let perPart = this.data.source.part[perInstance.part];
            let matrix;
            let source = {
                nodes: false,
                hexahedron: [],
                res: {},
                matrix: [],
            };
            let C3D8_array = [];
            for (let Ei in perPart.element) {
                if (Ei.indexOf("C3D8") != -1) {
                    this.mesh.inpGroup.traverse(function (child) {
                        if (child.name == i) {
                            source.matrix.push(child.matrix);
                        }
                    });
                    source.hexahedron.push(perPart.element[Ei].element);
                    source.nodes=perPart.vertex;
                    source.nodesLength=perPart.vertex.length;
                    for(let Ri in Res){
                        source.res[Ri]={};
                        for(let Pressure_i in Res[Ri]){
                            let perRes= Res[Ri][Pressure_i];
                            if(typeof perRes.instance[i] != 'undefined'){
                                source.res[Ri][Pressure_i]= perRes.instance[i];
                            }
                        }
                    }
                }
            }
            value.meshs[i]={
                source:source
            }
        }
        console.log(value)

        this.flowLines = new flowLineRoot(value);
        let abc = 1;
    }


    center(objects) {
        /**
 * 包围盒全自动计算：模型整体居中
 */
        var box3 = new Box3()
        // 计算层级模型group的包围盒
        // 模型group是加载一个三维模型返回的对象，包含多个网格模型
        box3.expandByObject(objects)
        // 计算一个层级模型对应包围盒的几何体中心在世界坐标中的位置
        var center = new Vector3()
        box3.getCenter(center)
        // console.log('查看几何体中心坐标', center);

        // 重新设置模型的位置，使之居中。
        objects.position.x = objects.position.x - center.x
        objects.position.y = objects.position.y - center.y
        objects.position.z = objects.position.z - center.z

        // console.log(box3)
        this.box3 = box3;
    }


}

export { inpFlowRoot }