// version v.1.0
//功能：1、gid的msh与res显示
//      2、云图的变形与时间历程
import {
    BufferGeometry,
    Mesh,
    Vector3,
    Color,
    MeshStandardMaterial,
    MeshBasicMaterial,
    LineBasicMaterial,
    MeshPhongMaterial,
    Line,
    Line3,
    Plane,
    Group,
    BufferAttribute,
    DoubleSide,
    LineSegments,
    Ray,
    Raycaster,
    Float32BufferAttribute,
    LineLoop,
    FileLoader,
    ObjectLoader,
    ArrowHelper,
    CatmullRomCurve3,
    SplineCurve,
    Vector2,
} from 'three';
import triangulate from "delaunay-triangulate";
 

let that;

class flowLineTrace {
    constructor(value) {
        // super(parent);
        that = this;
        this.parent = value.parent;
        this.scene = this.parent.parent.scene;
        this.render = this.parent.parent.render;
        // this.value = value;
        this.data = {
            startPosition: [[-15.5, 0, 0], [1, 0, 0]],//this.parent.box3.min.x;//数值偏移量  //固定的，后期需要改变
            endPosition: [[-9.5, 0, 0], [1, 0, 0]],//-this.parent.box3.max.x;//固定的，后期需要改变
            countOfSeed: 10,
            interpolation: 0.05,
            interpolationCount: 10,
            flowSetting: value.flowSetting,
            pointOfTriangles: value.pointOfTriangles,
            originPressures: value.originPressures,
            meshOfTriangles: [],//mesh of triangles of instance 
            arrayOfTriangles: [],//array of triangle  of instance 
            type: value.typeOfMesh,
            lines: [],
        };
        this.material = new LineBasicMaterial({
            vertexColors: true,
        });
        this.drawLines = new Group();
        this.drawLines.applyMatrix4(this.data.flowSetting.matrix);
        this.scene.add(this.drawLines);
        this.normal = new Vector3();
        this.p4 = new Vector3();
        this.ray = new Ray();
        this.raycaster = new Raycaster();
        this.intersects;

        this._init();
    }

    _init() {
        this.beforeInit();
        this.init();
        this.afterInit();
    }
    afterInit() { }
    beforeInit() {
        // super.beforeInit();
        // this.data = {}

    }

    init() {
        this.initTriSArray();
        this.initTriS();
        let lines = this.seed(this.data.countOfSeed);
        // this.draw(lines);
    }

    initTriS() {
        const material = new MeshBasicMaterial({ color: 0xff0000 });
        for (let Ii in this.data.arrayOfTriangles) {
            let perInstance = this.data.arrayOfTriangles[Ii];
            this.data.meshOfTriangles[Ii] = [];
            let Tris = this.data.meshOfTriangles[Ii];
            for (let oenTri of perInstance) {
                const geometry = new BufferGeometry();
                geometry.setAttribute('position', new BufferAttribute(new Float32Array(oenTri[0]), 3));
                const mesh = new Mesh(geometry, material);
                mesh.pointIndex = oenTri[1];
                mesh.instanceName = Ii;
                Tris.push(mesh);
            }
        }
    }
    initTriSArray(currentTime = 1) {
        for (let Ii in this.data.pointOfTriangles) {
            let perInstance = this.data.pointOfTriangles[Ii];
            this.data.arrayOfTriangles[Ii] = [];
            let Tris = this.data.arrayOfTriangles[Ii];
            let nodes = this.data.pointOfTriangles[Ii].nodes;
            let pressures = this.data.pointOfTriangles[Ii].nodes;
            for (let Ei in perInstance) {
                let perElement = perInstance[Ei];
                for (let TSi in perElement) {
                    let perElementType = perElement[TSi];
                    for (let Ti in perElementType) {
                        let perTri = perElementType[Ti];
                        if (perTri.enable == true) {
                            let ps = perTri.points;
                            Tris.push([
                                [
                                    nodes[ps[0]][0], nodes[ps[0]][1], nodes[ps[0]][2],
                                    nodes[ps[1]][0], nodes[ps[1]][1], nodes[ps[1]][2],
                                    nodes[ps[2]][0], nodes[ps[2]][1], nodes[ps[2]][2],
                                ],
                                ps,
                            ]);
                        }
                    }
                }
            }
        }
    }
    /////////////////////////////////////////////////////////////////////////////////////////////////////////
    //
    //clean lines of flow
    clean() {

    }
    seed(n = 1) {
        let seeds = this.initSeed(n);
        for (let oneSeed of seeds) {
            let line = this.caculateOneSeedGrow(oneSeed);
            this.data.lines.push(line);
            // this.drawLine(line);
        }
    }
    initSeed(n) {
        let p = 0
        return [
            [[-16, -4, 0.5], [1, 0, 0], p]
        ];
    }
    //seed:[[x,y,z],[v1,v2,v3],p]
    caculateOneSeedGrow(seed) {
        let newPoint = [];
        let nextPoint = this.RayIntersectOfTriangles(seed);
        if (nextPoint != false) {
           return  [ nextPoint, this.caculateOneSeedGrow(nextPoint)];
        }
        else{
            return false;
        }

    }

    //自动插值
    autoInterpolation() {
        let interpolation = this.data.interpolation;
        let interpolationCount = this.data.interpolationCount;
        let x = 0, y = 0, z = 0, p = 0, vx = 0, vy = 0, vz = 0;

        return [[x, y, z], [vx, vy, vz], p];
    }


    /////////////////////////////////////////////////////////////////////////////////////////////////////////

    drawLine(line) {
        let linesAllInstance = line.instance;
        let pointsArray = line.points;

        oneLine.applyMatrix4(this.data.pointOfTriangles[linesAllInstance].matrix);

        let pointsCatmullRom = [];
        let pressures = [];
        let colors = [];
        for (let perPoint of pointsArray) {
            let xyz = perPoint.xyz;
            pointsCatmullRom.push(new Vector3(xyz[0], xyz[1], xyz[2]));
            pressures.push(new Vector2(perPoint.pressure, 0));
        }
        if (pointsCatmullRom.length > 4) {
            // if (pointsCatmullRom.length > parseInt(this.data.flowSetting.layersCount * 0.7)) {
            const curve = new CatmullRomCurve3(pointsCatmullRom);
            const points = curve.getPoints(pointsCatmullRom.length * 6);
            let geometry = new BufferGeometry().setFromPoints(points);

            const curve2DofPreesures = new SplineCurve(pressures);
            const point2DofPreesures = curve2DofPreesures.getPoints(pressures.length * 6);

            for (let spline_i of point2DofPreesures) {
                let oneColor = this.parent.parent.LUT.lut.getColor(spline_i.x);
                colors.push(oneColor.r, oneColor.g, oneColor.b);
            }

            geometry.setAttribute('color', new Float32BufferAttribute(colors, 3));
            let mesh = new Line(geometry, this.material);

            this.drawLines.add(mesh);
            this.parent.parent.render();
        }

    }

    /////////////////////////////////////////////////////////////////////////////////////////////////////////

    // 计算三角形内任意点的权重
    _getWeightedValeOfTriangle(p4, p1, p2, p3) {
        //需要一个投影变换

        let a1 = p1.z, b1 = p1.y;
        let a2 = p2.z, b2 = p2.y;
        let a3 = p3.z, b3 = p3.y;
        let a4 = p4.z, b4 = p4.y;

        let u = -(a1 - a3) * (a1 * b2 - a1 * b4 - a2 * b1 + a2 * b4 + a4 * b1 - a4 * b2) / (a1 - a2) * (a1 * b2 - a1 * b3 - a2 * b1 + a2 * b3 + a3 * b1 - a3 * b2) + (a1 - a4) / (a1 - a2);
        let v = (a1 * b2 - a1 * b4 - a2 * b1 + a2 * b4 + a4 * b1 - a4 * b2) / (a1 * b2 - a1 * b3 - a2 * b1 + a2 * b3 + a3 * b1 - a3 * b2);

        //直角三角形，不适合勾股
        if (isNaN(u)) {
            let abc = 1
            u = 0;
        }
        if (isNaN(v)) {
            let abc = 1
            v = 0;
        }
        return { u: u, v: v };
    }

    //判断ray与层次的三角形的交点，
    //  1、交点（点的法向量）
    //      1.1 正向交点
    //      1.2 断点
    //      1.3 反向交点（循环调用到本切面，或断点）
    //  2、权重
    //  3、单点信息，（相交可能在点上，有多个三角形返回数据，取第一个）
    //  4、返回点信息，或array或false
    getRayIntersectOfTriangles(ray) {
        let point;
        let triangles = this.data.meshOfTriangles;
        let points = layer.points;
        let a = new Vector3();
        let b = new Vector3();
        let c = new Vector3();

        let normal = new Vector3();

        let target = new Vector3();
        for (let i in triangles) {
            let perTri = triangles[i];
            a.x = points[perTri[0]].xyz[0];
            a.y = points[perTri[0]].xyz[1];
            a.z = points[perTri[0]].xyz[2];

            b.x = points[perTri[1]].xyz[0];
            b.y = points[perTri[1]].xyz[1];
            b.z = points[perTri[1]].xyz[2];

            c.x = points[perTri[2]].xyz[0];
            c.y = points[perTri[2]].xyz[1];
            c.z = points[perTri[2]].xyz[2];


            let ok = ray.intersectTriangle(a, b, c, false, target)
            if (ok != null)
                if (target.x != a.x && target.y != a.y && target.z != a.z) {
                    point = {
                        xyz: [a.x, a.y, a.z],
                        pressure: points[perTri[0]].pressure,
                        dir: points[perTri[0]].dir,
                        normOfVector: points[perTri[0]].norm,//模
                    };
                    return point;
                }
                else if (target.x != b.x && target.y != b.y && target.z != b.z) {
                    point = {
                        xyz: [b.x, b.y, b.z],
                        pressure: points[perTri[1]].pressure,
                        dir: points[perTri[1]].dir,
                        normOfVector: points[perTri[1]].norm,//模
                    };
                    return point;
                }
                else if (target.x != c.x && target.y != c.y && target.z != c.z) {
                    point = {
                        xyz: [c.x, c.y, c.z],
                        pressure: points[perTri[2]].pressure,
                        dir: points[perTri[2]].dir,
                        normOfVector: points[perTri[2]].norm,//模
                    };
                    return point;
                }
                else //if (ok != null)
                {//相交
                    // let weighted = this._getWeightedValeOfTriangle(ok, points[perTri[0]], points[perTri[1]], points[perTri[2]], layer)
                    let weighted = this._getWeightedValeOfTriangle(ok, a, b, c, layer)
                    let pressure = (1 - weighted.u - weighted.v) * points[perTri[0]].pressure + weighted.u * points[perTri[1]].pressure + weighted.v * points[perTri[2]].pressure;
                    normal.x = (1 - weighted.u - weighted.v) * points[perTri[0]].dir[0] + weighted.u * points[perTri[1]].dir[0] + weighted.v * points[perTri[2]].dir[0];
                    normal.y = (1 - weighted.u - weighted.v) * points[perTri[0]].dir[1] + weighted.u * points[perTri[1]].dir[1] + weighted.v * points[perTri[2]].dir[1];
                    normal.z = (1 - weighted.u - weighted.v) * points[perTri[0]].dir[2] + weighted.u * points[perTri[1]].dir[2] + weighted.v * points[perTri[2]].dir[2];
                    normal.normalize();
                    let dir = [normal.x, normal.y, normal.z]
                    point = {
                        xyz: [ok.x, ok.y, ok.z],
                        pressure: pressure,
                        dir: dir,
                        normOfVector: 0,//模
                        uv: weighted,
                    };
                    return point;
                }
        }
        // console.log(Pi,Pindex,ray.direction.x,ray.direction.y,ray.direction.z)
        return false;
    }

    RaycasterIntersectOfTriangles(newPoint) {
        let point;
        let triangles
        for(let i in  this.data.meshOfTriangles){
            triangles= this.data.meshOfTriangles[i];
            break;
        }
        let norm = this.data.originPressures.norm;
        let v1 = this.data.originPressures.V1;
        let v2 = this.data.originPressures.V2;
        let v3 = this.data.originPressures.V3;

        this.raycaster.ray.origin.x = newPoint[0][0];
        this.raycaster.ray.origin.y = newPoint[0][1];
        this.raycaster.ray.origin.z = newPoint[0][2];

        this.raycaster.ray.direction.x = newPoint[1][0];
        this.raycaster.ray.direction.y = newPoint[1][1];
        this.raycaster.ray.direction.z = newPoint[1][2];

        let intersects = this.raycaster.intersectObjects(triangles);
        if (intersects.length > 0) {
            if (intersects[0].object) {
                let mesh = intersects[0].object;
                let p4 = intersects[0].point;
                let weighted = intersects[0].uv;
                let perTri = mesh.pointIndex;
                let instanceName = mesh.instanceName;
                let normal = this.normal;
                // let weighted = this._getWeightedValeOfTriangle(ok, a, b, c, layer)
                let pressure = (1 - weighted.u - weighted.v) * norm[instacenName][perTri[0]] + weighted.u * norm[instacenName][perTri[1]] + weighted.v * norm[instacenName][perTri[2]];
                normal.x = (1 - weighted.u - weighted.v) * v1[instacenName][perTri[0]] + weighted.u * v1[instacenName][perTri[0]] + weighted.v * v1[instacenName][perTri[0]];
                normal.y = (1 - weighted.u - weighted.v) * v2[instacenName][perTri[1]] + weighted.u * v2[instacenName][perTri[1]] + weighted.v * v2[instacenName][perTri[1]];
                normal.z = (1 - weighted.u - weighted.v) * v3[instacenName][perTri[2]] + weighted.u * v3[instacenName][perTri[2]] + weighted.v * v3[instacenName][perTri[2]];
                normal.normalize();

                point = [
                    [ok.x, ok.y, ok.z],
                    [normal.x, normal.y, normal.z],
                    pressure,
                ];
                return point;
            }
        }
        else
            return false;

    }


}

export { flowLineTrace }