import * as THREE from "three";
// import { TransformControls } from 'three/examples/jsm/controls/TransformControls.js';

import { ViewHelper } from "../helper_3/Viewport.ViewHelper.js";
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer.js";
// import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
// import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js';
// import { OutlinePass } from 'three/examples/jsm/postprocessing/OutlinePass.js';
// import { FXAAShader } from 'three/examples/jsm/shaders/FXAAShader.js';
import { SSAARenderPass } from "three/examples/jsm/postprocessing/SSAARenderPass.js";
// import { SSAOPass } from 'three/examples/jsm/postprocessing/SSAOPass.js';
// import { SMAAPass } from 'three/examples/jsm/postprocessing/SMAAPass.js';
import { fontLayer } from "../font/fontlayer";
import { BoxBufferGeometry } from "three";

import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import { TransformControls } from "three/examples/jsm/controls/TransformControls.js";
// import { VertexNormalsHelper } from "three/examples/jsm/helpers/VertexNormalsHelper"
import { TrackballControls } from "three/examples/jsm/controls/TrackballControls.js";

// var that;

class threeMain {
  constructor(value = false, orbit = true) {
    this.log2ohter = false; //输出信息到其他callback
    if (typeof value.log == "function") {
      this.log2ohter = value.log;
    }
    if (typeof value.getFrame == "function") {
      this.getFrame2Other = value.getFrame;
    }
    this.ctl = 'orbit';

    if (orbit == false) {
      this.ctl = 'Trackball';
    }

    this.DIV = {
      // helper: "helper",
      container: "container",
    };
    if (value.DIV) {
      this.DIV = value.DIV;
    }
    this.value = value;
    // if (value.DIR) this.DIR = value.DIR;
    if (value.callback) this.callback = value.callback;

    // this.Status = new baseStatus();
    this.composer = false;
    this.effectFXAA = false;
    this.outlinePass = false;
    this.selectedObjects = [];
    this.raycaster = new THREE.Raycaster();
    this.mouse = new THREE.Vector2();
    this.clock = new THREE.Clock(); // only used for animations

    this.viewport_width = false;
    this.viewport_height = false;
    this.viewpoint_px = false;
    this.viewpoint_py = false;
    this.container = false;
    this.perpCamera = false;
    this.scene = false;
    this.renderer = false;
    this.orbit = false;
    this.gridHelper = false;
    this.viewHelper = false;
    this.viewHelperFlag = true;

    this.globalTransformControls = false;
    this.control = false;

    this.dragging = false;
    this.needsUpdate = true;
    this.event = []; //listen list
    this.mouseUp = false;
    this.mouseDown = false;
    this.box = false;
    this.flageGrid = "grid";

    this.worldBox = new THREE.Group();
    this.worldBox.name = "worldBox";
    this.worldCenter = new THREE.Vector3(0, 0, 0);
    this.cameraLookAt = new THREE.Vector3(0, 0, 0);
    this.farDistance = 600;
    this.goBackFromFarDistance = 30;

    this.boxOfCameraStep = 0.13; //摄像机步进距离
    this.boxWHD = 0.02; //
    this.subboxOfCamera = new THREE.Mesh(
      new BoxBufferGeometry(0.2, 0.2, 0.2),
      new THREE.MeshBasicMaterial({ transparent: true, opacity: 0 })
    );
    this.subboxOfCamera.visible = false;

    this._init();
  }

  reinitWorldBox() {
    this.scene.remove(this.scene.getObjectByName("worldBox"));
    this.worldBox = [];
    this.worldBox = new THREE.Group();
    this.worldBox.name="worldBox";
    this.scene.add(this.worldBox);
  }
  init() { }
  beforeInit() { }
  afterInit() {
    this.log("可视化场景初始化完成。");
  }
  _init() {
    this.log("可视化场景开始初始化。");
    let that = this;
    this.layerFont = new fontLayer(this);
    this.beforeInit();
    this.initScene();
    this.initCamera();
    this.initLights();
    this.initControls();
    this.initMisc();
    this.init_UI();
    this.PP();

    this.init();

    // this.render();

    // let clock = this.clock;
    // let viewHelper = this.viewHelper;
    // let render = this.render;
    // let renderer = this.renderer;
    // let scene = this.scene;
    // let perpCamera = this.perpCamera;
    // let composer = this.composer;
    // function animate() {
    //     requestAnimationFrame(animate);
    //     if (that.needsUpdate) {
    //         var delta = clock.getDelta();
    //         if (viewHelper.animating === true) {
    //             viewHelper.update(delta);
    //             // needsUpdate = true;
    //         }
    //         viewHelper.render(viewHelper.renderer);
    //         // this.renderer.render(this.viewHelper.uiScene, this.viewHelper.orthoCamera);
    //         renderer.render(scene, perpCamera);
    //         // composer.render();
    //     }
    // }
    this.afterInit();
    // this.animate(this);
    function animate() {
      requestAnimationFrame(animate);
      if (that.orbit)
        that.orbit.update();
      var delta = that.clock.getDelta();
      if (that.viewHelper.animating === true) {
        that.viewHelper.update(delta);
        // needsUpdate = true;
      }
      if (that.needsUpdate) {
        that.render();
      }
    }
    animate();
    this.needsUpdate = false;
  }
  initCamera() {
    this.perpCamera = new THREE.PerspectiveCamera(
      60,
      this.viewport_width / this.viewport_height,
      0.1,
      1000000
    );
    this.perpCamera.position.set(100, 0, 0);
    this.perpCamera.lookAt(0, 0, 0);
    this.scene.add(this.perpCamera);
  }
  animate(that) {
    requestAnimationFrame(function (time, that) {
      that.animate(that);
    });
    if (that.orbit)
      that.orbit.update();
    that.render();
    if (that.needsUpdate) {
      // that.render();
      // var delta = that.clock.getDelta();
      // if (that.viewHelperFlag) {
      //     if (that.viewHelper.animating === true) {
      //         that.viewHelper.update(delta);
      //         // needsUpdate = true;
      //     }
      //     that.viewHelper.render(that.viewHelper.renderer);
      // }
      // // this.renderer.render(this.viewHelper.uiScene, this.viewHelper.orthoCamera);
      // that.renderer.render(that.scene, that.perpCamera);
      // // composer.render();
      // if (that.uiScene && that.orthoCamera) {
      //     that.renderer.render(that.uiScene, that.orthoCamera);
      // }
    }
  }
  PP() {
    // // postprocessing

    this.composer = new EffectComposer(this.renderer);

    // const renderPass = new RenderPass(this.scene, this.perpCamera);
    // this.composer.addPass(renderPass);

    // const pass = new SMAAPass(this.viewport_width* this.renderer.getPixelRatio(),this.viewport_height * this.renderer.getPixelRatio());
    // this.composer.addPass(pass);

    // const ssaoPass = new SSAOPass( this.scene, this.perpCamera,this.viewport_width, this.viewport_height );
    // ssaoPass.kernelRadius = 16;
    // this.composer.addPass( ssaoPass );

    let ssaaRenderPassP = new SSAARenderPass(this.scene, this.perpCamera);
    this.composer.addPass(ssaaRenderPassP);

    // this.effectFXAA = new ShaderPass(FXAAShader);
    // this.effectFXAA.uniforms['resolution'].value.set(1 / this.viewport_width, 1 / this.viewport_height);
    // this.composer.addPass(this.effectFXAA);

    //////////////////////////////////////////////////////////
    // this.outlinePass = new OutlinePass(new THREE.Vector2(this.viewport_width, this.viewport_height), this.scene, this.perpCamera);
    // this.outlinePass.pulsePeriod = this.Status.partOutline.pulsePeriod; //数值越大，律动越慢
    // this.outlinePass.visibleEdgeColor.set(this.Status.partOutline.visibleEdgeColor); // 高光颜色，临时设定
    // this.outlinePass.hiddenEdgeColor.set(this.Status.partOutline.hiddenEdgeColor);// 阴影颜色
    // this.outlinePass.usePatternTexture = this.Status.partOutline.usePatternTexture; // 使用纹理覆盖？
    // this.outlinePass.edgeStrength = this.Status.partOutline.edgeStrength; // 高光边缘强度
    // this.outlinePass.edgeGlow = this.Status.partOutline.edgeGlow; // 边缘微光强度
    // this.outlinePass.edgeThickness = this.Status.partOutline.edgeThickness; // 高光厚度

    // this.composer.addPass(this.outlinePass);
  }
  Trender(that) {
    //update for geometry
    if (that.globalTransformControls) {
      that.globalTransformControls.callParent.updatePositionForInner();
      // console.log(that.globalTransformControls)
      that.dragging = true;
      that.showBoxHelper();
    }
  }
  onClick() { //event
    // var x, y;
    // if (event.changedTouches) {
    //     x = event.changedTouches[0].pageX;
    //     y = event.changedTouches[0].pageY;
    // } else {
    //     x = event.clientX;
    //     y = event.clientY;
    // }
    // that.mouse.x = ((x - that.viewpoint_px) / that.viewport_width) * 2 - 1;
    // that.mouse.y = - ((y - that.viewpoint_py) / that.viewport_height) * 2 + 1;
    // // if (that.mouseDown)
    // {
    //     that.raycaster.setFromCamera(that.mouse, that.perpCamera);
    //     let intersects;
    //     if (that.orbit.enabled) {
    //         intersects = that.raycaster.intersectObjects(that.scene.pickupGroup, false);
    //         if (intersects.length > 0) {
    //             that.control.detach();
    //             if (intersects[0].object != that.selectedMesh && that.selectedMesh != false) {
    //                 if (that.selectedMesh != intersects[0].object) {
    //                     that.selectedMesh.callParent.unSelected();//cancel seleted
    //                     that.selectedGEO = false;
    //                 }
    //                 if (that.scene.selectKind == "part") {
    //                     if (that.box != false) {
    //                         that.scene.remove(that.box);
    //                     }
    //                     const selectedObject = intersects[0].object;
    //                     that.box = new THREE.BoxHelper(selectedObject, 0xffff00);
    //                     that.scene.add(that.box);
    //                     that.box.visible = true;
    //                 }
    //             }
    //             that.selectedMesh = intersects[0].object;
    //             that.selectedMesh.callParent.pickup(that.raycaster);
    //             that.selectedMesh.callParent.setControl(that.control)
    //             that.globalTransformControls = that.selectedMesh;
    //             that.selectedGEO = that.selectedMesh.callParent;
    //             that.callback.click(that);
    //             // console.log(that.outlinePass.selectedObjects)
    //         } else {
    //             // if (that.scene.selectKind != "part")
    //             // {
    //             //     if (that.selectedMesh) {
    //             //         that.selectedMesh.callParent.unSelected();//cancel seleted
    //             //     }
    //             //     that.selectedMesh = false;
    //             //     that.selectedGEO = false;
    //             //     that.globalTransformControls = false;
    //             //     that.control.detach();
    //             //     that.callback.click(false);
    //             // }
    //         }
    //     }
    // }
  }

  initScene() {
    this.container = document.getElementById(this.DIV.container);
    this.viewport_width = this.container.offsetWidth;
    this.viewport_height = this.container.offsetHeight;
    this.viewpoint_px = this.container.offsetLeft;
    this.viewpoint_py = this.container.offsetTop;

    this.scene = new THREE.Scene();

    this.scene.pickupGroup = [];
    // this.scene.background = new THREE.Color(0x142d51);
    this.scene.add(this.worldBox);

    this.scene._parent = this;
    this.scene.selectKind = "part";
    this.scene.selectMulti = false;
    ////////////////////////////////////////////////////////

    this.renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
    this.renderer.autoClear = false;
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.setSize(this.viewport_width, this.viewport_height);
    this.container.appendChild(this.renderer.domElement);

    // console.log(this.worldBox)
  }
  initLights() {
    const lights = [];
    // lights[0] = new THREE.PointLight(0xffffff, 1, 0);
    // lights[1] = new THREE.PointLight(0xffffff, 1, 0);
    // lights[2] = new THREE.PointLight(0xffffff, 1, 0);

    // lights[0].position.set(0, 19, 0);
    // lights[1].position.set(11, 18, 11);
    // lights[2].position.set(- 11, - 10, - 11);

    // this.scene.add(lights[0]);
    // this.scene.add(lights[1]);
    // this.scene.add(lights[2]);
    const light = new THREE.AmbientLight(0x808080); // soft white light
    this.scene.add(light);

    const pointLight = new THREE.PointLight(0xffffff, 0.31);
    this.perpCamera.add(pointLight);
    this.perpCamera.add(this.subboxOfCamera);
  }
  initMisc() {
    if (typeof this.DIV.helper != "undefined") {
      this.viewHelperFlag = true;
      var container = document.getElementById(this.DIV.helper);

      if (this.viewHelperFlag) {
        this.viewHelper = new ViewHelper(this.perpCamera, container, this);
        this.viewHelper._parent = this;
      }
      // this.viewHelper.controls = this.orbit;
      // this.gridHelper = new THREE.GridHelper(300, 30);
      // this.scene.add(this.gridHelper);
    } else {
      this.viewHelperFlag = false;
    }
  }
  initControls() {
    // let that = this;
    // // this.control = new TransformControls(this.perpCamera, this.renderer.domElement);
    // this.control = new OrbitControls(this.perpCamera, this.renderer.domElement);
    // // console.log(this.renderer.domElement);
    // // that.control.addEventListener('change', that.Trender);
    // this.control.addEventListener("dragging-changed", function(event) {
    //   that.orbit.enabled = !event.value;
    //   that.dragging = true;
    //   that.Trender(that);
    // });
    // this.control.addEventListener("mouseUp", function(event) {
    //   that.needsUpdate = false;
    //   that.Trender();
    //   that.CM.repairPoly();
    //   that.render();
    // });
    // this.control.addEventListener("mouseDown", function(event) {
    //   that.needsUpdate = true;
    // });
    // this.scene.add(this.control);

    // this.orbit = new OrbitControls(this.perpCamera, this.renderer.domElement);
    // this.orbit.addEventListener("change", function() {
    //   if (that.showPoint.isShowMove || that.showPoint.isShowClick) {
    //     if (that.layerFont.isCleaned == false) that.layerFont.clean();
    //   }
    //   that.render();
    // });

    // window.addEventListener("resize", function(event) {
    //   that._onWindowResize(event, that);
    // });
    // window.addEventListener("keydown", this.controlSwitch);
    // this.container.addEventListener("click", that.onClick);
    // this.container.addEventListener("mousemove", that.onMouseMove);
    // // this.container.addEventListener('keydown', function (event) { console.log("asdfasdfasdfaf"); });
  }
  controlSwitch(that) {
    let abc = 1;
  }
  init_UI() { }

  _onWindowResize(event, that) {
    // that.orbit.handleResize();
    // console.log('画布更改', left, top, activeWidth, activeHeight)
    that.viewport_width = that.container.offsetWidth;
    that.viewport_height = that.container.offsetHeight;
    that.viewpoint_px = that.container.offsetLeft;
    that.viewpoint_py = that.container.offsetTop;
    const width = that.viewport_width;
    const height = that.viewport_height;
    that.perpCamera.aspect = width / height;
    that.perpCamera.updateProjectionMatrix();
    that.renderer.setSize(width, height);
    that.composer.setSize(width, height);
    that.render();
  }
  onWindowResize(
    left = false,
    top = false,
    activeWidth = false,
    activeHeight = false
  ) {
    // console.log('画布更改', left, top, activeWidth, activeHeight)
    // this.viewport_width = activeWidth || this.container.offsetWidth;
    // this.viewport_height = activeHeight || this.container.offsetHeight;
    // this.viewpoint_px = left || this.container.offsetLeft;
    // this.viewpoint_py = top || this.container.offsetTop;
    // const width = this.viewport_width;
    // const height = this.viewport_height;
    // this.perpCamera.aspect = width / height;
    // this.perpCamera.updateProjectionMatrix();
    // this.renderer.setSize(width, height);
    // this.composer.setSize(width, height);
    // this.render()
  }

  render() {
    if (this.CM) {
      let disBox = this.getMaxOfWolrdBox();
      let disCam = this.getDistanceFromCameraToZero();
      if (disCam - disBox * 0.051 <= disBox) {
        if (disCam > 100) this.perpCamera.near = 1.01;
        else if (disCam > 10) this.perpCamera.near = 0.1;
        else this.perpCamera.near = 0.01;
      } else {
        this.perpCamera.near = this.getDistanceFromCameraToZero() / 20;
      }
    }
    // if (typeof this.CM != 'undefined') {
    //     let x = this.perpCamera.position.x;
    //     let y = this.perpCamera.position.y;
    //     let z = this.perpCamera.position.z;
    //     if (x > this.farDistance) {
    //         this.perpCamera.x -= this.goBackFromFarDistance;
    //     }
    //     else if (x < -this.farDistance) {
    //         this.perpCamera.position.x += this.goBackFromFarDistance;
    //     }
    //     if (y > this.farDistance) {
    //         this.perpCamera.position.y -= this.goBackFromFarDistance;
    //     }
    //     else if (y < -this.farDistance) {
    //         this.perpCamera.position.y += this.goBackFromFarDistance;
    //     }
    //     if (z > this.farDistance) {
    //         this.perpCamera.position.z -= this.goBackFromFarDistance;
    //     }
    //     else if (z < -this.farDistance) {
    //         this.perpCamera.position.z += this.goBackFromFarDistance;
    //     }
    // }

    if (typeof this.debug == "undefined")
      if (typeof this.box3OfCamera != "undefined" && typeof this.box3OfWorld != "undefined") {
        this.box3OfCamera = new THREE.Box3();
        this.box3OfCamera.expandByObject(this.subboxOfCamera);
        let intersect = this.box3OfWorld.intersectsBox(this.box3OfCamera);
        let inside = this.box3OfWorld.containsBox(this.box3OfCamera);
        let x = this.perpCamera.position.x;
        let y = this.perpCamera.position.y;
        let z = this.perpCamera.position.z;
        let doX = false,
          doY = false,
          doZ = false;
        if (intersect) {
          // console.log('intersect');
          if (
            x > this.box3OfWorld.min.x &&
            x < this.box3OfWorld.max.x &&
            y > this.box3OfWorld.min.y &&
            y < this.box3OfWorld.max.y
          ) {
            if (z <= this.box3OfWorld.min.z - this.boxOfCameraStep) {
              this.perpCamera.position.z =
                this.box3OfWorld.min.z - this.boxOfCameraStep;
              // console.log(1);
            } else if (z > this.box3OfWorld.max.z) {
              this.perpCamera.position.z =
                this.box3OfWorld.max.z + this.boxOfCameraStep;
              // console.log(2);
            }
          } else if (
            x > this.box3OfWorld.min.x &&
            x < this.box3OfWorld.max.x &&
            z > this.box3OfWorld.min.z &&
            z < this.box3OfWorld.max.z
          ) {
            if (y < this.box3OfWorld.min.y) {
              this.perpCamera.position.y =
                this.box3OfWorld.min.y - this.boxOfCameraStep;
              // console.log(3);
            } else if (y > this.box3OfWorld.max.y) {
              this.perpCamera.position.y =
                this.box3OfWorld.max.y + this.boxOfCameraStep;
              // console.log(4);
            }
          } else if (
            z > this.box3OfWorld.min.z &&
            z < this.box3OfWorld.max.z &&
            y > this.box3OfWorld.min.y &&
            y < this.box3OfWorld.max.y
          ) {
            if (x < this.box3OfWorld.min.x) {
              this.perpCamera.position.x =
                this.box3OfWorld.min.x - this.boxOfCameraStep;
              // console.log(5);
            } else if (x > this.box3OfWorld.max.x) {
              this.perpCamera.position.x =
                this.box3OfWorld.max.x + this.boxOfCameraStep;
              // console.log(6);
            }
          }
        }
        if (inside) {
          // console.log('inside');
          this.perpCamera.getWorldDirection(this.cameraLookAt);
          let cx = Math.abs(this.cameraLookAt.x);
          let cy = Math.abs(this.cameraLookAt.y);
          let cz = Math.abs(this.cameraLookAt.z);
          cx > cy ? (doX = true) : cy > cz ? (doY = true) : (doZ = true);
          let xUp = Math.abs(this.box3OfWorld.max.x - x);
          let xDown = Math.abs(this.box3OfWorld.min.x - x);

          if (doX)
            if (xUp < xDown) {
              // if (x > this.box3OfWorld.min.x && x < this.box3OfWorld.max.x)
              this.perpCamera.position.x =
                this.box3OfWorld.max.x + this.boxOfCameraStep;
              // console.log(7);
              doX = true;
            } else {
              this.perpCamera.position.x =
                this.box3OfWorld.min.x - this.boxOfCameraStep;
              // console.log(8);
              doX = true;
            }
          let yUp = Math.abs(this.box3OfWorld.max.y - y);
          let yDown = Math.abs(this.box3OfWorld.min.y - y);
          if (doY)
            if (yUp < yDown) {
              // if (y > this.box3OfWorld.min.y && y < this.box3OfWorld.max.y && doX == false)
              this.perpCamera.position.y =
                this.box3OfWorld.max.y - this.boxOfCameraStep;
              // console.log(9);
              doY = true;
            } else {
              this.perpCamera.position.y =
                this.box3OfWorld.min.y + this.boxOfCameraStep;
              // console.log(10);
              doY = true;
            }

          let zUp = Math.abs(this.box3OfWorld.max.z - z);
          let zDown = Math.abs(this.box3OfWorld.min.z - z);
          if (doZ)
            if (zUp < zDown) {
              // if (z > this.box3OfWorld.min.z && z < this.box3OfWorld.max.z && doX == false && doY == false)
              this.perpCamera.position.z =
                this.box3OfWorld.max.z + this.boxOfCameraStep;
              // console.log(11);
            } else {
              this.perpCamera.position.z =
                this.box3OfWorld.min.z - this.boxOfCameraStep;
              // console.log(12);
            }
        }
      }
    this.renderer.render(this.scene, this.perpCamera);
    this.composer.render();

    // if (this.uiScene && this.orthoCamera) {
    //     this.renderer.render(this.uiScene, this.orthoCamera);
    // }
    // if (this.LUT) {
    //     this.LUT.render();
    // }

    if (typeof this.layerFont != "undefined") {
      // this.layerFont.update();
      this.renderer.render(this.layerFont.uiScene, this.layerFont.orthoCamera);
    }

    this.oldPositionOfCamera = {
      x: this.perpCamera.position.x,
      y: this.perpCamera.position.y,
      z: this.perpCamera.position.z,
    };
    if (this.viewHelperFlag) {
      this.viewHelper.render(this.viewHelper.renderer);
      // console.log("view helper: V", this.viewHelperFlag);
    }
    else {
      // console.log("view helper: X", this.viewHelperFlag);
    }
  }

  setViewAxis(axis = false) {
    let object;
    this.perpCamera.up.x=0;
    this.perpCamera.up.y=1;
    this.perpCamera.up.z=0;
    switch (axis) {
      case "X":
        object = "posX";

        break;

      case "Y":
        object = "posY";
 
        break;

      case "Z":
        object = "posZ";
        break;

      case "-X":
        object = "negX";
        break;

      case "-Y":
        object = "negY";
        break;

      case "-Z":
        object = "negZ";
        break;
    }

    if (axis) {
      this.viewHelper.setViewAxis(object, new THREE.Vector3(0, 0, 0));
    }
  }

  // ground grid
  groudFor3D() {
    // this.gridHelper = new THREE.GridHelper(3000, 300);
    this.gridHelper.rotation._x = 0;
    this.gridHelper.rotation._y = 0;
    this.gridHelper.rotation._z = 0;
    this.gridHelper.setRotationFromEuler(this.gridHelper.rotation);
  }
  groudFor2D() {
    // this.gridHelper = new THREE.GridHelper(3000, 300);
    this.gridHelper.rotation._x = (90 * Math.PI) / 180;
    this.gridHelper.rotation._y = 0;
    this.gridHelper.rotation._z = 0;
    this.gridHelper.setRotationFromEuler(this.gridHelper.rotation);
  }
  groundUpdateSize(size = false, count = false) {
    let rotationX = this.gridHelper.rotation._x;
    if (size && count) {
      this.scene.remove(this.gridHelper);
      this.gridHelper = new THREE.GridHelper(size, count);
      this.scene.add(this.gridHelper);
      this.gridHelper.rotation._x = rotationX;
    }
  }
  groud3DGetGridOrPlane() {
    return this.gridHelper.visible ? "grid" : "plane";
  }
  groud3DSetGrid2Plane(plane = false) {
    if (plane) {
      this.flageGrid = "plane";
      this.gridHelper.visible = false;
      if (this.gridPlane) {
        this.gridPlane.visible = true;
      } else {
        let geometry = new THREE.PlaneGeometry();
        let material = new THREE.MeshBasicMaterial({
          color: 0xffff00,
          side: THREE.DoubleSide,
        });
        this.gridPlane = new THREE.Mesh(geometry, material);
        this.scene.add(this.gridPlane);
      }
    } else {
      this.gridHelper.visible = true;
      if (this.gridPlane) this.gridPlane.visible = false;
    }
  }
  groudSet3DOffset(y) {
    this.gridHelper.position.y = y;
    if (this.gridPlane) this.gridPlane.position.y = y;
  }
  groudEnable(enable = true) {
    if (enable) {
      if (this.flageGrid == "grid") this.gridHelper.visible = true;
      if (this.flageGrid != "grid") {
        this.gridHelper.visible = false;
        this.gridPlane.visible = true;
      }
    } else {
      this.gridHelper.visible = false;
      if (this.gridPlane) this.gridPlane.visible = false;
    }
  }

  setENVLast() {
    let box3 = this.CM.box3;
    let x = box3.max.x - box3.min.x;
    let y = box3.max.y - box3.min.y;
    let z = box3.max.z - box3.min.z;
    let max = x > y ? x : y;
    max = max > z ? max : z;
    this.perpCamera.position.set(0, 0, max * 1.6/*2.5 < this.farDistance ? this.farDistance : max * 2.5*/);
    this.perpCamera.lookAt(0, 0, 0);
    this.perpCamera.far = max * 2.5 < this.farDistance ? this.farDistance * 1000 : max * 1000;
    this.render();
    this.box3OfCamera = new THREE.Box3();
    this.box3OfCamera.expandByObject(this.perpCamera);
    this.box3OfWorld = new THREE.Box3();
    this.box3OfWorld.expandByObject(this.worldBox);
    // this.orbit.maxDistance = this.farDistance;
    this.perpCamera.near = this.getDistanceFromCameraToZero() / 10;
    this.render();
    this.needsUpdate=true;
    let that =this;
    let disable=function(){
      that.needsUpdate=false;
    }
    window.setTimeout(disable,5000)
    // this.setViewAxis("X");
    this.setViewAxis("Z");
    let abc = 1;
    abc = 2;
  }
  updateENVLast() {
    this.box3OfCamera = new THREE.Box3();
    this.box3OfCamera.expandByObject(this.perpCamera);
    this.box3OfWorld = new THREE.Box3();
    this.box3OfWorld.expandByObject(this.worldBox);
    // this.orbit.maxDistance = this.farDistance;
    this.render();
  }
  getMaxOfWolrdBox() {
    if (this.CM) {
      this.box3OfWorld = new THREE.Box3();
      this.box3OfWorld.expandByObject(this.worldBox);
      if (this.CM.box3) {
        let box3 = this.CM.box3;
        let x = box3.max.x - box3.min.x;
        let y = box3.max.y - box3.min.y;
        let z = box3.max.z - box3.min.z;
        let max = x > y ? x : y;
        max = max > z ? max : z;
        return max;
      }
    } else {
      return false;
    }
  }
  getDistanceFromCameraToZero() {
    return this.worldCenter.distanceTo(this.perpCamera.position);
  }
  gotoOrignViewPoint() {
    let max;
    if (this.CM) {
      let box3 = this.CM.box3;
      let x = box3.max.x - box3.min.x;
      let y = box3.max.y - box3.min.y;
      let z = box3.max.z - box3.min.z;
      max = x > y ? x : y;
      max = max > z ? max : z;
      this.perpCamera.position.set(
        box3.max.x * 0.15,
        box3.max.y * 0.8,
        max * 1.6 /*> this.farDistance ? this.farDistance : max * 1.5*/
      );
      // this.perpCamera.far = max * 2.5 < this.farDistance ? this.farDistance * 1000 : max * 1000;
    } else {
      var box3 = new THREE.Box3();
      box3.expandByObject(this.worldBox);
      this.perpCamera.position.set(
        box3.max.x * 0.15,
        box3.max.y * 0.8,
        max * 1.1
      );
    }
    this.perpCamera.lookAt(0, 0, 0);
    this.perpCamera.up.x = 0;
    this.perpCamera.up.y = 1;
    this.perpCamera.up.z = 0;
  }

  log() {
    let msg = "";
    for (let perMsg of arguments) {
      msg += perMsg;
    }
    if (this.log2ohter) {
      this.log2ohter(msg);
    } else {
      console.log(msg);
    }
  }
  CMInitAfter() { }
  getFrameNo() {
    let msg = "";
    for (let perMsg of arguments) {
      msg += perMsg;
    }
    if (this.getFrame2Other) {
      this.getFrame2Other(msg);
    } else {
      console.log(msg);
    }
  }
  downLoad(content, fileName) {
    var aEle = document.createElement("a");
    var blob = new Blob([content]);
    aEle.download = fileName;
    aEle.href = URL.createObjectURL(blob);
    aEle.click();
}
}

export { threeMain };
