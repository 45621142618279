// version v.1.0
//功能：1、gid的msh与res显示
//      2、云图的变形与时间历程
import {
    BufferGeometry,
    Mesh,
    Vector3,
    Color,
    MeshStandardMaterial,
    MeshBasicMaterial,
    LineBasicMaterial,
    MeshPhongMaterial,
    Line,
    Line3,
    Plane,
    Group,
    BufferAttribute,
    DoubleSide,
    LineSegments,
    Ray,
    Float32BufferAttribute,
    LineLoop,
    FileLoader,
    ObjectLoader,
    SphereGeometry
} from 'three';

import { baseCM } from "../csmesh/basecm.js"

let thisGID;
class cloudAnsysCM extends baseCM {
    constructor(parent, inputFile = false) {
        // this.inputFile = inputFile;
        super(parent, inputFile);
        return this;
    }


    beforeInit() {

        thisGID = this;
        this.meshType = "gid";
        this.originFrame = false;//old frame

        super.beforeInit();
    }

    initFormat() {
        // this.data.resKey = this.zipFile.resKey;


        if (this.zipFile.ziped) {
            this.zipFile.list = {};
            let perOne = this.getfirstEXTFile("msh");
            this.zipFile.list[perOne] = thisGID.getFileMesh;
            let list = this.getfirstEXTFileList("res");
            if (list.length > 0) {
                let ii = 1;
                for (let j in list) {
                    let secndPerOne = list[j]
                    this.zipFile.list[secndPerOne] = thisGID.getRES;
                    break;
                }
            }
        }//end of if 
    }
    init() {
        let abc = 1;
    }
    afterInit(that) {
        if (thisGID.setting.matrix)
            for (let i in thisGID.mesh) {
                let child = thisGID.mesh[i];
                //   child.rotateX(-90 * Math.PI / 180);
                child.applyMatrix4(thisGID.setting.matrix);
            }
        super.afterInit(that);
    }
    ////////////////////////////////////////////////////////////////////////////////
    //
    // callDraw() { }


    getFileMesh(text) {
        this.parent.log("......数据优化;");
        if (text) {
            let msh_file_lines = text.split('\n')
            ////consolee.log(msh_file_lines)//array
            let points = [];
            let elements = false;
            let nodes = false;
            let elementsName = false;
            let nodesName = false;
            let meshName = false, meshDimension = false, meshType = false, groups = false;
            let lastNodesName = false;
            let meshs = [];//标志位，vertex
            let flag_vertex = false, flag_polygon = false, flagMesh = false;
            let countGrammar = 0;
            let dimension;
            let elementType;
            let countOfNode;


            for (let i in msh_file_lines) {
                let perLine = msh_file_lines[i].split('\r');
                let perLineArray = perLine[0].split(/\s+/);
                let perLineFirst;
                // if(perLineArray[0]=='') perLineArray.splice(0,1);
                if (perLineArray.length > 1) perLineFirst = perLineArray[1];

                if (perLineFirst == "Mesh") {
                    meshName = perLineArray[2].replace(/\"/g, '');
                    meshDimension = perLineArray[4];
                    dimension = perLineArray[4];
                    meshType = perLineArray[6] + " " + perLineArray[7] + " " + perLineArray[8];
                    elementType = perLineArray[6];
                    countOfNode = perLineArray[8];
                    this.data.source.nodes[meshName] = [];//{};
                    this.data.source.elements[meshName] = {
                        elements: [],// {},
                        meshType: meshType,
                        groups: {},
                        dimension: dimension,
                        countOfNode: countOfNode,
                        elementType: elementType,
                    };
                    this.data.source.dimension = dimension;
                    this.data.source.mesh[meshName] = {
                        meshName: meshName,
                        elements: this.data.source.elements[meshName],
                        nodes: this.data.source.nodes[meshName],
                        meshType: meshType,
                        dimension: dimension,
                    }
                    if (lastNodesName == false) lastNodesName = meshName;
                    elements = this.data.source.elements[meshName].elements;
                    groups = this.data.source.elements[meshName].groups;
                    nodes = this.data.source.nodes[meshName];
                    flagMesh = true;
                }

                if (perLineFirst == 'Coordinates') {
                    flag_vertex = true;
                }
                if (perLineFirst == "End" && perLineArray[2] == "coordinates") {
                    //if (Object.keys(nodes).length > 0)
                    if (nodes.length > 0) {
                        lastNodesName = meshName;
                        this.data.source.mesh[meshName].nodesLength = nodes.length;
                    }
                    flag_vertex = false;
                }
                if (perLineFirst == "Elements") {
                    flag_polygon = true;
                }
                if (perLineFirst == "End" && perLineArray[2] == "elements") {
                    if (Object.keys(nodes).length == 0 && lastNodesName) {
                        this.data.source.mesh[meshName].nodes = this.data.source.nodes[lastNodesName];
                        let abc = 1;
                    }
                    flag_polygon = false;
                }
                if (flag_vertex && perLineFirst != "Coordinates") {
                    if (dimension == "3") {
                        let temp_line_vertex_array = msh_file_lines[i].trim().split(/\s+/);
                        if (temp_line_vertex_array.length == 5) {
                            temp_line_vertex_array.splice(0, 1);
                        }
                        //consolee.log(temp_line_vertex_array)
                        if (temp_line_vertex_array.length == 4) {
                            nodes[parseInt(temp_line_vertex_array[0])] = [parseFloat(temp_line_vertex_array[1]), parseFloat(temp_line_vertex_array[2]), parseFloat(temp_line_vertex_array[3])];
                        }
                    }
                    else if (dimension == '2') {
                        let temp_line_vertex_array = msh_file_lines[i].trim().split(/\s+/);
                        if (temp_line_vertex_array.length == 4) {
                            temp_line_vertex_array.splice(0, 1);
                        }
                        //consolee.log(temp_line_vertex_array)
                        if (temp_line_vertex_array.length == 3) {
                            nodes[parseInt(temp_line_vertex_array[0])] = [parseFloat(temp_line_vertex_array[1]), parseFloat(temp_line_vertex_array[2]), 0];
                        }
                    }
                }

                if (flag_polygon && perLineFirst != "Elements") {
                    let temp_line_poly_array = msh_file_lines[i].trim().split(/\s+/)
                    // if (temp_line_poly_array.length == 9) {
                    //     temp_line_poly_array.splice(0, 1);
                    // }
                    if (temp_line_poly_array.length == 10 && meshType == "Hexahedra Nnode 8") { //8 point
                        // elements[temp_line_poly_array[0]] = {
                        //     type: temp_line_poly_array.length - 1,
                        //     points:
                        //         [
                        //             parseInt(temp_line_poly_array[1]), parseInt(temp_line_poly_array[2]), parseInt(temp_line_poly_array[3]), parseInt(temp_line_poly_array[4]),
                        //             parseInt(temp_line_poly_array[5]), parseInt(temp_line_poly_array[6]), parseInt(temp_line_poly_array[7]), parseInt(temp_line_poly_array[8]),
                        //         ]
                        // }

                        elements[temp_line_poly_array[0]] =
                            [
                                parseInt(temp_line_poly_array[1]), parseInt(temp_line_poly_array[2]), parseInt(temp_line_poly_array[3]), parseInt(temp_line_poly_array[4]),
                                parseInt(temp_line_poly_array[5]), parseInt(temp_line_poly_array[6]), parseInt(temp_line_poly_array[7]), parseInt(temp_line_poly_array[8]),
                            ];



                        if ("undefined" == typeof groups[temp_line_poly_array[9]])
                            groups[temp_line_poly_array[9]] = []
                        groups[temp_line_poly_array[9]].push(temp_line_poly_array[0])
                    }
                    else if (meshType == "Quadrilateral Nnode 4") { // 四边形
                        elements[temp_line_poly_array[0]] =
                            [
                                parseInt(temp_line_poly_array[1]), parseInt(temp_line_poly_array[2]), parseInt(temp_line_poly_array[3]), parseInt(temp_line_poly_array[4])
                            ]
                        if ("undefined" == typeof groups[temp_line_poly_array[5]])
                            groups[temp_line_poly_array[5]] = [];
                        groups[temp_line_poly_array[5]].push(temp_line_poly_array[0]);
                    }
                    else if (meshType == "Triangle Nnode 3") { // Triangle Nnode 3
                        elements[temp_line_poly_array[0]] =
                            [
                                parseInt(temp_line_poly_array[1]), parseInt(temp_line_poly_array[2]), parseInt(temp_line_poly_array[3])
                            ]
                        if ("undefined" == typeof groups[temp_line_poly_array[4]])
                            groups[temp_line_poly_array[5]] = [];
                        groups[temp_line_poly_array[5]].push(temp_line_poly_array[0]);
                    }
                    else if (meshType == "Point Nnode 1") {
                        elements[temp_line_poly_array[0]] = {
                            type: temp_line_poly_array.length - 1,
                            points:
                                [
                                    parseInt(temp_line_poly_array[1])
                                ]
                        };

                        if ("undefined" == typeof groups[temp_line_poly_array[2]])
                            groups[temp_line_poly_array[2]] = []
                        groups[temp_line_poly_array[2]].push(temp_line_poly_array[0])
                    }
                    else if (meshType == "Linear Nnode 2") {
                        elements[temp_line_poly_array[0]] = {
                            type: temp_line_poly_array.length - 1,
                            points:
                                [
                                    parseInt(temp_line_poly_array[1]), parseInt(temp_line_poly_array[2])
                                ]
                        };

                        if ("undefined" == typeof groups[temp_line_poly_array[5]])
                            groups[temp_line_poly_array[5]] = []
                        groups[temp_line_poly_array[5]].push(temp_line_poly_array[0])
                    }
                    else if (meshType == "Quadrilateral Nnode 9" && dimension == 2) { // 二阶 四边形
                        elements[temp_line_poly_array[0]] =
                            [
                                parseInt(temp_line_poly_array[1]), parseInt(temp_line_poly_array[2]), parseInt(temp_line_poly_array[3]), parseInt(temp_line_poly_array[4])
                            ]
                        if ("undefined" == typeof groups[temp_line_poly_array[5]])
                            groups[temp_line_poly_array[5]] = [];
                        groups[temp_line_poly_array[5]].push(temp_line_poly_array[0]);
                    }

                }//end of if elements

            }//end of for
        }//end of if text
    }

    //设置变形场
    setTransCM(CM = false) {
        if (CM) {
            this.setting.transCM = CM;
        }
    }
    //内部获取array
    getInterTransCMName(trans = false) {
        let CM = [];
        if (trans) {
            for (let i of this.data.res[trans].ComponentNames) {
                CM.push(i);
            }
            return CM;
        }
        return false;

    }
    //外部获取变形场
    getTransformName() {
        let transArray = [];
        for (let i in this.data.res) {
            let per = this.data.res[i]
            if (per.type == "Vector") {
                transArray.push(i);
            }
        }
        return transArray;
    }
    reNewRES(text) {
        this.data.res = {};
        this.getRES(text);
    }
    getRES(text) {
        let a = 1;
        if (text) {
            let msh_file_lines = text.split('\n')
            ////consolee.log(msh_file_lines)//array
            let points = [];
            let elements = [];
            let meshs = [];//标志位，vertex
            let flag_Result = false, flag_name = false, flag_ComponentNames = false, flag_value = false, flag_No;
            let ComponentNames = [];
            let countGrammar = 0;
            let nameOfRes = false;
            let Value_No = false, Value_Vector = false;
            for (var i in msh_file_lines) {
                let perLine = msh_file_lines[i].split('\r');
                let perLineArray = perLine[0].split(/\s+/);
                if (perLineArray.length > 1) {
                    perLineArray.splice(0, 1);
                    perLine = perLineArray;
                }
                if (perLine[0] == 'Result') {
                    flag_Result = true, flag_name = false, flag_ComponentNames = false, flag_value = false;

                    nameOfRes = perLine[1].replace(/\"/g, '');
                    flag_No = perLine[4];
                    if (perLine[5] == "Vector") {
                        Value_Vector = true;
                    }
                    else Value_Vector = false;

                    if (typeof this.data.res[nameOfRes] == "undefined") {
                        this.data.res[nameOfRes] = {};
                        // this.data.res[nameOfRes].value = {};
                        this.data.res[nameOfRes].type = perLine[5];
                        // this.data.res[nameOfRes].ComponentNames = [];
                    }
                    continue;
                }
                if (flag_Result && perLine[0] == 'ComponentNames') {
                    flag_ComponentNames = true;
                    if (typeof this.data.res[nameOfRes][flag_No] == "undefined") {
                        this.data.res[nameOfRes][flag_No] = {};
                        ComponentNames = [];
                        for (let i = 1; i < perLine.length; i++) {
                            let temp = perLine[i].replace(/\"/g, '');
                            ComponentNames.push(temp);
                            this.data.res[nameOfRes][flag_No][temp] = [];//按照u,v,w排列[] 
                        }
                        if (this.data.res[nameOfRes].type == "Vector") {
                            this.data.res[nameOfRes][flag_No]["M"] = [];
                        }
                    }
                    continue;
                }
                if (flag_ComponentNames && perLine[0] == 'Values') {
                    flag_value = true;
                    continue;
                }
                if (perLine[0] == '' && perLine[1] == "end" && perLine[2] == "Values") {
                    flag_Result = false, flag_name = false, flag_ComponentNames = false, flag_value = false, flag_No = false;
                    nameOfRes = false;
                    continue;
                }


                if (flag_value) {
                    let perValue = msh_file_lines[i].trim().split(/\s+/);
                    let M = 0;
                    for (let j in ComponentNames) {
                        let kind = ComponentNames[j];

                        if (typeof this.data.res[nameOfRes][flag_No][kind] == "undefined") {
                            this.data.res[nameOfRes][flag_No][kind] = [];
                        }
                        this.data.res[nameOfRes][flag_No][kind][perValue[0]] = parseFloat(perValue[parseInt(j) + a]);
                        M += parseFloat(perValue[parseInt(j) + a]) * parseFloat(perValue[parseInt(j) + a]);
                    }
                    if (this.data.res[nameOfRes].type == "Vector") {
                        if (typeof this.data.res[nameOfRes][flag_No]["M"] == "undefined") {
                            this.data.res[nameOfRes][flag_No]["M"] = [];
                        }
                        this.data.res[nameOfRes][flag_No]["M"][perValue[0]] = Math.sqrt(M);
                    }
                }
            }
        }
        let abc = 1;// this.data.res=JSON.parse(data)
        // console.log(this.data.res)
        this.parent.log("......数据优化;");
    }

    getCMName() {
        return this.getPressureSName();
    }
    getPressureSName() {
        let names = []
        for (let i in this.data.res) {
            let perName = this.data.res[i]['1'];
            // names.push(i);
            for (let j in perName) {
                names.push(i + "." + j);
                // for(let k in perName[j]){
                //     names.push(i+"."+j+"."+k);
                // }
            }
        }
        // console.log(names)
        return names;
    }

    confirmTrans() {
        // if (this.setting.trans) {
        //     if (thisGID.data.res[thisGID.nameOfCloudmap[0]].type == "Vector") {
        //         // this.setting.trans=value;
        //     }
        //     else {
        //         this.setting.trans = false;
        //     }
        // }
    }
    setMatrix(M) {
        this.setting.matrix = M;
    }
    setEnableTransformation(value = true) {
        this.setting.trans = value;
    }
    setTransformationValue(value = false) {
        this.setting.transValue = value;
    }
    //获取时间历程列表
    getCurrentCMLevel() {
        let No = 1;
        if (thisGID.nameOfCloudmap) {
            let level = [];
            let ii = 1;
            for (let i in thisGID.data.res[thisGID.nameOfCloudmap[0]]) {

                if (i != "type") {
                    ii++;
                    level.push(i);
                    if (thisGID.timerA.countCurrent) {
                        No = ii;
                    }
                }
            }
            if (thisGID.timerA.countCurrent) { }
            else {
                thisGID.timerA.countCurrent = level[0];
            }
            return [level, thisGID.timerA.countCurrent, ii];
        }
        console.log("PLS select one field of CM");
        return false;
    }
    //显示云图的时间历程的帧
    showStep(step) {

        if (thisGID.nameOfCloudmap) {
            thisGID.timerA.countCurrent = step;
            thisGID.doTrans();
            thisGID.updatePressures(thisGID.data.res[thisGID.nameOfCloudmap[0]][thisGID.timerA.countCurrent][thisGID.nameOfCloudmap[1]]);
            thisGID.updateColors();
        }
        if (thisGID.flageClipping) {
            thisGID.repairPoly()
        }
        this.centerForTrains();
        this.parent.render();
    }
    //显示云图，value是字符串，exp:unodb0.sxx
    show(value = false) {
        if (value) {
            let temp = value.split(".");
            thisGID.nameOfCloudmap = temp;
        }
        if (thisGID.nameOfCloudmap) {
            thisGID.doTrans();
            if (this.setting.globalCMMM) {
                let MM = this.getCMMM([thisGID.nameOfCloudmap[0], thisGID.nameOfCloudmap[1]]);
                this.parent.LUT.setMaxMinOfGlobal(MM);
                this.parent.LUT.update_text(" ");
            }
            thisGID.updatePressures(thisGID.data.res[thisGID.nameOfCloudmap[0]][thisGID.timerA.countCurrent][thisGID.nameOfCloudmap[1]]);
            thisGID.updateColors();
        }
        if (thisGID.flageClipping) {
            thisGID.repairPoly()
        }
        this.centerForTrains();
        this.parent.render();
    }
    stop() {
        clearInterval(thisGID.timerA.timer);
        thisGID.timerA.visible = false;
        thisGID.timerA.index = 0;
    }
    play(s = false, ComponentNames = false) {
        if (s) thisGID.timerA.sss = s;
        if (thisGID.nameOfCloudmap !== false) {
            let list = Object.keys(thisGID.data.res[thisGID.nameOfCloudmap[0]]).length;
            if (list > 1) {
                thisGID.timerA.count = list;

                thisGID.timerA.visible = true;
                thisGID.timerIndexList = this.getRESTimerList();
                if (!thisGID.timerIndex) {
                    thisGID.timerIndex = 0;
                    thisGID.timerA.countCurrent = thisGID.timerIndexList[thisGID.timerIndex];
                }

                thisGID.timerA.timer = setInterval(function () {

                    if (thisGID.setting.trans) {
                        thisGID.doTrans(ComponentNames);
                        // if (ComponentNames == false) {
                        //     ComponentNames = thisGID.getDefaultTrans();
                        // }
                        // if (ComponentNames) thisGID.doTrans(ComponentNames);
                    }

                    thisGID.updatePressures(thisGID.data.res[thisGID.nameOfCloudmap[0]][thisGID.timerA.countCurrent][thisGID.nameOfCloudmap[1]]);
                    thisGID.updateColors();
                    thisGID.parent.render();
                    if (thisGID.timerA.visible == false) {
                        clearInterval(thisGID.timerA.timer);
                    }

                    if (thisGID.timerIndex >= thisGID.timerIndexList.length - 1) {
                        thisGID.timerIndex = 0;
                        thisGID.timerA.countCurrent = thisGID.timerIndexList[thisGID.timerIndex];
                    }
                    else {
                        thisGID.timerIndex++;
                        thisGID.timerA.countCurrent = thisGID.timerIndexList[thisGID.timerIndex];
                    }
                    // console.log(thisGID.timerA.countCurrent);
                }, s * 1000);
            }
        }
        else {
            console.log("plases select one value of 参数")
            return { type: false, msg: "none value for select" }
        }
    }
    getRESTimerList() {
        let indexs = [];
        for (let i in thisGID.data.res[thisGID.nameOfCloudmap[0]]) {
            if (i != 'type')
                indexs.push(i);
        }
        return indexs;
    }
    setCloudMap(value) {

    }
    getDefaultTrans() {
        return this.getUVW();
        let ComponentNames = [];
        let RES = thisGID.data.res[thisGID.nameOfCloudmap[0]][thisGID.timerA.countCurrent];
        for (let i in RES) {
            if (i != "M" || i != "norm") {
                ComponentNames.push(i);
            }
        }
        return ComponentNames;
    }
    getUVW() {
        let ComponentNames = [];
        let RES = thisGID.data.res;
        for (let i in RES) {
            let field = RES[i];
            if (i == "U") {
                for (let j in field) {
                    if (j != 'type') {
                        let perTimeField = field[j];
                        let names = [];
                        for (let k in perTimeField) {
                            if (k !== "M") {
                                names.push(k);
                            }
                        }
                        return ['U', names];
                    }
                }
            }
            else if (field.type == 'Vector') {
                for (let j in field) {
                    if (j != 'type') {
                        let perTimeField = field[j];
                        if (this.data.source.dimension == 2) {
                            if (typeof perTimeField.U1 != 'undefined' && typeof perTimeField.U2 != 'undefined') {
                                return [i, ['U1', 'U2']];
                            }
                            else if (typeof perTimeField.u != 'undefined' && typeof perTimeField.v != 'undefined') {
                                return [i, ['u', 'v']];
                            }
                        }
                        else {

                            if (typeof perTimeField.U1 != 'undefined' && typeof perTimeField.U2 != 'undefined' && typeof perTimeField.U3 != 'undefined') {
                                return [i, ['U1', 'U2', 'U3']];
                            }
                            else if (typeof perTimeField.u != 'undefined' && typeof perTimeField.v != 'undefined' && typeof perTimeField.w != 'undefined') {
                                return [i, ['u', 'v', 'w']];
                            }
                        }
                    }
                }
            }
        }
        return false;
    }
    doTrans(ComponentNames = false) {
        thisGID.confirmTrans();
        if (ComponentNames == false) {
            ComponentNames = this.getDefaultTrans();
        }
        if (ComponentNames) {
            let points = [];
            // let ComponentNames = thisGID.data.res[thisGID.nameOfCloudmap[0]].ComponentNames;
            for (let meshName in this.data.forDraw.trangle) {
                let per = this.data.forDraw.trangle[meshName];
                for (let i in per) {
                    let perOne = per[i];
                    if (perOne.enable) {
                        for (let j of perOne.points) {
                            let x1 = 0, x2 = 0, x3 = 0, y1 = 0, y2 = 0, y3 = 0, z1 = 0, z2 = 0, z3 = 0;
                            if (thisGID.setting.trans) {
                                x1 = thisGID.data.res[ComponentNames[0]][thisGID.timerA.countCurrent][ComponentNames[1][0]][j] * thisGID.setting.transValue;
                                y1 = thisGID.data.res[ComponentNames[0]][thisGID.timerA.countCurrent][ComponentNames[1][1]][j] * thisGID.setting.transValue;
                                if (this.data.source.dimension != 2)
                                    z1 = thisGID.data.res[ComponentNames[0]][thisGID.timerA.countCurrent][ComponentNames[1][2]][j] * thisGID.setting.transValue;

                            }
                            // let perPoint=perOne.points[j];
                            points.push(thisGID.data.source.mesh[meshName].nodes[j][0] + x1);
                            points.push(thisGID.data.source.mesh[meshName].nodes[j][1] + y1);
                            points.push(thisGID.data.source.mesh[meshName].nodes[j][2] + z1);
                        }
                    }
                }
            }
            const vertices = new Float32Array(points);
            thisGID.geometry.allTriangle.setAttribute('position', new BufferAttribute(vertices, 3));

            // if (thisGID.setting.matrix)
            //     thisGID.geometry.allTriangle.applyMatrix4(thisGID.setting.matrix);

            if (Object.keys(this.data.forDraw.lines).length > 0) {
                thisGID.doLinesOfMesh(ComponentNames);
            }
            if (this.setting.frameline) {
                if (thisGID.originFrame) {
                    thisGID.showOriginFrame(true);
                }
                else {
                    thisGID.showOriginFrame(false);
                }
                thisGID.doTransLines(ComponentNames);
            }
        }
    }
    doLinesOfMesh(ComponentNames) {
        thisGID.confirmTrans();
        if (ComponentNames == false) {
            ComponentNames = this.getDefaultTrans();
        }
        if (ComponentNames) {
            if (Object.keys(this.data.forDraw.lines).length > 0) {
                thisGID.worldBox.remove(thisGID.mesh.linesOfMesh);
                thisGID.geometry.linesOfMesh = {};
                thisGID.mesh.linesOfMesh = new Group();
                thisGID.mesh.linesOfMesh.name = "mesh Lines";

                thisGID.worldBox.add(thisGID.mesh.linesOfMesh);
                for (let Mi in this.data.forDraw.lines) {
                    let perMeshLines = this.data.forDraw.lines[Mi];
                    let pointsOfLines = [];
                    let colorsOfLines = [];
                    let pressuresOfLines = [];
                    for (let Li in perMeshLines) {
                        let perLine = perMeshLines[Li].points;
                        for (let Pi of perLine) {
                            let x1 = 0, x2 = 0, x3 = 0, y1 = 0, y2 = 0, y3 = 0, z1 = 0, z2 = 0, z3 = 0;
                            if (thisGID.setting.trans) {
                                // x1 = thisGID.data.res[thisGID.nameOfCloudmap[0]][thisGID.timerA.countCurrent][ComponentNames[0]][thisGID.timerA.countCurrent][j] * thisGID.setting.transValue;
                                // y1 = thisGID.data.res[thisGID.nameOfCloudmap[0]][thisGID.timerA.countCurrent][ComponentNames[1]][thisGID.timerA.countCurrent][j] * thisGID.setting.transValue;
                                // z1 = thisGID.data.res[thisGID.nameOfCloudmap[0]][thisGID.timerA.countCurrent][ComponentNames[2]][thisGID.timerA.countCurrent][j] * thisGID.setting.transValue;

                                x1 = thisGID.data.res[ComponentNames[0]][thisGID.timerA.countCurrent][ComponentNames[1][0]][Pi] * thisGID.setting.transValue;
                                y1 = thisGID.data.res[ComponentNames[0]][thisGID.timerA.countCurrent][ComponentNames[1][1]][Pi] * thisGID.setting.transValue;
                                if (this.data.source.dimension != 2)
                                    z1 = thisGID.data.res[ComponentNames[0]][thisGID.timerA.countCurrent][ComponentNames[1][2]][Pi] * thisGID.setting.transValue;

                            }

                            pointsOfLines.push(thisGID.data.source.mesh[Mi].nodes[Pi][0] + x1);
                            pointsOfLines.push(thisGID.data.source.mesh[Mi].nodes[Pi][1] + y1);
                            pointsOfLines.push(thisGID.data.source.mesh[Mi].nodes[Pi][2] + z1);
                            pressuresOfLines.push(0);
                            colorsOfLines.push(1, 1, 1);
                        }
                    }
                    thisGID.geometry.linesOfMesh[Mi] = new BufferGeometry();
                    const vertices = new Float32Array(pointsOfLines)
                    thisGID.geometry.linesOfMesh[Mi].setAttribute('position', new BufferAttribute(vertices, 3));
                    thisGID.geometry.linesOfMesh[Mi].setAttribute('color', new Float32BufferAttribute(new Float32Array(colorsOfLines), 3));
                    thisGID.geometry.linesOfMesh[Mi].setAttribute('pressure', new Float32BufferAttribute(new Float32Array(pressuresOfLines), 1));

                    let L2D = new LineSegments(thisGID.geometry.linesOfMesh[Mi], thisGID.material.lineOfPressure);
                    thisGID.mesh.linesOfMesh.add(L2D);
                }
                thisGID.mesh.linesOfMesh.applyMatrix4(thisGID.mesh.allTriangle.matrix);
            }
        }
    }
    doTransLines(ComponentNames) {
        thisGID.confirmTrans();
        let points = [];
        // let ComponentNames = thisGID.data.res[thisGID.nameOfCloudmap[0]].ComponentNames;
        // if (thisGID.setting.transCM) {
        //     ComponentNames = thisGID.setting.transCM;
        // }
        // else {
        //     ComponentNames = thisGID.getTransformName()[0];
        // }

        // ComponentNames=thisGID.getInterTransCMName(ComponentNames);
        if (ComponentNames == false) {
            ComponentNames = this.getDefaultTrans();
        }
        if (ComponentNames) {
            for (let meshName in this.data.forDraw.pointOfSurfaceLinesIndex) {
                let per = this.data.forDraw.pointOfSurfaceLinesIndex[meshName];
                for (let i in per) {
                    let perOne = per[i];
                    if (perOne.enable) {
                        let x1 = 0, x2 = 0, x3 = 0, y1 = 0, y2 = 0, y3 = 0, z1 = 0, z2 = 0, z3 = 0;
                        if (thisGID.setting.trans) {
                            x1 = thisGID.data.res[ComponentNames[0]][thisGID.timerA.countCurrent][ComponentNames[1][0]][perOne.points[0]] * thisGID.setting.transValue;
                            y1 = thisGID.data.res[ComponentNames[0]][thisGID.timerA.countCurrent][ComponentNames[1][1]][perOne.points[0]] * thisGID.setting.transValue;
                            if (this.data.source.dimension != 2)
                                z1 = thisGID.data.res[ComponentNames[0]][thisGID.timerA.countCurrent][ComponentNames[1][2]][perOne.points[0]] * thisGID.setting.transValue;

                            x2 = thisGID.data.res[ComponentNames[0]][thisGID.timerA.countCurrent][ComponentNames[1][0]][perOne.points[1]] * thisGID.setting.transValue;
                            y2 = thisGID.data.res[ComponentNames[0]][thisGID.timerA.countCurrent][ComponentNames[1][1]][perOne.points[1]] * thisGID.setting.transValue;
                            if (this.data.source.dimension != 2)
                                z2 = thisGID.data.res[ComponentNames[0]][thisGID.timerA.countCurrent][ComponentNames[1][2]][perOne.points[1]] * thisGID.setting.transValue;
                        }
                        points.push(thisGID.data.source.mesh[meshName].nodes[perOne.points[0]][0] + x1);
                        points.push(thisGID.data.source.mesh[meshName].nodes[perOne.points[0]][1] + y1);
                        points.push(thisGID.data.source.mesh[meshName].nodes[perOne.points[0]][2] + z1);

                        points.push(thisGID.data.source.mesh[meshName].nodes[perOne.points[1]][0] + x2);
                        points.push(thisGID.data.source.mesh[meshName].nodes[perOne.points[1]][1] + y2);
                        points.push(thisGID.data.source.mesh[meshName].nodes[perOne.points[1]][2] + z2);


                    }
                }
            }

            thisGID.worldBox.remove(thisGID.mesh.allTriangleLines);

            const vertices = new Float32Array(points);
            thisGID.geometry.line = new BufferGeometry();
            thisGID.geometry.line.setAttribute('position', new BufferAttribute(vertices, 3));
            thisGID.mesh.allTriangleLines = new LineSegments(thisGID.geometry.line, thisGID.material.line);
            thisGID.mesh.allTriangleLines.applyMatrix4(thisGID.mesh.allTriangle.matrix);
            thisGID.worldBox.add(thisGID.mesh.allTriangleLines);
        }
    }
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //覆写 计算三角形与lines
    //计算六面体的全部，三角形，只有不重复的三角形*2（四边形）
    calculateTrangle() {
        for (let i in thisGID.data.source.mesh) {
            let perMesh = thisGID.data.source.mesh[i];
            if (perMesh.meshType == "Hexahedra Nnode 8") {
                thisGID.Hexahedra_N_8(perMesh)
            }
            else if (perMesh.meshType == "Quadrilateral Nnode 4") {
                thisGID.Quadrilateral_N_4(perMesh);
            }
            else if (perMesh.meshType == "Quadrilateral Nnode 9") {
                thisGID.Quadrilateral_N_4(perMesh);
            }
            else if (perMesh.meshType == "Triangle Nnode 3") {
                thisGID.Triangle_N_3(perMesh);
            }
        }

    }
    Triangle_N_3(perMesh) {
        let elements = perMesh.elements;
        let meshName = perMesh.meshName;
        if (typeof this.data.forDraw.trangle[meshName] == "undefined") {
            this.data.forDraw.trangle[meshName] = {};
        }
        for (let i in elements.elements) {
            let perOne = elements.elements[i];
            let points = perOne;//.points;
            let abc = 1;
            let top1 = [points[0], points[1], points[2]];

            if (typeof this.data.forDraw.trangle[meshName][top1.join("_")] == "undefined") {
                this.data.forDraw.trangle[meshName][top1.join("_")] = {
                    meshName: meshName,
                    points: top1,
                    uv: [
                        0, 0,
                        1, 0,
                        1, 1,
                    ],
                    enable: true,
                    index: 0
                }
            }
            else {
                this.data.forDraw.trangle[meshName][top1.join("_")].enable = false;
            }

        }
        this.data.source.mesh[meshName].shell_index = this.data.forDraw.trangle[meshName];
        this.data.source.mesh[meshName].shell_points = {};
        for (let i in this.data.source.mesh[meshName].shell_index) {
            let perOne = this.data.source.mesh[meshName].shell_index[i];
            if (perOne.enable) {
                let points = perOne.points;
                this.data.source.mesh[meshName].shell_points[points[0]] = points[0];
                this.data.source.mesh[meshName].shell_points[points[1]] = points[1];
                this.data.source.mesh[meshName].shell_points[points[2]] = points[2];
            }
        }
    }
    Hexahedra_N_8(perMesh) {
        let elements = perMesh.elements;
        let meshName = perMesh.meshName;
        let cubeFace = {};
        if (typeof this.data.forDraw.trangle[meshName] == "undefined") {
            this.data.forDraw.trangle[meshName] = {};
        }
        for (let i in elements.elements) {
            // let perOne = elements.elements[i];
            // let points = perOne.points;
            let points = elements.elements[i];
            let e1 = points[0];//element 的点序列，矩形体
            let e2 = points[1];
            let e3 = points[2];
            let e4 = points[3];
            let e5 = points[4];
            let e6 = points[5];
            let e7 = points[6];
            let e8 = points[7];

            let box = [
                [e5, e1, e2, e6],//f
                [e8, e4, e3, e7],//b
                [e6, e2, e3, e7],//U
                [e5, e1, e4, e8],//D
                [e8, e5, e6, e7],//L
                [e4, e1, e2, e3],//R
            ];

            for (let side of box) {
                let per = JSON.parse(JSON.stringify(side)).sort().join("-");
                if (typeof cubeFace[per] == 'undefined') {
                    cubeFace[per] = {
                        points: side,
                        enable: true,
                    }
                }
                else {
                    cubeFace[per].enable = false;
                }
            }
        }
        if (typeof this.data.forDraw.plane_index == "undefined") {//无序列的 点索引
            this.data.forDraw.plane_index = {};//new Array();
        }
        this.data.forDraw.plane_index[meshName] = cubeFace;
        let pointIndexObject = this.data.forDraw.trangle[meshName];
        this.data.source.mesh[meshName].shell_points = {};
        for (let i in cubeFace) {
            let perFace = cubeFace[i];
            let name = i;
            if (perFace.enable) {
                let points = perFace.points;
                pointIndexObject[name] = {
                    points: [points[0], points[1], points[3], points[1], points[2], points[3]],
                    uv: [0, 0,
                        1, 0,
                        1, 1,

                        0, 0,
                        1, 0,
                        1, 1,
                    ],
                    enable: true,
                };
                this.data.source.mesh[meshName].shell_points[points[0]] = points[0];
                this.data.source.mesh[meshName].shell_points[points[1]] = points[1];
                this.data.source.mesh[meshName].shell_points[points[2]] = points[2];
                this.data.source.mesh[meshName].shell_points[points[3]] = points[3];
            }
        }
        this.data.source.mesh[meshName].shell_index = pointIndexObject;
    }
    Quadrilateral_N_4(perMesh) {
        let elements = perMesh.elements;
        let meshName = perMesh.meshName;
        if (typeof this.data.forDraw.trangle[meshName] == "undefined") {
            this.data.forDraw.trangle[meshName] = {};
        }
        for (let i in elements.elements) {
            let perOne = elements.elements[i];
            let points = perOne;//.points;
            let abc = 1;
            let top1 = [points[0], points[1], points[3]];
            let top2 = [points[1], points[2], points[3]];

            if (typeof this.data.forDraw.trangle[meshName][top1.join("_")] == "undefined") {
                this.data.forDraw.trangle[meshName][top1.join("_")] = {
                    meshName: meshName,
                    points: top1,
                    uv: [
                        0, 0,
                        1, 0,
                        1, 1,
                    ],
                    enable: true,
                    index: 0
                }
            }
            else {
                this.data.forDraw.trangle[meshName][top1.join("_")].enable = false;
            }
            if (typeof this.data.forDraw.trangle[meshName][top2.join("_")] == "undefined") {
                this.data.forDraw.trangle[meshName][top2.join("_")] = {
                    meshName: meshName,
                    points: top2,
                    uv: [
                        0, 0,
                        1, 0,
                        1, 1
                    ],
                    enable: true,
                    index: 1
                }
            }
            else this.data.forDraw.trangle[meshName][top2.join("_")].enable = false;
        }
        this.data.source.mesh[meshName].shell_index = this.data.forDraw.trangle[meshName];
        this.data.source.mesh[meshName].shell_points = {};
        for (let i in this.data.source.mesh[meshName].shell_index) {
            let perOne = this.data.source.mesh[meshName].shell_index[i];
            if (perOne.enable) {
                let points = perOne.points;
                this.data.source.mesh[meshName].shell_points[points[0]] = points[0];
                this.data.source.mesh[meshName].shell_points[points[1]] = points[1];
                this.data.source.mesh[meshName].shell_points[points[2]] = points[2];
                this.data.source.mesh[meshName].shell_points[points[3]] = points[3];
            }
        }
    }
    //计算所有线段，内外部，全部
    calculateAllLines() {
        thisGID.data.forDraw.pointOfLinesIndex = {};//cube 线段index
        for (let mi in thisGID.data.source.mesh) {
            let meshName = mi;
            thisGID.data.forDraw.pointOfLinesIndex[meshName] = [];
            this.data.source.mesh[mi].line_index = [];//add 20211115,同步abaqus的data
            let elements = thisGID.data.source.mesh[mi].elements;
            let shell = thisGID.data.source.mesh[mi].shell_index;//add 20211115,同步abaqus的data
            if (elements.elementType == "Linear") {
                thisGID.data.forDraw.lines[meshName] = elements.elements;
                continue;
            }
            // for (let i in elements.elements) {//
            for (let i in elements.elements) {
                let abc = [];
                let perOne = elements.elements[i];
                let points = perOne//.points;
                if (elements.meshType == "Hexahedra Nnode 8") {
                    abc = [
                        points[4], points[5], points[5], points[1], points[1], points[0], points[0], points[4],
                        points[4], points[7], points[5], points[6], points[1], points[2], points[0], points[3],
                        points[7], points[6], points[6], points[2], points[2], points[3], points[3], points[7],

                    ];
                }
                else if (elements.meshType == "Quadrilateral Nnode 4" || elements.meshType == "Quadrilateral Nnode 9") {
                    abc = [
                        points[0], points[1], points[1], points[2], points[2], points[3], points[3], points[0],

                    ];
                }
                // else if (elements.elementType == "Linear") {
                //     abc = [
                //         points[0], points[1],

                //     ];
                // }
                thisGID.data.forDraw.pointOfLinesIndex[meshName].push(abc);
                if (elements.elementType != "Linear") {
                    for (let j = 0; j < abc.length; j += 2) {
                        let line1 = [abc[j], abc[j + 1]];
                        this.subCalculateSurfaceLine(line1, mi);
                    }
                }
            }
        }
    }
    subCalculateSurfaceLine(line1, meshName) {
        if (typeof this.data.forDraw.pointOfSurfaceLinesIndex[meshName] == "undefined") {
            this.data.forDraw.pointOfSurfaceLinesIndex[meshName] = {};
        }
        let perStore = JSON.parse(JSON.stringify(line1)).sort().join("-");
        if (typeof this.data.forDraw.trangle[meshName][perStore] == "undefined") {
            this.data.forDraw.pointOfSurfaceLinesIndex[meshName][perStore] = {
                points: line1,
                enable: true,
                index: 0
            }
        }
        else {
            // this.data.forDraw.pointOfSurfaceLinesIndex[meshName][perStore].enable = false;
        }
    }

    //draw 三角形，
    drawTrangleAll() {
        let points = [];
        let pressures = [];
        let colors = [];
        let normals = [];
        let uv = [];
        let cm = [];
        for (let meshName in this.data.forDraw.trangle) {
            let per = this.data.forDraw.trangle[meshName];
            for (let i in per) {
                let perOne = per[i];
                if (perOne.enable) {
                    for (let j of perOne.points) {
                        // let perPoint=perOne.points[j];
                        points.push(thisGID.data.source.mesh[meshName].nodes[j][0]);
                        points.push(thisGID.data.source.mesh[meshName].nodes[j][1]);
                        points.push(thisGID.data.source.mesh[meshName].nodes[j][2]);
                        // pressures.push(1);
                        cm.push(.5, .5, .5);
                        // colors.push(1, 1, 1);
                        colors.push(thisGID.material.rgbCM.r, thisGID.material.rgbCM.g, thisGID.material.rgbCM.b);
                        normals.push(0, 0, 1);
                        this.data.forDraw.pointOfTrangleAll.push(j);//update pressures use
                    }
                    for (let perUV of perOne.uv) {
                        uv.push(perUV);
                    }
                }
            }
        }
        thisGID.geometry.allTriangle = new BufferGeometry();
        const vertices = new Float32Array(points);
        thisGID.geometry.allTriangle.setAttribute('position', new BufferAttribute(vertices, 3));
        thisGID.geometry.allTriangle.setAttribute('color', new Float32BufferAttribute(new Float32Array(colors), 3));
        thisGID.geometry.allTriangle.setAttribute('cm', new Float32BufferAttribute(new Float32Array(cm), 3));
        thisGID.geometry.allTriangle.setAttribute('uv', new Float32BufferAttribute(new Float32Array(uv), 2));
        thisGID.geometry.allTriangle.setAttribute('normal', new Float32BufferAttribute(new Float32Array(normals), 3));
        thisGID.geometry.allTriangle.computeVertexNormals();
        // thisGID.geometry.allTriangle.setAttribute('pressure', new Float32BufferAttribute(new Float32Array(pressures), 1));
        // let material = new     MeshStandardMaterial({ color: 0xff0000, });
        // thisGID.mesh.allTriangle = new Mesh(thisGID.geometry.allTriangle, material);


        // thisGID.mesh.allTriangle = new Mesh(thisGID.geometry.allTriangle, thisGID.material.allTriangle);
        let use = thisGID.setting.cm.use;
        thisGID.mesh.allTriangle = new Mesh(thisGID.geometry.allTriangle, thisGID.setting.cm[use]);
        thisGID.mesh.allTriangle.name = "allTrangels"
        thisGID.worldBox.add(thisGID.mesh.allTriangle);


    }

    drawAllLines() {
        let points = []
        for (let meshName in thisGID.data.forDraw.pointOfSurfaceLinesIndex) {
            let per = thisGID.data.forDraw.pointOfSurfaceLinesIndex[meshName];
            for (let i in per) {
                let perOne = per[i];
                if (perOne.enable) {
                    for (let j in perOne.points) {
                        let perLine = perOne.points[j];
                        if (typeof thisGID.data.source.mesh[meshName].nodes[perLine] == 'undefined') {
                            let abc = 1;
                        }
                        else {
                            // console.log(thisGID.data.source.mesh[meshName].nodes[perLine]);
                            points.push(thisGID.data.source.mesh[meshName].nodes[perLine][0]);
                            points.push(thisGID.data.source.mesh[meshName].nodes[perLine][1]);
                            points.push(thisGID.data.source.mesh[meshName].nodes[perLine][2]);
                        }
                    }
                }
            }
        }

        thisGID.geometry.line = new BufferGeometry();
        const vertices = new Float32Array(points)
        thisGID.geometry.line.setAttribute('position', new BufferAttribute(vertices, 3));
        // thisGID.material.line = new LineBasicMaterial({ color: thisGID.color.lineColor, side: DoubleSide });
        if (typeof thisGID.mesh.allLinesOriginFrame != "undefined") thisGID.worldBox.remove(thisGID.mesh.allLinesOriginFrame);
        thisGID.mesh.allLinesOriginFrame = new LineSegments(thisGID.geometry.line, thisGID.material.line);
        thisGID.mesh.allLinesOriginFrame.name = "allLinesOriginFrame"
        thisGID.worldBox.add(thisGID.mesh.allLinesOriginFrame);
        // thisGID.data.forDraw.pointOfLinesXYZ = points;//所有三角形的xyz，cube*index*3

        if (Object.keys(this.data.forDraw.lines).length > 0) {
            thisGID.geometry.linesOfMesh = {};
            thisGID.mesh.linesOfMesh = new Group();
            thisGID.mesh.linesOfMesh.name = "mesh Lines";
            thisGID.worldBox.add(thisGID.mesh.linesOfMesh);
            for (let Mi in this.data.forDraw.lines) {
                let perMeshLines = this.data.forDraw.lines[Mi];
                let pointsOfLines = [];
                let colorsOfLines = [];
                let pressuresOfLines = [];
                for (let Li in perMeshLines) {
                    let perLine = perMeshLines[Li].points;
                    for (let Pi of perLine) {
                        pointsOfLines.push(thisGID.data.source.mesh[Mi].nodes[Pi][0]);
                        pointsOfLines.push(thisGID.data.source.mesh[Mi].nodes[Pi][1]);
                        pointsOfLines.push(thisGID.data.source.mesh[Mi].nodes[Pi][2]);
                        pressuresOfLines.push(0);
                        colorsOfLines.push(1, 1, 1);
                    }
                }
                thisGID.geometry.linesOfMesh[Mi] = new BufferGeometry();
                const vertices = new Float32Array(pointsOfLines)
                thisGID.geometry.linesOfMesh[Mi].setAttribute('position', new BufferAttribute(vertices, 3));
                thisGID.geometry.linesOfMesh[Mi].setAttribute('color', new Float32BufferAttribute(new Float32Array(colorsOfLines), 3));
                thisGID.geometry.linesOfMesh[Mi].setAttribute('pressure', new Float32BufferAttribute(new Float32Array(pressuresOfLines), 1));

                let L2D = new LineSegments(thisGID.geometry.linesOfMesh[Mi], thisGID.material.lineOfPressure);
                thisGID.mesh.linesOfMesh.add(L2D);
            }
        }
        // if (Object.keys(this.data.forDraw.lines).length > 0) {
        //     thisGID.geometry.linesOf2D = {};
        //     thisGID.mesh.linesOf2D = new Group();
        //     thisGID.mesh.linesOf2D.name = "2D Lines";
        //     thisGID.worldBox.add(thisGID.mesh.linesOf2D);
        //     for (let Mi in this.data.forDraw.lines) {
        //         let perMeshLines = this.data.forDraw.lines[Mi];
        //         let points2D = [];
        //         for (let Li in perMeshLines) {
        //             let perLine = perMeshLines[Li].points;
        //             for (let Pi of perLine) {
        //                 points2D.push(thisGID.data.source.mesh[Mi].nodes[Pi][0]);
        //                 points2D.push(thisGID.data.source.mesh[Mi].nodes[Pi][1]);
        //                 points2D.push(thisGID.data.source.mesh[Mi].nodes[Pi][2]);
        //             }
        //         }
        //         thisGID.geometry.linesOf2D[Mi] = new BufferGeometry();
        //         const vertices = new Float32Array(points2D)
        //         thisGID.geometry.linesOf2D[Mi].setAttribute('position', new BufferAttribute(vertices, 3));
        //         // if (typeof thisGID.mesh.linesOf2D != "undefined")  thisGID.worldBox.remove(thisGID.mesh.linesOf2D);
        //         let L2D = new LineSegments(thisGID.geometry.linesOf2D[Mi], thisGID.material.line);
        //         // let L2D = new LineSegments(thisGID.geometry.linesOf2D[Mi], new LineBasicMaterial({ color: 0xff0000 }));
        //         thisGID.mesh.linesOf2D.add(L2D);
        //     }
        // }
    }
    showOriginFrame(value = true) {
        thisGID.mesh.allLinesOriginFrame.visible = value;
        this.parent.render();
    }
    setEnableOriginFrame(value = true) {
        this.originFrame = value;
        thisGID.mesh.allLinesOriginFrame.visible = value;
        this.parent.render();
    }
    callDraw() {
        thisGID.calculateTrangle();
        thisGID.drawTrangleAll();
        thisGID.calculateAllLines();
        // thisGID.calculateSurfaceLines();
        if (this.flagshow.line)
            thisGID.drawAllLines();
        // thisGID.afterInit();
        thisGID.center(thisGID.mesh.allTriangle);
        thisGID.center(thisGID.mesh.allLinesOriginFrame);
        if (typeof thisGID.mesh.linesOfMesh != 'undefined') {
            this.center(thisGID.mesh.linesOfMesh, thisGID.mesh.allTriangle);
        }
        // if (this.theEndOfDraw.length > 0) {
        //     for (let fun in this.theEndOfDraw)
        //         fun(that);
        // }
        this.parent.CMInitAfter();
        this.parent.render();
    }
    // getPointXYZ(index) {//未完成
    //     let p1, x1 = 0, y1 = 0, z1 = 0;
    //     p1 = new Vector3(x1, y1, z1);
    //     p1.applyMatrix4(this.mesh.allTriangle.matrix);
    //     return p1
    // }
    //update thisGID.geometry.allTriangle pressures
    updatePressures(data) {
        // let pressures = {};
        if (this.setting.cm.use == "default") {
            let pressures = [];
            for (let i in this.data.forDraw.pointOfTrangleAll) {
                let perOne = this.data.forDraw.pointOfTrangleAll[i];
                let pressure = data[perOne];
                pressures.push(pressure);
            }
            thisGID.geometry.allTriangle.setAttribute('pressure', new Float32BufferAttribute(new Float32Array(pressures), 1));


        }
        else {
            let cm = [];
            let MM;
            if (this.setting.globalCMMM) {
                let maxmin = this.getCMMM([thisGID.nameOfCloudmap[0], thisGID.nameOfCloudmap[1]]);
                MM = [maxmin.max, maxmin.min];
            }
            else {
                MM = this.getMaxMin(data);
            }

            for (let i = 0; i < this.data.forDraw.pointOfTrangleAll.length - 1;) {
                let perOne1 = this.data.forDraw.pointOfTrangleAll[i++];
                let perOne2 = this.data.forDraw.pointOfTrangleAll[i++];
                let perOne3 = this.data.forDraw.pointOfTrangleAll[i++];
                let pressures1 = (data[perOne1] - MM[1]) / (MM[0] - MM[1]);
                let pressures2 = (data[perOne2] - MM[1]) / (MM[0] - MM[1]);
                let pressures3 = (data[perOne3] - MM[1]) / (MM[0] - MM[1]);
                cm.push(pressures1, pressures2, pressures3);
                cm.push(pressures1, pressures2, pressures3);
                cm.push(pressures1, pressures2, pressures3);
                // if (perOne1 == 816 || perOne2 == 816 || perOne3 == 816) {
                //     let abc = 1;
                // }
            }
            thisGID.geometry.allTriangle.setAttribute('cm', new Float32BufferAttribute(new Float32Array(cm), 3));

        }
        if (Object.keys(this.data.forDraw.lines).length > 0) {
            for (let Mi in this.data.forDraw.lines) {
                let perMeshLines = this.data.forDraw.lines[Mi];
                let colorsOfLines = [];
                let pressuresOfLines = [];
                for (let Li in perMeshLines) {
                    let perLine = perMeshLines[Li].points;
                    for (let Pi of perLine) {
                        pressuresOfLines.push(data[Pi]);
                    }
                }
                thisGID.geometry.linesOfMesh[Mi].setAttribute('pressure', new Float32BufferAttribute(new Float32Array(pressuresOfLines), 1));
            }
        }
        this.data.pressures.current = data;
        let abc = 1;

        this.data.pressures.current = data;
    }


    //update colors by LUT 
    updateColors() {
        if (this.parent.LUT) {
            if (this.setting.cm.use == "default") {
                let lut = this.parent.LUT.lut;
                let sprite = this.parent.LUT.sprite;
                if (this.setting.globalCMMM == false) {
                    // let MM = this.getMaxMin(data);
                    // let MM = this.getCMMM([thisGID.nameOfCloudmap[0], thisGID.nameOfCloudmap[1]]);
                    // this.parent.LUT.setMaxMinOfGlobal(MM);
                    this.parent.LUT.setMaxMin(this.data.pressures.current);//顺序顶点的pressure值
                }
                let mesh = this.mesh.allTriangle;
                let geometry = mesh.geometry;
                let pressures = geometry.attributes.pressure;
                let colors = geometry.attributes.color;
                for (let i = 0; i < pressures.array.length; i++) {
                    const colorValue = pressures.array[i];
                    const color = lut.getColor(colorValue);
                    if (color === undefined) {
                        //////console.log('Unable to determine color for value:', colorValue);
                    } else {
                        colors.setXYZ(i, color.r, color.g, color.b);
                    }
                }
                colors.needsUpdate = true;
                let map = sprite.material.map;
                if (this.setting.globalCMMM == false)
                    this.parent.LUT.update_text(" ");
                map.needsUpdate = true;
                let abc = 1;


                //////console.log(child);
            } else {
                let lut = this.parent.LUT.lut;
                let sprite = this.parent.LUT.sprite;
                if (this.setting.globalCMMM == false) {
                    // let MM = this.getCMMM([thisGID.nameOfCloudmap[0], thisGID.nameOfCloudmap[1]]);
                    // this.parent.LUT.setMaxMinOfGlobal(MM);
                    this.parent.LUT.setMaxMin(this.data.pressures.current);//顺序顶点的pressure值
                }
                let map = sprite.material.map;
                map.needsUpdate = true;
                if (this.setting.globalCMMM == false)
                    this.parent.LUT.update_text(" ");
            }
            if (Object.keys(this.data.forDraw.lines).length > 0) {
                let lut = this.parent.LUT.lut;
                let sprite = this.parent.LUT.sprite;
                if (this.setting.globalCMMM == false) {
                    // let MM = this.getCMMM([thisGID.nameOfCloudmap[0], thisGID.nameOfCloudmap[1]]);
                    // this.parent.LUT.setMaxMinOfGlobal(MM);
                    this.parent.LUT.setMaxMin(this.data.pressures.current);//顺序顶点的pressure值
                }
                let mesh = this.mesh.allTriangle;
                let geometry = mesh.geometry;
                let pressures = geometry.attributes.pressure;
                let colors = geometry.attributes.color;

                this.mesh.linesOfMesh.traverse(function (child) {
                    if (child.type === "LineSegments") {
                        let geometry = child.geometry;
                        let pressures = geometry.attributes.pressure;
                        let colors = geometry.attributes.color;
                        for (let i = 0; i < pressures.array.length; i++) {
                            const colorValue = pressures.array[i];
                            const color = lut.getColor(colorValue);
                            if (color === undefined) {
                                //////console.log('Unable to determine color for value:', colorValue);
                            } else {
                                colors.setXYZ(i, color.r, color.g, color.b);
                            }
                        }
                        colors.needsUpdate = true;
                        // let map = sprite.material.map;
                        // // this.parent.LUT.update_text(" ");
                        // map.needsUpdate = true;
                        // let abc = 1;
                    }
                })
            }
        }

    }

    ////////////////////////////////////////////////////////////////////////////
    // 以下是inp和msh都需要有的
    ////////////////////////////////////////////////////////////////////////////

    autoFixPointSize() {
        let x = Math.abs(this.box3.max.x - this.box3.min.x);
        let y = Math.abs(this.box3.max.x - this.box3.min.x);
        let z = Math.abs(this.box3.max.x - this.box3.min.x);
        let value = x > y ? x : y;
        value = value > z ? value : z;
        value = value / 120;
        this.setPointSize(value);
    }
    autoFixNsetPointSize() {
        let x = Math.abs(this.box3.max.x - this.box3.min.x);
        let y = Math.abs(this.box3.max.x - this.box3.min.x);
        let z = Math.abs(this.box3.max.x - this.box3.min.x);
        let value = x > y ? x : y;
        value = value > z ? value : z;
        value = value / 50;
        this.setNsetPointSize(value);
    }
    setPointSize(newSize) {
        if (typeof newSize == 'number') {
            this.material.point.size = newSize;
        }
        else {
            console.log("PLS number")
        }
    }

    /////////////////////////////////////////////////////
    isAvailablePoint(instanceName, index) {
        if (!instanceName) {
            return false;
        }
        let nodes = this.data.source.mesh[instanceName].nodes;
        if (typeof nodes[index] !== undefined) {
            return index;
        }
        else {
            return false;
        }
    }
    getOnePointattributes(instanceName, index) {
        index = this.isAvailablePoint(instanceName, index);
        if (index) {
            let nodes = this.data.source.mesh[instanceName].nodes;
            let pressure = this.pointInfo(instanceName, index);
            let inpMatrix = this.mesh.allTriangle.matrix;
            return {
                xyz: nodes[parseInt(index)],
                pressure: pressure,
                inpMatrix: inpMatrix,
                radius: [this.material.point.size, 6, 6],//2824 line
                material: this.material.pointBoxLight,
            }
        }
        return false;
    }
    drawPoints() {
        this.parent.pickupArray = [];
        //外壳，防止穿透
        this.mesh.allTriangle.traverse(function (child) {
            if (child.type == 'Mesh') {
                thisGID.parent.pickupArray.push(child);
            }
        })
        this.autoFixPointSize();
        this.mesh.Points = new Group();
        this.worldBox.add(this.mesh.Points);
        this.mesh.Points.name = "Points";
        this.mesh.Points.applyMatrix4(this.mesh.allTriangle.matrix);
        let instances = this.data.source.mesh;
        this.mesh.flagOfPoints = {};
        for (let instance_i in instances) {
            let perInstance = instances[instance_i];
            let perInstanceGroup = new Group();
            perInstanceGroup.name = instance_i;
            // perInstanceGroup.applyMatrix4(this.mesh.inpGroup.getObjectByName(instance_i).matrix)
            this.mesh.Points.add(perInstanceGroup);
            let perPart = instances[instance_i];//GID web database
            let nodes = perPart.nodes;//GID web database
            let targetPoints = perPart.shell_points;
            for (let element_i in targetPoints) {
                let perElementPoints = targetPoints[element_i];
                if (typeof this.mesh.flagOfPoints[perElementPoints] == 'undefined')
                    if (typeof perElementPoints != 'undefined') {
                        this.mesh.flagOfPoints[perElementPoints] = perElementPoints;
                        let perXYZ = nodes[perElementPoints];
                        // console.log(perElementPoints,perXYZ,element_i)
                        const geometry = new SphereGeometry(this.material.point.size, 6, 6);

                        let mesh = new Mesh(geometry, this.material.pointBoxLight);
                        mesh.position.x = perXYZ[0];
                        mesh.position.y = perXYZ[1];
                        mesh.position.z = perXYZ[2];

                        mesh.name = perElementPoints;
                        mesh.instanceName = instance_i;
                        mesh.isPoint = true;
                        mesh.visible = false;
                        // perInstanceGroup[parseInt(pp_i)]=mesh;
                        perInstanceGroup.add(mesh);
                        this.parent.pickupArray.push(mesh);
                    }
                // mesh.visible = false;
            }
        }
        let Points = this.data.forDraw.lines;
        let pointGroupOfLines = new Group();
        pointGroupOfLines.name = "pointsOfLine"
        this.mesh.Points.add(pointGroupOfLines);
        for (let perGi in Points) {
            let perLinesGroup = Points[perGi];
            let nodes = this.data.source.mesh[perGi].nodes;
            for (let element_i in perLinesGroup) {
                let perElementPoints = perLinesGroup[element_i];
                for (let perPoint of perElementPoints.points) {
                    if (typeof this.mesh.flagOfPoints[perPoint] == 'undefined') {
                        this.mesh.flagOfPoints[perPoint] = perPoint;

                        let perXYZ = nodes[perPoint];
                        const geometry = new SphereGeometry(this.material.point.size, 6, 6);
                        let mesh = new Mesh(geometry, this.material.pointBoxLight);
                        mesh.position.x = perXYZ[0];
                        mesh.position.y = perXYZ[1];
                        mesh.position.z = perXYZ[2];

                        mesh.name = perPoint;
                        // mesh.instanceName = instance_i;
                        mesh.isPoint = true;
                        mesh.visible = false;
                        // perInstanceGroup[parseInt(pp_i)]=mesh;
                        pointGroupOfLines.add(mesh);
                        this.parent.pickupArray.push(mesh);
                    }
                }
                // mesh.visible = false;
            }
        }
    }
    showPoints(show = false, name = false) {
        if (typeof this.mesh.Points == 'object')
            if (name == false) {
                if (typeof show == 'boolean') {
                    this.mesh.Points.visible = show;
                }
                else {
                    this.mesh.Points.visible = !this.mesh.Points.visible;
                }
            }
            else {
                let target = this.mesh.Points.getObjectByName(name);
                if (target) {
                    target.visible = show;
                    // target.visible=!target.visible;
                }
            }
        else
            this.drawPoints();
        this.parent.render();
    }
    showOnePointInfo(index) {

    }

    getPointXYZ(index) {
        // thisGID.updatePressures(thisGID.data.res[thisGID.nameOfCloudmap[0]][thisGID.timerA.countCurrent][thisGID.nameOfCloudmap[1]]);
        let nodes;
        for (let i in this.data.source.nodes) {
            if (this.data.source.nodes[i].length > 0)
                nodes = this.data.source.nodes[i];
        }
        if (typeof nodes[index] != undefined) {
            return nodes[index];
        }
        else
            return false;


    }
    pointInfo(instanceName, index) {
        // thisGID.updatePressures(thisGID.data.res[thisGID.nameOfCloudmap[0]][thisGID.timerA.countCurrent][thisGID.nameOfCloudmap[1]]);
        let res = [];
        if (thisGID.nameOfCloudmap)
            res = thisGID.data.res[thisGID.nameOfCloudmap[0]][thisGID.timerA.countCurrent];

        let pressures = {
            p: {},
            // xyz:{},
            index: index,
        }
        // pressures.xyz=
        for (let pi in res) {
            let perPressure = res[pi];
            pressures.p[pi] = perPressure[index];
        }
        return pressures;
    }
    //////////////////////////////////////////////////////////////////////////////////////
    // 流线
    //pressuresName= {
    //     V1: "w",
    //     V2: false,
    //     V3: false,
    //     norm: "w",
    //     default: "w",
    // },
    getDataOfFlowlines(input = false, color = 0xaaaaaa, vertexColors = false) {
        if (input == false) {
            console.log("PLS check the value of input!")
            return false;
        }
        let value = {
            parent: this,
            flowSetting: {
                Dimension: 2,
                lineColor: {
                    color: color,
                    vertexColors: vertexColors, input
                },
                meshType: "GID",
                // type: "C3",
                matrix: [thisGID.mesh.allTriangle.matrix],
                PMax: thisGID.parent.LUT.max,
                PMin: thisGID.parent.LUT.min,
                PStep: 20,
                perStep: (thisGID.parent.LUT.max - thisGID.parent.LUT.min) / 20,
            },
            meshs: {},
        }
        value.flowSetting.pressuresName = input.pressuresName;
        value.flowSetting.input = input.input;
        value.flowSetting.step = input.step;

        let Res = thisGID.data.res["unode0"];
        for (let i in thisGID.data.source.mesh) {
            let perPart = thisGID.data.source.mesh[i];
            let source = {
                nodes: false,
                quad: [],
                res: {},
                matrix: [],
                nodesLength: perPart.nodesLength,
            };
            if (perPart.elements.elementType == 'Quadrilateral') {
                source.quad.push(perPart.elements.elements);
                source.nodes = perPart.nodes;
                // source.nodesLength = source.nodes.length;
                source.res = Res;
                value.meshs[i] = {
                    source: source
                }
                if (source.length > perPart.nodesLength) {
                    source.nodes.splice(source.nodesLength, source.nodes.length - source.nodesLength);
                }
            }
        }
        return value;
    }

    ///////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // output 
    getInstanceAll() {
        let value = {};
        return value;
    }
    getPartAll() {
        let value = {};
        return value;
    }
    getMaterialAll() {
        return this.data.source.materials;
    }
    getSectionForInstance() {
        let value = {};
        return value;
    }
    getSectionForPart() {
        let value = {};
        return value;
    }
    getStepAll() {
        let value = {};
        return value;
    }
    getLoads() {
        let value = {};

        return value;

        // return false;
    }
    getBoundary() {
        let flag_New = false;
        let value = {};

        return value;

        // return false;
    }
    getFluidBoundaryDirction() {

        return [];
    }
    getSurface() {
        return {};

    }
    getNset() {
        return {};

    }
    getElset() {
        return {};

    }

    /////////////////////////////////
    getMeshList() {
        let meshs = [];
        for (let i in this.data.source.mesh) {
            meshs.push(i);
        }
        return meshs;
    }
    outputElementOfMesh(mesh) {
        // let csv=['index','x','y','z','\r'];

        let csv = 'index,';
        for (let i = 1; i <= this.data.source.mesh[mesh].elements.countOfNode; i++) {
            csv += 'node ' + i + ',';
        }
        csv += '\r';
        for (let i in this.data.source.mesh[mesh].elements.elements) {
            let perNode = this.data.source.mesh[mesh].elements.elements[i];
            csv += i + ',';
            for (let perXYZ of perNode) {
                // csv.push(perXYZ);
                csv += perXYZ + ',';
            }
            csv += "\r";
        }
        this.parent.downLoad(csv, mesh + '_element.csv');
    }
    outputNodesOfMesh(mesh) {
        // let csv=['index','x','y','z','\r'];
        let csv = 'index,x,y,z,\r';
        for (let i in this.data.source.mesh[mesh].nodes) {
            let perNode = this.data.source.mesh[mesh].nodes[i];
            csv += i + ',';
            for (let perXYZ of perNode) {
                // csv.push(perXYZ);
                csv += perXYZ + ',';
            }
            csv += "\r";
        }
        this.parent.downLoad(csv, mesh + '_nodes.csv');
    }
    getMeshType() {
        return this.meshType;
    }


}

export { cloudAnsysCM }
