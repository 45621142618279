// version v.1.0
//功能：1、gid的msh与res显示
//      2、云图的变形与时间历程
import {
    BufferGeometry,
    Mesh,
    Vector3,
    Color,
    MeshStandardMaterial,
    MeshBasicMaterial,
    LineBasicMaterial,
    MeshPhongMaterial,
    Line,
    Line3,
    Plane,
    Group,
    BufferAttribute,
    DoubleSide,
    LineSegments,
    Ray,
    Float32BufferAttribute,
    LineLoop,
    FileLoader,
    ObjectLoader,
    ArrowHelper,
    Box3
} from 'three';

import { cloudAnsysCM } from "./cloudmsh"
import { flowLineRoot } from "./flowlineroot"

let that;
class gidFlowRoot extends cloudAnsysCM {
    // constructor(parent, fun = false) {
    //     super(parent);
    // }
    afterInit() {
        super.afterInit();
        that = this;

        // that = this;
        // console.log(this.zipFile)
        let value = {
            parent: this,       
            flowSetting: {
                Dimension:2,
                lineColor: {
                    color: 0xaaaaaa,
                    vertexColors:true,
                },
                meshType:"GID",
                pressuresName: {
                    V1: "w",
                    V2: false,
                    V3: false,
                    norm: "w",
                    default: "w",
                },
                // type: "C3",
                matrix: [this.mesh.allTriangle.matrix],
                PMax: this.parent.LUT.max,
                PMin: this.parent.LUT.min,
                PStep: 20,
                perStep: (this.parent.LUT.max - this.parent.LUT.min) / 20,
            },
            meshs: {

            }


        }
        let Res = this.data.res["unode0"];
        for (let i in this.data.source.mesh) {
            let perPart = this.data.source.mesh[i];
            let source = {
                nodes: false,
                quad: [],
                res: {},
                matrix: [],
                nodesLength: 0,
            };
            if (perPart.elements.elementType == 'Quadrilateral') {
                source.quad.push(perPart.elements.elements);
                source.nodes = perPart.nodes;
                source.nodesLength = source.nodes.length;
                source.res=Res;
                // for (let Ri in Res) {//u,v,w
                //     for (let Time_i in Res[Ri]) {//1,2,
                //         let perPressureOfTime = Res[Ri][Time_i];
                //         if (typeof source.res[Time_i] == 'undefined') {
                //             source.res[Time_i] = {};
                //         }
                //         if (typeof source.res[Time_i][Ri] == 'undefined') {
                //             source.res[Time_i][Ri] = perPressureOfTime;
                //         }
                //     }
                // }
                value.meshs[i] = {
                    source: source
                }
            }

          
        }
        console.log(value)

        this.flowLines = new flowLineRoot(value);
        let abc = 1;
    }





}

export { gidFlowRoot }