<template>
  <div style="position: relative">
    <!-- <div id="loading_1" name="loading_1"  class=""></div> -->
    <el-button class="pointer flow-cal">在线计算</el-button>
    <div class="box mB10 win">
      <!-- <el-button @click="moduleChange">点击切换{{ i }}</el-button> -->
      <div class="fs18 noFlex" style="width: 86px">仿真场</div>
      <el-select v-model="valueNum" placeholder="请选择" @change="getValue">
        <el-option v-for="item in list" :key="item.id" :label="item.name" :value="item.id"> </el-option>
      </el-select>
    </div>

    <div id="container_10" style="top: 0px; left: 0px; width: 99.8%; height: 99.8%; position: absolute"></div>
    <div id="lut_10" style="top: 35px; left: 5px; width: 300px; height: 300px; position: absolute"></div>
    <div id="gui_10" style="top: 35px; right: 5px; position: absolute"></div>
    <div id="helper_10" style="bottom: 50px; left: 5px; width: 128px; height: 128px; position: absolute"></div>
  </div>
</template>

<script>
import { showMesh } from "../../jsm/threemain/showmesh"; // ./jsm/threemain/showmesh   tomcloudmap

export default {
  name: "show",
  props: {},
  components: {},
  data() {
    return {
      i: 1,
      valueNum: "U.U",
      // 'U.U', 'U.V', 'U.W', 'U.M', 'S.S11', 'S.S22', 'S.S33', 'S.S23', 'S.S13', 'S.S12'
      list: [
        { id: "U.U", name: "U.U(位移)" },
        { id: "U.V", name: "U.V(位移-X方向)" },
        { id: "U.W", name: "U.W(位移-Y方向)" },
        { id: "U.M", name: "U.M(位移-Z方向)" },
        { id: "S.S11", name: "S.S11(正应力-X方向)" },
        { id: "S.S22", name: "S.S22(正应力-Y方向)" },
        { id: "S.S33", name: "S.S33(正应力-Z方向)" },
        { id: "S.S23", name: "S.S23(剪应力-YZ方向)" },
        { id: "S.S13", name: "S.S13(剪应力-XZ方向)" },
        { id: "S.S12", name: "S.S12(剪应力-XY方向)" }
      ]
    };
  },
  computed: {},
  created() {},
  mounted() {
    // this.i = this.$route.query.i || 1;
    // console.log("mounted--i", this.i);
    // this.moduleVisbile(`./zip/${this.i}.zip`);
    this.moduleVisbile();
  },
  watch: {},
  methods: {
    getValue() {
      console.log("云图", this.valueNum);
      this.main.CM.show(this.valueNum);
      this.main.render();
    },
    // moduleChange() {
    //   this.i++;
    //   console.log("i", this.i);
    //   if (this.i >= 6) {
    //     this.i = 1;
    //   }
    //   let url = `./zip/${this.i}.zip`;
    //   console.log("url", url);
    //   this.moduleVisbile(url);
    // },
    moduleVisbile(url) {
      this.loadingPage = this.$loading({
        lock: true,
        text: "正在读取...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.2)"
        // target: document.querySelector(".tuBg")   //loading需要覆盖的DOM节点，默认为body
      });

      this.main = {};
      let thisOfVUEentry = this;
      let cmment = function () {
        thisOfVUEentry.main.worldBox.rotateY(Math.PI);
        thisOfVUEentry.main.worldBox.rotateX((-90 / 180) * Math.PI);
        // thisOfVUEentry.main.render();
        thisOfVUEentry.main.CM.show(thisOfVUEentry.main.CM.getPressureSName()[0]);

        thisOfVUEentry.main.LUT.setTextColor(0xffffff);
        thisOfVUEentry.main.perpCamera.position.x = 1.4716178305697283;
        thisOfVUEentry.main.perpCamera.position.y = 35;
        thisOfVUEentry.main.perpCamera.position.z = 78;
        thisOfVUEentry.main.render();
        setTimeout(function () {
          thisOfVUEentry.main.perpCamera.position.y = 35;
          thisOfVUEentry.main.perpCamera.position.z = 78;
          thisOfVUEentry.main.render();
        }, 50);
      };
      let value = {
        DIV: {
          container: "container_10",
          helper: "helper_10",
          lut: "lut_10",
          gui: "gui_10"
        }
        // log: abc,
      };

      let that = this;
      let mesh = {
        // zip: "./zip/1.zip",
        // zip: url,
        zip: "./zip/zh.zip",
        typeCM: true,
        type: "gid", // inp
        // type: "abaqus", // inp
        ziped: true,
        fun: {
          // menu: 2,
          cmment: cmment,
          addon: function () {
            console.log(that.main.CM.getPressureSName());
            // that.list = that.main.CM.getPressureSName();
            that.valueNum = that.list[0].id;
            that.getValue();

            // let abc=document.getElementById("loading_1")
            // abc.style.display="none";

            that.loadingPage.close();
          }
        }
      };

      this.main = new showMesh(value);
      this.main.import(mesh);
      window.main = this.main;
    }
  }
};
</script>

<style scoped lang="scss">
.win {
  position: absolute;
  right: 30px;
  z-index: 1;
  top: 150px !important;
}

#lut_10 {
  position: absolute;
  // z-index: 99;
  top: 120px !important;
}

::v-deep .el-input {
  height: 32px;
}

#loading_1 {
  position: absolute;
  left: 50%;
  top: 40%;
  margin-left: -30px;
  z-index: 3;
  display: block;
  width: 60px;
  height: 60px;
  background: url("../../../../assets/images/gif.gif");
  background-size: 100% 100%;
}

.flow-cal {
  position: absolute;
  right: 30px;
  top: 30px;
}
</style>
